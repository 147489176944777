import React from "react";
import { useFieldArray, useFormContext, useFormState } from "react-hook-form";
// import InputFieldPhone from "../../../registration/Inputs/InputFieldPhone";
import { ErrorMessage } from "@hookform/error-message";
import { Alert, Stack } from "@mui/material";
import { useSelector } from "react-redux";
// import { SelectField } from "react-admin";
// import SelectField from "../../../registration/Inputs/SelectField";
import TextInputPhone from "../components/inputComponents/TextInputPhone";
import InputSelectField from "../../common/InputComponents/InputSelectField";

const InputFieldArray = (props) => {
  const { register } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "applicant.mobile", // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });

  //   const viewOnlyFlag = useSelector((state) => state.formSubmit.viewOnlyFlag);
  //   below content adding for now
  //
  const selectData = [
    {
      value: "Home",
      label: "Home",
    },
    {
      value: "Work",
      label: "Work",
    },
    {
      value: "Mobile",
      label: "Mobile",
    },
  ];
  const viewOnlyFlag = false;
  if (viewOnlyFlag)
    return (
      <>
        {fields.map((field, index) => (
          <div key={field.id} className="row align-items-center">
            <div className="col-md-12 mo-i-box">
              {/* <InputFieldPhone
                label={`Mobile Number`}
                register={register(
                  `applicant.mobile.${index}.number`
                )}
              /> */}
              <TextInputPhone
                source={`applicant.mobile.${index}.number`}
                label={`Mobile Number`}
                isCountryCode={`applicant.mobile.${index}.country_code`}
                labelClass={"lable-title"}
                additionalInputClass={"cus-control"}
                widthClass={"w-100"}
                requiredClass={{
                  fontSize: "70%",
                }}
                onChangeCallback={() => {
                  props?.handleAPIDebounced();
                }}
              />
            </div>
          </div>
        ))}
      </>
    );

  return (
    <>
      {fields.map((field, index) => (
        <div key={field.id} className="row align-items-center">
          {!props?.showNumberType && (
            <div className="col-lg-8 col-md-8 col-sm-6 mo-i-box">
              {/* <InputFieldPhone
                label={`Mobile Number`}
                register={register(
                  `applicant.mobile.${index}.number`
                )}
              /> */}
              <TextInputPhone
                source={`applicant.mobile.${index}.number`}
                label="Mobile Number"
                isCountryCode={`applicant.mobile.${index}.country_code`}
                labelClass={"lable-title"}
                additionalInputClass={"cus-control"}
                widthClass={"w-100"}
                requiredClass={{
                  fontSize: "70%",
                }}
                onChangeCallback={() => {
                  props?.handleAPIDebounced();
                }}
              />

              {/* {errors && errors[index] && errors[index].number} */}
            </div>
          )}
          {props?.showNumberType && (
            <div className="col-lg-8 col-md-8 col-sm-6 mo-i-box">
              {/* <SelectField
                notRequired={true}
                options={[
                  {
                    value: "Home",
                    label: "Home",
                  },
                  {
                    value: "Work",
                    label: "Work",
                  },
                  {
                    value: "Mobile",
                    label: "Mobile",
                  },
                ]}
                label={`Phone Type`}
                register={register(`applicant.otherPhoneCaption`)}
              /> */}

              <InputSelectField
                label={`Phone Type`}
                source={`applicant.otherPhoneCaption`}
                options={selectData?.map((item) => ({
                  label: item.label,
                  value: item.value,
                }))}
                labelAsteriskClass={"color-red"}
                labelClass={"lable-title"}
                requiredClass={{
                  fontSize: "70%",
                }}
                onChangeCallback={() => {
                  props?.handleAPIDebounced();
                }}
              />

              {/* <InputFieldPhone
                label={`Phone Number`}
                register={register(`applicant.otherPhoneNumber`)}
              /> */}
              <TextInputPhone
                source={`applicant.otherPhoneNumber`}
                label="Mobile Number"
                isCountryCode={`applicant.country_code`}
                labelClass={"lable-title"}
                additionalInputClass={"cus-control"}
                widthClass={"w-100"}
                requiredClass={{
                  fontSize: "70%",
                }}
                onChangeCallback={() => {
                  props?.handleAPIDebounced();
                }}
              />

              {/* {errors && errors[index] && errors[index].number} */}
            </div>
          )}
          {/* <div className="col-lg-8 col-md-8 col-sm-6 mo-i-box">
            <InputFieldPhone
              label={`Mobile Number`}
              register={register(`applicant.mobile.${index}.number`)}
            />

            {errors && errors[index] && errors[index].number}
          </div> */}
          {props?.addMultipleNumber && (
            <div className="col-md-4  col-lg-4 col-sm-4">
              <div className="d-flex align-items-end">
                <div>
                  {index !== 0 ? (
                    <div className="add-text-btn">
                      <button type="button" onClick={(event) => remove(index)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="13"
                          fill="currentColor"
                          className="bi bi-x-lg"
                          viewBox="0 0 16 16"
                        >
                          <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                        </svg>{" "}
                        &nbsp; &nbsp;
                      </button>
                    </div>
                  ) : (
                    <div className=""></div>
                  )}
                </div>
                <div>
                  {index === fields?.length - 1 && index <= 1 && (
                    <div className="flex-fill">
                      <button
                        className="add-num-number"
                        type="button"
                        onClick={(event) => append({ number: "" })}
                      >
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 13 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.68652 5.44287V0.442871H7.35319V5.44287H12.3532V7.10954H7.35319V12.1095H5.68652V7.10954H0.686523V5.44287H5.68652Z"
                            fill="#2DC3E8"
                          />
                        </svg>
                        &nbsp;<span className="f-12">Add another number</span>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
      <div className="row">
        <div className="col-12">
          <Stack
            sx={{ width: "100%" }}
            style={{ textTransform: "capitalize", marginTop: "10px" }}
            spacing={2}
          >
            <ErrorMessage
              errors={errors}
              name="applicant.mobile"
              render={({ message }) => {
                if (message) return <Alert severity="error">{message}</Alert>;

                return <></>;
              }}
            />
          </Stack>
        </div>
      </div>
    </>
  );
};

export default InputFieldArray;
