import React, { useCallback, useState } from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
// import CustomRadioButton from "../../../registration/Inputs/customRadioButton";
// import InputDateField from "../../../registration/Inputs/InputDateField";
// import InputField from "../../../registration/Inputs/InputField";
// import InputTextArea from "../../../registration/Inputs/InputTextArea";
import { TiDelete } from "react-icons/ti";
import { useSelector } from "react-redux";
import { BsCheckCircleFill } from "react-icons/bs";
import {
  AccordionDetails,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowDown } from "react-icons/ai";
import CustomRadioButton from "../../../common/InputComponents/CustomRadioButton";
import { CardHeader } from "@mui/material";
import DeleteContainedBtn from "../../../common/DeleteContainedBtn";
import InputField2 from "../../../common/InputComponents/InputField2";
import InputDateField from "../../../common/InputComponents/InputDateFieldOld";
import InputTextArea from "../../../common/InputComponents/InputTextArea";

const FinancialDetailsSection = (props) => {
  const [removeRenters, setRemoveRenters] = React.useState(false);
  const { register, formState } = useFormContext();
  //   const useWindowWidth = useSelector(
  //     (state) => state.identification.sideMenuSlice
  //   );
  const { control, watch, setValue, getValues } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "renters_insurance.fields", // unique name for your Field Array
    keyName: "arrId", //default to "id", you can change the key name
  });

  const e_o_n = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "eviction_or_nonpayment.fields", // unique name for your Field Array
    keyName: "arrId", //default to "id", you can change the key name
  });
  const contingencies = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "contingencies.fields", // unique name for your Field Array
    keyName: "arrId", //default to "id", you can change the key name
  });

  const filed_for_bankruptcy = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "filed_for_bankruptcy.fields", // unique name for your Field Array
    keyName: "arrId", //default to "id", you can change the key name
  });

  const [nextSection, setNextSection] = useState(false);
  const navigate = useNavigate();
  // highlight-starts
  // const debouncedSave = useCallback(
  //   debounce(
  //     (nextValue) =>
  //       (async () => {
  //         setNextSection(await props.checkValidation("Finance", false));
  //       })(),
  //     1000
  //   ),
  //   [] // will be created only once initially
  // );
  // highlight-ends
  const checkSourceAvailableHandler = (section) => {
    if (
      section == "renters" &&
      watch("renters_insurance.fields")?.length == 0
    ) {
      setValue("renters_insurance.response", "No");
    }
  };

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // debouncedSave(name);
      if (type === "change" && name === `renters_insurance.response`) {
        if (getValues(`renters_insurance.response`) === "Yes")
          setValue("renters_insurance.fields", [
            {
              id: 0,
              policy_expirationdate: "",
              policy_number: "",
              email: "",
              insurance_companyName: "",
            },
          ]);
      }
      // else if (
      //   type === "change" &&
      //   name === `eviction_or_nonpayment.response`
      // ) {
      //   if (getValues(`eviction_or_nonpayment.response`) === "Yes")
      //     setValue("eviction_or_nonpayment.fields", [
      //       {
      //         id: 0,
      //         eviction_or_nonpayment_reason: "",
      //       },
      //     ]);
      // } else if (type === "change" && name === `contingencies.response`) {
      //   //contingencies.fields
      //   if (getValues(`contingencies.response`) === "Yes")
      //     setValue("contingencies.fields", [
      //       { id: 0, contigencies_reason: "" },
      //     ]);
      // } else if (
      //   type === "change" &&
      //   name === `filed_for_bankruptcy.response`
      // ) {
      //   //contingencies.fields
      //   if (getValues(`filed_for_bankruptcy.response`) === "Yes")
      //     setValue("filed_for_bankruptcy.fields", [
      //       { id: 0, filed_for_bankruptcy_reason: "" },
      //     ]);
      // }
    });
    return () => subscription.unsubscribe();
  }, [getValues, setValue, watch]);

  return (
    <AccordionDetails>
      <div className="fd-body">
        <div className="row">
          {watch("renters_insurance.display") === true && (
            <>
              <div className="col-sm-12">
                <div className="i-a-block mr-b15">
                  <CustomRadioButton
                    onClick={() => {
                      if (watch("renters_insurance.fields")?.length > 0) {
                        setRemoveRenters(true);
                      }
                    }}
                    notRequired={!getValues("renters_insurance.required")}
                    disable={watch("renters_insurance.fields")?.length > 0}
                    label={`${getValues(
                      `renters_insurance.labelIndex`
                    )}. Do you have renters insurance?`}
                    register={register("renters_insurance.response")}
                    handleOnChange={() => {
                      props?.handleAPIDebounced();
                    }}
                  />
                </div>
              </div>
              {watch("renters_insurance.response") === "Yes" && (
                <div className="col-sm-12">
                  <div className="blue-box a-blue-box pd15 mr-b30">
                    {fields.map((field, index) => {
                      return (
                        <div key={field.arrId}>
                          <div className="row">
                            <div className="col-sm-12 mb-2">
                              {/* <span className="s-no float-left">
                                  {index + 1}
                                </span>{" "} */}
                              {/* <button
                                type="button"
                                onClick={async () => {
                                  remove(index);
                                  checkSourceAvailableHandler("renters");
                                }}
                                title="Delete this Employer"
                                className="float-right btn btn-sm btn-danger"
                              >
                                <TiDelete size={25} /> Delete
                              </button> */}
                              <CardHeader
                                sx={{ padding: 0 }}
                                // title={
                                //   <b className="s-no-index-number">{index + 1}</b>
                                // }
                                action={
                                  <DeleteContainedBtn
                                    onClick={async () => {
                                      remove(index);
                                      checkSourceAvailableHandler("renters");
                                      props?.handleAPIDebounced();
                                    }}
                                    // title="Delete this Employer"
                                  />
                                }
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-4 mr-b10">
                              {/* <InputField
                                label={`Insurance Company`}
                                register={register(
                                  `renters_insurance.fields.${index}.insurance_companyName`
                                )}
                              /> */}
                              <InputField2
                                label="Insurance Company"
                                source={`renters_insurance.fields.${index}.insurance_companyName`}
                                placeholder="Insurance Company"
                                labelClass={"lable-title"}
                                additionalInputClass={"cus-control"}
                                handleOnChange={() => {
                                  props?.handleAPIDebounced();
                                }}
                              />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mr-b10">
                              {/* <InputField
                                label={`Policy Number`}
                                register={register(
                                  `renters_insurance.fields.${index}.policy_number`
                                )}
                              /> */}
                              <InputField2
                                label="Policy Number"
                                source={`renters_insurance.fields.${index}.policy_number`}
                                placeholder="Policy Number"
                                labelClass={"lable-title"}
                                additionalInputClass={"cus-control"}
                                handleOnChange={() => {
                                  props?.handleAPIDebounced();
                                }}
                              />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mr-b10">
                              {/* <InputDateField
                                minDate={new Date()}
                                type={"date"}
                                label="Policy Expiration Date"
                                placeholder="Enter Date"
                                register={register(
                                  `renters_insurance.fields.${index}.policy_expirationdate`
                                )}
                              /> */}
                              <InputDateField
                                source={`renters_insurance.fields.${index}.policy_expirationdate`}
                                label="Policy Expiration Date"
                                labelClass={"lable-title"}
                                onChangeCallback={() => {
                                  props?.handleAPIDebounced();
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {!fields.length && (
                      <div className="add-text-btn flex1 text-center ps-2 pt-3">
                        <button
                          type="button"
                          onClick={(event) =>
                            append({
                              id: 0,
                              first_name: "",
                              last_name: "",
                              email: "",
                              mobile: "",
                            })
                          }
                        >
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 13 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.68652 5.44287V0.442871H7.35319V5.44287H12.3532V7.10954H7.35319V12.1095H5.68652V7.10954H0.686523V5.44287H5.68652Z"
                              fill="#2DC3E8"
                            />
                          </svg>
                          &nbsp; Add renters insurance detail
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}

          {watch("eviction_or_nonpayment.display") === true && (
            <>
              <div className="col-sm-12">
                <div className="i-a-block mr-b15">
                  <CustomRadioButton
                    label={`${getValues(
                      `eviction_or_nonpayment.labelIndex`
                    )}. Have your ever been filed for eviction or
                                    sued for non-payment of rent?`}
                    register={register("eviction_or_nonpayment.response")}
                    notRequired={!getValues(`eviction_or_nonpayment.required`)}
                    handleOnChange={() => {
                      props?.handleAPIDebounced();
                    }}
                  />
                </div>
              </div>
              {watch("eviction_or_nonpayment.response") === "Yes" && (
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <div className="blue-box a-blue-box pd15 mr-b30">
                    <div className="col-sm-12">
                      {/* <InputTextArea
                        label="Please Explain"
                        register={register(
                          `eviction_or_nonpayment.explanation`
                        )}
                        placeholder="Please Explain"
                      /> */}
                      <div className="emp-inputs">
                        <InputTextArea
                          register={"eviction_or_nonpayment.explanation"}
                          label="Please Explain"
                          placeholder="Please Explain"
                          labelClass={"lable-title"}
                          labelAsteriskClass={"color-red"}
                          additionalInputClass={"cus-control"}
                          styleProperty={{ height: "auto" }}
                          messageText={false}
                          requiredClass={{
                            fontSize: "70%",
                          }}
                          handleOnChange={() => {
                            props?.handleAPIDebounced();
                          }}
                        />
                        <em
                          className="d-block"
                          style={{
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "19px",
                            textAlign: "right",
                            color: "#B4B4B4",
                          }}
                        >
                          Max 5000 Characters
                        </em>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {watch("contingencies.display") === true && (
            <>
              <div className="col-sm-12">
                <div className="i-a-block mr-b15">
                  <CustomRadioButton
                    // disable={watch("contingencies.fields")?.length > 0}
                    label={`${getValues(
                      `contingencies.labelIndex`
                    )}. Do you have any contingencies?`}
                    register={register("contingencies.response")}
                    notRequired={!getValues(`contingencies.required`)}
                    handleOnChange={() => {
                      props?.handleAPIDebounced();
                    }}
                  />
                </div>
              </div>
              {watch("contingencies.response") === "Yes" && (
                <div className="col-sm-12">
                  <div className="blue-box a-blue-box pd15 mr-b30">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      {/* <InputTextArea
                        label="Please Explain"
                        register={register(`contingencies.explanation`)}
                        placeholder="Please Explain"
                      /> */}
                      <div className="emp-inputs">
                        <InputTextArea
                          register={"contingencies.explanation"}
                          label="Please Explain"
                          placeholder="Please Explain"
                          labelClass={"lable-title"}
                          labelAsteriskClass={"color-red"}
                          additionalInputClass={"cus-control"}
                          styleProperty={{ height: "auto" }}
                          messageText={false}
                          requiredClass={{
                            fontSize: "70%",
                          }}
                          handleOnChange={() => {
                            props?.handleAPIDebounced();
                          }}
                        />
                        <em
                          className="d-block"
                          style={{
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "19px",
                            textAlign: "right",
                            color: "#B4B4B4",
                          }}
                        >
                          Max 5000 Characters
                        </em>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {watch("filed_for_bankruptcy.display") === true && (
            <>
              <div className="col-sm-12">
                <div className="i-a-block mr-b15">
                  <CustomRadioButton
                    // disable={watch("filed_for_bankruptcy.fields")?.length > 0}
                    label={`${getValues(
                      `filed_for_bankruptcy.labelIndex`
                    )}. Have you ever filed for bankruptcy?`}
                    register={register("filed_for_bankruptcy.response")}
                    notRequired={!getValues(`filed_for_bankruptcy.required`)}
                    handleOnChange={() => {
                      props?.handleAPIDebounced();
                    }}
                  />
                </div>
              </div>
            </>
          )}
          {watch("filed_for_bankruptcy.response") === "Yes" && (
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="blue-box a-blue-box pd15 mr-b30">
                {/* <InputTextArea
                  label="Please Explain"
                  register={register(`filed_for_bankruptcy.explanation`)}
                  placeholder="Please Explain"
                /> */}
                <div className="emp-inputs">
                  <InputTextArea
                    register={"filed_for_bankruptcy.explanation"}
                    label="Please Explain"
                    placeholder="Please Explain"
                    labelClass={"lable-title"}
                    labelAsteriskClass={"color-red"}
                    additionalInputClass={"cus-control"}
                    styleProperty={{ height: "auto" }}
                    messageText={false}
                    requiredClass={{
                      fontSize: "70%",
                    }}
                    handleOnChange={() => {
                      props?.handleAPIDebounced();
                    }}
                  />
                  <em
                    className="d-block"
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "19px",
                      textAlign: "right",
                      color: "#B4B4B4",
                    }}
                  >
                    Max 5000 Characters
                  </em>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Dialog
        open={removeRenters}
        onClose={() => {
          setRemoveRenters(!removeRenters);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Are you sure want to delete
           ?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Warning! Once deleted cannot revert back .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            className="btn btn-info text-light"
            onClick={() => {
              setRemoveRenters(!removeRenters);
            }}
          >
            No
          </button>
          <button
            className="btn btn-info text-light"
            onClick={() => {
              setRemoveRenters(false);
              setValue("renters_insurance.fields", []);
              setValue("renters_insurance.response", "No");
              props?.handleAPIDebounced();
            }}
          >
            Yes
          </button>
        </DialogActions>
      </Dialog>
      {nextSection && (
        <Button
          onClick={() => {
            navigate("#others");
          }}
          variant="contained"
          endIcon={<AiOutlineArrowDown />}
        >
          Next Tab
        </Button>
      )}
    </AccordionDetails>
  );
};

export default FinancialDetailsSection;
