import React from "react";
import ArchiveIcon from "@mui/icons-material/Archive";
import { Box, Button, InputAdornment } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  TextInput,
  required,
  useUpdate,
  useNotify,
  useRefresh,
  NumberInput,
} from "react-admin";
import { useForm, FormProvider, get } from "react-hook-form";
import { Button as MuiButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import { useParams } from "react-router-dom";
import { Update } from "@mui/icons-material";
import { JSONTree } from "react-json-tree";
import InputField2 from "../common/InputComponents/InputField2";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import LoadingSpinner from "../common/loadingSpinner";
import TextInputPhone from "../DocumentAnalysis/components/inputComponents/TextInputPhone";

export default function ApplicantNameEdit(props) {
  const { id } = useParams();
  const notify = useNotify();
  const refresh = useRefresh();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // form validation schema
  const validationSchema = Yup.lazy((value) =>
    Yup.object().shape({
      first_name: Yup.string()
        .trim("First name is required!")
        .required("required")
        .max(75, "maximum 75 characters is required")
        .min(2, "minimum 2 characters is required")
        .typeError("required"),
      last_name: Yup.string()
        .trim("Last name is required!")
        .max(75, "maximum 75 characters is required")
        .required("required")
        .min(2, "minimum 2 characters is required")
        .typeError("required"),
      email: Yup.string()
        .email("Invalid email format")
        .max(75, "maximum 75 characters is required")
        .required("Email is required!")
        .matches(/^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/, "Invalid domain"),
      mobile_number: Yup.string()
        .required("Mobile number is required!")
        .typeError("Mobile number is required!")
        .test("rent", "Min 10 digits are required", (value) => {
          let temp: any = value?.toString().replace(/\D/g, "");
          return temp?.length > 10 ? true : false;
        }),
    })
  );

  // form initial states
  const form = useForm({
    defaultValues: {
      first_name: props.record.FirstName,
      last_name: props.record.LastName,
      email: props.record.Email,
      mobile_number: props.record.MobileNumber,
    },
    resolver: yupResolver(validationSchema),
  });

  // Destructuring form
  const { register, reset } = form;

  const [update, { data, isLoading, error }] = useUpdate();
  // form submit handler
  const onSubmit = (value) => {
    update(
      "editapplicationdetail",
      { id: props.record.id, data: value },
      {
        onSuccess: (d) => {
          notify(`Data Updated`, {
            type: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          refresh();
          handleClose();
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    );
  };
  return (
    <>
      <EditIcon onClick={handleOpen} color="primary" fontSize="small" />
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Edit</DialogTitle>
        <DialogContent>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-12 mr-b10">
                  <InputField2
                    label="First Name"
                    source={`first_name`}
                    placeholder="First Name"
                    labelClass={"lable-title"}
                    additionalInputClass={"cus-control"}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 mr-b10">
                  <InputField2
                    label="Last Name"
                    source={`last_name`}
                    placeholder="Last Name"
                    labelClass={"lable-title"}
                    additionalInputClass={"cus-control"}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 mr-b10">
                  <InputField2
                    label="Email"
                    source={`email`}
                    placeholder="Email"
                    labelClass={"lable-title"}
                    additionalInputClass={"cus-control"}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 mr-b20">
                  <TextInputPhone
                    source={`mobile_number`}
                    label="Mobile"
                    isCountryCode={`country_code`}
                    labelClass={"lable-title"}
                    additionalInputClass={"cus-control"}
                    widthClass={"w-100"}
                    requiredClass={{
                      fontSize: "70%",
                    }}
                    // required={false}
                  />
                </div>
              </div>
              <Box display={"flex"} justifyContent={"end"}>
                <Button
                  sx={{ mx: 1 }}
                  onClick={() => {
                    handleClose();
                    reset();
                  }}
                  variant="outlined"
                  color="secondary"
                  type="button"
                >
                  Cancel
                </Button>

                <Button
                  startIcon={<EditIcon />}
                  sx={{ mx: 1 }}
                  variant="contained"
                  type="submit"
                >
                  Update
                </Button>
              </Box>
            </form>
          </FormProvider>
        </DialogContent>
        {/* <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions> */}
      </Dialog>
      {isLoading ? <LoadingSpinner /> : ""}
    </>
  );
}
