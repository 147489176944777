import React from "react";
import { useEffect } from "react";
import { Confirm, useGetOne } from "react-admin";
import EmployerDetails from "./EmployerDetails";
import DragDropDocumentButton from "../components/DragDropDocumentButton";
import ShowValidationMessages from "../components/ShowValidationMessages";
import ShowComments from "../components/ShowComments";
import YesNoRadioButton from "../../../componets/common/InputComponents/YesNoRadioButton";
import NoDocReasonRadioButtons from "../../common/InputComponents/NoDocReasonRadioButtons";
import InputField2 from "../../common/InputComponents/InputField2";
import ShowDocumentStatus from "../components/ShowDocumentStatus";
import StatusComponent from "../components/StatusComponent";
import AddDocuverusComment from "../Modals/AddDocuverusComment";
import overa from "../../../assets/images/overa.svg";
import greenTick from "../../../assets/images/greenTick.svg";
import Card from "@mui/material/Card";
import { CardHeader } from "@mui/material";
import FormInstruction from "../../common/FormInstruction";
import DeleteContainedBtn from "../../common/DeleteContainedBtn";
import InputSelectField from "../../common/InputComponents/InputSelectField";
import { NumericFormat } from "react-number-format";
import InputCheckBoxField from "../../common/InputComponents/InputCheckBoxField";
import ShowValidationMessagesApplicant from "../components/ShowValidationMessagesApplicant";

import SyncIcon from "@mui/icons-material/Sync";
import CustomPreviewUpload from "../../common/CustomPreviewUpload";
import FilePreview from "../../common/FilePreview";
import TextInputPhone from "../components/inputComponents/TextInputPhone";
import InputDateField from "../../common/InputComponents/InputDateFieldOld";
import InputCurrencyField from "../components/inputComponents/InputCurrencyFiled";
import { JSONTree } from "react-json-tree";

const EmployerSource = ({
  field,
  index,
  mannualSOCRstatus,
  socrStatus,
  serEmployerNumber,
  setConfirmDeleteSource,
  methods,
  loadingdeleteEmployer,
  confirmDeleteSource,
  meta_d_response,
  IncomeAPIResponse,
  role,
  trn_id,
  handleDeleteEmployer,
  getSrcfromStatus,
  getClassfromStatus,
  gettextStylefromStatus,
  grossPayAvg,
  refresh,
  adminValidationMessages,
  invitationType,
  handleAPIDebounced,
  filePreview,
  setFilePreview,
  collapse,
  setCollapse,
  handleReprocessEmployer,
  mlClassDetails,
  LookbacksData,
  ...props
}) => {
  const { EmployerNumber } = field;
  let statusValue = null;
  if (mannualSOCRstatus)
    statusValue = mannualSOCRstatus[`Source_${EmployerNumber}`]
      ? mannualSOCRstatus[`Source_${EmployerNumber}`]
      : socrStatus[`source_${EmployerNumber}`]?.SOCRStatus;

  const getNoofWeeksText = (look_back_period, no_of_weeks) => {
    return `${no_of_weeks} (required ${look_back_period})`;
  };

  const { data: getlookbacks } = useGetOne(
    "getlookbacks",
    { id: field?.application_id },
    { enabled: field?.application_id != null, refetchOnWindowFocus: false }
  );
  const handleToggle = (fieldId) => {
    setCollapse((prevOpenCollapsibles) => {
      if (prevOpenCollapsibles.includes(fieldId)) {
        // If the collapsible is already open, close it
        return prevOpenCollapsibles.filter((id) => id !== fieldId);
      } else {
        // If the collapsible is closed, open it
        return [...prevOpenCollapsibles, fieldId];
      }
    });
  };
  const { watch, getValues, setValue } = methods;
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (
        type === "change" &&
        (name == `employer[${index}].pay_frequency` ||
          name == `employer.${index}.avrgross_pay`)
      ) {
        let _monthlyIncome = getValues(`employer.${index}.avrgross_pay`) || 0;
        const pay_frequency = getValues(
          `employer[${index}].pay_frequency`
        ).value;
        if (watch(`employer[${index}].pay_frequency`) !== "") {
          if (pay_frequency == "Weekly") {
            _monthlyIncome = (_monthlyIncome * 52) / 12;
          } else if (pay_frequency == "Bi-Weekly") {
            _monthlyIncome = (_monthlyIncome * 26) / 12;
          } else if (pay_frequency == "Monthly") {
            //_monthlyIncome = _monthlyIncome;
          } else if (pay_frequency == "Bi-Monthly") {
            _monthlyIncome = (_monthlyIncome * 24) / 12;
          } else if (pay_frequency == "One-Time") {
            _monthlyIncome = _monthlyIncome / 12;
          } else {
            _monthlyIncome = _monthlyIncome / 12;
          }
          setValue(`employer.${index}.total_income`, _monthlyIncome || 0);
          setValue(
            `employer.${index}.total_annual_income`,
            (_monthlyIncome || 0) * 12
          );
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [getValues, index, setValue, watch]);

  return (
    <div className="pt-1" key={field.fieldId}>
      <div className="login-radio-types p-2">
        <div className="row pb-2">
          <div className="col-12">
            <Card
              sx={{
                background: "#f5fdff",
                boxShadow: "none",
                borderBottom: "1px solid black",
                borderRadius: 0,
              }}
            >
              <CardHeader
                sx={{ padding: 0 }}
                title={
                  <b style={{ fontSize: 13 }}>{`Employer ${
                    index + 1
                  }: ${methods.watch(`employer.${index}.employer_name`)}`}</b>
                }
                action={
                  <DeleteContainedBtn
                    onClick={() => {
                      serEmployerNumber(field.id);
                      setConfirmDeleteSource(true);
                    }}
                    disable={loadingdeleteEmployer}
                  />
                }
              />
            </Card>
            <Confirm
              isOpen={confirmDeleteSource}
              // loading={isLoading}
              sx={{
                "& .RaConfirm-iconPaddingStyle": {
                  display: "none",
                },
              }}
              title="Confirm"
              content="Are you sure you want to delete the income source?"
              onConfirm={() => handleDeleteEmployer()}
              onClose={() => {
                serEmployerNumber(0);
                setConfirmDeleteSource(false);
              }}
            />
          </div>
        </div>
        {field?.files &&
        field?.files.length > 0 &&
        (!!meta_d_response?.records?.other_check ||
          IncomeAPIResponse?.data?.employment_details?.SOCRstatus[
            `Source_${index + 1}`
          ]) &&
        role !== "applicant" ? (
          <div className="appl-status-wrap">
            {field?.employer_source_ocr_complete ? (
              <div className="d-flex flex-wrap ">
                <div className="pr-2 pt-2">
                  {role === "Docuverus Super Admin" ? (
                    <ShowDocumentStatus
                      label={"Overall"}
                      trn_id={trn_id}
                      documentId={3}
                      SourceId={index + 1}
                      value={
                        (IncomeAPIResponse?.data?.employment_details
                          ?.SOCRstatus &&
                          `Source_${index + 1}` in
                            IncomeAPIResponse?.data?.employment_details
                              ?.SOCRstatus &&
                          IncomeAPIResponse?.data?.employment_details
                            ?.SOCRstatus[`Source_${index + 1}`]) ||
                        (!!meta_d_response?.records?.hasOwnProperty(
                          "other_check"
                        )
                          ? meta_d_response?.records?.other_check[
                              `source_${index + 1}`
                            ].SOCRStatus
                          : "")
                      }
                      src={getSrcfromStatus(
                        (IncomeAPIResponse?.data?.employment_details
                          ?.SOCRstatus &&
                          `Source_${index + 1}` in
                            IncomeAPIResponse?.data?.employment_details
                              ?.SOCRstatus &&
                          IncomeAPIResponse?.data?.employment_details
                            ?.SOCRstatus[`Source_${index + 1}`]) ||
                          (meta_d_response?.records?.hasOwnProperty(
                            "other_check"
                          )
                            ? meta_d_response?.records?.other_check[
                                `source_${index + 1}`
                              ].SOCRStatus
                            : "")
                      )}
                      blockStyle={getClassfromStatus(
                        (IncomeAPIResponse?.data?.employment_details
                          ?.SOCRstatus &&
                          `Source_${index + 1}` in
                            IncomeAPIResponse?.data?.employment_details
                              ?.SOCRstatus &&
                          IncomeAPIResponse?.data?.employment_details
                            ?.SOCRstatus[`Source_${index + 1}`]) ||
                          (meta_d_response?.records?.hasOwnProperty(
                            "other_check"
                          )
                            ? meta_d_response?.records?.other_check[
                                `source_${index + 1}`
                              ].SOCRStatus
                            : "")
                      )}
                      className={"col-lg-3 col-sm-3 col-3"}
                      textStyle={gettextStylefromStatus(
                        (IncomeAPIResponse?.data?.employment_details
                          ?.SOCRstatus &&
                          `Source_${index + 1}` in
                            IncomeAPIResponse?.data?.employment_details
                              ?.SOCRstatus &&
                          IncomeAPIResponse?.data?.employment_details
                            ?.SOCRstatus[`Source_${index + 1}`]) ||
                          (meta_d_response?.records?.hasOwnProperty(
                            "other_check"
                          )
                            ? meta_d_response?.records?.other_check[
                                `source_${index + 1}`
                              ].SOCRStatus
                            : "")
                      )}
                      invalidateQueries={"income"}
                    />
                  ) : (
                    <ShowDocumentStatus
                      label={"Overall"}
                      trn_id={trn_id}
                      documentId={3}
                      SourceId={index + 1}
                      src={getSrcfromStatus(
                        (IncomeAPIResponse?.data?.employment_details
                          ?.SOCRstatus &&
                          `Source_${index + 1}` in
                            IncomeAPIResponse?.data?.employment_details
                              ?.SOCRstatus &&
                          IncomeAPIResponse?.data?.employment_details
                            ?.SOCRstatus[`Source_${index + 1}`]) ||
                          (!!meta_d_response?.records?.hasOwnProperty(
                            "other_check"
                          )
                            ? meta_d_response?.records?.other_check[
                                `source_${index + 1}`
                              ].SOCRStatus
                            : "")
                      )}
                      blockStyle={getClassfromStatus(
                        (IncomeAPIResponse?.data?.employment_details
                          ?.SOCRstatus &&
                          `Source_${index + 1}` in
                            IncomeAPIResponse?.data?.employment_details
                              ?.SOCRstatus &&
                          IncomeAPIResponse?.data?.employment_details
                            ?.SOCRstatus[`Source_${index + 1}`]) ||
                          (meta_d_response?.records?.hasOwnProperty(
                            "other_check"
                          )
                            ? meta_d_response?.records?.other_check[
                                `source_${index + 1}`
                              ].SOCRStatus
                            : "")
                      )}
                      className={"col-lg-3 col-sm-3 col-3"}
                      textStyle={gettextStylefromStatus(
                        (IncomeAPIResponse?.data?.employment_details
                          ?.SOCRstatus &&
                          `Source_${index + 1}` in
                            IncomeAPIResponse?.data?.employment_details
                              ?.SOCRstatus &&
                          IncomeAPIResponse?.data?.employment_details
                            ?.SOCRstatus[`Source_${index + 1}`]) ||
                          (meta_d_response?.records?.hasOwnProperty(
                            "other_check"
                          )
                            ? meta_d_response?.records?.other_check[
                                `source_${index + 1}`
                              ].SOCRStatus
                            : "")
                      )}
                      invalidateQueries={"income"}
                      disable={true}
                      value={
                        (IncomeAPIResponse?.data?.employment_details
                          ?.SOCRstatus &&
                          `Source_${index + 1}` in
                            IncomeAPIResponse?.data?.employment_details
                              ?.SOCRstatus &&
                          IncomeAPIResponse?.data?.employment_details
                            ?.SOCRstatus[`Source_${index + 1}`]) ||
                        (meta_d_response?.records?.hasOwnProperty("other_check")
                          ? meta_d_response?.records?.other_check[
                              `source_${index + 1}`
                            ].SOCRStatus
                          : "")
                      }
                      customWidth={150}
                    />
                  )}
                </div>
                <div className="px-2 pt-2">
                  <StatusComponent
                    blockStyle={""}
                    label={"Name Matched"}
                    validationIcon={
                      meta_d_response?.records?.other_check[
                        `source_${index + 1}`
                      ].name_match == true
                        ? greenTick
                        : overa
                    }
                    validationText={
                      meta_d_response?.records?.other_check[
                        `source_${index + 1}`
                      ].name_match == null
                        ? "NA"
                        : meta_d_response?.records?.other_check[
                            `source_${index + 1}`
                          ].name_match == true
                        ? "Yes"
                        : "No"
                    }
                    validationTextStyle={
                      meta_d_response?.records?.other_check[
                        `source_${index + 1}`
                      ].name_match == true
                        ? "green-stat"
                        : "brown-stat"
                    }
                  />
                </div>
                <div className="px-2 pt-2">
                  <StatusComponent
                    blockStyle={""}
                    label={"Current"}
                    validationIcon={
                      meta_d_response?.records?.other_check[
                        `source_${index + 1}`
                      ].current == true
                        ? greenTick
                        : overa
                    }
                    validationText={
                      meta_d_response?.records?.other_check[
                        `source_${index + 1}`
                      ].current == null
                        ? "NA"
                        : meta_d_response?.records?.other_check[
                            `source_${index + 1}`
                          ].current == true
                        ? "Yes"
                        : "No"
                    }
                    validationTextStyle={
                      meta_d_response?.records?.other_check[
                        `source_${index + 1}`
                      ].current == true
                        ? "green-stat"
                        : "brown-stat"
                    }
                  />
                </div>
                <div className="px-2 pt-2">
                  <StatusComponent
                    blockStyle={""}
                    label={"Consecutive"}
                    validationIcon={
                      meta_d_response?.records?.other_check[
                        `source_${index + 1}`
                      ]?.concurrent
                        ? greenTick
                        : overa
                    }
                    validationText={
                      meta_d_response?.records?.other_check[
                        `source_${index + 1}`
                      ].concurrent == null
                        ? "NA"
                        : meta_d_response?.records?.other_check[
                            `source_${index + 1}`
                          ].concurrent == true
                        ? "Yes"
                        : "No"
                    }
                    validationTextStyle={
                      meta_d_response?.records?.other_check[
                        `source_${index + 1}`
                      ]?.concurrent
                        ? "green-stat"
                        : "brown-stat"
                    }
                  />
                </div>
                <div className="px-2 pt-2">
                  <div className={`block`}>
                    <label
                      style={{
                        fontSize: "12px",
                        lineHeight: "16px",
                        textAlign: "center",
                        color: "black",
                      }}
                      htmlFor=""
                    >
                      No. of Weeks
                    </label>
                    <img
                      src={
                        parseInt(
                          meta_d_response?.records?.other_check[
                            `source_${index + 1}`
                          ]?.no_of_weeks
                        ) >=
                        parseInt(
                          meta_d_response?.records?.other_check[
                            `source_${index + 1}`
                          ]?.look_back_period
                        )
                          ? greenTick
                          : overa
                      }
                      alt=""
                    />
                    <span
                      style={{
                        fontSize: "12px",
                        lineHeight: "16px",
                        textAlign: "center",
                      }}
                    >
                      {getNoofWeeksText(
                        meta_d_response?.records?.other_check[
                          `source_${index + 1}`
                        ].look_back_period,
                        meta_d_response?.records?.other_check[
                          `source_${index + 1}`
                        ].no_of_weeks
                      )}
                    </span>
                  </div>
                </div>

                <div className="px-2 pt-2">
                  <div className={`block`}>
                    <label
                      style={{
                        fontSize: "12px",
                        lineHeight: "16px",
                        textAlign: "center",
                        color: "black",
                      }}
                      htmlFor=""
                    >
                      Monthly Gross Pay
                    </label>
                    <br />
                    <span
                      style={{
                        fontSize: "12px",
                        lineHeight: "16px",
                        textAlign: "center",
                        paddingTop: "4px",
                      }}
                    >
                      <NumericFormat
                        thousandSeparator={","}
                        style={{ fontSize: 12 }}
                        prefix={"$"}
                        decimalScale={2}
                        displayType={"text"}
                        value={grossPayAvg(
                          methods.watch(`employer.${index}.files`)
                        ).toFixed(2)}
                      />
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="d-flex align-item-center">
                <FormInstruction message={"Document under Processing"} />
                <button
                  type="button"
                  onClick={() => refresh()}
                  className="btn btn-sm btn-outline-primary"
                >
                  <SyncIcon sx={{ fontSize: 12 }} /> Refresh
                </button>
              </div>
            )}
            {/* </div> */}
          </div>
        ) : null}
        {field?.files &&
        field?.files.length > 0 &&
        field?.employer_source_ocr_complete &&
        role !== "applicant" ? (
          <>
            <ShowValidationMessages
              role={role === "applicant" ? "applicant" : role}
              validation_message={
                role === "applicant"
                  ? field?.validation_message_status
                  : adminValidationMessages?.[`Source_${EmployerNumber}`]
              }
              fetchRecord={() => null}
              section={"Employment"}
              invalidateQueries={"getMetaData"}
              tag={`updateValidationMessages_employment`}
            />
          </>
        ) : null}
        <ShowComments
          section_comment={field?.section_comment}
          role={role === "applicant" ? "applicant" : role}
        />
        {/*  */}
        {invitationType == "1" ? (
          <div className="mr-t20 bullet-style">
            <YesNoRadioButton
              required={props.required}
              label={
                "Do you have proof of employment income available to upload?"
              }
              source={`employer.${index}.availableToUpload`}
              disable={methods?.getValues()?.employer[index]?.fileCount}
              // handleOnClick={(val) => {

              //   if (val === "No") {
              //     handleAPIDebounced();
              //   }
              // }}
            />
          </div>
        ) : (
          ""
        )}
        {/* <div className="login-radio-types p-2"> */}
        {invitationType == "1" &&
          methods?.watch()?.employer[index]?.availableToUpload == "No" && (
            <>
              {/* <div> */}
              <div className="login-radio-types p-2">
                <NoDocReasonRadioButtons
                  handleOnchange={() => {
                    handleAPIDebounced();
                  }}
                  source={`employer.${index}.no_doc_reason`}
                  options={[
                    {
                      name: "I don't currently have access to document(s), but can provide later",
                      value:
                        "I don't currently have access to document(s), but can provide later",
                    },
                    {
                      name: "I do not have proof of employment",
                      value: "I do not have proof of employment",
                    },
                    {
                      name: "Other",
                      value: "Other",
                    },
                  ]}
                  section_id={4}
                />
                {methods.watch(`employer.${index}.no_doc_reason`) ==
                  "Other" && (
                  <div className="col-12 col-md-4 col-lg-4 mr-t20 mr-l30 mr-b10">
                    <InputField2
                      // handleOnChange={() => {
                      //   handleAPIDebounced();
                      // }}
                      source={`employer.${index}.other_reason`}
                      label={"Other reason"}
                      placeholder=""
                    />
                  </div>
                )}
              </div>
            </>
          )}
        {/*  */}

        <div className="row align-items-center">
          {(invitationType == "1" &&
            methods?.watch()?.employer[index]?.availableToUpload == "Yes") ||
          invitationType !== "1" ? (
            <>
              <div className="row">
                <div
                  className={
                    filePreview.proof_of_income !== ""
                      ? `col-md-8 col-sm-12 px-4 card-body pt-2`
                      : ` px-4 card-body pt-2`
                  }
                >
                  <div
                    className="col-sm-12 col-md-12 mt-2"
                    style={{
                      fontSize: "11px",
                      color: "#606060",
                      paddingLeft: "16px",
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <strong>
                        {`${index + 1}. ${
                          methods.watch(`employer.${index}.employer_name`) ||
                          "Proof of Employment Income:"
                        }`}
                      </strong>
                    </div>
                    <p>(Paystubs must cover the {LookbacksData?.paystub_lookback || getlookbacks?.data?.paystub_lookback} most recent weeks)</p>
                    <FormInstruction
                      message={
                        'IMPORTANT! PDF paystubs downloaded directly from payroll website ONLY! Please do NOT upload pictures of paystubs.'
                      }
                    />
                    {
                      <div className="mt-2">
                        <InputCheckBoxField
                          notRequired={true}
                          name={`employer.${index}.allowOfferLetter`}
                          label="Newly employed? If you are newly employed and do not yet have access to original PDF’s of your paystubs, then please check the box to the left , which will allow you to upload a PDF of your offer letter."
                        />
                      </div>
                    }
                  </div>
                  <div className="col-md-12 col-sm-12 row mt-2">
                    <div className="d-flex flex-wrap ">
                      <div>
                        <DragDropDocumentButton
                          SourceId={field.id}
                          documentId={"3"}
                          fetchRecord={() => null}
                          allowOfferLetter={watch(
                            `employer.${index}.allowOfferLetter`
                          )}
                          acceptedFiles={"application/pdf"}
                          invalidateQueries={"income"}
                          tag={"IncomeUpload"}
                          trn_id={trn_id}
                          fileError={
                            methods.formState?.errors?.employer?.[
                              EmployerNumber - 1
                            ] && role === "applicant"
                              ? true
                              : false
                          }
                          notifyMessage={
                            "Supported file format is pdf files only."
                          }
                        />
                      </div>
                      {field?.files?.map((item, i) => {
                        return (
                          <div
                            className="px-1"
                            key={item.application_document_id}
                          >
                           
                            <FilePreview
                              source_index={index}
                              trn_id={trn_id}
                              file_name={item.file_name}
                              doc_id={item.application_document_id}
                              img={item.path_image}
                              tag={"delete_document_other_income"}
                              invalidateQueries={"otherIncomeDoc"}
                              is_Duplicate={item.is_Duplicate}
                              file={item.path}
                              handleClick={() =>
                                setFilePreview({
                                  ...filePreview,
                                  proof_of_income: item.application_document_id,
                                  proof_of_income_filePath: item.path,
                                  startpage: item.startpage,
                                  id: field.fieldId,
                                })
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                {field.files.findIndex(
                  (file) =>
                    file.application_document_id === filePreview.proof_of_income
                ) !== -1 &&
                  filePreview.proof_of_income !== "" &&
                  filePreview.id == field?.fieldId && (
                    <div className="col-md-4 col-sm-12 mt-2">
                      <CustomPreviewUpload
                        filePath={filePreview.proof_of_income_filePath}
                        startpage={filePreview.startpage}
                      />
                    </div>
                  )}
              </div>              
            </>
          ) : (
            ""
          )}
          {role === "applicant" && (
            <>
              <div className="col-12">
                {
                  <ShowValidationMessagesApplicant
                    validation_message={field.validation_message_status}
                  />
                }
              </div>
              {/* <div className="col-12">
                <div className="d-flex justify-content-end">
                  <AddDocuverusComment
                    label={
                      role === "applicant"
                        ? `Add Comment`
                        : "Add Docuverus Comment"
                    }
                    title={
                      role === "applicant"
                        ? `Add Comment`
                        : "Add Docuverus Comment"
                    }
                    defaultValue={
                      IncomeAPIResponse?.data?.employment_details?.employer[0]
                        ?.section_comment?.admin
                        ? IncomeAPIResponse?.data?.employment_details
                            ?.employer[0]?.section_comment?.admin
                        : ""
                    }
                    trn_id={trn_id}
                    is_applicant={role === "applicant" ? true : false}
                    document_id={3}
                    tag={"addComment_income"}
                    invalidateQueries={"income"}
                    emp_id={field?.id}
                    role={role === "applicant" ? "applicant" : role}
                  />
                </div>
              </div> */}
            </>
          )}
          {role !== "applicant" && (
            <>
              <div className="expand-heads d-flex print-hide">
                <button
                  className="ml-auto print-hide"
                  type="button"
                  onClick={() => handleToggle(field.id)}
                >
                  Expand for Details
                  {/* {collapse == field.id ? ( */}
                  {collapse.includes(field.id) ? (
                    <span>
                      <svg
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.00001 2.21856L1.70001 5.51856L0.757341 4.57589L5.00001 0.333222L9.24268 4.57589L8.30001 5.51855L5.00001 2.21856Z"
                          fill="#2DC3E8"
                        />
                      </svg>
                    </span>
                  ) : (
                    <span>
                      <svg
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.99999 3.78047L8.29999 0.480469L9.24266 1.42314L4.99999 5.6658L0.757324 1.42314L1.69999 0.480469L4.99999 3.78047Z"
                          fill="#2DC3E8"
                        />
                      </svg>
                    </span>
                  )}
                </button>
              </div>
              <div
                style={{
                  display: collapse.includes(field.id) ? "block" : "none",
                }}
                className="collapse cust-coll-appl print-hide"
                id={`app-det-coll${field.id}`}
              >
                <div className="row align-items-center">
                  <div className="col-12 col-md-4 col-lg-4">
                    <InputField2
                      // handleOnChange={() => {
                      //   handleAPIDebounced();
                      // }}
                      type="text"
                      label="Employer Name"
                      placeholder={""}
                      source={`employer.${index}.employer_name`}
                      notRequired={true}
                    />
                  </div>
                  {invitationType == "1" && (
                    <>
                      <div className="col-12 col-md-4 col-lg-4">
                        <InputField2
                          // handleOnChange={() => {
                          //   handleAPIDebounced();
                          // }}
                          type="text"
                          label="Position"
                          placeholder={""}
                          source={`employer.${index}.position`}
                          notRequired={true}
                        />
                      </div>
                      <div className="col-12 col-md-4 col-lg-4">
                        <TextInputPhone
                          source={`employer.${index}.phone_no`}
                          label={`Employer Phone Number`}
                          isCountryCode={`employer.${index}.country_code`}
                          labelClass={"lable-title"}
                          additionalInputClass={"cus-control"}
                          widthClass={"w-100"}
                          requiredClass={{
                            fontSize: "70%",
                          }}
                          // onChangeCallback={() => {
                          //   {
                          //     handleAPIDebounced();
                          //   }
                          // }}
                          required={false}
                        />
                      </div>
                    </>
                  )}
                  <div className="col-12 col-md-8 col-lg-8">
                    <InputField2
                      // handleOnChange={() => {
                      //   handleAPIDebounced();
                      // }}
                      type="text"
                      placeholder={""}
                      label="Address"
                      source={`employer.${index}.employer_address`}
                      notRequired={true}
                    />
                  </div>
                  <div className="col-12 col-md-4 col-lg-4">
                    <InputField2
                      // handleOnChange={() => {
                      //   handleAPIDebounced();
                      // }}
                      type="text"
                      placeholder={""}
                      label="City"
                      source={`employer.${index}.employer_city`}
                      notRequired={true}
                    />
                  </div>
                  <div className="col-12 col-md-4 col-lg-4">
                    <InputSelectField
                      label={`State`}
                      stopAutoFocus={true}
                      source={`employer.${index}.employer_state`}
                      options={IncomeAPIResponse?.data?.States?.map((item) => ({
                        value: item?.ID || index,
                        label: item.StateShortCode + " - " + item.StateName,
                      }))}
                      labelAsteriskClass={"color-red"}
                      labelClass={"lable-title"}
                      requiredClass={{
                        fontSize: "70%",
                      }}
                      // onChangeCallback={() => {
                      //   handleAPIDebounced();
                      // }}
                      required={false}
                    />
                  </div>
                  <div className="col-12 col-md-4 col-lg-4">
                    <InputField2
                      // handleOnChange={() => {
                      //   handleAPIDebounced();
                      // }}
                      type="number"
                      placeholder={""}
                      label="Zip Code"
                      source={`employer.${index}.employer_zipcode`}
                      notRequired={true}
                    />
                  </div>
                  {/*  */}
                  {invitationType == "1" && (
                    <>
                      <div className="col-12 col-md-4 col-lg-4">
                        <InputField2
                          // handleOnChange={() => {
                          //   handleAPIDebounced();
                          // }}
                          type="text"
                          label="Manager Name"
                          placeholder={""}
                          source={`employer.${index}.manager_name`}
                          notRequired={true}
                        />
                      </div>
                      <div className="col-12">
                        <div className="row">
                          {methods.watch(
                            `employer.${index}.isfuture_employer`
                          ) == false && (
                            <div className="col-sm-12 col-md-6 col-lg-4 d-flex justify-content-between align-items-center">
                              <InputCheckBoxField
                                notRequired={true}
                                checked={methods.watch(
                                  `employer.${index}.iscurrent_employer`
                                )}
                                label="Is this current employer?"
                                name={`employer.${index}.iscurrent_employer`}
                                // handleOnChange={() => {
                                //   handleAPIDebounced();
                                // }}
                              />
                            </div>
                          )}
                          {methods.watch(
                            `employer.${index}.iscurrent_employer`
                          ) == false && (
                            <div className="col-sm-12 col-md-6 col-lg-4 d-flex justify-content-between align-items-center">
                              <InputCheckBoxField
                                notRequired={true}
                                checked={methods.watch(
                                  `employer.${index}.isfuture_employer`
                                )}
                                label="Is this future employer?"
                                name={`employer.${index}.isfuture_employer`}
                                // handleOnChange={() => {
                                //   handleAPIDebounced();
                                // }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="row">
                          <div className="col-12 col-md-4 col-lg-4">
                            <InputSelectField
                              label={`Employment Status`}
                              source={`employer[${index}].employer_status`}
                              options={[
                                {
                                  value: "Employed Full-Time",
                                  label: "Employed Full-Time",
                                },
                                {
                                  value: "Employed Part-Time",
                                  label: "Employed Part-Time",
                                },
                              ]}
                              labelAsteriskClass={"color-red"}
                              stopAutoFocus={true}
                              labelClass={"lable-title"}
                              requiredClass={{
                                fontSize: "70%",
                              }}
                              // onChangeCallback={() => {
                              //   handleAPIDebounced();
                              // }}
                              required={false}
                            />
                          </div>
                          <div className="col-12 col-md-4 col-lg-4">
                            <InputDateField
                              source={`employer.${index}.employment_start_date`}
                              label="Employment Start Date"
                              labelClass={"lable-title"}
                              // onChangeCallback={() => {
                              //   handleAPIDebounced();
                              // }}
                              notRequired={true}
                            />
                          </div>
                          {methods.watch(
                            `employer.${index}.iscurrent_employer`
                          ) === false &&
                            methods.watch(
                              `employer.${index}.isfuture_employer`
                            ) === false && (
                              <div className="col-sm-12 col-md-6 col-lg-4">
                                <div className="form-group cus-input-group mr-b20">
                                  <InputDateField
                                    source={`employer.${index}.employment_end_date`}
                                    label="Employment End Date"
                                    labelClass={"lable-title"}
                                    // onChangeCallback={() => {
                                    //   handleAPIDebounced();
                                    // }}
                                    notRequired={true}
                                  />
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-lg-4">
                        <InputCurrencyField
                          label="Gross Pay (Pre Tax)"
                          source={`employer.${index}.avrgross_pay`}
                          required={false}
                          labelClass={"lable-title"}
                          requiredClass={{
                            fontSize: "70%",
                          }}
                          // handleOnChange={() => {
                          //   handleAPIDebounced();
                          //   // trigger(`address.${index}.current_rent`);
                          // }}
                        />
                      </div>
                      <div className="col-12 col-md-4 col-lg-4">
                        <InputCurrencyField
                          disabled={true}
                          label="According to document Gross Pay (Pre Tax)"
                          source={`employer.${index}.monthly_avr_gross`}
                          required={false}
                          labelClass={"lable-title"}
                          requiredClass={{
                            fontSize: "70%",
                          }}
                        />
                      </div>
                      <div className="col-12 col-md-4 col-lg-4">
                       
                        <InputSelectField
                          label={`Pay Frequency (of the amt entered)`}
                          source={`employer[${index}].pay_frequency`}
                          options={[
                            {
                              label: "Weekly",
                              value: "Weekly",
                            },
                            {
                              label: "Bi-Weekly",
                              value: "Bi-Weekly",
                            },
                            {
                              label: "Monthly",
                              value: "Monthly",
                            },
                            {
                              label: "Bi-Monthly",
                              value: "Bi-Monthly",
                            },
                            {
                              label: "One-Time",
                              value: "One-Time",
                            },
                            {
                              label: "Yearly",
                              value: "Yearly",
                            },
                          ]}
                          labelAsteriskClass={"color-red"}
                          labelClass={"lable-title"}
                          stopAutoFocus={true}
                          requiredClass={{
                            fontSize: "70%",
                          }}
                          // onChangeCallback={() => {
                          //   handleAPIDebounced();
                          // }}
                          required={false}
                        />
                      </div>
                      <div className="col-12 col-md-4 col-lg-4">
                        <div className="incom-info-block">
                          <h4>
                            <span
                              style={{
                                marginTop: "-6px",
                                marginBottom: "-5px",
                              }}
                              className="lable-title"
                            >
                              Calculated Amounts:
                            </span>
                          </h4>
                          <div className="row">
                            <div className="col-8 small">
                              Yearly Gross Income:
                            </div>
                            <div className="col">
                              <NumericFormat
                                decimalScale={2}
                                value={methods.watch(
                                  `employer.${index}.total_annual_income`
                                )}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-8 small">
                              {" "}
                              Monthly Gross Income:
                            </div>
                            <div className="col">
                              <NumericFormat
                                decimalScale={2}
                                value={methods.watch(
                                  `employer.${index}.total_income`
                                )}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {/*  */}
                  {(invitationType == "1" &&
                    methods?.watch()?.employer[index]?.availableToUpload ==
                      "Yes") ||
                  (invitationType !== "1") ? (
                    <>
                      {role !== "Applicant" &&
                        role !== "applicant" &&
                         (
                          <>
                            <div className="d-flex justify-content-center">
                              <div className="border-top mt-3 w-100"></div>
                            </div>
                            {/* <div className="col-12 col-md-4 col-lg-4 mt-2">
                              <InputSelectField
                                onChangeCallback={() =>
                                  handleReprocessEmployer(index, field.id)
                                }
                                stopAutoFocus={true}
                                required={false}
                                label={`Reprocess Employer`}
                                source={`employer.${index}.correctedDocType`}
                                options={mlClassDetails}
                              />
                            </div> */}

                            <div className="col-12 col-md-4 col-lg-4"></div>
                            <div className="col-12 col-md-4 col-lg-4"></div>
                            {/* <div className="col-12 col-md-4 col-lg-4 ">
                              <div style={{float:"right"}}>
                              <AddDocuverusComment
                                label={
                                  role === "applicant"
                                    ? `Add Comment`
                                    : "Add Docuverus Comment"
                                }
                                title={
                                  role === "applicant"
                                    ? `Add Comment`
                                    : "Add Docuverus Comment"
                                }
                                defaultValue={
                                  IncomeAPIResponse?.data?.employment_details
                                    ?.employer[0]?.section_comment?.admin
                                    ? IncomeAPIResponse?.data
                                        ?.employment_details?.employer[0]
                                        ?.section_comment?.admin
                                    : ""
                                }
                                trn_id={trn_id}
                                is_applicant={
                                  role === "applicant" ? true : false
                                }
                                document_id={3}
                                tag={"addComment_income"}
                                invalidateQueries={"income"}
                                emp_id={field?.id}
                                role={role === "applicant" ? "applicant" : role}
                              />
                              </div>
                            </div> */}

                          </>
                        )}
                    </>
                  ) : (
                    ""
                  )}
                  {(invitationType == "1" &&
                    methods?.watch()?.employer[index]?.availableToUpload ==
                      "Yes") ||
                  invitationType !== "1" ? (
                    <>
                      <EmployerDetails
                        role={role}
                        sourceEmpField={field}
                        sourceEmpIndex={index}
                        mlClassDetails={mlClassDetails}
                        trn_id={trn_id}
                        setError={(fieldName, errObj, focusObj) => {
                          //
                          methods.setError(
                            fieldName,
                            { ...errObj },
                            { ...focusObj }
                          );
                        }}
                        handleOnChange={() => {
                          handleAPIDebounced();
                        }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </>
          )}
           <div className="col-12">
                <div className="d-flex justify-content-end">

                 
                  <AddDocuverusComment
                    label={
                      role === "applicant"
                        ? `Add Comment`
                        : "Add Docuverus Comment"
                    }
                    title={
                      role === "applicant"
                        ? `Add Comment`
                        : "Add Docuverus Comment"
                    }
                    defaultValue={
                      IncomeAPIResponse?.data?.employment_details?.employer[index]?.section_comment?.admin
                        ? IncomeAPIResponse?.data?.employment_details?.employer[index]?.section_comment?.admin
                        : ""
                    }
                    trn_id={trn_id}
                    is_applicant={role === "applicant" ? true : false}
                    document_id={3}
                    tag={"addComment_income"}
                    invalidateQueries={"income"}
                    emp_id={field?.id}
                    role={role === "applicant" ? "applicant" : role}
                  />
                </div>
              </div>
        </div>
        {/* <br />
        <button
          type="submit"
          className="blue-btn px-4"
          disabled={!methods.formState.isDirty}
          // ref={submitButtonRef}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-floppy-fill" viewBox="0 0 16 16">
            <path d="M0 1.5A1.5 1.5 0 0 1 1.5 0H3v5.5A1.5 1.5 0 0 0 4.5 7h7A1.5 1.5 0 0 0 13 5.5V0h.086a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5H14v-5.5A1.5 1.5 0 0 0 12.5 9h-9A1.5 1.5 0 0 0 2 10.5V16h-.5A1.5 1.5 0 0 1 0 14.5z" />
            <path d="M3 16h10v-5.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5zm9-16H4v5.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5zM9 1h2v4H9z" />
          </svg>
          &nbsp;Save Changes
        </button> */}
      </div>
    </div>
  );
};

export default EmployerSource;
