import { createSlice } from "@reduxjs/toolkit";
// Define a type for the slice state
interface applicantDetails {
  uploadPetImageId: any[];
}

// Define the initial state using that type
const initialState: applicantDetails = {
  uploadPetImageId: [],
};
const applicantDetailsSlice = createSlice({
  name: "applicantDetails",
  initialState,
  reducers: {
    // add actions and reducers here
    setUploadPetImageId: (state, action) => {
      if (action.payload?.type == "APICALL") {
        state.uploadPetImageId = action.payload?.data?.map((data, index) => {
          return {
            index: index,
            id: data?.file_id,
          };
        });
      }
      if (action.payload?.type === "UPLOAD") {
      if (state.uploadPetImageId[action.payload?.index]) {
        state.uploadPetImageId[action.payload?.index] = {
          index: action.payload?.index,
          id: action.payload?.id,
          file: action.payload?.file,
        };
        return;
      }
      state.uploadPetImageId = [
        ...state.uploadPetImageId,
        {
          index: action.payload?.index,
          id: action.payload?.id,
          file: action.payload?.file,
        },
      ];
      }
      if (action.payload?.type === "DELETE") {
        for (let index = 0; index < state?.uploadPetImageId?.length; index++) {
          const element = state.uploadPetImageId[index];

          if (element?.index == action.payload?.index) {
            state.uploadPetImageId[element.index] = {
              index: action.payload?.index,
              id: null,
            };
          }
        }
      }
      if (action.payload?.type === "DELETESOURCE") {
        state.uploadPetImageId = [];
      }
    },
  },
});

export const { setUploadPetImageId } = applicantDetailsSlice.actions;
export default applicantDetailsSlice.reducer;
