import {
  Datagrid,
  FunctionField,
  ListBase,
  Pagination,
  TextField,
  useListContext,
  useStore,
  Resource
} from "react-admin";
import { Grid, Typography, TextField as MuiTextField } from "@mui/material";
import { debounce } from "lodash";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import DateComponent from "../DateComponent";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { JSONTree } from "react-json-tree";

// Define a filter component for the list view
const MyListToolBar = ({ title }) => {
  const { displayedFilters, filterValues, setFilters, hideFilter, sort } =
    useListContext();

  // Debounce the search function
  const debouncedSearch = debounce((value) => {
    // Perform your search logic here
    setFilters({ ...filterValues, q_Logs: value }, []);
  }, 500); // 500ms debounce delay

 
  const handleSearchChange = (event) => {
    const value = event.target.value;

    debouncedSearch(value);
  };

  return (
    <Grid style={{ marginTop: 0, marginBottom: 8 }} container spacing={3}>
      {/* Left side with title */}
      <Grid item xs={12} md={6}>
        <Typography variant="h6">{title}</Typography>
      </Grid>

      {/* Right side with search input, filter button, and add button */}
      <Grid
        item
        xs={12}
        md={6}
        container
        justifyContent="flex-end"
        alignItems="center"
      >
        <div className="group-btn">
          {/* Search Input */}
          <MuiTextField
            placeholder="search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            defaultValue={filterValues.q_Logs}
            onChange={handleSearchChange}
            variant="outlined"
            size="small"
          />

          {/* Filter Button */}
        </div>
      </Grid>
    </Grid>
  );
};

// Define the list view component using ListBase
export const MyList = ({ children, toolBar, ...props }) => {
  return (
    <ListBase
      {...props}
      perPage={100} // Number of items per page
    >
      {toolBar}

      {children}

      <Pagination rowsPerPageOptions={[100, 200, 300, 400, 500]} />
      <br/>
      <br/>
      <br/>
      <br/>
    </ListBase>
  );
};

const AuditLogsList = ({ record }) => {

  const [switchSubscriberObj] = useStore("switchSubscriberName");

  let tabsData = [
    {
      id: 1,
      tab: "Application Logs",
      name: "get-application-logs",
      contextFromTab: "ApplicationLogs",
    },
    {
      id: 2,
      tab: record?.appName + " Logs",
      name: "get-pms-logs",
      contextFromTab: "PMSLogs",
    },
  ];
  if(record?.appName == null){
    tabsData?.pop();
  }
  // const { state } = useLocation();
  const [tab, setTab] = useState(0);
  return (
    <div>
     
    <div className="application-tabs">
    <div className="nav-flexes">
          <nav>
            <div
              className="nav nav-tabs app-nav-tabs"
              id="nav-tab"
              role="tablist"
              // autoFocus={(state?.type==="unit"||state?.type==="floor")}

            >
            
              {tabsData?.map((item, index) => (
                <button
                  key={item.id}
                  className={`nav-link ${tab === index ? "active" : ""}`}
                  id={`nav-name-tabAudit0${index}`}
                  data-bs-toggle="tab"
                  data-bs-target={"#nav-nameAudit" + index}
                  type="button"
                  role="tab"
                  aria-controls={"nav-nameAudit" + index}
                  aria-selected="true"
                  // autoFocus={(state?.type==="unit"||state?.type==="floor")}
                  onClick={() => {
                    setTab(index);
                  }}
                >
                  <div className="name1">
                    <h6>{item?.tab}</h6>
                  </div>
                </button>
              ))}
            </div>
          </nav>
        </div>
        <div className="tab-content app-nav-tabcontent" id="nav-tabContent" >
          {tabsData?.map((item, index) => (
         
            <div
              key={item.id}
              className={`tab-pane fade show ${
                tab === index ? "active" : ""
              } name1-wrap`}
              id={`nav-nameAudit${index}`}
              role="tabpanel"
              aria-labelledby={`nav-name-tabAudit${index}`}
              tabIndex={index}
              style={{padding:"2px 16px 0 16px"}}
            >
             
              {tab === index && <>
               { item.contextFromTab === "ApplicationLogs" ?(
                <Resource
                   name="application-logs"
                   list={<MyList
                toolBar={<MyListToolBar title={""} />}
                sort={{ field: "id", order: "DESC" }}
                filter={{ applicationNumber: record?.AppID, applicantID: record?.id }}
                disableSyncWithLocation
              >
                <Datagrid
                  sx={{
                    "& .RaDatagrid-headerCell": {
                      fontWeight: "bold",
                      fontSize: 12,
                    },
                    "& .RaDatagrid-rowEven": { backgroundColor: "#F9F9F9" },
                  }}
                  bulkActionButtons={false}
                >
                  <FunctionField
                    label="Date"
                    render={(record) => <DateComponent date={record?.CreatedDate} />}
                    sortBy="CreatedDate"
                  />
                  <TextField source="Action" />
                  <TextField source="ActionArea" />
                  <TextField source="UserName" />
                  <TextField label="Browser" source="Browser_Version" />
                  <TextField source="OS" />
                  <TextField label="IP Address" source="IP_Location" />
                  {/* Add more fields as needed */}
                </Datagrid></MyList>
                   }
                   />
              ): (
                <Resource
                   name="pms-logs"
                   list={<MyList
                toolBar={<MyListToolBar title={""} />}
                sort={{ field: "id", order: "DESC" }}
                filter={{ applicationNumber: record?.AppID, applicantID: record?.id }}
                disableSyncWithLocation
              >
                <Datagrid
                  sx={{
                    "& .RaDatagrid-headerCell": {
                      fontWeight: "bold",
                      fontSize: 12,
                    },
                    "& .RaDatagrid-rowEven": { backgroundColor: "#F9F9F9" },
                  }}
                  bulkActionButtons={false}
                >
                  <FunctionField
                    label="Date"
                    render={(record) => <DateComponent date={record?.createdAt} />}
                    sortBy="createdAt"
                  />
                  {/* <TextField source="AppName" /> */}
                  <TextField source="action" />
                  <TextField source="InteractionType" />
                  <TextField source="ApplicantName" />
                  <TextField source="DocumentName" />
                  <TextField source="status"/>
                  {/* Add more fields as needed */}
                </Datagrid></MyList>
                   }
                   />
              )}
              </> }
            </div>
          ))}
        </div>
    </div>
    </div>
  );
};

export default AuditLogsList;
