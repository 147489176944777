import { createSlice } from '@reduxjs/toolkit';
interface users {
    role: string | null,
    userTrnId: string | null,
    aspNetUserId: string | null,
    token:string|null,
    loginData: {}|null,
    isLoggedIn:boolean,
    // switchSubscriber:boolean,
    applicationStatus: boolean | null;
}

const initialState: users = {
    role: null,
    userTrnId: null,
    aspNetUserId: null,
    token:null,
    loginData:null,
    isLoggedIn:false,
    // switchSubscriber:false,
    applicationStatus: null,
}
const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setUserRole: (state, action) => {
            state.role = action.payload;
        },
        setUserTrnId: (state, action) => {
            state.userTrnId = action.payload;
        },
        setaspNetUserId: (state, action) => {
            state.aspNetUserId = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setLoginData: (state, action) => {
            state.loginData = action.payload;
        },
        setIsLoggedIn: (state, action) => {
            state.isLoggedIn = action.payload;
        },
        // setSwitchSubscriber:(state,action)=>{
        //     state.switchSubscriber=action.payload;
        // },
        setLogout: (state, action) => {
            state.isLoggedIn = false;
            state.loginData = null;
            state.role = null;
            state.aspNetUserId = null;
            state.token = null;
            sessionStorage.clear();
        },
        setApplicationStatus: (state, action) => {
            state.applicationStatus = action.payload;
        },
    },
});

export const { setUserRole, setUserTrnId,setaspNetUserId,setToken,setLoginData,setIsLoggedIn,setLogout,setApplicationStatus} = usersSlice.actions
export default usersSlice.reducer
