import React from 'react'
import { Box, Button, InputAdornment } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextInput, required, useUpdate, useNotify, useRefresh, SelectInput, useCreate } from 'react-admin';
import { useForm, FormProvider, get } from 'react-hook-form';
import { Button as MuiButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useParams } from "react-router-dom";
import img1 from "../../assets/images/img1.svg";
import InputField2 from '../common/InputComponents/InputField2';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import LoadingSpinner from '../common/loadingSpinner';
import TextInputPhone from '../DocumentAnalysis/components/inputComponents/TextInputPhone';

export default function AddCoapplicant(props) {
    const notify = useNotify();
    const refresh = useRefresh();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { id } = useParams();
    const subscriberId=id || props.record.SubscriberID
    // form validation schema 
    const validationSchema = Yup.lazy((value) =>
    Yup.object().shape({
        // first_name: Yup.string().trim("First name is required!").required("First name is required!"),
        // last_name: Yup.string().trim("Last name is required!").required("Last name is required!"),
        first_name : Yup.string()
          .typeError("First name is required!")
          .max(75, "maximum 75 characters is required")
          .required("First name is required!")
          .min(2, "Minimum 2 characters is required"),
        last_name : Yup.string()
          .typeError("Last name is required!")
          .max(75, "maximum 75 characters is required")
          .required("Last name is required!")
          .min(2, "Minimum 2 characters is required"),
        email: Yup.string().max(75, "maximum 75 characters is required").email("Invalid email format").required("Email is required!") .matches(/^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/, "Invalid domain"),
        // mobile_number: Yup.string().required("Mobile number is required!").min(10, "Min 10 digits are required").typeError("Required!"),
        mobile_number: Yup.string().required("Mobile number is required!").typeError("Mobile number is required!")       
        .test("rent", "Min 10 digits are required",(value) => 
        {
          let temp:any =  value?.toString().replace(/\D/g, '');
            return temp?.length > 10 ? true : false;
          }),
    })
    );

    // form initial states
    const form = useForm({
        defaultValues: {
            first_name: '',
            last_name: '',
            email: '',
            ApplicationNumber: props.record.id,
            role: 'Co-applicant',
            SubscriberID: subscriberId,
            mobile_number: ""
        },
        resolver: yupResolver(validationSchema),
    });

    // Destructuring form
    const { register, reset } = form;

    const [create, { isLoading, error }] = useCreate();

    // form submit handler
    const onSubmit = (value) => {
        create('addcoappcosigner', { data: value }, {
            onSettled: (data, error) => {
                notify(`Applicant has been added`, { type: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' }})
                refresh();
                handleClose();
                reset();
            }, onError: (e: any) => notify(`Operation fail:${e.message}`, { type: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' }})
        })
    }
    return (
        <>
            {props.buttonAdd ? (
                <button
                  type="button"
                  onClick={handleOpen}
                >
                  <img src={img1} alt="" />
                  <span>Add</span>
                </button>
            ) : (
                 <MuiButton onClick={handleOpen} startIcon={<AddIcon />} variant='text' sx={{textTransform:"capitalize"}}>Add</MuiButton>
            )}
            <Dialog
                fullWidth
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    Add
                </DialogTitle>
                <DialogContent>
                    <FormProvider {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-auto">
                                <div className="cus-input app-option-btn mr-b10">
                                    <div className="it-option" id="a-oprtions">
                                    <label htmlFor="role">
                                        <input
                                        type="radio"
                                        id="role"
                                        value="Co-applicant"
                                        {...register("role")}
                                        />
                                        <span>Co-applicant</span>
                                    </label>
                                    <label htmlFor="role2">
                                        <input
                                        type="radio"
                                        id="role2"
                                        value="Co-signer"
                                        {...register("role")}
                                        />
                                        <span>Co-signer</span>
                                    </label>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 mr-b10">
                                <InputField2
                                    label="First Name"
                                    source={`first_name`}
                                    placeholder="First Name"
                                    labelClass={"lable-title"}
                                    additionalInputClass={"cus-control"}
                                  />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 mr-b10">
                                <InputField2
                                    label="Last Name"
                                    source={`last_name`}
                                    placeholder="Last Name"
                                    labelClass={"lable-title"}
                                    additionalInputClass={"cus-control"}
                                  />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 mr-b10">
                                <InputField2
                                    label="Email"
                                    source={`email`}
                                    placeholder="Email"
                                    labelClass={"lable-title"}
                                    additionalInputClass={"cus-control"}
                                  />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 mr-b20">
                                    <TextInputPhone
                                    source={`mobile_number`}
                                    label="Mobile"
                                    isCountryCode={`country_code`}
                                    labelClass={"lable-title"}
                                    additionalInputClass={"cus-control"}
                                    widthClass={"w-100"}
                                    requiredClass={{
                                        fontSize: "70%",
                                    }}
                                    // required={false}
                                    />
                                </div>
                            </div>
                            
                            <Box display={'flex'} justifyContent={'end'}>
                                <Button sx={{ mx: 1 }} onClick={() => {handleClose(); reset();}} variant='outlined' color='secondary' type='button' >
                                    Cancel
                                </Button>
                                <Button startIcon={<AddIcon />} sx={{ mx: 1 }} variant='contained' type='submit'>
                                    Add
                                </Button>
                            </Box>
                        </form>
                    </FormProvider>
                </DialogContent>
            </Dialog>
            {isLoading ? <LoadingSpinner /> : ""}
        </>
    )
}
