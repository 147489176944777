import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useCallback,
  useContext,
} from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormDataConsumer,
  useGetIdentity,
  useDataProvider,
  useNotify,
  Confirm,
  useGetOne,
  Button,
  useCreate,
  useRefresh,
  useList,
  useGetList,
} from "react-admin";
import * as Yup from "yup";
                    

import { throttle } from 'lodash';
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import YesNoRadioButton from "../../componets/common/InputComponents/YesNoRadioButton";
import ShowDocumentStatus from "./components/ShowDocumentStatus";
import ShowComments from "./components/ShowComments";
import overa from "../../assets/images/overa.svg";
import redTick from "../../assets/images/redTick.svg";
import greenTick from "../../assets/images/greenTick.svg";
import ShowValidationMessages from "./components/ShowValidationMessages";
import SelectInput from "./components/inputComponents/SelectInput";
import InputCurrencyField from "./components/inputComponents/InputCurrencyFiled";
import InputField2 from "../common/InputComponents/InputField2";
import AddDocuverusComment from "./Modals/AddDocuverusComment";
import DragDropDocumentButton from "./components/DragDropDocumentButton";
import FormInstruction from "../common/FormInstruction";
import AlertDialog from "../common/AlertDialog";
import DeleteContainedBtn from "../common/DeleteContainedBtn";
import Card from "@mui/material/Card";
import { CardHeader, Button as MuiButton } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SyncIcon from "@mui/icons-material/Sync";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import FilePreviewThumnail from "../common/FilePreviewThumnail";
import adBankInstructions1 from "../../assets/images/acceptable_documents_bank_tutorial.png";
import adBankInstructions2 from "../../assets/images/unacceptable_documents_tutorial.png";
import debounce from "lodash.debounce";
import NewMetaDataRulesBank from "./components/NewMetaDataRulesBank";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { RootState } from "../../Store/store";
import { useSelector } from "react-redux";
import { JSONTree } from "react-json-tree";
import InputSelectField from "../common/InputComponents/InputSelectField";
import { useFormContext } from "react-hook-form";
import CustomPreviewUpload from "../common/CustomPreviewUpload";
import FilePreview from "../common/FilePreview";
import NoDocReasonRadioButtons from "../common/InputComponents/NoDocReasonRadioButtons";
import { useParams } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import moment from "moment";
import InputDateField from "../common/InputComponents/InputDateFieldOld";
import BankGptReadingData from "./BankGptReadingData";
import StatusComponent from "./components/StatusComponent";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const ButtonInTabs = ({ onClick, children }) => {
  return <Button onClick={onClick} children={children} />;
};

const BankingStatementForm = forwardRef((props: any, ref) => {
  // const { identity, isLoading: identityLoading } = useGetIdentity();
  const queryClient = useQueryClient();
  const { id, appType } = useParams();
  const invitationType: any = appType;
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [confirmDelete, setConfirmDelete] = useState(false);

  const [confirmDeleteSource, setConfirmDeleteSource] = useState(false);
  const [source_id, setSourceId] = useState("");
  // const [fileError,setFileError] = useState(false)
  const { required } = props;
  const userRole = useSelector((state: RootState) => state.users.role);
  const [disableNewBtn, setdisableNewBtn] = useState(false);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  // const [collapse, setCollapse] = useState([]);

  useImperativeHandle(ref, () => ({ handleClickByRef, handleSubmitManual }));

  const localSchema = Yup.object({
    response: Yup.string()
      .required("required")
      .typeError("required")
      .max(75, "maximum 75 characters is required"),
    banking: Yup.array().when("response", {
      is: (value) => {
        return value === "Yes";
      },
      then: Yup.array()
        .of(
          Yup.object().shape({
            files: Yup.array().min(1, "At Least 1 Document Is Required"),
          })
        )
        .min(1, "must have atleast 1 records"),
    }),
  });

  const schema = Yup.object({
    response: Yup.string()
      .max(75, "maximum 75 characters is required")
      .nullable(),
  });

  const schemaNotRequired = Yup.object({
    response: Yup.string()
      .max(75, "maximum 75 characters is required")
      .nullable(),
  });

  const getSchema = () => {
    if (required) {
      if (userRole === "applicant") {
        return localSchema;
      } else {
        return schema;
      }
    } else {
      return schemaNotRequired;
    }
  };
  // const methods = useForm();

  const methods = useForm({
    resolver: yupResolver(getSchema()),
    mode: "onBlur",
  });

  async function handleClickByRef() {
    submitButtonRef?.current?.click();
    const isValidForm = await methods.trigger();

    return isValidForm;
  }
  // done changes to handle co-applicant issue
  const userTrnId = useSelector((state: RootState) => state.users.userTrnId);
  const trn_id = props?.trn_id || userTrnId;
  const isOpeningForDashboard = useSelector(
    (state: RootState) => state.app.isOpeningForDashboard
  );

  // const { data: bankData, isLoading } = useGetOne("bankDoc", { id: trn_id }, { refetchOnWindowFocus: false, keepPreviousData: true, staleTime: 5 });

  useEffect(() => {
    const formData = methods.getValues();
    const response = formData.response ? formData.response : props.response; //? props.response : props.status == 1 ? 'Yes' : null;

    // const ApiResponseCheck = props.banking.map((item, index) => ({
    //   ...item,
    //   bank_name: formData.banking[index].bank_name || item.bank_name,
    // }));
    // if(!isOpeningForDashboard)
    // if (response == 'Yes') {
    //   if (props.banking.length < 1) {
    //     if (formData.response == null) {
    //       handleAddNewAccount(1)
    //       return;
    //     }
    //   }
    // }
    methods.reset({
      ...props,
      banking: props.banking.map((item, index) => ({
        ...item,
        bank_name:
          methods.getValues(`banking.${index}.bank_name`) || item.bank_name,
        account_type:
          methods.getValues(`banking.${index}.account_type`) ||
          item.account_type,
        current_balance:
          methods.getValues(`banking.${index}.current_balance`) ||
          item.current_balance,
      })),
      response: response,
    });
    return () => {};
  }, [props?.banking, props?.response]);

  const {
    mutate: createNewAccount,
    isLoading: loadingNewAccount,
    isSuccess: newAccountSuccess,
  } = useMutation(
    "addNewAccount",
    (data: any) =>
      dataProvider.create("income", {
        data: {
          document_id: data.document_id,
          source_id: data.source_id,
          trn_id: data.trn_id,
        },
      }),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["bankDoc"]);
        notify("New account added successfully", {
          type: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
      onError: (data) => {
        alert("Fail adding new account");
        setdisableNewBtn(false);
      },
      onMutate: () => {
        notify("Adding new account...", {
          type: "info",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
    }
  );

  const {
    mutate: deleteAccount,
    isLoading: loadingDeleteAccount,
    isSuccess: createDeleteAccount,
  } = useMutation(
    "deleteAccount",
    (data: any) =>
      dataProvider.deleteSequence("income", {
        document_id: data.document_id,
        source_id: data.source_id,
        trn_id: data.trn_id,
        subUrl: data.subUrl,
      }),
    {
      onSuccess: (data) => {
        setConfirmDeleteSource(false);
        queryClient.invalidateQueries(["bankDoc"]);
        notify("Account deleted successfully", {
          type: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
      onError: (data) => {
        setConfirmDeleteSource(false);
        alert("Fail deleting account");
      },
      onMutate: () => {
        notify("Deleting account...", {
          type: "info",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
    }
  );

  const {
    mutate: deleteAllAccount,
    isLoading: loadingdeleteAllAccount,
    isSuccess: createdeleteAllAccount,
  } = useMutation(
    "deleteAllAccount",
    (data: any) => {
      let promises: any = [];
      data.banking?.map(async (bankDetail) => {
        promises.push(
          dataProvider.deleteSequence("income", {
            document_id: 6,
            source_id: bankDetail?.bank_statement_number,
            trn_id: trn_id,
            subUrl: "/income/deleteSource",
          })
        );
      });
      return Promise.all(promises);
    },
    {
      onSuccess: (data) => {
        handleSubmitDocReason("No");
        setConfirmDelete(false);
        queryClient.invalidateQueries(["bankDoc"]);
        notify("Account deleted successfully", {
          type: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
      onError: (data) => {
        setConfirmDelete(false);
        alert("Fail deleting account");
      },
      onMutate: () => {
        notify("Deleting account...", {
          type: "info",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
    }
  );

  const {
    mutate: updateBanking,
    isLoading: loadingupdateBanking,
    isSuccess: createupdateBanking,
  } = useMutation(
    "updateBanking",
    (data: any) =>
      dataProvider.update("other_sections", {
        id: data.id,
        data: data.data,
        previousData: data.previousData,
      }),
    {
      onSuccess: (data) => {
        if (userRole !== "applicant") {
          // queryClient.invalidateQueries(['bankDoc'])
          notify("Bank details updated successfully", {
            type: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        }
      },
      onError: (data) => {
        alert("Fail updating bank details");
      },
      onMutate: () => {
        notify("Updating bank details...", {
          type: "info",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
    }
  );

  // const handleAddNewAccount = async (source) => {
  //   const payload = {
  //     document_id: 6,
  //     source_id: source,
  //     trn_id: trn_id,
  //   };
  //   createNewAccount(payload);
  // };
useEffect(()=>{
   
    setdisableNewBtn(false);  
  },[props?.banking?.length + 1])
  
  useEffect(()=>{
    if(props?.banking?.length === 0)
      methods.setValue("response", "No");
  },[props?.banking?.length]);

  const handleAddNewAccount = useCallback (
     throttle(() => {
      if(methods.watch("banking")?.length < 3){
        console.log("bankResopose",props?.banking)
        createNewAccount({
          document_id: 6,
          source_id: props?.banking?.length + 1,
          trn_id: trn_id,
        });
      }
     //setdisableNewBtn(false);    
    }, 4000), 
    [methods,props?.banking]
  );
  const handleAddNewAccountYesNo = async () => {
    const payload = {
      document_id: 6,
      source_id: 1,
      trn_id: trn_id,
    };
    createNewAccount(payload);
  };

  const handleDeleteAccount = async (s_id) => {};

  const onSubmitEmpty = (val) => null;

  const handleSubmitManual = () => {
    onSubmit(methods.getValues());
  };

  const onSubmit = (values) => {
    if (values.response === "Yes") {
      const more_props = {
        availableToUpload: "Yes",
        fileCount: 1,
        account_number: "",
        no_doc_reason: null,
        other_reason: null,
      };

      const more_props_ats = {
        availableToUpload: "Yes",
        fileCount: 1,
        account_number: "",
      };

      const payload = values.banking.map((obj) => ({
        ...obj,
        ...(invitationType !== "1" ? more_props : more_props_ats),
        fileCount: obj.files.length,
        no_doc_reason:
          obj.files?.length > 0
            ? null
            : obj?.no_doc_reason == "other"
            ? obj?.other_reason
            : obj?.no_doc_reason,
        other_reason:
          obj?.availableToUpload !== "Yes" && obj?.no_doc_reason == "other"
            ? obj?.other_reason
            : null,
      }));

      const final_paylaod = {
        ...values,
        banking: payload,
        subUrl: "/income/saveBankingSection",
        trn_id: trn_id,
        response: "Yes",
      };

      delete final_paylaod.SOCRstatus;
      delete final_paylaod.adminValidationMessages;
      delete final_paylaod.applicantValidationMessages;
      delete final_paylaod.OCR_SOCRstatus;
      delete final_paylaod.rules;
      delete final_paylaod.role;

      updateBanking({
        id: trn_id,
        data: final_paylaod,
        previousData: [],
      });
    } else {
      const final_paylaod = {
        subUrl: "/income/saveBankingSection",
        trn_id: trn_id,
        Question: "Do you have a Bank Account?",
        response: "No",
        banking: [],
        employerCount: 0,
      };
      updateBanking({
        id: trn_id,
        data: final_paylaod,
        previousData: [],
      });
    }
  };

  const handleSubmitDocReason = async (val) => {
    const final_paylaod = {
      subUrl: "/income/saveBankingSection",
      trn_id: trn_id,
      Question: "Do you have a Bank Account?",
      response: val,
      banking: [],
      employerCount: 0,
    };
    updateBanking({
      id: trn_id,
      data: final_paylaod,
      previousData: [],
    });
  };

  const deleteAllSource = async () => {
    deleteAllAccount({
      banking: props.banking,
    });
  };
  const handleAddEmployerAPIDebounced = useCallback(
    debounce(
      (nextValue) =>
        (async () => {
          handleAddNewAccountYesNo();
        })(),
      1000
    ),
    [] // will be created only once initially
  );

  const { data: meta_d_response } = useGetOne(
    "getMetaData",
    { id: 6, meta: { trn_id } },
    { refetchOnWindowFocus: false, staleTime: 5 }
  );

  const [expandBankSection, setExpandBankSection] = useState(true);

  useEffect(() => {
    if (userRole === "applicant") setExpandBankSection(true);
  }, [userRole]);

  useEffect(() => {
    if (methods.watch("response") === "Yes") setExpandBankSection(true);
  }, [methods.watch("response")]);

  return (
    <>
      {props && (
        <FormProvider {...methods}>
          <div className="info-toggle">
            <div className="toggle-heads ">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <b className="mx-3">Bank Statements</b>
                  <br />
                  <div className="subcontent p-1 pb-t10">
                    <div className="upload-subwrap">
                      {userRole == "applicant" && (
                        <p className="prf-upload">
                          Upload PDF Bank Statements Here
                        </p>
                      )}
                      {userRole != "applicant" &&
                        methods.watch("response") !== "Yes" && (
                          <p className="prf-upload">
                            <strong style={{ color: "#FF8585" }}>
                              Note:{" "}
                            </strong>
                            No documents uploaded
                          </p>
                        )}
                    </div>
                  </div>
                </div>
                {userRole !== "applicant" && (
                  <IconButton
                    className="m-1 print-hide"
                    onClick={() => setExpandBankSection((v) => !v)}
                    color="primary"
                  >
                    {!expandBankSection ? <AddIcon /> : <RemoveIcon />}
                  </IconButton>
                )}
              </div>
              <div
                className={` ${!expandBankSection ? "d-none" : "d-display"} `}
              >
                <form
                  onSubmit={methods.handleSubmit(
                    userRole === "applicant" ? onSubmitEmpty : onSubmit
                  )}
                >
                  <>
                    <div className={`px-3 card-body cust-card-appl`}>
                      <div className="inner-block-flex w-100">
                        <YesNoRadioButton
                          required={props.required}
                          // label="Do you have PDF bank statements available to upload? "
                          label={
                            invitationType == "1"
                              ? "Do you have a Bank Account ? "
                              : "Do you have PDF bank statements available to upload?"
                          }
                          source="response"
                          disable={loadingNewAccount}
                          handleOnClick={(val) => {
                            if (val === "Yes" && props.banking.length === 0) {
                              handleAddEmployerAPIDebounced();
                            }
                            if (
                              val === "No" &&
                              props.banking.length === 0 &&
                              (methods.watch("response") === "Yes" ||
                                !methods.watch("response"))
                            ) {
                              handleSubmitDocReason(val);
                            }
                            if (val === "No" && props.banking.length > 0) {
                              setConfirmDelete(true);
                            }
                          }}
                        />
                      </div>
                      {methods.watch("response") &&
                        methods.watch("response") === "Yes" && (
                          <ShowComments
                            section_comment={
                              props.banking?.[0]?.section_comment || []
                            }
                            role={
                              userRole === "applicant" ? "applicant" : userRole
                            }
                          />
                        )}
                    </div>
                    <FormDataConsumer>
                      {({ formData, ...rest }) => {
                        if (formData.response === "Yes")
                          return (
                            <div>
                              <BankingSections
                                source="banking"
                                // role={userRole === 'applicant' ? 'applicant' : userRole}
                                trn_id={trn_id}
                                SOCRstatus={
                                  props.OCR_SOCRstatus || {
                                    source_1: { SOCRStatus: null },
                                    source_2: { SOCRStatus: null },
                                    source_3: { SOCRStatus: null },
                                  }
                                }
                                mannualSOCRstatus={
                                  props?.SOCRstatus || {
                                    Source_1: null,
                                    Source_2: null,
                                    Source_3: null,
                                  }
                                }
                                adminValidationMessages={
                                  props.adminValidationMessages
                                }
                                applicantValidationMessages={
                                  props.applicantValidationMessages
                                }
                                metaRules={meta_d_response?.records?.rules}
                                meta_d_response={meta_d_response}
                                handleSubmitManual={handleSubmitManual}
                                formErrors={methods.formState.errors}
                                invitationType={invitationType}
                              />
                              <div style={{marginLeft: 16}}>
                                <img src={adBankInstructions1} width="400px"/>
                                <img src={adBankInstructions2} width="400px"/>
                              </div>
                            </div>
                          );
                      }}
                    </FormDataConsumer>
                    <div className="d-flex justify-content-between pt-4 print-hide mb-2">
                      {props?.banking?.length > 0 &&
                      methods.watch("response") === "Yes" &&
                      userRole !== "applicant" ? (
                        <button
                          type="submit"
                          className="btn btn-primary print-hide mb-2"
                          style={{
                            backgroundColor: "rgb(45, 195, 232)",
                            color: "white",
                            marginLeft: 18,
                            padding: "8px 15px 8px 15px",
                          }}
                          disabled={loadingupdateBanking}
                        >
                          {" "}
                          Save
                        </button>
                      ) : (
                        <div></div>
                      )}

                      {props?.banking?.length > 0 &&
                        methods.watch("response") === "Yes" && (
                          <span className="print-hide">
                            <AddDocuverusComment
                              label={
                                userRole === "applicant"
                                  ? `Add Comment`
                                  : "Add Docuverus Comment"
                              }
                              title={
                                userRole === "applicant"
                                  ? `Add Comment`
                                  : "Add Docuverus Comment"
                              }
                              trn_id={trn_id}
                              is_applicant={
                                userRole === "applicant" ? true : false
                              }
                              defaultValue={
                                props?.banking?.[0]?.section_comment?.admin ||
                                ""
                              }
                              document_id={6}
                              tag={"addComment_banking"}
                              invalidateQueries={"bankDoc"}
                              emp_id={null}
                              role={
                                userRole === "applicant"
                                  ? "applicant"
                                  : userRole
                              }
                            />
                          </span>
                        )}
                    </div>

                    {userRole === "applicant" && (
                      <button
                        type="submit"
                        ref={submitButtonRef}
                        className="btn btn-primary print-hide"
                        style={{ display: "none" }}
                      >
                        {" "}
                        Save
                      </button>
                    )}
                  </>
                </form>
              </div>
              {/* <Dialog
                open={confirmDelete}
                onClose={() => {
                  methods.setValue("response", "Yes");
                  setConfirmDelete(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {`Are you sure want to delete bank statements ?`}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Warning! Once deleted cannot revert back .
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <button
                    className="btn btn-info text-light"
                    onClick={() => {
                      methods.setValue("response", "Yes");
                      setConfirmDelete(false);
                    }}
                  >
                    No
                  </button>
                  <button
                    className="btn btn-info text-light"
                    onClick={() => {
                      deleteAllSource();
                    }}
                  >
                    Yes
                  </button>
                </DialogActions>
              </Dialog> */}
              <Card>
                <CardHeader sx={{ padding: 0 }} />
              </Card>
              <Confirm
                isOpen={confirmDelete}
                sx={{
                  "& .RaConfirm-iconPaddingStyle": { display: "none" },
                }}
                title=" Are you sure want to delete bank statements ?"
                content={" Warning! Once deleted cannot revert back ."}
                onConfirm={() => deleteAllSource()}
                onClose={() => {
                  methods.setValue("response", "Yes");
                  setConfirmDelete(false);
                }}
              />

              {/* <Dialog
                open={confirmDeleteSource}
                onClose={() => {
                  setSourceId("");
                  setConfirmDeleteSource(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {`Delete bank statement ?`}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure, you want to delete bank statement ?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <button
                    className="btn btn-info text-light"
                    onClick={() => {
                      setSourceId("");
                      setConfirmDeleteSource(false);
                    }}
                  >
                    No
                  </button>
                  <button
                    className="btn btn-info text-light"
                    onClick={() => {
                      handleDeleteAccount(source_id);
                    }}
                  >
                    Yes
                  </button>
                </DialogActions>
              </Dialog> */}
            </div>

            {props?.banking?.length < 3 &&
              methods.watch("response") === "Yes" && (
                <div
                  className={`${
                    !expandBankSection ? "d-none" : "d-display"
                  }  collpase-ic-new text-center print-hide`}
                >
                  {props?.banking?.length >= 1 && <button
                    style={{ fontSize: "1rem", fontFamily: "OpenSans-Bold" }}
                    disabled={disableNewBtn}
                    onClick={() =>{
                      setdisableNewBtn(true);
                      handleAddNewAccount();
                    }}
                  >
                    + Add Account
                  </button>}
                </div>
              )}
          </div>
        </FormProvider>
      )}
    </>
  );
});

const BankingSections = ({
  source,
  metaRules = [],
  trn_id,
  meta_d_response,
  mannualSOCRstatus,
  SOCRstatus,
  adminValidationMessages,
  applicantValidationMessages,
  formErrors,
  handleSubmitManual,
  invitationType,
}) => {
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      name: source,
    }
  );
  const [filePreview, setFilePreview] = useState<any>({
    bank_proof_of_income: "",
    bank_proof_of_income_filePath: "",
    index: "",
  });
  const userRole = useSelector((state: RootState) => state.users.role);

  const userId = useSelector((state: RootState) => state.users.aspNetUserId);
  const queryClient = useQueryClient();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [confirmDeleteSource, setConfirmDeleteSource] = useState(false);
  const [bankStatementNumber, setBankStatementNumber] = useState(0);
  const refresh = useRefresh();
  const {
    mutate: deleteAccount,
    isLoading: loadingDeleteAccount,
    isSuccess: createDeleteAccount,
  } = useMutation(
    "deleteAccount",
    (data: any) =>
      dataProvider.deleteSequence("income", {
        document_id: data.document_id,
        source_id: data.source_id,
        trn_id: data.trn_id,
        subUrl: data.subUrl,
      }),
    {
      onSuccess: (data) => {
        setConfirmDeleteSource(false);
        setBankStatementNumber(0);
        queryClient.invalidateQueries(["bankDoc", "getMetaData"]);
        refresh();
        notify("Account deleted successfully", {
          type: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
      onError: (data) => {
        setBankStatementNumber(0);
        setConfirmDeleteSource(false);
        alert("Fail deleting account");
      },
      onMutate: () => {
        notify("Deleting account...", {
          type: "info",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
    }
  );
  //
  const handleDeleteAccount = async () => {
    if (bankStatementNumber !== 0) {
      deleteAccount({
        document_id: 6,
        source_id: bankStatementNumber,
        trn_id: trn_id,
        subUrl: "/income/deleteSource",
      });
    }
  };
  const getSrcfromStatus = (socrValue) => {
    if (socrValue) {
      switch (Number(socrValue)) {
        case 1:
          return greenTick;
        case 2:
          return redTick;
        case 3:
          return overa;
      }
    } else {
      return overa;
    }
  };

  const getClassfromStatus = (socrValue) => {
    if (socrValue) {
      switch (Number(socrValue)) {
        case 1:
          return "green-block bg-success text-dark";
        case 2:
          return "red-block bg-danger text-dark";
        case 3:
          return "brwn-block bg-warning text-dark";
      }
    } else {
      return "brwn-block bg-warning text-dark";
    }
  };

  const gettextStylefromStatus = (socrValue) => {
    if (socrValue) {
      switch (Number(socrValue)) {
        case 1:
          return "green-ltr";
        case 2:
          return "red-ltr";
        case 3:
          return "brown-ltr";
      }
    } else {
      return "brown-ltr";
    }
  };

  const handleAPIDebounced = useCallback(
    debounce(
      (nextValue) =>
        (async () => {
          handleSubmitManual();
        })(),
      5000
    ),
    [] // will be created only once initially
  );
  const { data: bankData } = useGetOne(
    "bankDoc",
    { id: trn_id },
    { refetchOnWindowFocus: false }
  );

  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const methods = useFormContext(); // retrieve those props

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const [reprocessingEnable, setReprocessingEnable] = React.useState(false);
  const [collapse, setCollapse] = useState<string[]>([]);

  const handleToggle = (fieldId: string) => {
    setCollapse((prevOpenCollapsibles) => {
      if (prevOpenCollapsibles.includes(fieldId)) {
        // If the collapsible is already open, close it
        return prevOpenCollapsibles.filter((id) => id !== fieldId);
      } else {
        // If the collapsible is closed, open it
        return [...prevOpenCollapsibles, fieldId];
      }
    });
  };
  const [valueSelectTab, setValueSelectTab] = React.useState(0);
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValueSelectTab(newValue);
  };

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box
            sx={{
              paddingLeft: 3,
              paddingRight: 3,
              paddingBottom: 3,
              paddingTop: 1,
            }}
          >
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  // get bank list data
  const { data: bankList } = useGetOne(
    "mlbanklist",
    { id: 1 },
    { refetchOnWindowFocus: false }
  );
  const getNoofWeeksText = (look_back_period, no_of_weeks) => {
    return `${no_of_weeks} (required ${look_back_period})`;
  };
  const [create] = useCreate();

  const handleReprocess = async (index) => {
    // alert("hie");
    const bank_id = methods.watch("banking." + index).id;
    const new_type_id = methods.watch(`banking.${index}.reprocessClass`).value;

    create(
      "reprocessEmployer",
      {
        data: {
          trn_id: trn_id,
          document_id: 6,
          bank_id: bank_id,
          new_type: new_type_id,
          method: "POST",
        },
      },
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries(["bankDoc"]);
          notify(`Employer Reprocess successfully.`, {
            type: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          setReprocessingEnable(false);
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    );
  };

  const { data, isLoading } = useGetList(
    "GETMLDetailsList",
    { filter: { DocumentTypeID: 6 } },
    { enabled: reprocessingEnable }
  );

  return (
    <>
      {fields.map((field: any, index) => {
        const bank_statement_number = index + 1;
        const statusValue = mannualSOCRstatus[`Source_${bank_statement_number}`]
          ? mannualSOCRstatus[`Source_${bank_statement_number}`]
          : SOCRstatus[`source_${bank_statement_number}`]?.SOCRStatus;

        return (
          <div className="px-3 mt-3" key={field.id}>
            <div className="login-radio-types">
              <div key={`${field?.id}${index}`} className="income-wrap">
                <div className="row">
                  <div className="col-12">
                    <Card
                      sx={{
                        background: "#f5fdff",
                        boxShadow: "none",
                        borderBottom: "1px solid black",
                        borderRadius: 0,
                      }}
                    >
                      <CardHeader
                        sx={{ padding: 0 }}
                        title={
                          <b style={{ fontSize: 13 }}>
                            {bank_statement_number}. Bank Statement
                          </b>
                        }
                        action={
                          <DeleteContainedBtn
                            onClick={() => {
                              setBankStatementNumber(bank_statement_number);
                              setConfirmDeleteSource(true);
                            }}
                            disable={loadingDeleteAccount}
                          />
                        }
                      />
                    </Card>
                    <Confirm
                      isOpen={confirmDeleteSource}
                      // loading={isLoading}
                      sx={{
                        "& .RaConfirm-iconPaddingStyle": { display: "none" },
                      }}
                      title="Confirm"
                      content={
                        "Are you sure you want to delete Bank Statement ?"
                      }
                      onConfirm={() => handleDeleteAccount()}
                      onClose={() => {
                        setBankStatementNumber(0);
                        setConfirmDeleteSource(false);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="pt-1 card-body">
                {bankData?.banking_details?.banking[index]?.files.length > 0 &&
                userRole !== "applicant" ? (
                  <div className="appl-status-wrap">
                    {field?.files.findIndex(
                      (item) => item.OCRCompleted == false
                    ) != "-1" ? (
                      <div className="px-2 d-flex">
                        <FormInstruction
                          message={"Document under Processing"}
                        />
                        <button
                          type="button"
                          onClick={() => refresh()}
                          className="btn btn-sm btn-outline-primary"
                        >
                          <SyncIcon fontSize="small" /> Refresh
                        </button>
                      </div>
                    ) : (
                      <div className="d-flex">
                        {
                          <div className="">
                            {userRole === "Docuverus Super Admin" &&
                            bankData?.banking_details?.banking[index]?.files
                              .length > 0 ? (
                              <ShowDocumentStatus
                                label={"Overall"}
                                trn_id={trn_id}
                                documentId={6}
                                SourceId={bank_statement_number}
                                value={statusValue}
                                src={getSrcfromStatus(statusValue)}
                                blockStyle={getClassfromStatus(statusValue)}
                                className={"col-lg-3 col-sm-3 col-3"}
                                textStyle={gettextStylefromStatus(statusValue)}
                                invalidateQueries={"bankDoc"}
                              />
                            ) : (
                              <ShowDocumentStatus
                                label={"Overall"}
                                trn_id={trn_id}
                                documentId={6}
                                SourceId={bank_statement_number}
                                src={getSrcfromStatus(statusValue)}
                                blockStyle={getClassfromStatus(statusValue)}
                                className={"col-lg-3 col-sm-3 col-3"}
                                textStyle={gettextStylefromStatus(statusValue)}
                                invalidateQueries={"bankDoc"}
                                disable={true}
                                value={statusValue}
                                customWidth={150}
                              />
                            )}
                          </div>
                        }
                        {meta_d_response?.records?.other_check?.hasOwnProperty(
                          `source_${index + 1}`
                        ) && (
                          <div className="px-2 pt-1">
                            <StatusComponent
                              blockStyle={""}
                              label={"Name Matched"}
                              validationIcon={
                                meta_d_response?.records?.other_check[
                                  `source_${index + 1}`
                                ]?.name_match == true
                                  ? greenTick
                                  : overa
                              }
                              validationText={
                                meta_d_response?.records?.other_check[
                                  `source_${index + 1}`
                                ]?.name_match == null
                                  ? "NA"
                                  : meta_d_response?.records?.other_check[
                                      `source_${index + 1}`
                                    ]?.name_match == true
                                  ? "Yes"
                                  : "No"
                              }
                              validationTextStyle={
                                meta_d_response?.records?.other_check[
                                  `source_${index + 1}`
                                ]?.name_match == true
                                  ? "green-stat"
                                  : "brown-stat"
                              }
                            />
                          </div>
                        )}
                        {meta_d_response?.records?.other_check?.hasOwnProperty(
                          `source_${index + 1}`
                        ) && (
                          <div className="px-2 pt-1">
                            <div className={`block`}>
                              <label
                                style={{
                                  fontSize: "12px",
                                  lineHeight: "16px",
                                  textAlign: "center",
                                  color: "black",
                                }}
                                htmlFor=""
                              >
                                No. of Months
                              </label>
                              <img
                                src={
                                  // parseInt(
                                  //   meta_d_response?.records?.other_check[
                                  //     `source_${index + 1}`
                                  //   ]?.no_of_weeks
                                  // ) >=
                                  // parseInt(
                                  //   meta_d_response?.records?.other_check[
                                  //     `source_${index + 1}`
                                  //   ]?.look_back_period
                                  // )
                                  bankData?.banking_details?.banking[index]
                                    ?.files?.length >= 3
                                    ? greenTick
                                    : overa
                                }
                                alt=""
                              />
                              <span
                                style={{
                                  fontSize: "12px",
                                  lineHeight: "16px",
                                  textAlign: "center",
                                }}
                              >
                                {getNoofWeeksText(3, field?.files?.length)}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ) : null}
                {field?.files &&
                field?.files.length > 0 &&
                field?.files.findIndex((item) => item.OCRCompleted == false) ==
                  "-1" ? (
                  <>
                    <div className="py-2">
                      <ShowValidationMessages
                        role={userRole}
                        validation_message={
                          userRole === "applicant"
                            ? applicantValidationMessages?.[
                                `source_${bank_statement_number}`
                              ]
                            : adminValidationMessages?.[
                                `source_${bank_statement_number}`
                              ]
                        }
                        fetchRecord={() => null}
                        invalidateQueries={"getMetaData"}
                        tag={`updateValidationMessages_banking`}
                      />
                    </div>
                  </>
                ) : null}
              </div>
              <div className="px-2">
                <div className="row add-md-form mr-t10">
                  <div className="col-sm-6 col-6 col-md-4">
                    {/* <SelectInput
                      onChangeCallback={handleAPIDebounced}
                      label="Bank Name"
                      placeholder={"Select Bank Name"}
                      source={`banking.${index}.bank_name`}
                      options={
                        bankList?.data && bankList?.data?.length > 0
                          ? bankList?.data?.map((item) => ({
                              name: item?.ClassName,
                              value: item?.ClassName,
                            }))
                          : []
                      }
                      required={false}
                    /> */}
                    <InputField2
                      label="Bank Name"
                      source={`banking.${index}.bank_name`}
                      placeholder="Enter Bank Name"
                      labelClass={"lable-title"}
                      additionalInputClass={"cus-control"}
                      notRequired={true}
                      handleOnChange={handleAPIDebounced}
                    />
                  </div>
                  <div className="col-sm-6 col-6 col-md-4">
                    <SelectInput
                      onChangeCallback={handleAPIDebounced}
                      label="Account Type"
                      placeholder={"Select Account Type"}
                      source={`banking.${index}.account_type`}
                      options={[
                        { name: "Checking", value: "Checking" },
                        { name: "Savings", value: "Savings" },
                        {
                          name: "Investments/Other",
                          value: "Investments/Other",
                        },
                        { name: "Combined", value: "Combined" },
                      ]}
                      required={false}
                    />
                  </div>
                  <div className="col-sm-6 col-6 col-md-4">
                    <InputCurrencyField
                      handleOnChange={handleAPIDebounced}
                      label="Current Balance"
                      source={`banking.${index}.current_balance`}
                      required={false}
                      placeholder={"Enter Current Balance"}
                    />
                  </div>
                  {userRole === "Docuverus Super Admin" &&
                    bankData?.banking_details?.banking[index]?.files.length >
                      0 && (
                      <>
                        <div className="col-sm-12 col-12 col-md-12 print-hide d-flex">
                          <InputField2
                            handleOnChange={handleAPIDebounced}
                            type="text"
                            label="Bank Statement Type"
                            source={`banking.${index}.files.0.bank_doc_type`}
                            placeholder="Bank Statement Type"
                            notRequired={true}
                            disable={true}
                          />
                          <button
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();
                              setReprocessingEnable((v) => !v);
                            
                            }}
                           
                            className="btn btn-link"
                            title="Select document type for 1st file"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-box-arrow-in-down-right"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M6.364 2.5a.5.5 0 0 1 .5-.5H13.5A1.5 1.5 0 0 1 15 3.5v10a1.5 1.5 0 0 1-1.5 1.5h-10A1.5 1.5 0 0 1 2 13.5V6.864a.5.5 0 1 1 1 0V13.5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5v-10a.5.5 0 0 0-.5-.5H6.864a.5.5 0 0 1-.5-.5z"
                              ></path>
                              <path
                                fillRule="evenodd"
                                d="M11 10.5a.5.5 0 0 1-.5.5h-5a.5.5 0 0 1 0-1h3.793L1.146 1.854a.5.5 0 1 1 .708-.708L10 9.293V5.5a.5.5 0 0 1 1 0v5z"
                              ></path>
                            </svg>
                          </button>
                        </div>
                        {reprocessingEnable && (
                          <div className="col-6 col-md-4 col-lg-4">
                            {/* <JSONTree data={methods.watch(`banking.${index}.reprocessClass`)}/> */}
                            <InputSelectField
                              onChangeCallback={() => {
                                handleReprocess(index);
                              }}
                              required={false}
                              label="Select document type for 1st file"
                              source={`banking.${index}.reprocessClass`}
                              options={data?.map((item: any) => ({
                                label: item?.ClassName,
                                value: item.id,
                              }))}
                            />
                          </div>
                        )}
                      </>
                    )}
                </div>
              </div>
              {/* 2nd ques start */}
              {invitationType == "1" ? (
                <div className="mr-t20 bullet-style">
                  <YesNoRadioButton
                    required={true}
                    label="Do you have PDF bank statement(s) available to upload ?"
                    source={`banking.${index}.availableToUpload`}
                    disable={methods?.watch(`banking.${index}.fileCount`)}
                    handleOnClick={(val) => {
                      // handleAPIDebounced();
                      if (val === "No") {
                        handleAPIDebounced();
                      }
                    }}
                  />
                </div>
              ) : (
                ""
              )}
              {invitationType == "1" &&
                methods?.watch(`banking.${index}.availableToUpload`) ==
                  "No" && (
                  <>
                    <div className="login-radio-types p-2">
                      <NoDocReasonRadioButtons
                        handleOnchange={() => handleAPIDebounced()}
                        source={`banking.${index}.no_doc_reason`}
                        options={[
                          {
                            name: "I don't currently have access to document(s), but can provide later",
                            value:
                              "I don't currently have access to document(s), but can provide later",
                          },
                          {
                            name: "I do not have proof of bank account",
                            value: "I do not have proof of bank account",
                          },
                          {
                            name: "Other",
                            value: "other",
                          },
                        ]}
                        section_id={6}
                      />
                      {methods?.watch(`banking.${index}.no_doc_reason`) ==
                        "other" && (
                        <div className="col-12 col-md-4 col-lg-4 mr-t20 mr-l30 mr-b10">
                          <InputField2
                            handleOnChange={() => handleAPIDebounced()}
                            source={`banking.${index}.other_reason`}
                            label={"Other reason"}
                            placeholder=""
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}
              {/* 2nd ques end */}
              {(invitationType == "1" &&
                methods.watch()?.banking[index]?.availableToUpload == "Yes") ||
              invitationType !== "1" ? (
                <div className="row align-items-center">
                  <div
                    className="col-sm-12 col-md-10 print-hide"
                    style={{
                      fontSize: "11px",
                      color: "#606060",
                      paddingLeft: "16px",
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <strong>{`${index + 1}. ${"Banking:"}`}</strong>
                      <AlertDialog
                        message={`In order to verify bank statements, please upload PDF’s of your 3 most recent bank statements downloaded directly from your bank account website.  Please do NOT upload scanned images, redacted documents, or screen shots of documents, and do NOT “print to PDF”, “save as” or modify these documents in any way.  If you are self-employed and have business bank statements, you can include them, but personal PDF bank statements are required to verify self-employment income.

`}
                      />
                    </div>
                    <FormInstruction
                      message={
                        "IMPORTANT! PDF Bank Statements downloaded directly from bank website ONLY! Please do NOT upload pictures of bank statements. "
                      }
                    />
                  </div>
                  {/* <div className="col-md-2 col-sm-12 mb-2 print-hide">
                    <DragDropDocumentButton
                      SourceId={bank_statement_number}
                      documentId={6}
                      fetchRecord={() => null}
                      acceptedFiles={`image/*,application/pdf`}
                      invalidateQueries={"bankDoc"}
                      tag={`fileOprations_other_income`}
                      trn_id={trn_id}
                      fileError={
                        formErrors?.banking?.[bank_statement_number - 1]
                          ? true
                          : false
                      }
                    />
                  </div> */}
                  <div className="row">
                    <div className="col-md-8 col-sm-12">
                      <div className="d-flex flex-wrap ">
                        <div className="col-md-2 col-sm-12 mb-2 print-hide">
                          <DragDropDocumentButton
                            SourceId={bank_statement_number}
                            documentId={6}
                            fetchRecord={() => null}
                            acceptedFiles={`image/*,application/pdf`}
                            invalidateQueries={"bankDoc"}
                            tag={`fileOprations_other_income`}
                            trn_id={trn_id}
                            fileError={
                              formErrors?.banking?.[bank_statement_number - 1]
                                ? true
                                : false
                            }
                          />
                        </div>
                        {bankData?.banking_details?.banking[index]?.files?.map(
                          (item, i) => {
                            return (
                              <div
                                className="px-1"
                                key={item.application_document_id}
                              >
                                      
                                <FilePreview
                                  source_index={index}
                                  file_name={item.file_name}
                                  doc_id={item.application_document_id}
                                  img={item.path_image}
                                  is_Duplicate={item.is_Duplicate}
                                  tag={"delete_document_banking"}
                                  invalidateQueries={"bankDoc"}
                                  file={item.path}
                                  handleClick={() =>
                                    setFilePreview({
                                      ...filePreview,
                                      bank_proof_of_income:
                                        item.application_document_id,
                                      bank_proof_of_income_filePath: item.path,
                                      id: field.fieldId,
                                    })
                                  }
                                  // handleOnChange={() => {
                                  //   const isCurrentFile =
                                  //     filePreview.path ==
                                  //     item?.bank_proof_of_income_filePath
                                  //       ? true
                                  //       : false;
                                  //   const isCurrentIndex =
                                  //     filePreview.index == index ? true : false;

                                  //   if (isCurrentFile && isCurrentIndex) {
                                  //     setFilePreview({
                                  //       bank_proof_of_income: null,
                                  //       bank_proof_of_income_filePath: null,

                                  //       index: null,
                                  //     });
                                  //   }
                                  // }}
                                />
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                      {field.files.findIndex(
                        (file) =>
                          file.application_document_id ===
                          filePreview.bank_proof_of_income
                      ) !== -1 && filePreview.id == field?.fieldId ? (
                        <CustomPreviewUpload
                          filePath={filePreview.bank_proof_of_income_filePath}
                        />
                      ) : null}
                    </div>
                  </div>
                  {userRole !== "applicant" && (
                    <>
                      <div className="expand-heads d-flex print-hide">
                        <button
                          className="ml-auto print-hide"
                          type="button"
                          // onClick={() =>
                          //   setCollapse((value) =>
                          //     field?.id == value ? 0 : field?.id
                          //   )
                          // }
                          onClick={() =>
                            // setCollapse((value) => (field?.id == value ? 0 : field?.id))
                            handleToggle(field.id)
                          }
                        >
                          Expand for Details
                          {/* {collapse == field.id ? ( */}
                          {collapse.includes(field.id) ? (
                            <span>
                              <svg
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.00001 2.21856L1.70001 5.51856L0.757341 4.57589L5.00001 0.333222L9.24268 4.57589L8.30001 5.51855L5.00001 2.21856Z"
                                  fill="#2DC3E8"
                                />
                              </svg>
                            </span>
                          ) : (
                            <span>
                              <svg
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M4.99999 3.78047L8.29999 0.480469L9.24266 1.42314L4.99999 5.6658L0.757324 1.42314L1.69999 0.480469L4.99999 3.78047Z"
                                  fill="#2DC3E8"
                                />
                              </svg>
                            </span>
                          )}
                        </button>
                      </div>
                      <div
                        style={{
                          display: collapse.includes(field.id)
                            ? "block"
                            : "none",
                        }}
                        className="collapse cust-coll-appl print-hide"
                        id={`app-det-coll${field.id}`}
                      >
                        {methods.getValues()?.banking[index]
                          ?.availableToUpload == "Yes" && (
                          <>
                            <Box sx={{ width: "100%" }}>
                              <Box
                                sx={{ borderBottom: 1, borderColor: "divider" }}
                              >
                                <Tabs
                                  variant="scrollable"
                                  scrollButtons
                                  value={valueSelectTab}
                                  onChange={handleChangeTab}
                                  aria-label="basic tabs example"
                                >
                                  {field?.files.map((field: any, index) => {
                                    return (
                                      <Tab
                                        key={field.fieldId}
                                        label={
                                          field?.start_date
                                            ? `${index + 1}. ${moment(
                                                field?.start_date
                                              ).format("MM/DD/YYYY")}`
                                            : `${index + 1}. Bank Statement`
                                        }
                                        {...a11yProps(index)}
                                      />
                                    );
                                  })}
                                </Tabs>
                              </Box>
                              {field.files.map((files: any, fileIndex) => {
                                return (
                                  <TabPanel
                                    key={files.fieldId}
                                    value={valueSelectTab}
                                    index={fileIndex}
                                  >
                                    <BankGptReadingData
                                      fileIndex={fileIndex}
                                      index={index}
                                    />
                                    <div className="col-12 print-hide">
                                      {userRole !== "applicant" &&
                                        field?.files.findIndex(
                                          (item) => item.OCRCompleted == false
                                        ) == "-1" &&
                                        field?.files.length > 0 && (
                                          <div>
                                            <Accordion
                                              expanded={expanded === "panel1"}
                                              onChange={handleChange("panel1")}
                                            >
                                              <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                              >
                                                <Typography
                                                  sx={{
                                                    width: "33%",
                                                    flexShrink: 0,
                                                  }}
                                                >
                                                  Metadata Checks
                                                </Typography>
                                              </AccordionSummary>
                                              <AccordionDetails>
                                                <NewMetaDataRulesBank
                                                  metaDataCheck={metaRules.filter(
                                                    (item: any) =>
                                                      item.source_id ==
                                                      index + 1
                                                  )}
                                                />
                                              </AccordionDetails>
                                            </Accordion>
                                          </div>
                                        )}
                                    </div>
                                  </TabPanel>
                                );
                              })}
                            </Box>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default BankingStatementForm;
