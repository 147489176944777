import CurrencyInput from "react-currency-input-field";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
const InputCurrencyFieldOrginal = ({ notRequired = false, labelRequired = true, label = "", name }) => {
  const required = !notRequired;
  const { control } = useFormContext();

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState }) => {
          const { onChange, ...rest } = field;
          const error = fieldState.error !== undefined;
          return (
            <>
              {labelRequired && <label
                htmlFor={name}
                className={`form-label ${error && "text-danger"}`}
              >
                {label} {required && <span className="red">*</span>}
              </label>}
              <CurrencyInput
                style={{ fontSize: '11px',minHeight: '34px'}}
                allowNegativeValue={false}
                precision={2}
                id={name}
                onValueChange={(value, name) => {
                  onChange(value);
                }}
                className={`form-control small cus-input-control ${required && !field.value && "is-warning"
                  } ${error && "is-invalid"}`}
                placeholder={label || `Enter ${label}`}
                decimalsLimit={2}
                prefix="$ "
                {...rest}
              />
              <div className="invalid-feedback">
                {error && fieldState.error.message}
              </div>
            </>
          );
        }}
      />
    </>
  );
};

export default InputCurrencyFieldOrginal;
