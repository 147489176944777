import React ,{forwardRef}from "react";
// import greenTick from "../../assets/images/greenTick.svg";
// import notifyImg from "../../assets/images/notifyImg.svg";
// import doc3 from "../../assets/images/doc3.png";
// import dlt from "../../assets/images/dlt.svg";
// import bluecheck from "../../assets/images/bluecheck.svg";
// import UploadDocumentButton from "./components/UploadDocumentButton";
// import {
//   SimpleForm,
//   useDataProvider,
//   useNotify,
//   Button,
//   FormDataConsumer,
//   Confirm,
//   useGetIdentity
// } from "react-admin";
// import doc1 from "../../assets/images/doc1.png";
// import { useParams } from "react-router-dom";
// import { yupResolver } from "@hookform/resolvers/yup";
// import DeleteIcon from "@mui/icons-material/Delete";
import * as yup from "yup";
// import FileThumbnailArray from "./components/FileThumbnailArray";
// import CustomToolbar from "./components/CustomToolBar";
// import ShowComments from "./components/ShowComments";
// import DragDropDocumentButton from "./components/DragDropDocumentButton";
import {
  Controller,
  useController,
  // FormProvider,
  // useForm,
} from "react-hook-form";
// import YesNoRadioButton from "./components/inputComponents/YesNoRadioButton";
import ProofofOtherIncomeForm from "./ProofofOtherIncomeForm";

const ProofOfNonEmploymentSection = forwardRef((props:any,ref) => {
// 
  const { record, trn_id, role,required,status } = props;

  yup.addMethod(yup.array, "uniqueOtherIncome", function (message: string) {
    return this.test("unique", message, function (data) {
      const list = data?.map((item) => {
        return { other_income_type: item?.other_income_type };
      });
      let isUnique = true;
      let row = list?.length || 0;
      for (let i = 0; i < row; i++) {
        for (let j = i + 1; j < row; j++) {
          if (JSON.stringify(list?.[i]) === JSON.stringify(list?.[j])) {
            isUnique = false;
          }
        }
      }
      if (isUnique) {
        return true;
      }
      return false;
    });
  });

  const localSchema = yup.object().shape({
    response: yup
      .string()
      .typeError("Response Required")
      .required("Response Required"),
    other_income_type: yup.string().typeError("required").required("required"),
    files: yup.array().min(1, "At Least 1 Document Is Required"),

    monthly_income: yup
      .number()
      .typeError("The value must be a number")
      .required("required")
      .min(1, "The value must be greater than 0"),

    otherincome: yup.array().when("response", {
      is: (value) => {
        return value === "Yes";
      },
      then: yup
        .array()
        .of(
          yup.object().shape({
            other_income_type: yup
              .string()
              .typeError("required")
              .required("required"),
            files: yup.array().min(1, "At Least 1 Document Is Required"),
          
            monthly_income: yup
              .number()
              .typeError("The value must be a number")
              .required("required")
              .min(1, "The value must be greater than 0"),
           
          })
        )
        .min(1, "must have atleast 1 records"),
      // .uniqueOtherIncome("You cannot add duplicate Other income "),
    }),
  });

  

  // validation

  const schema = yup.object({
    other_income_type: yup.string().typeError("required").required("required"),
    monthly_income: yup.string().typeError("required").required("required"),
    pension_source: yup.string().typeError("required").required("required"),
    other_source: yup.string().typeError("required").required("required"),
    rental_assistance_type: yup
      .string()
      .typeError("required")
      .required("required"),
  });

  

  return(<ProofofOtherIncomeForm
  {...record}
  trn_id={trn_id}
  role={role}
  ref={ref}
  required={required}
  status={status}
  />)

});

export default ProofOfNonEmploymentSection;

