import React from 'react'

import DeleteIcon from '@mui/icons-material/Delete';
import { useDataProvider, useNotify, useRefresh,Confirm } from 'react-admin';
import { useMutation } from 'react-query';
import Card from "@mui/material/Card";
import { CardHeader } from "@mui/material";
import { useQueryClient } from 'react-query';
import DeleteContainedBtn from "../common/DeleteContainedBtn";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import { useDispatch } from 'react-redux';
import { setUploadPetImageId } from '../../Store/Slice/applicantDetailsSlice';

export default function DeleteFileConfirmBtn({ doc_id, isArrayRemove = false, index = 1, invalidateQueries, tag, ...props }) {
    const dataProvider = useDataProvider();
    const queryClient = useQueryClient();
    const notify = useNotify();
    const refresh=useRefresh();
    const dispatch = useDispatch();
    const { mutate, isLoading, isSuccess } = useMutation(tag, (id) => dataProvider.deleteDocument(id), {
        onSuccess: (data) => {
            setConfirmDelete(false);
            queryClient.invalidateQueries([`${invalidateQueries}`])
            refresh();
            notify('Document deleted successfully...', { type: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' }})
            dispatch(
                setUploadPetImageId({
                  index: props?.petIndex,
                  id: doc_id,
                  type: "DELETE",
                })
              );
            if(props?.handleOnChange){
              props?.handleOnChange();
            }
        },
        onError: (data) => {
            setConfirmDelete(false);
            alert("Fail deleting document")
        },
        onMutate: () => {
            notify('Document is Deleting...', { type: 'info', anchorOrigin: { vertical: 'top', horizontal: 'right' }})
        }
    })

    const { mutate: deleteDocumentNoDoc, isLoading: deleteDocumentNoDocIsLoading } = useMutation(tag, (id) => dataProvider.deleteDocumentNoDoc(id), {
        onSuccess: (data) => {
            setConfirmDelete(false);
            queryClient.invalidateQueries([`${invalidateQueries}`])
            notify('Document deleted successfully...', { type: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' }})
        },
        onError: (data) => {
            setConfirmDelete(false);
            alert("Fail deleting document")
        },
        onMutate: () => {
            notify('Document is Deleting...', { type: 'info', anchorOrigin: { vertical: 'top', horizontal: 'right' }})
        }
    })
    const handleDeleteDocument = (id) => {
        if (isArrayRemove)
            return deleteDocumentNoDoc(id)
        mutate(id)
    }
    const [confirmDelete, setConfirmDelete] = React.useState(false);
    return (
        <>
            <div className='print-hide' style={{ textAlign: 'center' }}>
                <a
                    type='button'
                    href={'#'}
                    onClick={(e) => {
                        e.preventDefault();
                        setConfirmDelete(true)
                    }}
                    className="btn btn-link" style={{ color: 'red', textAlign: 'center', fontSize: 10 }}
                ><DeleteIcon style={{ fontSize: 12 }} /> Delete</a>
            </div>
           
                    {/* <Card
                      sx={{
                        background: "#f5fdff",
                        boxShadow: "none",
                        borderBottom: "1px solid black",
                        borderRadius: 0,
                      }}
                    >
                      <CardHeader
                        sx={{ padding: 0 }}
                        title={
                          <b style={{ fontSize: 13 }}>
                            {`Delete Document`}
                          </b>
                        }
                        action={
                          <DeleteContainedBtn
                            onClick={() => {
                            //   setOtherincomeNumber(other_income_number);
                              setConfirmDelete(true);
                            }}
                            disable={isLoading}
                          />
                        }
                      />
                    </Card> */}
                    <Confirm
                      isOpen={confirmDelete}
                      // loading={isLoading}
                      sx={{
                        "& .RaConfirm-iconPaddingStyle": { display: "none" },
                      }}
                      title="Confirm"
                      content={
                        "Are you sure you want to delete this document?"
                      }
                      onConfirm={() =>  handleDeleteDocument(doc_id)}
                      onClose={() => {
                        // setOtherincomeNumber(0);
                        setConfirmDelete(false);
                      }}
                    />
                  
            {/* <Dialog
                open={confirmDelete}
                onClose={() => {
                    setConfirmDelete(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {`Delete Document`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this document?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button
                        className="btn btn-info text-light"
                        onClick={() => {
                            setConfirmDelete(false);
                        }}
                    >
                       Cancel
                    </button>
                    <button
                        className="btn btn-info text-light"
                        onClick={() => {
                            handleDeleteDocument(doc_id)
                        }}
                        disabled={isLoading}
                    >
                       Confirm
                    </button>
                </DialogActions>
            </Dialog> */}
            </>
    )
}
