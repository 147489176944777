import React, { Fragment } from "react";
import MarkInactiveModal from "./MarkInactiveModal";
import MarkActiveModal from "./MarkActiveModal";
import FilterModal from "./FilterModal";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const EmailSettings = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <MarkInactiveModal />
      <MarkActiveModal />
      <FilterModal />
      <Button
        onClick={() => navigate("/ats/emailSettings/templateDetails")}
        variant="text"
        sx={{ textTransform: "capitalize" }}
      >
        templateDetails
      </Button>
      <Button
        onClick={() =>
          navigate("/ats/emailSettings/subscriberTemplateDetails")
        }
        variant="text"
        sx={{ textTransform: "capitalize" }}
      >
        subscriberTemplateDetails
      </Button>
      <Button
        onClick={() =>
          navigate("/ats/emailSettings/profiledetails")
        }
        variant="text"
        sx={{ textTransform: "capitalize" }}
      >
        Profile Details
      </Button>
      <Button
        onClick={() =>
          navigate("/ats/emailSettings/applicationsettings")
        }
        variant="text"
        sx={{ textTransform: "capitalize" }}
      >
      Application Settings
      </Button>
      <Button
        onClick={() =>
          navigate("/ats/emailSettings/addnewprofile")
        }
        variant="text"
        sx={{ textTransform: "capitalize" }}
      >
        Add New Profile
      </Button>
    </Fragment>
  );
};

export default EmailSettings;
