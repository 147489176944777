import React, { useEffect, useState } from "react";
import {
  Edit,
  TextInput,
  useGetIdentity,
  useGetList,
  useCreate,
  useGetOne,
  Resource,
  useNotify,
  NumberField,
  FunctionField,
  Datagrid,
  useListContext,
  useRecordContext,
  ListGuesser,
} from "react-admin";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { useForm, Controller, FormProvider } from "react-hook-form";
import addNote from "../../assets/images/addNote.svg";
import moment from "moment";

import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  FormLabel,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@mui/material";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import CloseIcon from "@mui/icons-material/Close";
import InputSelectField from "../common/InputComponents/InputSelectField";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";

import DialogActions from "@mui/material/DialogActions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Menu from "@mui/material/Menu";

import DocumentAnalysisShow from "../DocumentAnalysis";
import List from "@mui/material/List";
import SaveIcon from "@mui/icons-material/Save";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import img1 from "../../assets/images/img1.svg";
import pencilIcon from "../../assets/images/pencilIcon.svg";
import overa from "../../assets/images/overa.svg";
import greenTick from "../../assets/images/greenTick.svg";
import redTick from "../../assets/images/redTick.svg";
import notifyImg from "../../assets/images/notifyImg.svg";
import doc1 from "../../assets/images/doc1.png";
import doc2 from "../../assets/images/doc2.png";
import doc3 from "../../assets/images/doc3.png";
import doc4 from "../../assets/images/doc4.png";
import dlt from "../../assets/images/dlt.svg";
import viewapp from "../../assets/images/viewapp.svg";
import viewdoc from "../../assets/images/viewdoc.svg";
import remove from "../../assets/images/remove.svg";
import archieve from "../../assets/images/archieve.svg";
import { RxCross2 } from "react-icons/rx";
import CancelAppSvg from "../../assets/images/CancelApp.svg";
import Transfer from "../../assets/images/Transfer.svg";
import mail from "../../assets/images/mail.svg";
import Shareapp from "../../assets/images/Shareapp.svg";
import greenStatus from "../../assets/images/greenStatus.svg";
import viewresult from "../../assets/images/viewresult.svg";
import flag from "../../assets/images/flag.svg";
import greenCircle from "../../assets/images/greenCircle.svg";
import dropdown from "../../assets/images/dropdown.svg";
import bluecheck from "../../assets/images/bluecheck.svg";
// import AddCoapplicant from "./AddCoapplicant";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckIcon from "@mui/icons-material/Check";
// import Archive from "./Archive";
// import TransferApp from "./TransferApp";
// import SendEmailToApplicant from "./SendEmailToApplicant";
// import ArchiveAplicant from "./ArchiveAplicant";
// import ApplicantDetailBox from "./ApplicantDetailBox";
import { useDispatch, useSelector } from "react-redux";
import InputField2 from "../common/InputComponents/InputField2";

import { RootState } from "../../Store/store";
import { setUserRole, setUserTrnId } from "../../Store/Slice/usersSlice";
import {
  setAppFooter,
  setAppHeader,
  setisGuestCard,
  setisOpeningForDashboard,
} from "../../Store/Slice/applicationSettingSlice";
import { JSONTree } from "react-json-tree";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Button as MuiButton,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { ArrowBack } from "@mui/icons-material";
import { FiMoreVertical, FiX } from "react-icons/fi";
import { MdNoteAlt } from "react-icons/md";
import { RiHome6Line } from "react-icons/ri";
import { BiBookContent } from "react-icons/bi";
import { RiHome7Line } from "react-icons/ri";
import { AiOutlineLogin } from "react-icons/ai";
import { FiEye } from "react-icons/fi";
import { RiSaveLine } from "react-icons/ri";
import Frame from "../../assets/images/Frame.svg";
import { RiDeleteBinLine } from "react-icons/ri";
import "../../App.css";
import { hi } from "date-fns/locale";
import YesNoRadioButton from "../../componets/common/InputComponents/YesNoRadioButton";
import { GetdeleteddocumentlistList } from "../DocuploadTableComponent/GetDeletedDocumentList";
import AlertDialogTable from "../DocuploadTableComponent/AlertDialogTable";
import useStripeBarActions from "../../useStripeBarActions";
import ProcessScreening from "../Ats/ProcessScreening";
import AuditlogModal from "../DocumentAnalysis/Modals/AuditlogModal";
import Modal from "../DocumentAnalysis/Modals/ParentModal";
import RootGridList from "./RootGridList";
import DateComponent from "../Ats/DateComponent";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  const color = props.value === 100 ? "green" : "orange";
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      {/* Apply the calculated color to CircularProgress */}
      <CircularProgress
        variant="determinate"
        value={props.value}
        size="33px"
        sx={{ color: color }}
        thickness={4}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
          border:
            props?.value === 100
              ? "0"
              : props?.value === 0
              ? "2px solid #E0E0E0"
              : "1px solid #E0E0E0",
        }}
      >
        {props.value === 100 ? (
          <CheckIcon style={{ color: "green" }} />
        ) : (
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
            fontSize="10px"
          >{`${Math.round(props.value)}%`}</Typography>
        )}
      </Box>
    </Box>
  );
}
export default function GrayStripeBar({ data }) {
  const {
    anchorEl,
    handleCloseMenu,
    deleteddocs,
    open,
    openRentalDecision,
    openPrelimReport,
    openLease,
    reset,
    appType,
    handleCloseLease,
    register,
    watch,
    handleClosePrelimReport,
    handleCloseRentalDecision,
    handleOpen,
    handlePrelimReport,
    handleClose,
    handleRentalDecision,
    handleOpenLease,
    handleopendeleteddocs,
    handleclosedeleteddocs,
    setValue,
    value,
  } = useStripeBarActions();

  const processScreeningConditionalRendering = [
    "Invited",
    "Application In Progress",
  ];

  const navigate = useNavigate();
  const {
    data: applicationData,
    isLoading,
    error,
  } = useGetOne(
    "getapplicationinboxdetails",
    {
      id: data?.id,
    },
    { enabled: !!data?.id }
  );
  return (
    <>
      <Dialog open={deleteddocs} onClose={handleclosedeleteddocs}>
        <DialogTitle
          sx={{ m: 0, p: 1, backgroundColor: "Gainsboro" }}
          id="customized-dialog-title"
        >
          Deleted documents
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleclosedeleteddocs}
          sx={{
            position: "absolute",
            right: 6,
            top: 1,

            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon style={{ fontSize: "12px" }} />
        </IconButton>
        <DialogContent onClick={handleopendeleteddocs}>
          <Resource name="deletedDocument" list={GetdeleteddocumentlistList} />
        </DialogContent>
      </Dialog>

      {/* Apply Rental Decision */}
      <Dialog open={openRentalDecision}>
        <DialogTitle
          sx={{ m: 0, p: 1, backgroundColor: "Gainsboro" }}
          id="customized-dialog-title"
        >
          Adverse Letter
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseRentalDecision}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent style={{ width: "600px" }}>
          <div className="button-grp">
            <Button
              style={{ marginBottom: "20px" }}
              onClick={handleClose}
              variant="contained"
            >
              Approve
            </Button>
            <Button
              style={{ marginBottom: "20px" }}
              onClick={handleClose}
              variant="contained"
            >
              Approve with Conditions
            </Button>
            <Button
              style={{ marginBottom: "20px" }}
              onClick={handleClose}
              variant="contained"
            >
              Reject
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {/* Prelim Report */}
      <Dialog
        fullWidth
        open={openPrelimReport}
        onClose={handleClosePrelimReport}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Prelim Notice</DialogTitle>
        <DialogContent>
          <div className="cus-modal-body">
            {/* <form onSubmit={handleSubmit(onSubmit)}> */}
            <div className="row">
              <div className="col-auto">
                <div className="cus-input app-option-btn mr-b30">
                  <label htmlFor="" className="lable-title">
                    Pursuant to NJ law, the criminal report has been either
                    redacted from view or not processed and the applicant was
                    not asked about criminal history as part of their rental
                    application. notice?
                  </label>
                  <label htmlFor="" className="lable-title mr-t20">
                    In order to view/process the criminal report, you must
                    preliminarily notify the applicant that he/she/they are
                    approved/conditionally approved pending the results of their
                    criminal background report results and/or completion of a
                    secondary application. Have you provided or do you wish to
                    send this preliminary approval notice?
                  </label>
                  <div className="it-option" id="Notice">
                    <label htmlFor="notice1">
                      <input
                        type="radio"
                        id="notice1"
                        value="1"
                        {...register("Notice", { required: true })}
                      />
                      <span>Yes</span>
                    </label>
                    <label htmlFor="notice2">
                      <input
                        type="radio"
                        id="notice2"
                        value="0"
                        {...register("Notice", { required: true })}
                      />
                      <span>No</span>
                    </label>
                  </div>
                </div>
              </div>
              {watch("Notice") == "1" ? (
                <div className="col-auto">
                  <div className="cus-input app-option-btn mr-b30">
                    <label htmlFor="" className="lable-title">
                      Would you like to send preliminary notice to the applicant
                      now via E-mail?
                    </label>
                    <div className="it-option" id="IsEmail">
                      <label htmlFor="notice3">
                        <input
                          type="radio"
                          id="notice3"
                          value="1"
                          {...register("IsEmail", { required: true })}
                        />
                        <span>Yes</span>
                      </label>
                      <label htmlFor="notice4">
                        <input
                          type="radio"
                          id="notice4"
                          value="0"
                          {...register("IsEmail", { required: true })}
                        />
                        <span>No</span>
                      </label>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="col-sm-12">
                <div className="g-btm-btn d-flex align-items-center justify-content-end mr-t20">
                  <button
                    type="button"
                    className="blue-line-btn mr-r20"
                    onClick={() => {
                      handleClosePrelimReport();
                      reset();
                    }}
                  >
                    <svg
                      className="mr-r10"
                      width="10"
                      height="9"
                      viewBox="0 0 10 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 3.55732L8.3 0.257324L9.24267 1.19999L5.94267 4.49999L9.24267 7.79999L8.3 8.74266L5 5.44266L1.7 8.74266L0.757334 7.79999L4.05733 4.49999L0.757334 1.19999L1.7 0.257324L5 3.55732Z"
                        fill="#2DC3E8"
                      />
                    </svg>
                    Cancel
                  </button>
                  <button type="submit" className="blue-btn">
                    <svg
                      className="mr-r10"
                      width="12"
                      height="9"
                      viewBox="0 0 12 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.66666 6.61463L10.7947 0.485962L11.738 1.42863L4.66666 8.49996L0.423996 4.2573L1.36666 3.31463L4.66666 6.61463Z"
                        fill="white"
                      />
                    </svg>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog open={openLease}>
        <DialogTitle
          sx={{ m: 0, p: 1, backgroundColor: "Gainsboro" }}
          id="customized-dialog-title"
        >
          Generate Lease
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseLease}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent style={{ width: "600px" }}>
          <div className="col-sm-12">
            <div className="details-card-row 2 mr-b30">
              <div className="static-d-info">
                <label>App ID</label>
                <h5>{data?.id ? data?.id : "NA"}</h5>
              </div>
              <div className="static-d-info">
                <label>App Date</label>

                <h5>
                  <DateComponent date={data?.AppDate} />
                </h5>
              </div>
              <div className="basic-subinfo px-2">
                <div className="info-block">
                  <label htmlFor="">App Email</label>
                </div>
                <span>{data?.email}</span>
              </div>

              <div className="static-d-info">
                <label>Subscriber</label>
                <h5>{data?.SubscriberName ? data?.SubscriberName : "NA"}</h5>
              </div>
              <div className="static-d-info">
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <MuiButton
                    variant="contained"
                    disableElevation={true}
                    color={
                      data?.ScreeningDetails == "ConditionallyApproved"
                        ? "warning"
                        : data?.ScreeningDetails == "Unacceptable"
                        ? "error"
                        : data?.ScreeningDetails == "NoAssessment"
                        ? "primary"
                        : data?.ScreeningDetails == ""
                        ? "inherit"
                        : "success"
                    }
                    style={{
                      // color: "#127790",
                      fontSize: "12px",
                      // height: "32px",
                      textTransform: "capitalize",
                      cursor: "text",
                      fontFamily: "OpenSans-SemiBold",
                      padding: "5px 10px",
                      background:
                        data?.ApplicationStatus == "Review Pending"
                          ? "#ecfbff"
                          : "",
                      color:
                        data?.ApplicationStatus == "Review Pending"
                          ? "#2dc3e8"
                          : "",
                    }}
                  >
                    {data?.ApplicationStatus == "Application In Progress"
                      ? "In Progress"
                      : data?.ApplicationStatus}
                  </MuiButton>
                </Box>
              </div>
              <div className="name1">
                {appType == "1" && (
                  <CircularProgressWithLabel
                    value={
                      data?.ApplicantStatus == "Invited"
                        ? 0
                        : Number(data?.ApplicationCompleted)
                    }
                  />
                )}
              </div>
            </div>
          </div>

          <Grid container spacing={2} columns={16}>
            <Grid item xs={8}>
              <FormLabel>
                &lt;Custom Lease Field &gt;
                <span className="required">*</span>
              </FormLabel>
              <TextField size="small" fullWidth />
            </Grid>
            <Grid item xs={8}>
              <FormLabel>
                &lt; Custom Lease Field&gt;
                <span className="required">*</span>
              </FormLabel>
              <TextField size="small" fullWidth />
            </Grid>
            <Grid item xs={8}>
              <FormLabel>Select Addendum</FormLabel>
              <TextField select fullWidth size="small" />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} variant="contained">
            <RiSaveLine style={{ marginRight: "5px" }} />
            Save
          </Button>
          <Button autoFocus onClick={handleClose} variant="contained">
            <FiEye style={{ marginRight: "5px" }} />
            Preview
          </Button>
        </DialogActions>
      </Dialog>

      {/* <Box
        sx={{
          width: "100%",
          marginTop: "10px",
          justifyContent: "space-between",
          paddingBottom: '20px',
        }}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          style={{
            backgroundColor: "#202020",
            height: "40px",
            alignItems: "center",
            justifyContent:'left',
          }}
        >
          <BottomNavigationAction
            style={{ color: "#2cc0e4", display: "inline" }}
            label="Rental Decision"
            icon={<RiHome6Line style={{ marginRight: "5px" }} />}
            onClick={handleRentalDecision}
          />
          {appType === "1" && applicationData?.data[0]?.State ==="NJ" && data?.ApplicationStatus === "Screening" &&  <BottomNavigationAction
            style={{ color: "#2cc0e4", display: "inline" }}
            label="Prelim Notice"
            icon={<BiBookContent style={{ marginRight: "5px" }} />}
            onClick={handlePrelimReport}
          />}
           <BottomNavigationAction
            style={{ color: "#2cc0e4", display: "inline" }}
            label="General Lease"
            icon={<RiHome7Line style={{ marginRight: "5px" }} />}
            onClick={handleOpenLease}
          />
          <BottomNavigationAction
            style={{ color: "#2cc0e4", display: "inline" }}
            label="Audit Logs"
            icon={<AiOutlineLogin style={{ marginRight: "5px" }} />}
            onClick={()=>{navigate('auditlogs/'+data.id)}}
          />
          {processScreeningConditionalRendering?.includes(
            data?.ApplicationStatus
          ) ? null : (
            <ProcessScreening record={data} />
          )}
          <BottomNavigationAction
            style={{ color: "#2cc0e4", display: "inline" }}
            label="Deleted Docs"
            icon={<RiDeleteBinLine style={{ marginRight: "5px" }} />}
            onClick={handleopendeleteddocs}
          />
        </BottomNavigation>
      </Box> */}
      {/* </div> */}
    </>
  );
}
