import { createSlice } from '@reduxjs/toolkit';
interface app {
    appHeader: boolean,
    appFooter:boolean,
    isOpeningForDashboard:boolean,
    isGuestCard:boolean,
}

const initialState: app = {
    appHeader: true,
    appFooter: true,
    isOpeningForDashboard:false,
    isGuestCard:false,
}
const appSettingSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setAppHeader: (state, action) => {
            state.appHeader = action.payload;
        },
        setAppFooter: (state, action) => {
            state.appFooter = action.payload;
        },
        setisOpeningForDashboard: (state, action) => {
            state.isOpeningForDashboard = action.payload;
        },
        setisGuestCard: (state, action) => {
            state.isGuestCard = action.payload;
        },
    }
});

export const { setAppHeader,setAppFooter,setisOpeningForDashboard,setisGuestCard} = appSettingSlice.actions
export default appSettingSlice.reducer
