import React from "react";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Legend,
  Tooltip,
  Cell,
} from "recharts";

const PieChartGraph = ({ records }) => {
  //   const COLORS = ["#95e0f3", "#d5f3fa", "#c0edf9", "#eaf9fd", "#c0edf9"];
  const COLORS = ["#95e0f3", "#c0edf9", "#c0edf9", "#eaf9fd", "#d5f3fa"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const fixedPercentage = (percent * 100).toFixed(0);
    if (+fixedPercentage > 10) {
      return (
        <g>
          {/* <text
            x={x}
            y={y}
            dx={5}
            fill="#303030"
            textAnchor={x > cx ? "start" : "end"}
            dominantBaseline="central"
            style={{
              fontWeight: "bold",
              fontSize: "8px",
              fontFamily: "OpenSans-Bold",
            }}
          >
            {records[index].name}
          </text> */}
          <text
            x={x}
            y={y}
            // dy={11}
            //   dx={30}
            fill="#606060"
            textAnchor={x > cx ? "start" : "end"}
            dominantBaseline="central"
            style={{ fontSize: "6px", fontFamily: "OpenSans-Regular" }}
          >
            {`${(percent * 100).toFixed(0)}%`}
          </text>
        </g>
      );
    }
    return null;
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{ background: "white", padding: "10px", textAlign: "center" }}
        >
          <p className="label">{`${payload[0].name} : ${payload[0].value}`}</p>
          <p className="intro">Users</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div style={{ width: "100%", height: 190 }}>
      <ResponsiveContainer>
        <PieChart margin={{ top: -10, right: -10, bottom: -10, left: -10 }}>
          <Pie
            dataKey="value"
            isAnimationActive={true}
            data={records}
            fill="#2DC3E8"
            label={renderCustomizedLabel}
            labelLine={false}
          >
            {records?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PieChartGraph;
