import React from "react";

const InputSwitchField = ({ labelRight, labelLeft, onChange }) => {
  return (
    <div className="form-check form-switch">
      <label className="form-check-label left" htmlFor="flexSwitchCheckDefault">
        {labelRight}
      </label>
      <input
        className="form-check-input"
        type="checkbox"
        role="switch"
        id="flexSwitchCheckDefault"
        onChange={onChange}
      />
      <label
        className="form-check-label right"
        htmlFor="flexSwitchCheckDefault"
      >
        {labelLeft}
      </label>
    </div>
  );
};

export default InputSwitchField;
