export const RaDatagrid_headerCell={
    '& .RaDatagrid-headerCell': {
        backgroundColor: "primary.main",
        color: '#fff',
        fontWeight: 'bold'
    },
    '& .css-ccn5u2-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active': {
        color: '#fff',
        fontWeight: 'bold'
    },
    '& .RaDatagrid-table': {
        fontSize: 14
    }
};