const StatusComponent = (props) => {
  return (
    // <div className="col-lg col-sm-6 col-6 sub-aapl">
      <div className={`block ${props?.blockStyle}`} style={props?.customWidth?{width:props?.customWidth}:{}}>
        <div style={{fontSize:'12px',lineHeight:'16px',textAlign:'center'}}>{props.label}</div>
        <img src={props.validationIcon} alt="" />
        {
          props.validationText === "NA"?
          <div style={{fontSize:'12px',lineHeight:'16px',textAlign:'center',color:'#909090'}}>{props.validationText}</div>
          :
          <span className={`${props.validationTextStyle} px-2`} style={{border:0,fontSize:'12px'}}>
            {props.validationText}
          </span>
        }
      </div>
    // </div>
  );
};

export default StatusComponent;
