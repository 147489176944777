import React from "react";
import { Box, Button, InputAdornment } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Shareapp from "../../assets/images/Shareapp.svg";
import { useForm, FormProvider, get } from "react-hook-form";
import InputField2 from "../common/InputComponents/InputField2";
import TextInputPhone from "../DocumentAnalysis/components/inputComponents/TextInputPhone";
import InputDateField from "../common/InputComponents/InputDateFieldOld";
import InputTextArea from "../common/InputComponents/InputTextArea";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Admin,
  Resource,
  useCreate,
  useNotify,
  useRefresh,
  defaultTheme,
  ListGuesser,
  useRecordContext,
} from "react-admin";
import LoadingSpinner from "../common/loadingSpinner";
import { GetLandlordThirdPartyAgentDetail } from "./GetLandlordThirdPartyAgentDetail";
import { Button as MuiButton } from "@mui/material";

const ShareAppReport = (props) => {
  const [open, setOpen] = React.useState(false);
  // handled username field
  const [showUsername, setShowUsername] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const refresh = useRefresh();
  const notify = useNotify();
  const [create, { isLoading, error }] = useCreate();

  // get aspNetUserId from global store
  const aspNetUserId = useSelector((state: any) => state?.users?.aspNetUserId);
  // get id for application id
  const { id } = useParams();
  const record = useRecordContext();

  // form validation
  const validationSchema = Yup.lazy((value) =>
    Yup.object().shape({
      invitation_sendto: Yup.string().required("Required!"),
      firstname: Yup.string()
        .typeError("First name is required!")
        .max(75, "maximum 75 characters is required")
        .required("First name is required!")
        .min(2, "Minimum 2 characters is required"),
      lastname: Yup.string()
        .typeError("Last name is required!")
        .max(75, "maximum 75 characters is required")
        .required("Last name is required!")
        .min(2, "Minimum 2 characters is required"),
      email: Yup.string()
        .email("Invalid email format")
        .max(75, "maximum 75 characters is required")
        .required("Email is required!")
        .matches(/^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/, "Invalid domain"),
      confirmemail: Yup.string()
        .email("Invalid email format")
        .max(75, "maximum 75 characters is required")
        .oneOf([Yup.ref("email"), ""], "Email must match!")
        .required("Confirm Email is required!")
        .matches(/^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/, "Invalid domain"),
      mobile: Yup.string()
        .required("Mobile number is required!")
        .typeError("Mobile number is required!")
        // .min(10, "Min 10 digits are required"),
        .test("rent", "Min 10 digits are required", (value) => {
          let temp: any = value?.toString().replace(/\D/g, "");
          return temp?.length > 10 ? true : false;
        }),
      // expire: Yup.string()
      //   .required("Expire is required!")
      //   .nullable()
      //   .typeError("Expire is required!"),
      message: Yup.string()
        .nullable()
        .trim("Message is required!")
        .max(5000, "maximum 5000 characters is required")
        .required("Message is required!"),
      username:
        showUsername && value.email !== ""
          ? Yup.string()
              .typeError("User name is required!")
              .trim("User name is required!")
              .required("User name is required!")
              .max(75, "maximum 75 characters is required")
              .min(2, "Minimum 2 characters is required")
          : Yup.string().nullable(),
    })
  );

  // Initial form values
  const defaultFormValues = {
    invitation_sendto: "Landlord",
    // Landlord: null,
    // ThirdPartyAgent: null,
    firstname: "",
    lastname: "",
    email: "",
    confirmemail: "",
    mobile: "",
    // expire: "",
    message: "",
    username: null,
  };

  //   Initial call for useForm or initial state
  const form = useForm({
    mode: "onChange",
    defaultValues: defaultFormValues,
    resolver: yupResolver(validationSchema),
  });

  // destructuring a form values
  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = form;

  // form onsubmit Handler
  const onSubmit = (value) => {
    const sendData = {
      ...value,
      subscriberid: id || record?.SubscriberID,
      applicationnumber: props?.record?.id,
      createdby: aspNetUserId,
    };
    create(
      "shareappreport",
      { data: sendData },
      {
        onSuccess: (data) => {
          notify(`Share App / Report has been send`, {
            type: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          refresh();
          handleClose();
          reset();
          setShowUsername(false);
        },
        onError: (e: any) => {
          if (e.message == '"Email already exists please enter username"') {
            setShowUsername(true);
          }
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        },
      }
    );
  };

  return (
    <>
      {props?.buttonShareAppReport ? (
        <button
          type="button"
          className=""
          onClick={() => {
            handleOpen();
            reset();
            setShowUsername(false);
          }}
        >
          <img src={Shareapp} alt="" />
          <span>Share App / Report</span>
        </button>
      ) : (
        <MuiButton
          onClick={handleOpen}
          startIcon={<img src={Shareapp} alt="" />}
          variant="text"
          sx={{ textTransform: "capitalize" }}
        >
          Share App / Report
        </MuiButton>
      )}

      <Dialog
        fullWidth
        open={open}
        sx={{ "& .MuiDialog-paperFullWidth ": { maxWidth: "800px" } }}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Invite Landlord / Third Party Agent
        </DialogTitle>
        <DialogContent>
          <FormProvider {...form}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-auto">
                      <div className="cus-input app-option-btn mr-b30">
                        <div className="it-option" id="a-oprtions">
                          <label htmlFor="Landlord">
                            <input
                              type="radio"
                              id="Landlord"
                              value="Landlord"
                              {...register("invitation_sendto")}
                            />
                            <span>Landlord</span>
                          </label>
                          <label htmlFor="ThirdPartyAgent">
                            <input
                              type="radio"
                              id="ThirdPartyAgent"
                              {...register("invitation_sendto")}
                              value="Third Party Agent"
                            />
                            <span>Third Party Agent</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 mr-b30">
                      <InputField2
                        label="First Name"
                        source={`firstname`}
                        placeholder="Enter First Name"
                        labelClass={"lable-title"}
                        additionalInputClass={"cus-control"}
                      />
                    </div>
                    <div className="col-sm-6 mr-b30">
                      <InputField2
                        label="Last Name"
                        source={`lastname`}
                        placeholder="Enter Last Name"
                        labelClass={"lable-title"}
                        additionalInputClass={"cus-control"}
                      />
                    </div>
                    <div className="col-sm-6 mr-b30">
                      <InputField2
                        label="Email"
                        source={`email`}
                        placeholder="Enter Email ID"
                        labelClass={"lable-title"}
                        additionalInputClass={"cus-control"}
                        handleOnChange={() => {
                          trigger(`confirmemail`);
                          setShowUsername(false);
                        }}
                      />
                    </div>
                    <div className="col-sm-6 mr-b30">
                      <InputField2
                        label="Confirm Email"
                        source={`confirmemail`}
                        placeholder="Confirm Email ID"
                        labelClass={"lable-title"}
                        additionalInputClass={"cus-control"}
                      />
                    </div>
                    <div className="col-sm-6 mr-b30">
                      <TextInputPhone
                        source={`mobile`}
                        label="Mobile"
                        isCountryCode={`country_code`}
                        labelClass={"lable-title"}
                        additionalInputClass={"cus-control"}
                        widthClass={"w-100"}
                        requiredClass={{
                          fontSize: "70%",
                        }}
                      />
                    </div>
                    {/* <div className="col-sm-6 mr-b30">
                      <InputDateField
                        source={"expire"}
                        label="Expire"
                        labelClass={"lable-title"}
                      />
                    </div> */}
                    {showUsername && watch("email") !== "" && (
                      <div className="col-sm-6 mr-b30">
                        <InputField2
                          label="Username"
                          source={`username`}
                          placeholder="Enter User Name"
                          labelClass={"lable-title"}
                          additionalInputClass={"cus-control"}
                        />
                      </div>
                    )}
                    <div className="col-sm-12 mr-b30">
                      <div className="emp-inputs">
                        <InputTextArea
                          register={"message"}
                          label="Message"
                          placeholder="Start typing here..."
                          labelClass={"lable-title"}
                          labelAsteriskClass={"color-red"}
                          additionalInputClass={"cus-control"}
                          styleProperty={{ height: "auto" }}
                          messageText={false}
                          requiredClass={{
                            fontSize: "70%",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="g-btm-btn d-flex align-items-center justify-content-end mr-b30">
                    <button
                      type="button"
                      className="blue-line-btn mr-r20"
                      onClick={() => {
                        handleClose();
                        reset();
                        setShowUsername(false);
                      }}
                    >
                      <svg
                        className="mr-r10"
                        width="10"
                        height="9"
                        viewBox="0 0 10 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 3.55732L8.3 0.257324L9.24267 1.19999L5.94267 4.49999L9.24267 7.79999L8.3 8.74266L5 5.44266L1.7 8.74266L0.757334 7.79999L4.05733 4.49999L0.757334 1.19999L1.7 0.257324L5 3.55732Z"
                          fill="#2DC3E8"
                        />
                      </svg>
                      Cancel
                    </button>
                    <button type="submit" className="blue-btn">
                      <svg
                        className="mr-r10"
                        width="13"
                        height="15"
                        viewBox="0 0 13 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.494 0.438615L12.802 7.20795C12.8543 7.23672 12.8978 7.279 12.9282 7.33036C12.9586 7.38172 12.9746 7.44029 12.9746 7.49995C12.9746 7.55961 12.9586 7.61818 12.9282 7.66954C12.8978 7.7209 12.8543 7.76317 12.802 7.79195L0.494 14.5613C0.443249 14.5892 0.386099 14.6034 0.328182 14.6025C0.270265 14.6016 0.213582 14.5857 0.163718 14.5562C0.113854 14.5267 0.072531 14.4847 0.0438223 14.4344C0.0151136 14.3841 9.91698e-06 14.3272 0 14.2693V0.730615C9.91698e-06 0.672692 0.0151136 0.61577 0.0438223 0.565462C0.072531 0.515153 0.113854 0.473194 0.163718 0.44372C0.213582 0.414246 0.270265 0.398274 0.328182 0.397379C0.386099 0.396484 0.443249 0.410696 0.494 0.438615ZM1.33333 8.16662V12.5779L10.5667 7.49995L1.33333 2.42195V6.83328H4.66667V8.16662H1.33333Z"
                          fill="white"
                        />
                      </svg>
                      Share
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
          {/* <Resource
            name="getlandlorddetail"
            list={
              <GetLandlordThirdPartyAgentDetail
                ApplicationNumber={props?.record?.id}
                SubscriberId={props?.record.SubscriberID}
              />
            }
          /> */}
          <GetLandlordThirdPartyAgentDetail
            ApplicationNumber={props?.record?.id}
            SubscriberId={props?.record.SubscriberID}
          />
          {/* <Resource<GetLandlordThirdPartyAgentDetail />
              name="getlandlorddetail"
              list={<ListGuesser />}
            /> */}
          {/* <Resource
            name="getsubscriberusage"
            list={<GetLandlordThirdPartyAgentDetail />}
          /> */}
          {/* <Resource name='getadmininbox' list={<GetAdmininboxList />} /> */}
          {/* <Resource name="getlandlorddetail" list={<GetAdmininboxList />} /> */}
        </DialogContent>
      </Dialog>
      {isLoading ? <LoadingSpinner /> : ""}
    </>
  );
};

export default ShareAppReport;
