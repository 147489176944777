// in src/Dashboard.js
import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Title, useGetIdentity, useGetList, useGetOne, useListContext } from "react-admin";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TotalApplicationGraph from "./componets/Ats/TotalApplicationGraph";
import { JSONTree } from "react-json-tree";
import StatsCard from "./componets/Ats/StatsCard";
import StatsCardTotalApplication from "./componets/Ats/StatsCardTotalApplication";
import StatsCardCompletedSreen from "./componets/Ats/StatsCardCompletedSreen";
import StatsCardCurrency from "./componets/Ats/StatsCardCurrency";
import { useParams } from "react-router";

import { LineChart, Line } from "recharts";
import { NumericFormat } from "react-number-format";

const StatsAndGraph = ({ data, statusCountData, subscriberCount, isAdmin }) => {
  const { filterValues } = useListContext();
  const [toggleButton, setToggleButton] = React.useState(false);
  // statusCountData
  // const statusCountDataNew:any[]=statusCountData;

  const percentageHandler = (value: number, type:string, totalPerCount?:string) => {
    const application =
      statusCountData?.filter((item) => item.ID == 13)[0]?.Count +
      statusCountData?.filter((item) => item.ID == 14)[0]?.Count +
      statusCountData?.filter((item) => item.ID == 15)[0]?.Count;
    const docUpload =
      statusCountData?.filter((item) => item.ID == 16)[0]?.Count +
      statusCountData?.filter((item) => item.ID == 17)[0]?.Count +
      statusCountData?.filter((item) => item.ID == 18)[0]?.Count;
    const total = type === "Application" ? application : docUpload;
    const percentage =
      value && value > 0 && total && total > 0 ? (value / total) * 100 : 0;
    // return Math.round(percentage) + "%";
    // return percentage.toFixed(2) + "%";
    if (totalPerCount) {
      return percentage;
    } else {
      return percentage.toFixed(2) + "%";
    }
  };

  const totalPercentageCountHandler = (type: string) => {
    const forApplication: any = (value: number) => {
      const per = percentageHandler(
        statusCountData?.filter((item) => item.ID == value)[0]?.Count,
        "Application",
        "totalPerCount"
      );
      return per;
    };
    const forDocUpload: any = (value: number) => {
      const per = percentageHandler(
        statusCountData?.filter((item) => item.ID == value)[0]?.Count,
        "DocUpload",
        "totalPerCount"
      );
      return per;
    };
    let totalPercentageCount: number = 100;
    if (type == "Application") {
      totalPercentageCount =
        forApplication(13) + forApplication(14) + forApplication(15);
    } else {
      totalPercentageCount =
        forDocUpload(16) + forDocUpload(17) + forDocUpload(18);
    }
    return Math.round(totalPercentageCount) + "%";
  };

  return (
    <>
      <div className="">
        {/* <div className="green-alert">
                <h5 className="red-alert-text">
                    Please note: The system will be down for maintainance on 21st June, 2022 from 12:00 hrs EST to 14:00 hrs
                    EST. We are sorry for any inconvinience caused.
                </h5>
                <button type="button" className="alert-close">
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4.99999 4.05733L8.29999 0.757332L9.24266 1.7L5.94266 5L9.24266 8.3L8.29999 9.24267L4.99999 5.94266L1.69999 9.24267L0.757324 8.3L4.05732 5L0.757324 1.7L1.69999 0.757332L4.99999 4.05733Z"
                            fill="#909090" />
                    </svg>
                </button>
            </div> */}

        <div className={`revenue-info-row mr-t20 ${subscriberCount && "mr-b30"}`}>
          <div className="row">
            {data?.subscriber_revenueaccess
              ? filterValues?.app_invitation_type == "1" && (
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <div className="revenue-box">
                    <h4 className="mr-b10">
                      <NumericFormat
                        thousandSeparator=","
                        displayType="text"
                        thousandsGroupStyle="thousand"
                        decimalScale={2}
                        prefix="$"
                        value={data?.subscriber_revenue}
                        fixedDecimalScale
                      />
                    </h4>
                    <small>Revenue</small>
                  </div>
                </div>
              )
              : ""}
            {(subscriberCount && isAdmin) && (
              <div className="col-12 col-md-4">
                <div className="app-count-table total_subscribers_box">
                  <table>
                    <thead>
                      <tr>
                        <th colSpan={2}>
                          <div className="t-count">
                            <h5>
                              {subscriberCount?.Total}
                              <small>Total Subscribers</small>
                            </h5>
                          </div>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td rowSpan={2} colSpan={2} className="light-green-td">
                          <div className="ta-no-block">
                            <h4 className="color-green">
                              {subscriberCount?.Active}
                            </h4>
                            <small className="color-green">Active</small>
                          </div>
                        </td>
                        <td rowSpan={2} className="gray-td">
                          <div className="ta-no-block">
                            <h4 className="color_light_gray">
                              {subscriberCount?.Inactive}
                            </h4>
                            <small className="color_light_gray">Inactive</small>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            <div
              className={`${
                filterValues?.app_invitation_type == "1" && data?.subscriber_revenueaccess
                  ? "col-md-8 col-lg-8"
                  : subscriberCount && isAdmin
                    ? "col-md-8 col-lg-8"
                    : "col-sm-12"
                } ${
                filterValues?.app_invitation_type !== "1" && !subscriberCount
                  ? "col-sm-12"
                  : filterValues?.app_invitation_type !== "1" && !isAdmin
                    ? "col-sm-12"
                    : ""
                }
               ${filterValues?.app_invitation_type === "3" && !subscriberCount ? "mr-b30" : ""}
              `}
            >
              {data?.graph_details && (
                <TotalApplicationGraph records={data?.graph_details} />
              )}
            </div>
          </div>
        </div>
        {filterValues?.app_invitation_type !== "3" && statusCountData && (
          <div className="app-count-table mr-t30 mr-b30">
            <table>
              <thead>
                <tr>
                  <th colSpan={3}>
                    <div className="t-count">
                      <h5>
                        {
                          statusCountData?.filter((item) => item.ID == 0)[0]
                            ?.Count
                        }
                        <small>Total Applications</small>
                      </h5>
                    </div>
                  </th>
                  <th colSpan={3}>
                    {filterValues?.app_invitation_type == "1" && (
                      <div className="qv-block justify-content-end">
                        {!toggleButton ? (
                          <h4>Qualification</h4>
                        ) : (
                          <small>Qualification</small>
                        )}
                        <div className="mr-r10 mr-l10">
                          <label htmlFor="v2" className="cus-switch-btn">
                            <input
                              type="checkbox"
                              name=""
                              id="v2"
                              onChange={(event) =>
                                setToggleButton(event.target.checked)
                              }
                              checked={toggleButton}
                            />
                            <span></span>
                          </label>
                        </div>
                        {toggleButton ? (
                          <h4>Verification</h4>
                        ) : (
                          <small>Verification</small>
                        )}
                      </div>
                    )}
                    {filterValues?.app_invitation_type == "2" && (
                      <div className="qv-block justify-content-end">
                        <small>Verification</small>
                      </div>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={2} className="gray-td">
                    <div className="ta-no-block">
                      <h4 className="color-gray">
                        {
                          statusCountData?.filter((item) => item.ID == 1)[0]
                            ?.Count
                        }
                      </h4>
                      <small className="color-gray">Invited</small>
                    </div>
                  </td>
                  <td rowSpan={2} className="light-sb-td">
                    <div className="ta-no-block">
                      <h4 className="color-blue">
                        {" "}
                        {
                          statusCountData?.filter((item) => item.ID == 2)[0]
                            ?.Count
                        }
                      </h4>
                      <small className="color-blue">In Progress</small>
                    </div>
                  </td>
                  <td
                    rowSpan={2}
                    className="dark-sb-td"
                    style={{
                      backgroundColor:
                        !toggleButton &&
                          filterValues?.app_invitation_type == "1"
                          ? "#b9d5ff45"
                          : "",
                    }}
                  >
                    {!toggleButton &&
                      filterValues?.app_invitation_type == "1" && (
                        <div className="ta-no-block">
                          <h4 className="color-orange">
                            {
                              statusCountData?.filter((item) => item.ID == 3)[0]
                                ?.Count
                            }
                          </h4>
                          <small className="color-blue">Submitted</small>
                        </div>
                      )}
                    {(toggleButton ||
                      filterValues?.app_invitation_type == "2") && (
                        <div className="ta-no-block">
                          <h4 className="color-d-blue">
                            {statusCountData?.filter((item) => item.ID == 3)[0]
                              ?.Count +
                              statusCountData?.filter((item) => item.ID == 5)[0]
                                ?.Count}
                          </h4>
                          <small className="color-d-blue">
                            Submitted <br />/ Review Pending
                          </small>
                        </div>
                      )}
                  </td>
                  {!toggleButton &&
                    filterValues?.app_invitation_type == "1" && (
                      <td colSpan={3} className="light-green-td">
                        <div className="ta-no-block d-flex align-items-center justify-content-center">
                          <p className="per_count">
                            {totalPercentageCountHandler("Application")}
                          </p>
                          <h4 className="color-green">
                            {statusCountData?.filter((item) => item.ID == 13)[0]
                              ?.Count +
                              statusCountData?.filter((item) => item.ID == 14)[0]
                                ?.Count +
                              statusCountData?.filter((item) => item.ID == 15)[0]
                                ?.Count
                              ? statusCountData?.filter(
                                (item) => item.ID == 13
                              )[0]?.Count +
                              statusCountData?.filter(
                                (item) => item.ID == 14
                              )[0]?.Count +
                              statusCountData?.filter(
                                (item) => item.ID == 15
                              )[0]?.Count
                              : 0}
                          </h4>
                          <small className="color-green mr-l10">
                            Completed Screened
                          </small>
                        </div>
                      </td>
                    )}
                  {(toggleButton ||
                    filterValues?.app_invitation_type == "2") && (
                      <td colSpan={3} className="light-green-td">
                        <div className="ta-no-block d-flex align-items-center justify-content-center">
                          <p className="per_count">
                            {totalPercentageCountHandler("DocUpload")}
                          </p>
                          <h4 className="color-green">
                            {statusCountData?.filter((item) => item.ID == 16)[0]
                              ?.Count +
                              statusCountData?.filter((item) => item.ID == 17)[0]
                                ?.Count +
                              statusCountData?.filter((item) => item.ID == 18)[0]
                                ?.Count
                              ? statusCountData?.filter(
                                (item) => item.ID == 16
                              )[0]?.Count +
                              statusCountData?.filter(
                                (item) => item.ID == 17
                              )[0]?.Count +
                              statusCountData?.filter(
                                (item) => item.ID == 18
                              )[0]?.Count
                              : 0}
                          </h4>
                          <small className="color-green mr-l10">
                            Completed Screened
                          </small>
                        </div>
                      </td>
                    )}
                </tr>
                {!toggleButton && filterValues?.app_invitation_type == "1" && (
                  <tr>
                    <td className="light-green-td">
                      <div className="ta-no-block">
                        <p className="per_count">
                          {percentageHandler(
                            statusCountData?.filter((item) => item.ID == 13)[0]
                              ?.Count,
                            "Application"
                          )}
                        </p>
                        <h4 className="color-green">
                          {
                            statusCountData?.filter((item) => item.ID == 13)[0]
                              ?.Count
                          }
                        </h4>
                        <small className="color-green">Approved</small>
                      </div>
                    </td>
                    <td className="light-orange-td">
                      <div className="ta-no-block">
                        <p className="per_count">
                          {percentageHandler(
                            statusCountData?.filter((item) => item.ID == 14)[0]
                              ?.Count,
                            "Application"
                          )}
                        </p>
                        <h4 className="color-orange">
                          {
                            statusCountData?.filter((item) => item.ID == 14)[0]
                              ?.Count
                          }
                        </h4>
                        <small className="color-orange">Conditional</small>
                      </div>
                    </td>
                    <td className="light-red-td">
                      <div className="ta-no-block">
                        <p className="per_count">
                          {percentageHandler(
                            statusCountData?.filter((item) => item.ID == 15)[0]
                              ?.Count,
                            "Application"
                          )}
                        </p>
                        <h4 className="color-red">
                          {
                            statusCountData?.filter((item) => item.ID == 15)[0]
                              ?.Count
                          }
                        </h4>
                        <small className="color-red">Denied</small>
                      </div>
                    </td>
                  </tr>
                )}
                {(toggleButton || filterValues?.app_invitation_type == "2") && (
                  <tr>
                    <td className="light-green-td">
                      <div className="ta-no-block">
                        <p className="per_count">
                          {percentageHandler(
                            statusCountData?.filter((item) => item.ID == 16)[0]
                              ?.Count,
                            "DocUpload"
                          )}
                        </p>
                        <h4 className="color-green">
                          {
                            statusCountData?.filter((item) => item.ID == 16)[0]
                              ?.Count
                          }
                        </h4>
                        <small className="color-green">Verified</small>
                      </div>
                    </td>
                    <td className="light-orange-td">
                      <div className="ta-no-block">
                        <p className="per_count">
                          {percentageHandler(
                            statusCountData?.filter((item) => item.ID == 17)[0]
                              ?.Count,
                            "DocUpload"
                          )}
                        </p>
                        <h4 className="color-orange">
                          {
                            statusCountData?.filter((item) => item.ID == 17)[0]
                              ?.Count
                          }
                        </h4>
                        <small className="color-orange">Doc Req</small>
                      </div>
                    </td>
                    <td className="light-red-td">
                      <div className="ta-no-block">
                        <p className="per_count">
                          {percentageHandler(
                            statusCountData?.filter((item) => item.ID == 18)[0]
                              ?.Count,
                            "DocUpload"
                          )}
                        </p>
                        <h4 className="color-red">
                          {
                            statusCountData?.filter((item) => item.ID == 18)[0]
                              ?.Count
                          }
                        </h4>
                        <small className="color-red">Unverifiable</small>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {/* Code new end here */}
    </>
  );
};

export default ({ isAdmin = false }) => {
  const { filterValues } = useListContext();
  const { id: subscriberid} = useParams();
  const { data: identity }: any = useGetIdentity();
  const id = subscriberid  ||  identity?.subscriberid ;
  // get getapplication graph call
  const { data, isLoading, error } = useGetOne<any>("getapplicationgraph", {
    id,
    meta: { app_invitation_type: filterValues?.app_invitation_type },
  });

  // get getapplication count status call
  const {
    data: statusCountData,
    isLoading: statusCountLoading,
    error: statusCountError,
  } = useGetOne(
    "getapplicationcountstatus",
    {
      id,
      meta: { app_invitation_type: filterValues?.app_invitation_type },
    },
    {
      enabled: !isAdmin,
    }
  );

  // get subscriberCount call
  const {
    data: subscriberCount,
    isLoading: getsubscriberCountLoading,
    error: getsubscriberCountError,
  } = useGetOne(
    "getsubscribercount",
    {
      id: 1,
    },
    {
      enabled: isAdmin,
    }
  );

  // logic to check if it has redirect url or not
  React.useEffect(() => {
    if (identity?.userrole != 'Docuverus Super Admin') {
      if (identity?.redirectUrl)
        window.location.replace(identity?.redirectUrl);
    }
  }, [identity])
  return (
    <>
      {/* <Title title="Dashboard" /> */}
      {data && (
        <StatsAndGraph
          data={data}
          statusCountData={statusCountData?.records}
          subscriberCount={subscriberCount?.records[0]}
          isAdmin={isAdmin}
        />
      )}
    </>
  );
};
