import React, { Fragment } from "react";
import ArchiveIcon from "@mui/icons-material/Archive";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Button } from "@mui/material";

const MarkInactiveModal = () => {
  const [open, setOpen] = React.useState(false);

  const dialogTitle = (
    <DialogTitle color="error" id="alert-dialog-title">
      <InfoIcon /> Are you sure?
    </DialogTitle>
  );

  const dialogContent = (
    <DialogContent>
      <p className="mr-b20">
        Are you sure you want to merge the applications? You won’t be able to
        undo this later!
      </p>
      <Box display={"flex"} justifyContent={"end"}>
        <Button
          sx={{ mx: 1 }}
          onClick={() => {
            setOpen(false);
          }}
          variant="outlined"
          color="secondary"
          type="button"
        >
          Cancel
        </Button>
        <Button
          startIcon={<ArchiveIcon />}
          sx={{ mx: 1 }}
          variant="contained"
          type="submit"
        >
          Merge
        </Button>
      </Box>
    </DialogContent>
  );

  return (
    <Fragment>
      <Button
        onClick={() => setOpen(true)}
        variant="text"
        sx={{ textTransform: "capitalize" }}
      >
        Mark as Active
      </Button>
      <Dialog
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {dialogTitle && dialogTitle}
        {dialogContent && dialogContent}
      </Dialog>
    </Fragment>
  );
};

export default MarkInactiveModal;
