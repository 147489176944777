import React from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Alert from '@mui/material/Alert';
import { useMutation, useQueryClient } from 'react-query';
import { JSONTree } from 'react-json-tree';

const ShowValidationMessagesApplicant = ({ validation_message }) => {

  return <div className="notify-wrap mr-b10">
    {validation_message?.map((notification, index) => notification.message &&
      <Alert
        severity="warning"
        key={notification.message + index}
        sx={{
          '& .MuiAlert-icon': {
            fontSize: 12
          },
          fontSize: 11,
          paddingTop: 0,
          paddingBottom: 0
        }}

      >
        {notification.message}
      </Alert>
    )}
  </div>
}

export default ShowValidationMessagesApplicant;