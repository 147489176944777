import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Card } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import { useGetList } from "react-admin";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm, Controller, FormProvider } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";
import DocumentAnalysisShow from "../../componets/DocumentAnalysis";
import AlertDialogTable from "../../componets/DocuploadTableComponent/AlertDialogTable";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import "../../App.css";
import ToggleButtons from "../../componets/common/InputComponents/ToggleButtons";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  FormLabel,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Backdrop,
  Typography,
  Button as MuiButton,
} from "@mui/material";
import InputField2 from "../../componets/common/InputComponents/InputField2";
import InputSelectField from "../../componets/common/InputComponents/InputSelectField";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";

const ApplicationSettings = () => {
  const [value, setValue] = React.useState("1");
  const [tab, setTab] = useState(0);

  const methods = useForm();
  const [docuverusAccordion, setDocuverusAccordion] = React.useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const require = [
    {
      value: "require",
      label: "require",
      icon: <DoneAllIcon />,
      borderColor: "#46A96F",
      backgroundColor: "#EBFFF3",
      boxShadowColor: "#46a96f1a",
      color: "#46A96F",
    },
  ];
  const toggleButton = [
    {
      value: "Inactive",
      label: "Inactive",
      icon: <ClearIcon />,
      borderColor: "#909090",
      backgroundColor: "#EEE",
      boxShadowColor: "#90909033",
      color: "#606060",
    },
    {
      value: "Active",
      label: "Active",
      icon: <CheckIcon />,
      borderColor: "#46A96F",
      backgroundColor: "#EBFFF3",
      boxShadowColor: "#46a96f1a",
      color: "#46A96F",
    },
  ];
  const threetoggleButton = [
    {
      value: "Hide",
      label: "hide",
      icon: <VisibilityOffOutlinedIcon />,
      borderColor: "#FF0000",
      backgroundColor: "#FFF5EE",
      boxShadowColor: "#90909033",
      color: "#FF0000",
    },
    {
      value: "Display",
      label: "display",
      icon: <RemoveRedEyeOutlinedIcon />,
      borderColor: "#8A3324",
      backgroundColor: "#FFF5EE",
      boxShadowColor: "#46a96f1a",
      color: "#8A3324",
    },
    {
      value: "require",
      label: "require",
      icon: <DoneAllIcon />,
      borderColor: "#46A96F",
      backgroundColor: "#EBFFF3",
      boxShadowColor: "#46a96f1a",
      color: "#46A96F",
    },
  ];

  const documenttoggleButton = [
    {
      value: "No upload",
      label: "no upload",
      icon: <VisibilityOffOutlinedIcon />,
      borderColor: "#FF0000",
      backgroundColor: "#FFF5EE",
      boxShadowColor: "#46a96f1a",
      color: "#FF0000",
    },
    {
      value: "Optional",
      label: "optional",
      icon: <RemoveRedEyeOutlinedIcon />,
      borderColor: "#8A3324",
      backgroundColor: "#FFF5EE",
      boxShadowColor: "#90909033",
      color: "#8A3324",
    },

    {
      value: "require",
      label: "require",
      icon: <DoneAllIcon />,
      borderColor: "#46A96F",
      backgroundColor: "#EBFFF3",
      boxShadowColor: "#46a96f1a",
      color: "#46A96F",
    },
  ];
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <>
      <div className="home_content">
        <div style={{ fontWeight: "bold" }}>
          <p>
            Application Profile &gt; Profile Details &gt;Application Settings
          </p>
        </div>
        <div className="nav-flexes" style={{ marginTop: "20px" }}>
          <nav>
            <div
              className="nav nav-tabs app-nav-tabs"
              id="nav-tab"
              role="tablist"
            >
              <button
                type="button"
                className="nav-link active"
                data-bs-toggle="tab"
                data-bs-target="#tab-applicant"
              >
                Applicant
              </button>
              <button
                type="button"
                className="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#tab-Co-applicant"
              >
                Co-applicant
              </button>
              <button
                type="button"
                className="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#tab-Co-signer"
              >
                Co-Signer
              </button>
              <button
                type="button"
                className="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#tab-documents"
              >
                Documents
              </button>
            </div>
          </nav>
        </div>

        <div className="tab-content app-nav-tabcontent" id="nav-tabContent">
          <div className="tab-pane fade show active" id="tab-applicant">
            {/* <Card> */}
            <TabContext value={value}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  marginTop: "10px",
                }}
              >
                <TabList
                  onChange={handleChange}
                  style={{
                    width: "100%",
                  }}
                >
                  <Tab label="Pre-authorization questions" value="1" />
                  <Tab label="Registration questions" value="2" />
                  <Tab label="Identification Details" value="3" />
                  <Tab label="Applicant Details" value="4" />
                  <Tab label="Income & Banking Details" value="5" />
                  <Tab label="Consent & Payment Details" value="6" />
                </TabList>
              </Box>

              <TabPanel value="1">
                <p>Pre-authorization questions</p>
                <br />
                <Card>
                  {" "}
                  <div className="auth-form mr-t30">
                    <FormProvider {...methods}>
                      <form style={{ marginLeft: "20px", marginRight: "20px" }}>
                        <div className="col-12">
                          <InputField2
                            label="Question"
                            source={`question`}
                            placeholder="Select"
                            labelClass={"lable-title"}
                            additionalInputClass={"cus-control"}
                          />
                        </div>
                        <br />
                        <Grid container spacing={4} columns={12}>
                          <Grid item xs={4}>
                            <InputSelectField
                              label="Qualify Answer"
                              source={`No of Co-applicant`}
                              options={undefined}
                              labelAsteriskClass={"color-red"}
                              labelClass={"lable-title"}
                              requiredClass={{
                                fontSize: "70%",
                              }}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <InputSelectField
                              label="Default Selection"
                              source={`No of Co-applicant`}
                              options={undefined}
                              labelAsteriskClass={"color-red"}
                              labelClass={"lable-title"}
                              requiredClass={{
                                fontSize: "70%",
                              }}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <ToggleButtons
                              toggleButton={toggleButton}
                              label="Status"
                              labelClass={"lable-title"}
                              source={`Status`}
                              onClickHandler={(event) => event.stopPropagation()}
                            />
                          </Grid>
                        </Grid>
                        <br />
                        <Box
                          display={"flex"}
                          justifyContent={"center"}
                          marginBottom={"10px"}
                        >
                          <Button
                            startIcon={<AddIcon />}
                            sx={{ mx: 1 }}
                            variant="contained"
                            type="submit"
                          >
                            Add Question
                          </Button>
                        </Box>
                      </form>
                    </FormProvider>
                  </div>
                </Card>

                <TableContainer>
                  <Table sx={{ marginLeft: "45px" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Question</TableCell>
                        <TableCell>Default Selection</TableCell>
                        <TableCell>Expected Answer</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableCell>Pallavi</TableCell>
                      <TableCell>Notes</TableCell>
                      <TableCell>Person</TableCell>
                      <TableCell>56</TableCell>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box style={{ marginLeft: "75%", marginTop: "15px" }}>
                  <Button
                    sx={{ mx: 1 }}
                    onClick={undefined}
                    variant="outlined"
                    color="primary"
                    type="button"
                  >
                    Cancel
                  </Button>

                  <Button
                    startIcon={<EditIcon />}
                    sx={{ mx: 1 }}
                    variant="contained"
                    type="submit"
                  >
                    Update
                  </Button>
                </Box>
              </TabPanel>

              <TabPanel value="2">
                <div className="auth-form mr-t30">
                  <FormProvider {...methods}>
                    <form>
                      {" "}
                      <div className="info-toggle">
                        <div
                          className="toggle-heads"
                          onClick={(event) => {
                            event.preventDefault();
                            setDocuverusAccordion((v) => !v);
                          }}
                        >
                          <div className="row align-items-center justify-content-between ">
                            <div className="col">
                              <div className="d-flex align-items-center">
                                <b
                                  className="ms-3 me-2"
                                  style={{ fontSize: "14px" }}
                                >
                                  Personal Details
                                </b>
                              </div>
                            </div>

                            <div className="col text-end m-1 remove_mr">
                              <ToggleButtons
                                toggleButton={toggleButton}
                                label=""
                                labelClass={"lable-title"}
                                source={`PersonalStatus`}
                                onClickHandler={(event) => event.stopPropagation()}
                              />
                            </div>
                          </div>

                          <div
                            className={` ${
                              !docuverusAccordion ? "d-none" : "d-display"
                            } card-body cust-card-appl`}
                          >
                            <div
                              className="card card-body w-100"
                              style={{ paddingTop: 0 }}
                            >
                              <div className="row">
                                <div className="col-lg-3 mr-b20 mr-t20">
                                  <ToggleButtons
                                    toggleButton={require}
                                    label="First Name"
                                    labelClass={"lable-title"}
                                    source={`fname`}
                                    onClickHandler={(event) => event.stopPropagation()}
                                  />
                                </div>
                                <div className="col-lg-3 mr-b20 mr-t20">
                                  <ToggleButtons
                                    toggleButton={threetoggleButton}
                                    label="Middle Name"
                                    labelClass={"lable-title"}
                                    source={`mnane`}
                                    onClickHandler={(event) => event.stopPropagation()}
                                  />
                                </div>
                                <div className="col-lg-3 mr-b20 mr-t20">
                                  <ToggleButtons
                                    toggleButton={require}
                                    label="Last Name"
                                    labelClass={"lable-title"}
                                    source={`lname`}
                                    onClickHandler={(event) => event.stopPropagation()}
                                  />
                                </div>
                                <div className="col-lg-3 mr-b20 mr-t20">
                                  <ToggleButtons
                                    toggleButton={threetoggleButton}
                                    label="Mobile Number"
                                    labelClass={"lable-title"}
                                    source={`mobile`}
                                    onClickHandler={(event) => event.stopPropagation()}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </FormProvider>
                </div>

                <div className="auth-form mr-t30">
                  <FormProvider {...methods}>
                    <form>
                      {" "}
                      <div className="info-toggle">
                        <div
                          className="toggle-heads"
                          onClick={(event) => {
                            event.preventDefault();
                            setDocuverusAccordion((v) => !v);
                          }}
                        >
                          <div className="row align-items-center justify-content-between ">
                            <div className="col">
                              <div className="d-flex align-items-center">
                                <b
                                  className="ms-3 me-2"
                                  style={{ fontSize: "14px" }}
                                >
                                  Agent
                                </b>
                              </div>
                            </div>

                            <div className="col text-end m-1 remove_mr">
                              <ToggleButtons
                                toggleButton={toggleButton}
                                label=""
                                labelClass={"lable-title"}
                                source={`Agentstatus`}
                                onClickHandler={(event) => event.stopPropagation()}
                              />
                            </div>
                          </div>

                          <div
                            className={` ${
                              !docuverusAccordion ? "d-none" : "d-display"
                            } card-body cust-card-appl`}
                          >
                            <div
                              className="card card-body w-100"
                              style={{ paddingTop: 0 }}
                            >
                              <div className="row">
                                <div className="col-lg-3 mr-b20 mr-t20">
                                  <ToggleButtons
                                    toggleButton={threetoggleButton}
                                    label="Agent"
                                    labelClass={"lable-title"}
                                    source={`agent`}
                                    onClickHandler={(event) => event.stopPropagation()}
                                  />
                                </div>
                                <div className="col-lg-3 mr-b20 mr-t20">
                                  <ToggleButtons
                                    toggleButton={threetoggleButton}
                                    label="Applying with Co-operating Agent?"
                                    labelClass={"lable-title"}
                                    source={`CooperatingAgent`}
                                    onClickHandler={(event) => event.stopPropagation()}
                                  />
                                </div>
                                <div className="col-lg-3 mr-b20 mr-t20">
                                  <ToggleButtons
                                    toggleButton={threetoggleButton}
                                    label="Agent Name"
                                    labelClass={"lable-title"}
                                    source={`agentName`}
                                    onClickHandler={(event) => event.stopPropagation()}
                                  />
                                </div>
                                <div className="col-lg-3 mr-b20 mr-t20">
                                  <ToggleButtons
                                    toggleButton={threetoggleButton}
                                    label="Real Estate Agency"
                                    labelClass={"lable-title"}
                                    source={`agency`}
                                    onClickHandler={(event) => event.stopPropagation()}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </FormProvider>
                </div>

                <div className="auth-form mr-t30">
                  <FormProvider {...methods}>
                    <form>
                      {" "}
                      <div className="info-toggle">
                        <div
                          className="toggle-heads"
                          onClick={(event) => {
                            event.preventDefault();
                            setDocuverusAccordion((v) => !v);
                          }}
                        >
                          <div className="row align-items-center justify-content-between ">
                            <div className="col">
                              <div className="d-flex align-items-center">
                                <b
                                  className="ms-3 me-2"
                                  style={{ fontSize: "14px" }}
                                >
                                  Desired Property Details
                                </b>
                              </div>
                            </div>

                            <div className="col text-end m-1 remove_mr">
                              <ToggleButtons
                                toggleButton={toggleButton}
                                label=""
                                labelClass={"lable-title"}
                                source={`PropertyStatus`}
                                onClickHandler={(event) => event.stopPropagation()}
                              />
                            </div>
                          </div>

                          <div
                            className={` ${
                              !docuverusAccordion ? "d-none" : "d-display"
                            } card-body cust-card-appl`}
                          ></div>
                        </div>
                      </div>
                    </form>
                  </FormProvider>
                </div>

                <div className="auth-form mr-t30">
                  <FormProvider {...methods}>
                    <form>
                      {" "}
                      <div className="info-toggle">
                        <div
                          className="toggle-heads"
                          onClick={(event) => {
                            event.preventDefault();
                            setDocuverusAccordion((v) => !v);
                          }}
                        >
                          <div className="row align-items-center justify-content-between ">
                            <div className="col">
                              <div className="d-flex align-items-center">
                                <b
                                  className="ms-3 me-2"
                                  style={{ fontSize: "14px" }}
                                >
                                  Login Details
                                </b>
                              </div>
                            </div>

                            <div className="col text-end m-1 remove_mr">
                              <ToggleButtons
                                toggleButton={toggleButton}
                                label=""
                                labelClass={"lable-title"}
                                source={`loginStatus`}
                                onClickHandler={(event) => event.stopPropagation()}
                              />
                            </div>
                          </div>

                          <div
                            className={` ${
                              !docuverusAccordion ? "d-none" : "d-display"
                            } card-body cust-card-appl`}
                          >
                            <div
                              className="card card-body w-100"
                              style={{ paddingTop: 0 }}
                            >
                              <div className="row">
                                <div className="col-lg-3 mr-b20 mr-t20">
                                  <ToggleButtons
                                    toggleButton={require}
                                    label="Email"
                                    labelClass={"lable-title"}
                                    source={`email`}
                                    onClickHandler={(event) => event.stopPropagation()}
                                  />
                                </div>
                                <div className="col-lg-3 mr-b20 mr-t20">
                                  <ToggleButtons
                                    toggleButton={require}
                                    label="Password"
                                    labelClass={"lable-title"}
                                    source={`password`}
                                    onClickHandler={(event) => event.stopPropagation()}
                                  />
                                </div>
                                <div className="col-lg-3 mr-b20 mr-t20">
                                  <ToggleButtons
                                    toggleButton={threetoggleButton}
                                    label="How did you hear about us?"
                                    labelClass={"lable-title"}
                                    source={`hear`}
                                    onClickHandler={(event) => event.stopPropagation()}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </FormProvider>
                </div>

                <Box style={{ marginLeft: "75%", marginTop: "15px" }}>
                  <Button
                    sx={{ mx: 1 }}
                    onClick={undefined}
                    variant="outlined"
                    color="primary"
                    type="button"
                  >
                    Cancel
                  </Button>

                  <Button
                    startIcon={<EditIcon />}
                    sx={{ mx: 1 }}
                    variant="contained"
                    type="submit"
                  >
                    Update
                  </Button>
                </Box>
              </TabPanel>

              <TabPanel value="3">Third tab</TabPanel>
              <TabPanel value="4">Forth tab</TabPanel>
              <TabPanel value="5">Fifth tab</TabPanel>
              <TabPanel value="6">Sixth tab</TabPanel>
            </TabContext>
            {/* </Card> */}
          </div>

          <div className="tab-pane fade show" id="tab-Co-applicant"></div>

          <div className="tab-pane fade show" id="tab-Co-signer">
            {" "}
          </div>

          <div className="tab-pane fade show" id="tab-documents">
            <div className="auth-form mr-t10">
              <FormProvider {...methods}>
                <form>
                  <div className="info-toggle">
                    <div
                      className="toggle-heads"
                      onClick={(event) => {
                        event.preventDefault();
                        setDocuverusAccordion((v) => !v);
                      }}
                    >
                      <div className="row align-items-center justify-content-between ">
                        <div className="col">
                          <div className="d-flex align-items-center">
                            <b
                              className="ms-3 me-2"
                              style={{ fontSize: "14px" }}
                            >
                              Document Settings
                            </b>
                          </div>
                        </div>

                        <div className="col text-end m-1 remove_mr">
                          <ToggleButtons
                            toggleButton={toggleButton}
                            label=""
                            labelClass={"lable-title"}
                            source={`documentStatus`}
                            onClickHandler={(event) => event.stopPropagation()}
                          />
                        </div>
                      </div>

                      <div
                        className={` ${
                          !docuverusAccordion ? "d-none" : "d-display"
                        } card-body cust-card-appl`}
                      >
                        <div
                          className="card card-body w-100"
                          style={{ paddingTop: 0 }}
                        >
                          <div className="row">
                            <div className="col-xl-3 mr-b10 mr-t20">
                              <ToggleButtons
                                toggleButton={documenttoggleButton}
                                label="ID"
                                labelClass={"lable-title"}
                                source={`id`}
                                onClickHandler={(event) => event.stopPropagation()}
                              />
                            </div>
                            <div className="col-xl-3 mr-b10 mr-t20">
                              <ToggleButtons
                                toggleButton={documenttoggleButton}
                                label="SSN"
                                labelClass={"lable-title"}
                                source={`ssn`}
                                onClickHandler={(event) => event.stopPropagation()}
                              />
                            </div>
                            <div className="col-xl-3 mr-b10 mr-t20">
                              <ToggleButtons
                                toggleButton={require}
                                label="Employment Income"
                                labelClass={"lable-title"}
                                source={`income`}
                                onClickHandler={(event) => event.stopPropagation()}
                              />
                            </div>
                            <div className="col-xl-3 mr-b10 mr-t20">
                              <ToggleButtons
                                toggleButton={documenttoggleButton}
                                label="Non Employment Income"
                                labelClass={"lable-title"}
                                source={`nonEmployment`}
                                onClickHandler={(event) => event.stopPropagation()}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-3 mr-b10 mr-t10">
                              <ToggleButtons
                                toggleButton={require}
                                label="Banking"
                                labelClass={"lable-title"}
                                source={`banking`}
                                onClickHandler={(event) => event.stopPropagation()}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </FormProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationSettings;
