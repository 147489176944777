import React, { Fragment } from "react";
import { Box, Button } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { useForm, FormProvider } from "react-hook-form";
import { useListContext, CheckboxGroupInput } from "react-admin";
import InputDateField from "../../common/InputComponents/InputDateFieldOld";

const FilterModal = () => {
  const [open, setOpen] = React.useState(false);

  const { displayedFilters, filterValues, setFilters, hideFilter } =
    useListContext();

  const applicationStatus = [
    {
      ApplicationStatus: "Active",
    },
    {
      ApplicationStatus: "Inactive",
    },
  ];

  const form = useForm({
    defaultValues: filterValues,
  });

  // form submit handler
  const onSubmit = (value) => {
    console.log(value);
  };

  const dialogTitle = (
    <DialogTitle id="alert-dialog-title" className="dialog_title_filters">
      Filters
    </DialogTitle>
  );

  const dialogContent = (
    <DialogContent className="dialog_content_filters">
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Box sx={{ width: 450 }} component={"div"}>
            <Box
              className="card-s-title"
              display="flex"
              flexDirection={"column"}
              alignItems="flex-start"
              mb={1}
            >
              <h6>Date Range</h6>

              <Box
                className="date-label"
                sx={{ width: "100%", paddingTop: 1 }}
                alignItems={"center"}
                display={"flex"}
                component="span"
                mr={2}
              >
                <span style={{ paddingRight: 2, paddingBottom: 10 }}>
                  <strong>From:</strong>
                </span>
                <InputDateField source={"start_date"} maxDate={new Date()} />
                <strong
                  style={{
                    paddingRight: 2,
                    paddingLeft: 2,
                    paddingBottom: 10,
                    fontSize: "14px",
                    color: "#303030",
                    fontWeight: "inherit",
                  }}
                >
                  &nbsp;To&nbsp;
                </strong>
                <InputDateField source={"end_date"} maxDate={new Date()} />
              </Box>
              <hr className="w-100" />
              <Box component="span" mt={2}>
                <h6>Application Status</h6>
              </Box>
              <Box component="span" className="app-list">
                <CheckboxGroupInput
                  className="app_list_checkbox"
                  sx={{ marginTop: 0 }}
                  row={false}
                  label=""
                  source="ApplicationStatus"
                  choices={applicationStatus?.map((item) => {
                    return {
                      id: item.ApplicationStatus,
                      name: item.ApplicationStatus,
                    };
                  })}
                />
              </Box>
            </Box>
            <Box display={"flex"} justifyContent={"end"}>
              <Button
                sx={{ mx: 1 }}
                onClick={() => {
                  setOpen(false);
                }}
                variant="outlined"
                color="secondary"
                type="button"
              >
                Cancel
              </Button>
              <Button sx={{ mx: 1 }} variant="contained" type="submit">
                Apply
              </Button>
            </Box>
          </Box>
        </form>
      </FormProvider>
    </DialogContent>
  );

  return (
    <Fragment>
      <Button
        onClick={() => setOpen(true)}
        variant="text"
        sx={{ textTransform: "capitalize" }}
      >
        Filter
      </Button>
      <Dialog
        className="filter-modal"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {dialogTitle && dialogTitle}
        {dialogContent && dialogContent}
      </Dialog>
    </Fragment>
  );
};

export default FilterModal;
