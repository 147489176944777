import React from "react";

import SideBar from "../../pages/Sidebar/sidebar";
import { FormProvider, useForm } from "react-hook-form";
import InputField2 from "../../componets/common/InputComponents/InputField2";
import Grid from "@mui/material/Grid";
import "../../assets/styles/style.css";
import InputSelectField from "../../componets/common/InputComponents/InputSelectField";
import { Card, CardActions, CardContent, CardHeader } from "@mui/material";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import crossIcon from "../../assets/images/crossIcon.svg";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  FormLabel,
} from "@mui/material";
import Header from "../../componets/common/Header/Header";
import YesNoRadioButton from "../../componets/common/InputComponents/YesNoRadioButton";
import InputField from "../../componets/common/InputComponents/InputField";
import { useLogin, useNotify } from "react-admin";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import ToggleButtons from "../../componets/common/InputComponents/ToggleButtons";

const AddNewProfile = () => {
  // const login = useLogin();
  const notify = useNotify();
  // const methods = useForm();
  const schema = yup.object().shape({
    profilename: yup.string().required("required"),
    copyprofile:yup.string().required("required"),
    CoApplicant: yup.string().required("required"),
    Status: yup.string().required("required"),
    Questionaries: yup.string().required("required"),
    LookbackPeriods: yup.string().required("required"),
    incomeBanking: yup.string().required("required"),
    applicationFee: yup.string().required("required"),
    subscriberFee: yup.string().required("required"),
    screeningFee: yup.string().required("required"),
    processingFee: yup.string().required("required"),
    leadSource: yup.string().required("required"),
    sendLease: yup.string().required("required"),
    DesiredLease: yup.string().required("required"),
    planUnit: yup.string().required("required"),

  });
  const toggleButton = [
    {
      value: "Inactive",
      label: "Inactive",
      icon: <ClearIcon />,
      borderColor: "#909090",
      backgroundColor: "#EEE",
      boxShadowColor: "#90909033",
      color: "#606060",
    },
    {
      value: "Active",
      label: "Active",
      icon: <CheckIcon />,
      borderColor: "#46A96F",
      backgroundColor: "#EBFFF3",
      boxShadowColor: "#46a96f1a",
      color: "#46A96F",
    },
  ];
  const [docuverusAccordion, setDocuverusAccordion] = React.useState(false);
  const methods = useForm({
    defaultValues: {
      profilename: "",
      copyprofile:"",
      CoApplicant: "",
      Status:"",
      Questionaries:"",
      LookbackPeriods:"",
      incomeBanking:"",
      applicationFee:"",
      subscriberFee:"",
      screeningFee:"",
      processingFee:"",
      leadSource:"",
      sendLease:"",
      DesiredLease:"",
      planUnit:"",

    },
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const onSubmit = (data) => {
    const sendData = {
      profilename: data?.profilename,
      CoApplicant: data?.CoApplicant,
      Status:data?.status,
      Questionaries:data?.Questionaries,
      LookbackPeriods:data?.LookbackPeriods,
      incomeBanking:data?.incomeBanking,
      applicationFee:data?.applicationFee,
      subscriberFee:data?.subscriberFee,
      screeningFee:data?.screeningFee,
      processingFee:data?.processingFee,
      leadSource:data?.leadSource,
      sendLease:data?.sendLease,
      DesiredLease:data?.DesiredLease,
      planUnit:data?.planUnit,
    };
    // login({ ...sendData }).catch(() =>
    //   notify("Invalid data", {
    //     anchorOrigin: { vertical: "top", horizontal: "right" },
    //   })
    // );
    };
  return (
    <>
    <div className="home_content">

      <div
        style={{fontWeight: "bold"}}
      >
        <p>
          Application Profile &gt; Add New Profile
          
        </p>
      </div>
      <div className="form">
      <div className="auth-form mr-t30">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container spacing={3} columns={12}>
              <Grid item xs={4}>
                <InputField2
                  label="Profile Name"
                  // register={methods.register("email")}
                  source={`profilename`}
                  placeholder="Enter profile name here"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                />
              </Grid>
              <Grid item xs={4}>
                <InputSelectField
                  label="Copy Application Profile"
                  source={`copyprofile`}
                  options={undefined}
                  labelAsteriskClass={"color-red"}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                />
              </Grid>
              <Grid item xs={4}>
              <ToggleButtons
                      toggleButton={toggleButton}
                      label="Status"
                      labelClass={"lable-title"}
                      source={`Status`}
                    />
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={3} columns={12}>
              <Grid item xs={4}>
              <InputSelectField
                  label="No.of Co-applicant"
                  source={`CoApplicant`}
                  options={undefined}
                  labelAsteriskClass={"color-red"}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                />
              </Grid>
              <Grid item xs={4}>
              <YesNoRadioButton
                  required={true}
                  label="Pre-Auth Questionaries"
                  source="Questionaries"
                  disable={undefined}
                  handleOnClick={undefined}
                />
              </Grid>
              <Grid item xs={4}>
              <InputSelectField
                  label="Lookback Periods(in weeks)"
                  source={`LookbackPeriods`}
                  options={undefined}
                  labelAsteriskClass={"color-red"}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                />
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={3} columns={12}>
              <Grid item xs={4}>
              <YesNoRadioButton
                  required={true}
                  label="Allow applicant to edit income/banking?"
                  source="incomeBanking"
                  disable={undefined}
                  handleOnClick={undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} columns={12}>
              <Grid item xs={4}>
                <InputField2
                  label="Application Fee"
                  // register={methods.register("email")}
                  source={`applicationFee`}
                  placeholder="Enter Application Fee here"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField2
                  label="Subscriber Fee"
                  // register={methods.register("email")}
                  source={`subscriberFee`}
                  placeholder="Enter Subscriber Fee here"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField2
                  label="Screening Fee"
                  // register={methods.register("email")}
                  source={`screeningFee`}
                  placeholder="Enter Screening Fee here"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                />
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={3} columns={12}>
              <Grid item xs={4}>
                <InputField2
                  label="Processing Fee"
                  // register={methods.register("email")}
                  source={`processingFee`}
                  placeholder="Enter Processing Fee here"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                />
              </Grid>
              <Grid item xs={4}>
                <InputSelectField
                  label="Lead Source"
                  source={`leadSource`}
                  options={"Rent.com"}
                  labelAsteriskClass={"color-red"}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                />
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={3} columns={12}>
              <Grid item xs={4}>
                <YesNoRadioButton
                  required={true}
                  label="Send Lease to Landloard"
                  source="sendLease"
                  disable={undefined}
                  handleOnClick={undefined}
                />
              </Grid>
              <Grid item xs={4}>
                <InputSelectField
                  label="Desired Lease"
                  source={`DesiredLease`}
                  options={undefined}
                  labelAsteriskClass={"color-red"}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                />
                
              </Grid>
              <Grid item xs={4}>
                <YesNoRadioButton
                  required={true}
                  label="Floor Plan/Unit Required?"
                  source="planUnit"
                  disable={undefined}
                  handleOnClick={undefined}
                  
                />
              </Grid>
            </Grid>
            <br />
{/*           
          <Card
        variant="outlined"
        sx={{
          maxHeight: "max-content",
          overflow: "auto",
        }}
      >
        <CardHeader
          style={{ fontWeight: "800" }}
          title="Custom Application Settings"
          // action={
          //     <InputSelectField
          //     label="Lead Source"
          //     source={`No of Co-applicant`}
          //     options={undefined}
          //     labelAsteriskClass={"color-red"}
          //     labelClass={"lable-title"}
          //     requiredClass={{
          //       fontSize: "70%",
          //     }}
          //   />
          // }
        />
        <hr />
        <CardContent
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, minmax(80px, 1fr))",
            gap: 1.5,
          }}
        >
          <p>
            <strong>Send application completion confirmation email</strong>
          </p>
          <br />

          <FormProvider {...methods}>
            <form>
              <Grid container spacing={2} columns={16}>
                <Grid item xs={8}>
                  <InputSelectField
                    label="Value"
                    source={`value`}
                    options={undefined}
                    // labelAsteriskClass={"color-red"}
                    labelClass={"lable-title"}
                    // requiredClass={{
                    //   fontSize: "70%",
                    // }}
                  />
                </Grid>
                <Grid item xs={8}>
                  <InputSelectField
                    label="Send to"
                    source={`send-to`}
                    options={undefined}
                    labelAsteriskClass={"color-red"}
                    labelClass={"lable-title"}
                    requiredClass={{
                      fontSize: "70%",
                    }}
                  />
                </Grid>
              </Grid>
              <br />
              <p>
                <strong>Adverse Letter Type</strong>
              </p>
              <br />

              <Grid container spacing={2} columns={16}>
                <Grid item xs={8}>
                  <InputSelectField
                    label="Value"
                    source={`value1`}
                    options={undefined}
                    labelAsteriskClass={"color-red"}
                    labelClass={"lable-title"}
                    requiredClass={{
                      fontSize: "70%",
                    }}
                  />
                </Grid>
                <Grid item xs={8}>
                  <InputSelectField
                    label="Send to"
                    source={`send-to1`}
                    options={undefined}
                    labelAsteriskClass={"color-red"}
                    labelClass={"lable-title"}
                    requiredClass={{
                      fontSize: "70%",
                    }}
                  />
                </Grid>
              </Grid>
              <br />
            </form>
          </FormProvider>
        </CardContent>
      </Card> */}
        <div className="auth-form mr-t30">
                  <FormProvider {...methods}>
                    <form>
                      {" "}
                      <div className="info-toggle">
                        <div
                          className="toggle-heads"
                          onClick={(event) => {
                            event.preventDefault();
                            setDocuverusAccordion((v) => !v);
                          }}
                        >
                          <div className="row align-items-center justify-content-between ">
                            <div className="col">
                              <div className="d-flex align-items-center">
                                <b
                                  className="ms-3 me-2"
                                  style={{ fontSize: "18px" }}
                                >
                                  Custom Application Settings
                                </b>
                              </div>
                            </div>

                            <div className="col text-end m-1 remove_mr">
                              <ToggleButtons
                                toggleButton={toggleButton}
                                label=""
                                labelClass={"lable-title"}
                                source={`PersonalStatus`}
                              />
                            </div>
                          </div>

                          <div
                            className={` ${
                              !docuverusAccordion ? "d-none" : "d-display"
                            } card-body cust-card-appl`}
                          >
                            <div
                              className="card card-body w-100"
                              style={{ paddingTop: 0 }}
                            >


                              <div style={{marginTop:'20px'}}>
                                <strong>
                                  Send application completion confirmation email
                                </strong>
                              </div>

                              <div className="row">
                                <div className="col-lg-3 mr-t20">
                                  <InputSelectField
                                    label="Value"
                                    source={`value`}
                                    options={undefined}
                                    // labelAsteriskClass={"color-red"}
                                    labelClass={"lable-title"}
                                    // requiredClass={{
                                    //   fontSize: "70%",
                                    // }}
                                  />
                                </div>
                                <div className="col-lg-3 mr-b20 mr-t20">
                                  <InputSelectField
                                    label="Send To"
                                    source={`value`}
                                    options={undefined}
                                    // labelAsteriskClass={"color-red"}
                                    labelClass={"lable-title"}
                                    // requiredClass={{
                                    //   fontSize: "70%",
                                    // }}
                                  />
                                </div>
                              </div>
                              <div >
                                <strong>
                                Adverse Letter Type
                                </strong>
                              </div>

                              <div className="row">
                                <div className="col-lg-3 mr-t20">
                                  <InputSelectField
                                    label="Value"
                                    source={`value`}
                                    options={undefined}
                                    // labelAsteriskClass={"color-red"}
                                    labelClass={"lable-title"}
                                    // requiredClass={{
                                    //   fontSize: "70%",
                                    // }}
                                  />
                                </div>
                                <div className="col-lg-3 mr-b20 mr-t20">
                                  <InputSelectField
                                    label="Send To"
                                    source={`value`}
                                    options={undefined}
                                    // labelAsteriskClass={"color-red"}
                                    labelClass={"lable-title"}
                                    // requiredClass={{
                                    //   fontSize: "70%",
                                    // }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </FormProvider>
                </div>


      <Box style={{ marginLeft: "65%" ,marginTop:'15px'}}>
        <Button
          sx={{ mx: 1 }}
          onClick={undefined}
          variant="outlined"
          color="primary"
          type="button"
        >
          Cancel
        </Button>

        <Button
          startIcon={<EditIcon />}
          sx={{ mx: 1 }}
          variant="contained"
          type="submit"
        >
          Create Profile
        </Button>
      </Box>
      </form>
        </FormProvider>
      </div>
      </div>
      </div>
    </>
  );
};

export default AddNewProfile;
