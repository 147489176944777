import React, { useState, useEffect, useCallback } from "react";
// import GoogleAutoComplete from "../../../common/googleAutocomplete/googleAutocomplete";
import { useFieldArray, useFormContext, useFormState } from "react-hook-form";
// import InputField from "../../../registration/Inputs/InputField";
// import SelectField from "../../../registration/Inputs/SelectField";
// import InputDateField from "../../../registration/Inputs/InputDateField";
// import InputCurrencyField from "../../../registration/Inputs/InputCurrencyField";
// import InputTextArea from "../../../registration/Inputs/InputTextArea";
// import PropertyType from "./property-type/property-type";
// import { useGetMasterRegistrationDataQuery } from "../../../../services/registrationApi";
import { useSelector } from "react-redux";
import { TiDelete } from "react-icons/ti";
import {
  AccordionDetails,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CardHeader,
} from "@mui/material";
// import InputMaskField from "../../../registration/Inputs/InputMaskFieid";
// import InputFieldArrayLandlord from "../personal-details/InputFieldArrayLandlord";
// import InputFieldPhone from "../../../registration/Inputs/InputFieldPhone";
import { AiOutlineCheckCircle } from "react-icons/ai";
import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowDown } from "react-icons/ai";
import InputField2 from "../../common/InputComponents/InputField2";
import InputSelectField from "../../common/InputComponents/InputSelectField";
import { useGetOne } from "react-admin";
// import InputCheckBoxField from "../../../registration/Inputs/InputCheckBoxField";

// new import added
import { useParams } from "react-router-dom";
import InputField from "../../common/InputComponents/InputField";
import InputCheckBoxField from "../../common/InputComponents/InputCheckBoxField";
import InputDateField from "../../common/InputComponents/InputDateFieldOld";
import PropertyType from "./PropertyType";
import InputCurrencyField from "../components/inputComponents/InputCurrencyFiled";
import TextInputPhone from "../components/inputComponents/TextInputPhone";
import InputTextArea from "../../common/InputComponents/InputTextArea";
import GoogleAutoComplete from "../../common/InputComponents/GoogleAutocomplete";
import DeleteContainedBtn from "../../common/DeleteContainedBtn";

const AddressSection = (props) => {
  const navigate = useNavigate();
  let nextDate = new Date().setDate(new Date().getDate() + 1);
  const { register, watch, getValues, control, setValue, trigger } =
    useFormContext();
  const [isCurrentAddress, setIsCurrentAddress] = useState(
    register("identification_data.is_current_address")
  );
  const [open, setOpen] = useState(false);
  const [deleteAddressIndex, setDeleteAddressIndex] = useState(0);
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "address",
    keyName: "fields_id",
  });
  const { dirtyFields, errors, touchedFields } = useFormState({
    control,
  });
  const error = errors["propertyType"];

  //   const trn_id = useSelector(
  //     (state) => state.persistedReducer.loginSlice.trnId
  //   );
  //   const url = useSelector(
  //     (state) => state.persistedReducer.loginSlice.urlGeneratedAts
  //   );
  //   const useWindowWidth = useSelector(
  //     (state) => state.identification.sideMenuSlice
  //   );

  // get application detail data for state here
  // const { id } = useParams();
  // const { data, isLoading } = useGetOne("getnewapplicationdetail", {
  //   id,
  // });
  //   const { data, isLoading, isError, isSuccess } =
  //     useGetMasterRegistrationDataQuery({ trn_id, url });
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // debouncedSave(name, false);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const [nextSection, setNextSection] = useState(false);

  // const debouncedSave = useCallback(
  //   debounce(
  //     (nextValue) =>
  //       (async () => {
  //         setNextSection(await props.checkValidation("Address", false));
  //       })(),
  //     1000
  //   ),
  //   [] // will be created only once initially
  // );

  return (
    <AccordionDetails>
      {fields.map((field, index) => {
        let newIndex = index + 1;
        return (
          <div key={field.fields_id} id="address">
            {/* <div
              className={`${
                useWindowWidth.windowWidth < 766
                  ? "display-none xs-title-acc"
                  : "xs-title-acc"
              }`}
            >
              <h3 className="">Address</h3>
            </div> */}
            <div
              // id="addressCollapse"
              //   className={`${
              //     useWindowWidth.windowWidth < 766
              //       ? "  p-d-f-wrap white-box"
              //       : "p-d-f-wrap white-box"
              //   }`}
              className="p-d-f-wrap"
            >
              <div className="a-blue-box">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 mb-2">
                    {newIndex == 1 ? (
                      <div className="d-flex align-item-center">
                        <span className="s-no-index float-left">
                          {newIndex}
                        </span>
                        &nbsp;
                        <span className="address-text">Current Address</span>
                      </div>
                    ) : (
                      <></>
                    )}
                    {newIndex !== 1 && (
                      <>
                        {/* <button
                          type="button"
                          onClick={() => {
                            setDeleteAddressIndex(index);
                            setOpen(true);
                          }}
                          title="Delete this Employer"
                          className="float-right btn btn-sm btn-danger"
                        >
                          <TiDelete size={25} /> Delete
                        </button> */}
                        {/*  */}
                        <CardHeader
                          sx={{ padding: 0 }}
                          title={
                            <b className="s-no-index-number">{newIndex}</b>
                          }
                          action={
                            <DeleteContainedBtn
                              onClick={() => {
                                setDeleteAddressIndex(index);
                                setOpen(true);
                              }}
                            />
                          }
                        />
                        {/*  */}
                      </>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="form-group cus-input-group mr-b30">
                      <GoogleAutoComplete
                        notRequired={true}
                        groupFieldName={{
                          street: `address.${index}.street_address`,
                          city: `address.${index}.city`,
                          state: `address.${index}.state`,
                          zip: `address.${index}.zipcode`,
                          // state_data: data?.records?.States,
                          state_data: props?.getIdentificationData?.states.map(
                            (item) => ({
                              value: item?.ID || index,
                              label:
                                item.StateShortCode + " - " + item.StateName,
                            })
                          ),
                          // state_data: data?.States?.map((item) => ({
                          //   value: item?.ID || index,
                          //   label: item.StateShortCode + " - " + item.StateName,
                          // })),
                        }}
                        register={register(`address.${index}.addressSearch`)}
                        label={
                          index > 0
                            ? "Search Previous Address"
                            : "Search Current Address"
                        }
                        labelClass={"lable-title"}
                        handleOnChange={() => {
                          props?.handleAPIDebounced();
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="form-group cus-input-group mr-b30">
                      {/* <InputField
                        notRequired={false}
                        label="Street Address"
                        register={register(`address.${index}.street_address`)}
                      /> */}
                      <InputField2
                        label="Street Address"
                        source={`address.${index}.street_address`}
                        placeholder="Street Address"
                        labelClass={"lable-title"}
                        additionalInputClass={"cus-control"}
                        handleOnChange={() => {
                          props?.handleAPIDebounced();
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="form-group cus-input-group mr-b30">
                      {/* <InputField
                        notRequired={true}
                        label="Apartment"
                        register={register(`address.${index}.appartment`)}
                      /> */}
                      <InputField2
                        label="Apartment"
                        source={`address.${index}.appartment`}
                        placeholder="Apartment"
                        labelClass={"lable-title"}
                        additionalInputClass={"cus-control"}
                        handleOnChange={() => {
                          props?.handleAPIDebounced();
                        }}
                        notRequired={true}
                      />
                    </div>
                  </div>
                  <div className="w-100"></div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="form-group cus-input-group mr-b30">
                      {/* <InputField
                        label="City"
                        register={register(`address.${index}.city`)}
                      /> */}
                      <InputField2
                        label="City"
                        source={`address.${index}.city`}
                        placeholder="City"
                        labelClass={"lable-title"}
                        additionalInputClass={"cus-control"}
                        handleOnChange={() => {
                          props?.handleAPIDebounced();
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="form-group cus-input-group mr-b30">
                      {/* <SelectField
                        options={data ? data?.records?.States : []}
                        label="State"
                        register={register(`address.${index}.state`)}
                      /> */}
                      <InputSelectField
                        label={`State`}
                        source={`address.${index}.state`}
                        // options={data?.States?.map((item) => ({
                        //   value: item?.ID || index,
                        //   label: item.StateShortCode + " - " + item.StateName,
                        // }))}
                        options={props?.getIdentificationData?.states?.map(
                          (item) => ({
                            value: item?.ID || index,
                            label: item.StateShortCode + " - " + item.StateName,
                          })
                        )}
                        stopAutoFocus={true}
                        labelAsteriskClass={"color-red"}
                        labelClass={"lable-title"}
                        requiredClass={{
                          fontSize: "70%",
                        }}
                        onChangeCallback={() => {
                          props?.handleAPIDebounced();
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="form-group cus-input-group mr-b30">
                      {/* <InputField
                        type={"tel"}
                        maxLength={9}
                        minLength={5}
                        label="Zip Code"
                        register={register(`address.${index}.zipcode`)}
                      /> */}
                      <InputField2
                        label="Zip Code"
                        source={`address.${index}.zipcode`}
                        placeholder="Zip Code"
                        labelClass={"lable-title"}
                        additionalInputClass={"cus-control"}
                        handleOnChange={() => {
                          props?.handleAPIDebounced();
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12 mb-2">
                    <div className="mr-b15">
                      <InputCheckBoxField
                        notRequired={true}
                        disabled={index == 0 ? true : false}
                        checked={
                          index == 0
                            ? true
                            : watch(`address.${index}.is_current_address`)
                        }
                        label="Is this your current address?"
                        // register={register(
                        //   `address.${index}.is_current_address`
                        // )}
                        name={`address.${index}.is_current_address`}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="form-group cus-input-group mr-b30">
                      {/* <InputDateField
                        maxDate={new Date()}
                        type="date"
                        label="When did you move in to this address?"
                        placeholder="Enter Date"
                        register={register(`address.${index}.move_in_date`)}
                      /> */}
                      <InputDateField
                        source={`address.${index}.move_in_date`}
                        label="When did you move in to this address?"
                        labelClass={"lable-title"}
                        onChangeCallback={() => {
                          props?.handleAPIDebounced();
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-4">
                    {watch(`address.${index}.is_current_address`) == false && (
                      <div className="form-group cus-input-group mr-b30">
                        {/* <InputDateField
                          maxDate={new Date()}
                          label="When did you move out to this address?"
                          placeholder="Enter Date"
                          register={register(`address.${index}.move_out_date`)}
                        /> */}
                        <InputDateField
                          source={`address.${index}.move_out_date`}
                          label="When did you move out to this address?"
                          labelClass={"lable-title"}
                          onChangeCallback={() => {
                            props?.handleAPIDebounced();
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <PropertyType
                      register={register(`address.${index}.property_type`)}
                      availableValue={watch(`address.${index}.property_type`)}
                    />
                  </div>
                  {watch(`address.${index}.property_type`) == "1" &&
                    watch(`address.${index}.property_type`) !== "3" && (
                      <>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <div className="form-group cus-input-group mr-b30">
                            {/* <InputCurrencyField
                              label="Current Rent"
                              suffix="$"
                              register={register(
                                `address.${index}.current_rent`
                              )}
                            /> */}
                            <InputCurrencyField
                              label="Current Rent"
                              source={`address.${index}.current_rent`}
                              required={true}
                              labelClass={"lable-title"}
                              requiredClass={{
                                fontSize: "70%",
                              }}
                              handleOnChange={() => {
                                props?.handleAPIDebounced();
                                trigger(`address.${index}.current_rent`);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <div className="form-group cus-input-group mr-b30">
                            {/* <InputField
                              label="Landlord Name"
                              register={register(
                                `address.${index}.landlord_name`
                              )}
                            /> */}
                            <InputField2
                              label="Landlord Name"
                              source={`address.${index}.landlord_name`}
                              placeholder="Landlord Name"
                              labelClass={"lable-title"}
                              additionalInputClass={"cus-control"}
                              handleOnChange={() => {
                                props?.handleAPIDebounced();
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <div className="form-group cus-input-group mr-b30">
                            {/* <InputFieldPhone
                              label={`Landlord Number`}
                              register={register(
                                `address.${index}.landlord_number`
                              )}
                            /> */}
                            <TextInputPhone
                              source={`address.${index}.landlord_number`}
                              label="Landlord Number"
                              isCountryCode={`address.${index}.country_code`}
                              labelClass={"lable-title"}
                              additionalInputClass={"cus-control"}
                              widthClass={"w-100"}
                              requiredClass={{
                                fontSize: "70%",
                              }}
                              onChangeCallback={() => {
                                props?.handleAPIDebounced();
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  {watch(`address.${index}.property_type`) == "2" &&
                    watch(`address.${index}.property_type`) !== "3" && (
                      <>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <div className="form-group cus-input-group mr-b30">
                            {/* <InputCurrencyField
                              label="Mortgage"
                              suffix="$"
                              register={register(
                                `address.${index}.mortgage_rent`
                              )}
                            /> */}
                            <InputCurrencyField
                              label="Mortgage"
                              source={`address.${index}.mortgage_rent`}
                              required={true}
                              labelClass={"lable-title"}
                              requiredClass={{
                                fontSize: "70%",
                              }}
                              handleOnChange={() => {
                                props?.handleAPIDebounced();
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <div className="form-group cus-input-group mr-b30">
                            {/* <InputField
                              label="Mortgage Company"
                              register={register(
                                `address.${index}.mortgage_company`
                              )}
                            /> */}
                            <InputField2
                              label="Mortgage Company"
                              source={`address.${index}.mortgage_company`}
                              placeholder="Mortgage Company"
                              labelClass={"lable-title"}
                              additionalInputClass={"cus-control"}
                              handleOnChange={() => {
                                props?.handleAPIDebounced();
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="form-group cus-input-group mr-b30">
                      {/* <InputTextArea
                        label="Reason for moving"
                        register={register(`address.${index}.reason`)}
                      /> */}
                      <div className="emp-inputs">
                        <InputTextArea
                          register={`address.${index}.reason`}
                          label="Reason for moving"
                          placeholder="Reason for moving"
                          labelClass={"lable-title"}
                          labelAsteriskClass={"color-red"}
                          additionalInputClass={"cus-control"}
                          styleProperty={{ height: "auto" }}
                          messageText={false}
                          requiredClass={{
                            fontSize: "70%",
                          }}
                          handleOnChange={() => {
                            props?.handleAPIDebounced();
                          }}
                        />
                        <em
                          className="d-block"
                          style={{
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "19px",
                            textAlign: "right",
                            color: "#B4B4B4",
                          }}
                        >
                          Max 5000 Characters
                        </em>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <div className="d-flex justify-content-center">
        <small
          style={{
            color: "#2dc3e8",
          }}
        >
          Please include address history covering two most recent years.
        </small>
      </div>
      <div
        // className={`${
        //   useWindowWidth.windowWidth < 766
        //     ? "add-text-btn flex1 text-center ps-2 pt-3"
        //     : "add-text-btn flex1 text-center ps-2 pt-3 "
        // }`}
        className="add-text-btn flex1 text-center ps-2 pt-3"
      >
        <button
          className="mb-4"
          type="button"
          onClick={(event) =>
            append({
              id: 0,
              ID: 0,
              city: "",
              state: "",
              zipCode: "",
              movedDate: "",
              propertyType: "",
              rent: "",
              landLordName: "",
              landLordNumber: "",
              reasonForMoving: "",
              move_in_date: null,
              move_out_date: null,
              is_current_address: false,
            })
          }
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.68652 5.44287V0.442871H7.35319V5.44287H12.3532V7.10954H7.35319V12.1095H5.68652V7.10954H0.686523V5.44287H5.68652Z"
              fill="#2DC3E8"
            />
          </svg>
          &nbsp; Add current or previous address
        </button>
      </div>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Are you sure want to delete address ?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Warning! Once deleted cannot revert back .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            No
          </Button>
          <Button
            onClick={async () => {
              setTimeout(() => {
                remove(deleteAddressIndex);
              }, 200);
              setOpen(false);

              setOpen(false);
              props?.handleAPIDebounced();
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {nextSection && (
        <Button
          onClick={() => {
            navigate("#ssn");
          }}
          variant="contained"
          endIcon={<AiOutlineArrowDown />}
        >
          Next Tab{" "}
        </Button>
      )}
    </AccordionDetails>
  );
};

export default AddressSection;
