import React from "react";
import LogReportsGraph from "./LogReportsGraph";

const LogReportsDashboard = () => {
  return (
    <div>
      <LogReportsGraph />
    </div>
  );
};

export default LogReportsDashboard;
