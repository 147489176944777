// src/components/FilterChips.js

import React, { FC } from 'react';
import { useListContext, useDataProvider } from 'react-admin';
import Chip from '@mui/material/Chip';
import { JSONTree } from 'react-json-tree';
// import { makeStyles } from '@mui/styles';

// const useStyles = makeStyles({
//     chip: {
//         margin: '0 4px 4px 0',
//     },
// });
interface FilterChipsProps {
    excludeKeys?: string[];
}
const FilterChips: FC<FilterChipsProps> = ({ excludeKeys = [] }) => {
    const { filterValues, setFilters } = useListContext();
    // const classes = useStyles();

    const handleDelete = (filterName) => {
        const newFilters = { ...filterValues };
        delete newFilters[filterName];
        setFilters(newFilters, []);
    };
    const getFilterLabel = (filterName: string, filterValue: any) => {
        if (Array.isArray(filterValue)) {
            return `${filterValue.join(', ')}`;
        }
        else if (typeof filterValue === 'object' && filterValue !== null) {
            return `${filterValue.label || JSON.stringify(filterValue)}`;
        }
        return `${filterValue}`;
    };
    const MAX_LABEL_LENGTH = 100; // Define your desired maximum length

    const truncateText = (text, maxLength) => {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
      }
      return text;
    };
    return (
        <div style={{ marginBottom: '1em', width: 'auto', height: 'auto', display: 'flex', flexWrap: 'wrap', gap: '0.5em' }}>
        {Object.keys(filterValues)
          .filter(fname => !excludeKeys?.includes(fname))
          .map(filterName => {
            const fullText = getFilterLabel(filterName, filterValues[filterName]);
            const truncatedText = truncateText(fullText, MAX_LABEL_LENGTH);
            return (
              <Chip
                key={filterName}
                label={
                  <span 
                    style={{ whiteSpace: 'normal', wordBreak: 'break-word', textOverflow: 'ellipsis' }} 
                    title={fullText} // Show full text on hover
                  >
                    {truncatedText}
                  </span>
                }
                onDelete={() => handleDelete(filterName)}
              />
            );
          })}
      </div>
      
    );
};

export default FilterChips;
