import React, { Suspense, useContext, useEffect, useState } from "react";
import ArchiveIcon from "@mui/icons-material/Archive";
import { MdUnarchive } from "react-icons/md";
import {
  ListBase,
  Title,
  ListToolbar,
  Pagination,
  ChipField,
  CustomRoutes,
  Datagrid,
  DateField,
  defaultTheme,
  DeleteButton,
  FunctionField,
  List,
  ListGuesser,
  NumberField,
  Resource,
  SearchInput,
  TextField,
  useDataProvider,
  useGetList,
  useGetOne,
  useListContext,
  useRecordContext,
  useGetIdentity,
  useCreate,
} from "react-admin";
import {
  Box,
  // MuiButton,
  ToggleButton,
  ToggleButtonGroup,
  Button as MuiButton,
} from "@mui/material";
import { NumericFormat } from "react-number-format";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  TextInput,
  required,
  useUpdate,
  useNotify,
  useRefresh,
} from "react-admin";
import { useForm, FormProvider, get } from "react-hook-form";
import FilterListIcon from "@mui/icons-material/FilterList";
import IconButton from "@mui/material/IconButton";
import { TableHead, TableRow, TableCell, Checkbox } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import InfoIcon from "@mui/icons-material/Info";
import { DatagridBody, RecordContextProvider } from "react-admin";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import { useStore } from "react-admin";
import WarningIcon from "@mui/icons-material/Warning";
import { GetsubscriberinboxListDetails } from "../GetsubscriberinboxListDetails";
import Avatar from "@mui/material/Avatar";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Archive from "../Archive";
import Paper from "@mui/material/Paper";
import NextPlanOutlinedIcon from "@mui/icons-material/NextPlanOutlined";
import TransferApp from "../TransferApp";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { useParams, useNavigate } from "react-router-dom";
import AddCoapplicant from "../AddCoapplicant";
import { Card } from "@mui/material";
import AddressRentEdit from "../AddressRentEdit";
import WarningPrelimNotice from "../WarningPrelimNotice";
import { JSONTree } from "react-json-tree";
import remove from "../../../assets/images/remove.svg";
import ArchiveAplicant from "../ArchiveAplicant";
import CancelApp from "../CancelApp";
import LinearProgress from "@mui/material/LinearProgress";
import ShareAppReport from "../ShareAppReport";
import { useSelector } from "react-redux";
import LastSeen from "../LastSeen";
import { useLocation } from "react-router-dom";
import DateComponent from "../DateComponent";
import { DOCUPLOAD_SUB_URL_FRONT } from "../../../config";
import LaunchIcon from "@mui/icons-material/Launch";
import InputField2 from "../../common/InputComponents/InputField2";
export default function BasicPopover() {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
      null
    );
  
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
  
    return (
      <div>
        <IconButton
          color="warning"
          style={{ color: "#F9BB04" }}
          size="small"
          aria-label="info"
          aria-describedby={id}
          onClick={handleClick}
        >
          <InfoIcon style={{ fontSize: "14" }} />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Typography sx={{ p: 2 }}>This is the Rent to Income Ratio.</Typography>
        </Popover>
      </div>
    );
  }

const DatagridTableHeaderCell = ({ child }) => {
    const { sort, setSort } = useListContext();
    const [sortStatus, SetSortStatus] = React.useState(false);
    const sortBy = child?.props?.sortBy || child?.props?.source;
    return (
      <>
        {/* !sort.order?'ASC':sort.order=='ASC'?'DESC':'ASC' */}
        <Box display={"flex"} alignItems={"center"} justifyContent={"start"}>
          <span style={{ padding: "0px 2px" }}>
            {sortBy === sort.field ? (
              sort.order == "ASC" ? (
                <ArrowUpwardIcon style={{ fontSize: 18 }} />
              ) : (
                <ArrowDownwardIcon style={{ fontSize: 18 }} />
              )
            ) : null}
          </span>
          <span
            style={{
              cursor: "pointer",
              fontWeight: "bold",
              color: sortBy === sort.field ? "#000" : "#909090",
            }}
            onClick={() => {
              sortBy && setSort({ field: sortBy, order: "ASC" });
            }}
          >
            {child?.props?.label || child?.props?.source}
          </span>
          {/* <MuiButton size='small'  label={child.props.label || child.props.source} endIcon={} /> */}
          {(child?.props?.label || child?.props?.source) == "RI%" && (
            <BasicPopover />
          )}
        </Box>
      </>
    );
  };
  
export const DatagridHeader = (props) => {
    const { children, isRowSelectable, isRowExpandable } = props;
    return (
      <TableHead
        sx={{
          height: 50,
          backgroundColor: "#FFFFFF",
          border: "0.5px solid #E0E0E0",
        }}
      >
        <TableRow>
          {isRowSelectable() && <TableCell></TableCell>}
          {/* empty cell to account for the select row checkbox in the body */}
          {React.Children.map(children, (child) => {
            if (child)
              return (
                <TableCell sortDirection="asc" key={child?.props?.source}>
                  <DatagridTableHeaderCell child={child} />
                </TableCell>
              );
          })}
          {isRowExpandable && <TableCell></TableCell>}
        </TableRow>
      </TableHead>
    );
  };

  
export const DatagridTable = (props) => {
    const { id } = useParams();
    const { displayedFilters, filterValues, setFilters, hideFilter } =
      useListContext();
    const { data, isLoading, isFetching } = useListContext();
    useEffect(() => {
      if (isFetching || isLoading) window.document.title = "Loading";
      else {
        window.document.title = "Docuverus Dashboard";
      }
    }, [isFetching, isLoading]);
    // get user role from  global store
    const { data: identity } = useGetIdentity();
    const userRole =
      useSelector((state: any) => state?.users?.role) || identity?.userrole;
  
    // logic for button bg and color as per conditions
    const bgStyle = (condition: string) => {
      let style = {};
      switch (condition) {
        case "CompletedConditionallyApproved":
          style = { backgroundColor: "#FFF8E2", color: "#F9BB04" };
          break;
        case "Completed-Unacceptable":
          style = { backgroundColor: "#F8ECE2", color: "#FF4848" };
          break;
        case "Completed-Approved":
          style = { backgroundColor: "#E6FDF0", color: "#46A96F" };
          break;
        case "Invited":
          style = { backgroundColor: "#E9E9E9", color: "#909090" };
          break;
        case "Application In Progress":
          style = { backgroundColor: "#DDF8FF", color: "#1CA2C2" };
          break;
        case "Submitted":
        case "Review Pending":
          style = { backgroundColor: "#B0EFFF", color: "#127790" };
          break;
        // case "Screening":
        case "Completed":
        // case "Completed-Approved":
        case "Verified":
          style = { backgroundColor: "#E6FDF0", color: "#46A96F" };
          break;
        case "Cancelled":
        case "Archived":
        // case "Completed-Unacceptable":
        case "Unverifiable":
          style = { backgroundColor: "#FFEEEE", color: "#FF4848" };
          break;
        case "Completed-Conditionally Approved":
        case "CompletedConditionallyApproved":
        case "Further Documentation Required":
        case "Further Doc Req.":
          style = { backgroundColor: "#FFF8E2", color: "#F9BB04" };
          break;
        default:
          style = {};
      }
      return style;
    };
    const bgStyle1 = (condition: string) => {
      let style = {};
      switch (condition) {
        case "ConditionallyApproved":
        case "Conditionally Approved":
          style = { backgroundColor: "#FFF8E2", color: "#F9BB04" };
          break;
        case "Unacceptable":
          style = { backgroundColor: "#F8ECE2", color: "#FF4848" };
          break;
        case "NoAssessment":
        case "No Assessment":
          style = { backgroundColor: "#fff", color: "#000000" };
          break;
        case "Approved":
          style = { backgroundColor: "#E6FDF0", color: "#46A96F" };
          break;
        default:
          let abc = bgStyle;
          return abc;
      }
      return style;
    };
    const location = useLocation();
  
    const [create] = useCreate();
    const dataProvider = useDataProvider();
  
    const onClickHandler = async (event, record) => {
      // First API call using react-admin's dataProvider
      const applicantData = await dataProvider.getList(
        "get_subscriber_inbox_detials",
        {
          filter: {
            ApplicationNumber: record?.id,
            subscriberid: record?.SubscriberID,
          },
          pagination: { page: 0, perPage: 0 },
          sort: { field: "", order: "DESC" },
        }
      );
      //   updatenewtagsection API call
      if (
        (applicantData && applicantData?.data[0]?.NewTagID) ||
        (applicantData && applicantData?.data[0]?.NewTagIncome) ||
        (applicantData && applicantData?.data[0]?.NewTagBanking) ||
        (applicantData && applicantData?.data[0]?.NewTagOther)
      ) {
        if (record?.id && applicantData) {
          const formData = {
            ApplicationNumber: record?.id,
            ApplicantID: applicantData ? applicantData?.data[0]?.id : null,
            Section: 5,
          };
          create(
            "updatenewtagsection",
            { data: formData }
            // {
            //   onSuccess: (data) => {
            //     notify(`Invitation has been send`, { type: "success", anchorOrigin: { vertical: 'top', horizontal: 'right' }});
            //     refresh();
            //   },
            //   onError: (e: any) =>
            //     notify(`Operation fail:${e.message}`, { type: "error", anchorOrigin: { vertical: 'top', horizontal: 'right' }}),
            // }
          );
        }
      }
      //  updatenewtagapp  API call
      if (record?.NewTag && record?.id) {
        create(
          "updatenewtagapp",
          { data: { ApplicationNumber: record?.id } }
          // {
          //   onSuccess: (data) => {
          //     notify(`Invitation has been send`, { type: "success", anchorOrigin: { vertical: 'top', horizontal: 'right' }});
          //     refresh();
          //   },
          //   onError: (e: any) =>
          //     notify(`Operation fail:${e.message}`, { type: "error", anchorOrigin: { vertical: 'top', horizontal: 'right' }}),
          // }
        );
      }
    };
    const notify = useNotify();
    const getDocUploadStatus = (status) => {
      if (status === "CompletedConditionallyApproved") {
        return "Further doc req.";
      } else if (status === "Application In Progress") {
        return "In Progress";
      } else if (status === "Further Documentation Required") {
        return "Further doc req.";
      }
      return status;
    };
    const getAtsStatus = (status) => {
      if (status === "CompletedConditionallyApproved") {
        return "Completed Conditionally Approved";
      } else if (status === "Application In Progress") {
        return "In Progress";
      } else if (status === "Further Documentation Required") {
        return "Further doc req.";
      }
      return status;
    };
    const [isLoadingScreeningReport, setIsLoadingReport] = useState(false);
  
    const onClickScreeningReport = async (event, record) => {
      create(
        "getscreeningreport",
        {
          data: {
            applicantID: record?.ApplicantID,
            subscriberId: record?.SubscriberID,
          },
        },
        {
          onSuccess: (data) => {
            notify(`Screening report opened successfully.`, {
              type: "success",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
          },
          onError: (e: any) =>
            notify(`Operation fail:${e.message}`, {
              type: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            }),
        }
      );
    };
    return (
      <>
        {(isFetching || isLoading) && <LinearProgress />}
        <MyDatagrid
          isRowExpandable={() => true}
          isRowSelectable={() => false}
          header={<DatagridHeader isRowExpandable={true} />}
          expand={<ExpandTableComponent />}
          sx={{
            "& .RaDatagrid-rowEven": { backgroundColor: "#F9F9F9" },
            "& .RaDatagrid-expandIcon": { color: "#fff" },
            "& .RaDatagrid-expandIconCell": { backgroundColor: "primary.main" },
            "& .RaDatagrid-headerCell": {
              fontWeight: "bold",
              fontSize: 12,
            },
            "& .RaDatagrid-table": {
              fontSize: 12,
            },
            "& .MuiToggleButton-root.Mui-selected": {
              fontWeight: 700,
            },
          }}
        >
          {/* <TextField source="ROWNUM" label="Sr. No" /> */}
          <FunctionField
            source="App Id"
            label="App Id"
            sortByOrder="DESC"
            sortBy="id"
            render={(record) => {
              return (
                <>
                  <Box
                    display={"flex"}
                    justifyContent={"start"}
                    flexDirection={"column"}
                  >
                    {userRole == "Docuverus Super Admin" 
                    // &&
                    // !record.Archived &&
                    // record?.ApplicationStatus !== "Cancelled" &&
                    // !filterValues.archiveCancelApp 
                    ? (
                      record.IsRevamp ? (
                        <Link
                          style={{ textDecoration: "none" }}
                          to={`/Ats/Subscriber/Inbox/${
                            record.SubscriberID || id || identity?.subscriberid
                          }/edit/${record.id}/${
                            record?.ApplicationType == "Doc Upload"
                              ? 2
                              : record?.ApplicationType == "Application"
                              ? 1
                              : filterValues?.app_invitation_type
                          }${
                            record
                              ? `?data=${(
                                  (
                                    encodeURIComponent(
                                      JSON.stringify({
                                        ...record,
                                        from: location,
                                      })
                                    )
                                  )
                                )}`
                              : ""
                          }`}
                          state={{ ...record }}
                          onClick={(event) => onClickHandler(event, record)}
                        >
                          <Typography fontWeight={700} color="primary">
                            {record.id}
                          </Typography>
                        </Link>
                      ) : (
                        <Link
                          style={{ textDecoration: "none" }}
                          to={"#"}
                          onClick={async (event) => {
                            event.preventDefault();
                            // const applicantId:any=applicantData;
  
                            const applicantData = await dataProvider.getList(
                              "get_subscriber_inbox_detials",
                              {
                                filter: {
                                  ApplicationNumber: record?.id,
                                  subscriberid: record?.SubscriberID,
                                },
                                pagination: { page: 0, perPage: 0 },
                                sort: { field: "", order: "DESC" },
                              }
                            );
                            create(
                              "generateOTP_docupload",
                              {
                                data: {
                                  ApplicantID: applicantData
                                    ? applicantData?.data[0]?.id
                                    : null,
                                  loginuser: identity?.userrole,
                                  loginuserid: identity?.userid,
                                },
                              },
                              {
                                onSuccess: (data) => {
                                  // console.log(data)
                                  // notify(`OTP has been send`, {
                                  //   type: "success",
                                  //   anchorOrigin: {
                                  //     vertical: "top",
                                  //     horizontal: "right",
                                  //   },
                                  // });
                                  window.open(
                                    `${DOCUPLOAD_SUB_URL_FRONT}${applicantData?.data[0]?.Trnid}?token=${data?.Content}`,
                                    "_blank"
                                  );
                                },
                                onError: (e: any) =>
                                  notify(`Operation fail:${e.message}`, {
                                    type: "error",
                                    anchorOrigin: {
                                      vertical: "top",
                                      horizontal: "right",
                                    },
                                  }),
                              }
                            );
                          }}
                        >
                          {" "}
                          <Typography color="secondry">{record.id}</Typography>
                        </Link>
                      )
                    ) : (filterValues?.app_invitation_type == 1 &&
                        record?.ApplicationStatus !== "Cancelled" &&
                        !record.Archived) ||
                      (filterValues?.app_invitation_type != 1 &&
                        record?.ApplicationStatus !== "Cancelled" &&
                        !record.Archived &&
                        record?.ApplicationStatus !== "Review Pending" &&
                        !filterValues.archiveCancelApp) ? (
                      record.IsRevamp ? (
                        <Link
                          style={{ textDecoration: "none" }}
                          to={`/Ats/Subscriber/Inbox/${
                            record.SubscriberID || id || identity?.subscriberid
                          }/edit/${record.id}/${
                            record?.ApplicationType == "Doc Upload"
                              ? 2
                              : record?.ApplicationType == "Application"
                              ? 1
                              : filterValues?.app_invitation_type
                          }${
                            record
                              ? `?data=${encodeURIComponent(
                                  (
                                    JSON.stringify({ ...record, from: location })
                                  )
                                )}`
                              : ""
                          }`}
                          state={{ ...record }}
                          onClick={(event) => onClickHandler(event, record)}
                        >
                          <Typography fontWeight={700} color="primary">
                            {record.id}
                          </Typography>
                        </Link>
                      ) : (
                        <Link
                          style={{ textDecoration: "none" }}
                          to={"#"}
                          onClick={async (event) => {
                            event.preventDefault();
                            // const applicantId:any=applicantData;
  
                            const applicantData = await dataProvider.getList(
                              "get_subscriber_inbox_detials",
                              {
                                filter: {
                                  ApplicationNumber: record?.id,
                                  subscriberid: record?.SubscriberID,
                                },
                                pagination: { page: 0, perPage: 0 },
                                sort: { field: "", order: "DESC" },
                              }
                            );
                            create(
                              "generateOTP_docupload",
                              {
                                data: {
                                  ApplicantID: applicantData
                                    ? applicantData?.data[0]?.id
                                    : null,
                                  loginuser: identity?.userrole,
                                  loginuserid: identity?.userid,
                                },
                              },
                              {
                                onSuccess: (data) => {
                                  // console.log(data)
                                  // notify(`OTP has been send`, {
                                  //   type: "success",
                                  //   anchorOrigin: {
                                  //     vertical: "top",
                                  //     horizontal: "right",
                                  //   },
                                  // });
                                  window.open(
                                    `${DOCUPLOAD_SUB_URL_FRONT}${applicantData?.data[0]?.Trnid}?token=${data?.Content}`,
                                    "_blank"
                                  );
                                },
                                onError: (e: any) =>
                                  notify(`Operation fail:${e.message}`, {
                                    type: "error",
                                    anchorOrigin: {
                                      vertical: "top",
                                      horizontal: "right",
                                    },
                                  }),
                              }
                            );
                          }}
                        >
                          {" "}
                          <Typography color="secondry">{record.id}</Typography>
                        </Link>
                      )
                    ) : (
                      <Typography fontWeight={700} color="#303030">
                        {record.id}
                      </Typography>
                    )}
                    <span style={{ fontSize: 10 }} className="d-block">
                      {record.ApplicantCount}{" "}
                      {record.ApplicantCount > 1 ? "Applicants" : "Applicant"}
                    </span>
                  </Box>
                </>
              );
            }}
          />
          {/* <DateField source="AppDate" label="Date" locales="en-US" /> */}
  
          <FunctionField
            label="Date"
            render={(record) => <DateComponent date={record?.AppDate} />}
            // record={record}
            sortBy="AppDate"
          />
          <FunctionField
            label="Agent"
            render={(record) => {
              const applicants = record?.Agent?.split(",");
              const maxLength = 20; // Maximum length of the name before truncation
              if (applicants && applicants.length > 0) {
                return applicants.map((item, index) => (
                  <span key={index} title={item}>
                    {item.length > maxLength
                      ? `${item.substring(0, maxLength)}...`
                      : item}
                    {index < applicants.length - 1 && ", "}
                  </span>
                ));
              } else {
                return ""; // Handle the case when there are no applicants
              }
            }}
            sortBy="Agent"
  
            // record={record}
            // sortBy="AppDate"
          />
  
          {/* <FunctionField label="applicant" render={record => {
                      return record.ApplicantList?.split(",").map((item, index) => <span className='d-block'>{index + 1}. {item}</span>);
                  }} /> */}
  
          <FunctionField
            label="applicant"
            render={(record) => {
              const applicants = record.ApplicantList?.split(",");
              const maxLength = 20; // Maximum length of the name before truncation
              if (applicants && applicants.length > 0) {
                return applicants.map((item, index) => (
                  <span key={index} title={item}>
                    {item.length > maxLength
                      ? `${item.substring(0, maxLength)}...`
                      : item}
                    {index < applicants.length - 1 && ", "}
                  </span>
                ));
              } else {
                return ""; // Handle the case when there are no applicants
              }
            }}
          />
          <FunctionField
            label="Property/Office"
            render={(record) => {
              const applicants = record?.Property?.split(",");
              const maxLength = 20; // Maximum length of the name before truncation
              if (applicants && applicants.length > 0) {
                return applicants.map((item, index) => (
                  <span key={index} title={item}>
                    {item.length > maxLength
                      ? `${item.substring(0, maxLength)}...`
                      : item}
                    {index < applicants.length - 1 && ", "}
                  </span>
                ));
              } else {
                return ""; // Handle the case when there are no applicants
              }
            }}
            sortBy="Property"
            // record={record}
            // sortBy="AppDate"
          />
  
          {!props?.removeSubsriberColumn &&<TextField source="SubscriberName" label="Subscriber Name" />}
          {/* <TextField source="Property" label="Property/Office" /> */}
          {props.typeField == true && (
            <TextField source="ApplicationType" label="Type" />
          )}
          {filterValues?.app_invitation_type != 2 && !props.addressFieldHide &&
            filterValues?.app_invitation_type != 3 && (
              <FunctionField
                source="AppliedForAddress"
                label="App Address"
                render={(record) => {
                  return (
                    <>
                      <TextField source="AppliedForAddress" label="App Address" />
                      {record?.Subscribertype == "Real Estate" &&
                      record?.ApplicationStatus !== "Invited" ? (
                        <IconButton size="small">
                          <AddressRentEdit record={record} />
                        </IconButton>
                      ) : (
                        ""
                      )}
                    </>
                  );
                }}
              />
            )}
          {filterValues.inbox_type=="DA_REVIEW_QUEUE" && (
            <FunctionField
              source="TimeInQueue"
              label="Queued"
              render={(record) => {
                return <LastSeen record={record} />;
              }}
            />
          )}
          {filterValues?.app_invitation_type != 2 && !props.rentFieldHide &&
            filterValues?.app_invitation_type != 3 && (
              <FunctionField
                source="Rent"
                label="Rent"
                sortBy="Rent"
                render={(record) => {
                  return !record.AppliedForRent ? (
                    "NA"
                  ) : (
                    <>
                      <NumericFormat
                        thousandSeparator=","
                        displayType="text"
                        thousandsGroupStyle="thousand"
                        decimalScale={2}
                        prefix="$"
                        fixedDecimalScale
                        value={record.AppliedForRent}
                      />
                      {record?.Subscribertype == "Real Estate" &&
                      record?.ApplicationStatus !== "Invited" ? (
                        <IconButton size="small">
                          <AddressRentEdit record={record} />
                        </IconButton>
                      ) : (
                        ""
                      )}
                    </>
                  );
                }}
              />
            )}
          <FunctionField
            label={"Status"}
            source="ApplicationStatus"
            render={(record) => {
              return (
                <MuiButton
                  sx={{ width: "100%" }}
                  variant="contained"
                  disableElevation={true}
                  style={{
                    ...bgStyle1(
                      record.Archived == true
                        ? "Archived"
                        : record?.ScreeningDetails
                    ),
                    ...bgStyle(
                      record.Archived == true
                        ? "Archived"
                        : record?.ApplicationStatus
                    ),
                    textTransform: "capitalize",
                    cursor: "text",
                  }}
                >
                  {record.Archived == true
                    ? "Archived - " +
                      (record.ApplicationType === "Doc Upload"
                        ? getDocUploadStatus(record?.ApplicationStatus)
                        : getAtsStatus(record?.ApplicationStatus))
                    : record.ApplicationType === "Doc Upload"
                    ? getDocUploadStatus(record?.ApplicationStatus)
                    : getAtsStatus(record?.ApplicationStatus)}
                </MuiButton>
              );
            }}
          />
  
          {(filterValues?.inbox_type == 'ATS_LAST_30_DAYS' || filterValues?.inbox_type == 'ATS_SCREENING_QUEUE') && (
              <FunctionField
                source={"Screening / Report"}
                label="Screening / Report"
                render={(record) => {
                  return (
                    <>
                      {record.Score > 0 && (
                        <>
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"center"}
                          >
                            <Avatar
                              sx={{
                                bgcolor:
                                  record.ScreeningDetails ===
                                    "ConditionallyApproved" ||
                                  record.ScreeningDetails ===
                                    "Conditionally Approved"
                                    ? "#FFF8E2"
                                    : record.ScreeningDetails === "Unacceptable"
                                    ? "#F8ECE2"
                                    : record.ScreeningDetails ===
                                        "NoAssessment" ||
                                      record.ScreeningDetails === "No Assessment"
                                    ? "#fff"
                                    : record.ScreeningDetails === ""
                                    ? "inherit"
                                    : "#E6FDF0",
                                color:
                                  record.ScreeningDetails ===
                                    "ConditionallyApproved" ||
                                  record.ScreeningDetails ===
                                    "Conditionally Approved"
                                    ? "#F9BB04"
                                    : record.ScreeningDetails === "Unacceptable"
                                    ? "#FF4848"
                                    : record.ScreeningDetails == "NoAssessment" ||
                                      record.ScreeningDetails === "No Assessment"
                                    ? "#000000"
                                    : record.ScreeningDetails === ""
                                    ? "inherit"
                                    : "#46A96F",
                                border:
                                  record.ScreeningDetails ===
                                    "ConditionallyApproved" ||
                                  record.ScreeningDetails ===
                                    "Conditionally Approved"
                                    ? "1px solid #F9BB04"
                                    : record.ScreeningDetails === "Unacceptable"
                                    ? "1px solid #FF4848"
                                    : record.ScreeningDetails ===
                                        "NoAssessment" ||
                                      record.ScreeningDetails === "No Assessment"
                                    ? "1px solid #000000"
                                    : record.ScreeningDetails === ""
                                    ? "inherit"
                                    : "1px solid #46A96F",
                              }}
                            >
                              {record?.Score}
                            </Avatar>
                            {
                              <Box display={"flex"} justifyContent={"center"}>
                                <MuiButton
                                  disabled={isLoadingScreeningReport}
                                  size="small"
                                  // label="View"
                                  variant="text"
                                  onClick={(event) =>
                                    onClickScreeningReport(event, record)
                                  }
                                >
                                  View
                                </MuiButton>
                                {record.SecondaryApp && !record.EmailStatus && (
                                  <IconButton sx={{ p: "4px" }} size="small">
                                    {/* <WarningIcon fontSize='small' /> */}
                                    <WarningPrelimNotice
                                      showWithScoreAsIcon={true}
                                    />
                                  </IconButton>
                                )}
                              </Box>
                            }
                          </Box>
                        </>
                      )}
                    </>
                  );
                }}
              />
            )}
  
          {filterValues?.app_invitation_type != 2 && !props.riFieldHide &&
            filterValues?.app_invitation_type != 3 && (
              <FunctionField
                source="RI%"
                label="RI%"
                render={(record) => {
                  return (
                    <NumericFormat
                      thousandSeparator=","
                      displayType="text"
                      thousandsGroupStyle="thousand"
                      decimalScale={2}
                      suffix="%"
                      value={record.RI}
                    />
                  );
                }}
              />
            )}
        </MyDatagrid>
      </>
    );
  };

  const MyDatagridBody = (props) => (
    <DatagridBody {...props} row={<MyDatagridRow {...props} />} />
  );
  export const MyDatagrid = (props) => (
    <Datagrid {...props} body={<MyDatagridBody {...props} />} />
  );
  
  const MyDatagridRow = (props) => {
    const {
      record,
      id,
      onToggleItem,
      children,
      selected,
      selectable,
      isRowExpandable,
      isRowSelectable,
      expand,
    } = props;
    const [open, setOpen] = useStore("expandedTable", 0);
    const [tempOpen, setTempOpen] = React.useState(false);
  
    const timeFormatHandler = (timeData: string) => {
      // get value from fuction call
      const timestamp: any = new Date(timeData);
      // get current time stamp
      const now: any = new Date();
      // calculating a difference
      const diff = now - timestamp;
      // converting a value in to a seconds and seconds to minutes
      const seconds = Math.floor(diff / 1000);
      const minutes = Math.floor(seconds / 60);
      // assign a empty style
      let style = {};
      // code conditional logic
      // if (minutes < 30) {
      // //   formattedTime = "Normal color";
      if (minutes >= 30 && minutes < 150) {
        style = { backgroundColor: "#FFF8E2 !important" };
      } else if (minutes >= 150 && minutes < 360) {
        style = { backgroundColor: "#ffd89d !important" };
      } else if (minutes >= 360) {
        style = { backgroundColor: "#FFEEEE !important" };
      }
      // return a final value
      return style;
    };
  
    const refresh = useRefresh();
    // const notify = useNotify();
    const [create] = useCreate();
  
    const onClickHandler = (event, record) => {
      create(
        "updatenewtagapp",
        { data: { ApplicationNumber: record?.id } },
        {
          onSuccess: (data) => {
            //   notify(`Invitation has been send`, { type: "success", anchorOrigin: { vertical: 'top', horizontal: 'right' }});
            refresh();
          },
          // onError: (e: any) => {
          //   notify(`Operation fail:${e.message}`, { type: "error", anchorOrigin: { vertical: 'top', horizontal: 'right' }})
          // },
        }
      );
    };
  
    return (
      <RecordContextProvider value={record}>
        <TableRow
          className={`${props.className} ${
            record?.NewTag ? "position-relative" : ""
          }`}
          sx={record?.TimeInQueue ? timeFormatHandler(record?.TimeInQueue) : ""}
        >
          {/* first column: selection checkbox */}
          {isRowSelectable() && (
            <TableCell padding="none">
              <Checkbox
                disabled={!selectable}
                checked={selected}
                onClick={(event) => onToggleItem(id, event)}
              />
            </TableCell>
          )}
          {/* data columns based on children */}
          {React.Children.map(children, (field) => {
            if (field)
              return (
                <TableCell
                  data-label={field?.props?.label || field?.props?.source}
                  key={`${id}-${field?.props?.source || field?.props?.label}`}
                >
                  {field}
                  {field?.props?.source === "App Id" && record?.NewTag ? (
                    <div className="badge-cell position-absolute top-0 start-0">
                      <em className="m-0">New</em>
                    </div>
                  ) : (
                    <></>
                  )}
                </TableCell>
              );
          })}
          {isRowExpandable() && (
            <TableCell padding="none">
              <IconButton
                aria-label="expand row"
                size="small"
                className="arrow_icon"
                onClick={(event) => {
                  setTempOpen(!tempOpen);
                  setOpen(open == id ? (open != 0 ? 0 : id) : id);
                  if (record?.NewTag && !tempOpen) {
                    onClickHandler(event, record);
                  }
                }}
              >
                {open == id ? (
                  <KeyboardArrowUpIcon
                    sx={{ color: open == id ? "primary.main" : "#fff" }}
                    className="arrow_up_icon"
                  />
                ) : (
                  <KeyboardArrowDownIcon className="arrow_down_icon" />
                )}
              </IconButton>
            </TableCell>
          )}
        </TableRow>
        {isRowExpandable() && (
          <TableRow>
            <TableCell padding="none" colSpan={12}>
              <Collapse in={open == id} timeout="auto" unmountOnExit>
                {expand}
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </RecordContextProvider>
    );
  };
  
  const ExpandTableComponent = (props) => {
    const record = useRecordContext();
    const { id } = useParams();
    const { filterValues } = useListContext();
  
    // get user role from  global store
    const { data: identity } = useGetIdentity();
    const userRole =
      useSelector((state: any) => state?.users?.role) || identity?.userrole;
  
    // get subscriber inbox details call
    const { data: applicantData, isLoading } = useGetList(
      "get_subscriber_inbox_detials",
      {
        filter: {
          ApplicationNumber: record?.id,
          subscriberid: record.SubscriberID,
        },
      }
    );
  
    // handled a filter data for remove applicant
    const filterData =
      applicantData &&
      applicantData?.length > 0 &&
      applicantData?.filter(
        (item) =>
          (item?.ApplicantType !== "Applicant" &&
            item?.ApplicantStatus == "Invited") ||
          (item?.ApplicantType !== "Applicant")
      );
    const location = useLocation();
    const [create] = useCreate();
  
    const onClickHandler = (event, record) => {
      if (
        (applicantData && applicantData[0]?.NewTagID) ||
        (applicantData && applicantData[0]?.NewTagIncome) ||
        (applicantData && applicantData[0]?.NewTagBanking) ||
        (applicantData && applicantData[0]?.NewTagOther)
      ) {
        const formData = {
          ApplicationNumber: record?.id,
          ApplicantID: applicantData ? applicantData[0]?.id : null,
          Section: 5,
        };
        create(
          "updatenewtagsection",
          { data: formData }
          // {
          //   onSuccess: (data) => {
          //     notify(`Invitation has been send`, { type: "success", anchorOrigin: { vertical: 'top', horizontal: 'right' }});
          //     refresh();
          //   },
          //   onError: (e: any) =>
          //     notify(`Operation fail:${e.message}`, { type: "error", anchorOrigin: { vertical: 'top', horizontal: 'right' }}),
          // }
        );
      }
    };
    const refresh = useRefresh();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const [update] = useUpdate();
    const handleUnarchive = async (value) => {
      update(
        "unarchive",
        {
          id: record?.id,
          data: {
            SubscriberID: record?.SubscriberID,
            Reason: value?.Reason,
            IP: "192.168.11.1",
          },
        },
        {
          onError: (error) => {
            const message: any = error;
            notify(`${message.message}`, {
              type: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
          },
  
          onSuccess: (data) => {
            notify(`Your application has been Unarchive`, {
              type: "success",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
            refresh();
            handleCloseUnarchieve();
          },
        }
      );
      // .then(_ => {
      //     refresh();
      // }).catch(err => {
      //
      //     // refresh();
      // }).finally(() => handleDialogClose())
    };
    const validationSchema = Yup.lazy((value) =>
      Yup.object().shape({
        Reason: Yup.string()
          .trim("Reason is required!")
          .required("Reason is required!")
          .max(5000, "maximum 5000 characters is required"),
      })
    );
    const form = useForm({
      defaultValues: {
        Reason: "",
        SubscriberID: record.SubscriberID,
      },
      resolver: yupResolver(validationSchema),
    });
    const [openUnarchieve, setOpenUnarchieve] = React.useState(false);
    const handleOpenunarchive = () => setOpenUnarchieve(true);
    const handleCloseUnarchieve = () => setOpenUnarchieve(false);
    return (
      <>
        <Dialog
          fullWidth
          open={openUnarchieve}
          onClose={handleCloseUnarchieve}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle color="error" id="alert-dialog-title">
            <InfoIcon /> Are you sure?
          </DialogTitle>
          <DialogContent>
            <p>
              Are you sure you want to Unarchive the applications? <br />
            </p>
  
            <FormProvider {...form}>
              <form onSubmit={form.handleSubmit(handleUnarchive)}>
                <div className="row">
                  <div className="col-12 mr-b10 mt-3">
                    <InputField2
                      label="Reason"
                      source={`Reason`}
                      placeholder="Enter Reason for Archiving Application"
                      labelClass={"lable-title"}
                      additionalInputClass={"cus-control"}
                    />
                  </div>
                </div>
  
                <Box display={"flex"} justifyContent={"end"}>
                  <MuiButton
                    startIcon={<MdUnarchive />}
                    sx={{ mx: 1 }}
                    variant="contained"
                    type="submit"
                  >
                    <Typography>Unarchive</Typography>
                  </MuiButton>
                  <MuiButton
                    sx={{ mx: 1 }}
                    onClick={() => {
                      handleCloseUnarchieve();
                    }}
                    variant="outlined"
                    color="secondary"
                    type="button"
                  >
                    <Typography>Cancel</Typography>
                  </MuiButton>
                </Box>
              </form>
            </FormProvider>
          </DialogContent>
        </Dialog>
        <Paper elevation={0}>
          <Box sx={{ width: "100%", p: 2 }}>
            <GetsubscriberinboxListDetails
              appType={
                record?.ApplicationType == "Doc Upload"
                  ? 2
                  : record?.ApplicationType == "Application"
                  ? 1
                  : filterValues?.app_invitation_type
              }
              id={record.id}
              subsciberIdFromRecord={record.SubscriberID}
              secondaryApp={record.SecondaryApp}
              appInvitationType={filterValues?.app_invitation_type}
              applicationType={record?.ApplicationType}
              archiveCancelApp={
                !filterValues.archiveCancelApp &&
                record?.ApplicationStatus !== "Cancelled" &&
                !record.Archived &&
                record?.ApplicationStatus !== "Archived"
              }
            />
            <Box
              sx={{ p: 1, background: "rgba(44, 192, 228, 0.08)" }}
              display={"flex"}
              justifyContent={"space-between"}
            >
              {record?.ApplicationStatus != "Cancelled" &&
                (filterValues.archiveCancelApp || record.Archived) && (
                  <Box sx={{ py: 1 }} display={"flex"}>
                    {/* Add archive inbox menu here */}
                    <MuiButton
                      variant="text"
                      startIcon={<MdUnarchive fontSize="small" />}
                      sx={{ textTransform: "capitalize" }}
                      // onClick={handleUnarchive}
                      onClick={() => {
                        handleOpenunarchive();
                      }}
                    >
                      Unarchive
                    </MuiButton>
                  </Box>
                )}
              {!filterValues.archiveCancelApp &&
                record?.ApplicationStatus !== "Cancelled" &&
                !record.Archived && (
                  <Box sx={{ py: 1 }} display={"flex"}>
                    {/* {filterValues?.app_invitation_type != 3 &&
                    filterValues?.app_invitation_type != 2 &&
                    record?.ApplicationStatus !== "Cancelled" &&
                    !record.Archived && <AddCoapplicant record={record} />} */}
                    {/* new condition for add and remove  ATS -> "ADD" & "Remove" Buttons Should not display once application gets submitted*/}
                    {/* (record?.ApplicationStatus == "Invited" ||
                        record?.ApplicationStatus ==
                          "Application In Progress") && */}
                          
                    {filterValues?.app_invitation_type == 1 &&
                       (
                        <AddCoapplicant record={record} />
                      )}
                      {/* (record?.ApplicationStatus == "Invited" ||
                            record?.ApplicationStatus ==
                              "Application In Progress") && */}
                    {
                      filterData && filterData?.length > 0
                        ? filterValues?.app_invitation_type == 1 &&
                           (
                            <div className="tooltip-wrapper">
                              <MuiButton
                                startIcon={<img src={remove} alt="" />}
                                variant="text"
                                sx={{ textTransform: "capitalize" }}
                              >
                                Remove Applicant
                              </MuiButton>
  
                              <div className="tooltip">
                                <ul>
                                  {filterData?.map((application) => (
                                    <ArchiveAplicant
                                      record={application}
                                      buttonRemove
                                      subsciberId={record.SubscriberID}
                                    />
                                  ))}
                                </ul>
                              </div>
                            </div>
                          )
                        : ""
                      // <MuiButton
                      //   startIcon={
                      //     <svg
                      //       width="14"
                      //       height="16"
                      //       viewBox="0 0 14 16"
                      //       fill="none"
                      //       xmlns="http://www.w3.org/2000/svg"
                      //     >
                      //       <path
                      //         d="M7.33329 9.50135V10.8947C6.72991 10.6814 6.08414 10.6159 5.45023 10.7039C4.81631 10.7918 4.21276 11.0306 3.69025 11.4002C3.16774 11.7697 2.74152 12.2592 2.4474 12.8276C2.15328 13.396 1.99984 14.0267 1.99996 14.6667L0.666626 14.666C0.666419 13.8519 0.852573 13.0486 1.21083 12.3176C1.56908 11.5866 2.08992 10.9473 2.73344 10.4487C3.37696 9.95011 4.12608 9.60544 4.9234 9.44112C5.72071 9.27679 6.54507 9.29717 7.33329 9.50069V9.50135ZM5.99996 8.66669C3.78996 8.66669 1.99996 6.87669 1.99996 4.66669C1.99996 2.45669 3.78996 0.666687 5.99996 0.666687C8.20996 0.666687 9.99996 2.45669 9.99996 4.66669C9.99996 6.87669 8.20996 8.66669 5.99996 8.66669ZM5.99996 7.33335C7.47329 7.33335 8.66663 6.14002 8.66663 4.66669C8.66663 3.19335 7.47329 2.00002 5.99996 2.00002C4.52663 2.00002 3.33329 3.19335 3.33329 4.66669C3.33329 6.14002 4.52663 7.33335 5.99996 7.33335ZM10.6666 11.724L12.0806 10.3094L13.024 11.2527L11.6093 12.6667L13.024 14.0807L12.0806 15.024L10.6666 13.6094L9.25263 15.024L8.30929 14.0807L9.72396 12.6667L8.30929 11.2527L9.25263 10.3094L10.6666 11.724Z"
                      //         fill="rgba(0, 0, 0, 0.26)"
                      //       />
                      //     </svg>
                      //   }
                      //   variant="text"
                      //   style={{ cursor: "not-allowed" }}
                      //   disabled
                      //   sx={{ textTransform: "capitalize" }}
                      // >
                      //   Remove Applicant
                      // </MuiButton>
                    }
                    {record?.ApplicationStatus !== "Cancelled" &&
                      !record.Archived && <Archive record={record} />}
  
                    {(record?.ApplicationStatus == "Invited" ||
                      record?.ApplicationStatus == "Application In Progress" ||
                      record?.ApplicationStatus == "Submitted" ||
                      record?.ApplicationStatus == "Screening") &&
                      record?.ApplicationStatus !== "Cancelled" &&
                      !record.Archived && <CancelApp record={record} />}
                    {record.Subscribertype == "Real Estate" &&
                      record?.ApplicationStatus !== "Cancelled" &&
                      !record.Archived && <TransferApp record={record} />}
  
                    {record?.SecondaryApp &&
                      record?.ApplicationStatus !== "Cancelled" &&
                      !record.Archived &&
                      filterValues?.app_invitation_type == 1 && (
                        <WarningPrelimNotice
                          resendPreliminaryNotice={
                            record?.EmailStatus ? true : false
                          }
                          vewDetailsMounting={false}
                        />
                      )}
  
                    {/* 
                 
  
                  {/* hide a  Share App/Report for now */}
                    {/* {(filterValues?.app_invitation_type != 2 && filterValues?.app_invitation_type != 3) && <MuiButton startIcon={<NextPlanOutlinedIcon />} variant='text'>Share App/Report</MuiButton>} */}
                    {filterValues?.app_invitation_type == 1 &&
                      record?.ApplicationStatus !== "Cancelled" &&
                      !record.Archived &&
                      record?.ApplicationStatus !== "Application In Progress" &&
                      record?.ApplicationStatus !== "Submitted" &&
                      record?.ApplicationStatus !== "Review Pending" &&
                      record?.ApplicationStatus !== "Cancelled" &&
                      record?.ApplicationStatus !== "Archived" && (
                        <ShareAppReport record={record} />
                      )}
                    {filterValues?.app_invitation_type == 2 &&
                      !record.IsRevamp && (
                        <Link
                          style={{ textDecoration: "none" }}
                          to={`/Ats/Subscriber/Inbox/${
                            record.SubscriberID || id || identity?.subscriberid
                          }/edit/${record.id}/${
                            record?.ApplicationType == "Doc Upload"
                              ? 2
                              : record?.ApplicationType == "Application"
                              ? 1
                              : filterValues?.app_invitation_type
                          }${
                            record
                              ? `?data=${encodeURIComponent(
                                  (
                                    JSON.stringify({ ...record, from: location })
                                  )
                                )}`
                              : ""
                          }`}
                          state={{ ...record }}
                          onClick={(event) => onClickHandler(event, record)}
                        >
                          <MuiButton
                            variant="text"
                            startIcon={<LaunchIcon fontSize="small" />}
                            sx={{ textTransform: "capitalize" }}
                          >
                            New Docupload
                          </MuiButton>
                        </Link>
                      )}
                  </Box>
                )}
              {!filterValues.archiveCancelApp &&
                record?.ApplicationStatus !== "Cancelled" &&
                !record.Archived &&
                (userRole == "Docuverus Super Admin" ? (
                  record?.IsRevamp ? (
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`/Ats/Subscriber/Inbox/${
                        record.SubscriberID || id || identity?.subscriberid
                      }/edit/${record.id}/${
                        record?.ApplicationType == "Doc Upload"
                          ? 2
                          : record?.ApplicationType == "Application"
                          ? 1
                          : filterValues?.app_invitation_type
                      }${
                        record
                          ? `?data=${encodeURIComponent(
                              (JSON.stringify({ ...record, from: location }))
                            )}`
                          : ""
                      }`}
                      state={{ ...record }}
                      onClick={(event) => onClickHandler(event, record)}
                    >
                      <button type="button" className="blue-btn px-4">
                        View Details
                      </button>
                    </Link>
                  ) : (
                    <Link
                      to={"#"}
                      style={{ textDecoration: "none" }}
                      onClick={async (e) => {
                        e.preventDefault();
                        // const applicantId:any=applicantData;
  
                        const applicantData = await dataProvider.getList(
                          "get_subscriber_inbox_detials",
                          {
                            filter: {
                              ApplicationNumber: record?.id,
                              subscriberid: record?.SubscriberID,
                            },
                            pagination: { page: 0, perPage: 0 },
                            sort: { field: "", order: "DESC" },
                          }
                        );
                        create(
                          "generateOTP_docupload",
                          {
                            data: {
                              ApplicantID: applicantData
                                ? applicantData?.data[0]?.id
                                : null,
                              loginuser: identity?.userrole,
                              loginuserid: identity?.userid,
                            },
                          },
                          {
                            onSuccess: (data) => {
                              // console.log(data)
                              // notify(`OTP has been send`, {
                              //   type: "success",
                              //   anchorOrigin: {
                              //     vertical: "top",
                              //     horizontal: "right",
                              //   },
                              // });
                              window.open(
                                `${DOCUPLOAD_SUB_URL_FRONT}${applicantData?.data[0]?.Trnid}?token=${data?.Content}`,
                                "_blank"
                              );
                            },
                            onError: (e: any) =>
                              notify(`Operation fail:${e.message}`, {
                                type: "error",
                                anchorOrigin: {
                                  vertical: "top",
                                  horizontal: "right",
                                },
                              }),
                          }
                        );
                      }}
                    >
                      <button type="button" className="blue-btn px-4">
                        View Details
                      </button>
                    </Link>
                  )
                ) : (filterValues?.app_invitation_type == 1 &&
                    record?.ApplicationStatus !== "Cancelled" &&
                    !record.Archived &&
                    record?.ApplicationStatus !== "Invited") ||
                  (filterValues?.app_invitation_type != 1 &&
                    record?.ApplicationStatus !== "Cancelled" &&
                    !record.Archived &&
                    record?.ApplicationStatus !== "Review Pending") ? (
                  record.IsRevamp ? (
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`/Ats/Subscriber/Inbox/${
                        record.SubscriberID || id || identity?.subscriberid
                      }/edit/${record.id}/${
                        record?.ApplicationType == "Doc Upload"
                          ? 2
                          : record?.ApplicationType == "Application"
                          ? 1
                          : filterValues?.app_invitation_type
                      }${
                        record
                          ? `?data=${encodeURIComponent(
                              (JSON.stringify({ ...record, from: location }))
                            )}`
                          : ""
                      }`}
                      state={{ ...record }}
                      onClick={(event) => onClickHandler(event, record)}
                    >
                      <button type="button" className="blue-btn px-4">
                        View Details
                      </button>
                    </Link>
                  ) : (
                    <Link
                      to={"#"}
                      style={{ textDecoration: "none" }}
                      onClick={async (e) => {
                        e.preventDefault();
                        // const applicantId:any=applicantData;
  
                        const applicantData = await dataProvider.getList(
                          "get_subscriber_inbox_detials",
                          {
                            filter: {
                              ApplicationNumber: record?.id,
                              subscriberid: record?.SubscriberID,
                            },
                            pagination: { page: 0, perPage: 0 },
                            sort: { field: "", order: "DESC" },
                          }
                        );
                        create(
                          "generateOTP_docupload",
                          {
                            data: {
                              ApplicantID: applicantData
                                ? applicantData?.data[0]?.id
                                : null,
                              loginuser: identity?.userrole,
                              loginuserid: identity?.userid,
                            },
                          },
                          {
                            onSuccess: (data) => {
                              // console.log(data)
                              // notify(`OTP has been send`, {
                              //   type: "success",
                              //   anchorOrigin: {
                              //     vertical: "top",
                              //     horizontal: "right",
                              //   },
                              // });
                              window.open(
                                `${DOCUPLOAD_SUB_URL_FRONT}${applicantData?.data[0]?.Trnid}?token=${data?.Content}`,
                                "_blank"
                              );
                            },
                            onError: (e: any) =>
                              notify(`Operation fail:${e.message}`, {
                                type: "error",
                                anchorOrigin: {
                                  vertical: "top",
                                  horizontal: "right",
                                },
                              }),
                          }
                        );
                      }}
                    >
                      <button type="button" className="blue-btn px-4">
                        View Details
                      </button>
                    </Link>
                  )
                ) : (
                  <button
                    type="button"
                    disabled={true}
                    className="blue-btn px-4"
                    style={{ cursor: "not-allowed" }}
                  >
                    View Details
                  </button>
                ))}
  
              {/* removed all conditions for testing purpose. */}
              {/* <Link style={{ textDecoration: 'none' }} to={`/Ats/Subscriber/Inbox/${record.SubscriberID || id}/edit/${record.id}/${filterValues?.app_invitation_type}`} state={{ ...record }} >
                      <button type="button" className="blue-btn px-4">
                          View Details
                      </button>
                  </Link> */}
            </Box>
          </Box>
        </Paper>
      </>
    );
  };