import React, { Fragment, useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate } from "react-router";
import { ArrowBack } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import InputField2 from "../../common/InputComponents/InputField2";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import ToggleButtons from "../../common/InputComponents/ToggleButtons";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Button } from "@mui/material";
import {
  Loading,
  useCreate,
  useGetIdentity,
  useGetList,
  useGetOne,
  useListContext,
  useNotify,
  useRefresh,
  useStore,
} from "react-admin";
import InputSelectField from "../../common/InputComponents/InputSelectField";
import { useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import InputCurrencyField from "../../DocumentAnalysis/components/inputComponents/InputCurrencyFiled";
import CopyUrls from "./CopyUrls";
import save_icon from "../../../assets/images/save_icon.svg";
import formValidation from "../../../yupFormValidationRules/formValidator";
import { REACT_APPLICANT } from "../../../config";

const AddAndUpdateUnit = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const notify = useNotify();
  const refresh = useRefresh();
  const toggleButton = [
    {
      value: 0,
      label: "Inactive",
      icon: <ClearIcon />,
      borderColor: "#909090",
      backgroundColor: "#EEE",
      boxShadowColor: "#90909033",
      color: "#606060",
    },
    {
      value: 1,
      label: "Active",
      icon: <CheckIcon />,
      borderColor: "#46A96F",
      backgroundColor: "#EBFFF3",
      boxShadowColor: "#46a96f1a",
      color: "#46A96F",
    },
  ];
  const [switchSubscriberObj] = useStore("switchSubscriberName");
  const { data: identity } = useGetIdentity();
  const { id, unitId } = useParams();
  // call get Floor list data
  const { data: getFloorList, isLoading: getFloorLoading } = useGetList(
    "getfloorlist",
    {
      filter: {
        PropertyID: id,
        SubscriberID: switchSubscriberObj?.value,
      },
    },
    {
      enabled: switchSubscriberObj?.value && id ? true : false,
      refetchOnWindowFocus: false,
    }
  );

  // get unit details
  const { data: getUnitDetails, isLoading: loadingFloorDetails } = useGetList(
    "get-unit",
    {
      filter: {
        propertyID: id,
        subscriberID: switchSubscriberObj?.value,
        unitID: unitId,
      },
    },
    {
      enabled: switchSubscriberObj?.value && id && unitId ? true : false,
      refetchOnWindowFocus: false,
    }
  );

  // form validation schema
  const validationSchema = yup.lazy((value) =>
    yup.object().shape({
      unitName: formValidation.name,
      floor: formValidation.validationForDropDown,
      active: formValidation.StringRequiredValidation,
      minimumRent: yup
        .string()
        .matches(/^(\d*[0-9])|^$/, "Only Numbers Allowed")
        .min(1, "Greater than 0 value required")
        .nullable()
        .test("rent", "Required!", async (value) => {
          return value == null || parseInt(value) > 0 ? true : false;
        })
        .test(
          "min-less-than-max",
          "Minimum rent must be less than maximum rent.",
          (value: any, context) => {
            const { maximumRent } = context.parent;
            return +value <= +maximumRent;
          }
        ),
      maximumRent: yup
        .string()
        .matches(/^(\d*[0-9])|^$/, "Only Numbers Allowed")
        .min(1, "Greater than 0 value required")
        .nullable()
        .test("rent", "Required!", async (value) => {
          return value == null || parseInt(value) > 0 ? true : false;
        })
        .test(
          "max-greater-than-min",
          "Maximum rent must be greater than or equal to minimum rent.",
          (value: any, context) => {
            const { minimumRent } = context.parent;
            return +value >= +minimumRent;
          }
        ),
      deposit: yup
        .string()
        .matches(/^(\d*[0-9])|^$/, "Only Numbers Allowed")
        .min(1, "Greater than 0 value required")
        .nullable()
        .test("rent", "Required!", async (value) => {
          return value == null || parseInt(value) > 0 ? true : false;
        }),
    })
  );

  // Initial form values
  const defaultFormValues = {
    unitName: "",
    floor: "",
    active: 1,
    minimumRent: "",
    maximumRent: "",
    deposit: "",
  };

  const methods = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  useEffect(() => {
    if (!state?.isAdding) {
      if (getUnitDetails && getUnitDetails?.length > 0) {
        getUnitDetails &&
          getUnitDetails?.map((unitDetails, index) => {
            getFloorList?.filter((item) => {
              if (item?.ID == unitDetails?.FloorPlanID) {
                let body = {
                  value: item?.ID,
                  label: item.FloorCode,
                };

                unitDetails.FloorPlanID = body;
              }
            });
          });
      }

      let data = getUnitDetails && getUnitDetails[0];
      const getFormData: any = {
        unitName: data?.UnitName,
        floor: data?.FloorPlanID,
        active: data?.Active ? 1 : 0,
        minimumRent: data?.MinimumRent,
        maximumRent: data?.MaximumRent,
        deposit: data?.Deposit,
      };
      methods.reset(getFormData);
    }
  }, [getUnitDetails && getUnitDetails[0], methods]);

  useEffect(() => {
    if (state?.isAdding) {
      if (
        getFloorList &&
        getFloorList?.length > 0 &&
        getFloorList?.length === 1
      ) {
        let floor: any = {
          value: getFloorList[0]?.ID,
          label: getFloorList[0]?.FloorCode,
        };
        methods.setValue("floor", floor);
      }
    }
  }, [getFloorList]);

  const [create, { isLoading, error }] = useCreate();

  // form submit handler
  const onSubmit = (value) => {
    const addData = {
      ...value,
      subscriberID: switchSubscriberObj?.value,
      propertyID: id,
      // propertyName: "property new",
      // address: "",
    };
    const updateData = { ...value, ID: unitId };
    create(
      state?.isAdding ? `add-unit` : `update-unit`,
      { data: state?.isAdding ? addData : updateData },
      {
        onSuccess: (data) => {
          notify(
            state?.isAdding
              ? `Unit created successfully.`
              : `Unit updated successfully.`,
            {
              type: "success",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            }
          );
          refresh();
          methods?.reset();
          navigate(`/ats/settings/propertySetup/propertyDetails/${state?.propertyId}`,{state:{type:"unit"}});
          // navigate(-1);
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    );
  };

  useEffect(() => {
    document.title = state?.isAdding
      ? "Property Setup - Add New Unit"
      : "Property Setup - Unit Details";
  }, []);
 if(getFloorLoading || loadingFloorDetails) return <Loading />
  return (
    <Fragment>
      <div className="home_content">
        <div className="row">
          <div className="col-12 d-flex">
            <Breadcrumbs aria-label="breadcrumb">
              <Typography sx={{ fontWeight: "bold" }}>
                <button
                  title="Go Back"
                  style={{ color: "#2cc0e4" }}
                  className="btn btn-link"
                  onClick={() => navigate(`/ats/settings/propertySetup/propertyDetails/${state?.propertyId}`,{state:{type:"unit"}})}
                >
                  <ArrowBack />
                </button>
                {/* <span>Email Settings &gt; Template Details</span> */}
                <span>{state?.isAdding ? `Add New Unit` : `Unit Details`}</span>
              </Typography>
            </Breadcrumbs>
          </div>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} className="mr-t30">
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputField2
                  label="Unit Code"
                  source={`unitName`}
                  placeholder="Enter Unit Code"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputSelectField
                  label={`Floor`}
                  source={`floor`}
                  options={getFloorList?.map((item) => ({
                    value: item?.ID,
                    label: item.FloorCode,
                  }))}
                  labelAsteriskClass={"color-red"}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                    zIndexValue:2
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <ToggleButtons
                  toggleButton={toggleButton}
                  label="Status"
                  labelClass={"lable-title"}
                  source={`active`}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputCurrencyField
                  label="Minimum Rent"
                  source={`minimumRent`}
                  required={true}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                  handleOnChange={() => {
                    let watchMaximumRent: any = methods?.watch(`maximumRent`);
                    if (
                      watchMaximumRent &&
                      watchMaximumRent !== "" &&
                      watchMaximumRent !== 0
                    ) {
                      methods?.trigger(`minimumRent`);
                      methods?.trigger(`maximumRent`);
                    }
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputCurrencyField
                  label="Maximum Rent"
                  source={`maximumRent`}
                  required={true}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                  handleOnChange={() => {
                    let watchMinimumRent: any = methods?.watch(`minimumRent`);
                    if (
                      watchMinimumRent &&
                      watchMinimumRent !== "" &&
                      watchMinimumRent !== 0
                    ) {
                      methods?.trigger(`minimumRent`);
                      methods?.trigger(`maximumRent`);
                    }
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputCurrencyField
                  label="Deposit"
                  source={`deposit`}
                  required={true}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                  handleOnChange={() => {
                    methods?.trigger(`deposit`);
                  }}
                />
              </div>
            </div>
            {!state?.isAdding && (
              <div className="row mr-b50">
                {getUnitDetails &&
                getUnitDetails[0]?.SubscriberAts &&
                getUnitDetails &&
                getUnitDetails[0]?.URL ? (
                  <div className="col-12 mr-b10">
                    <CopyUrls
                      heading={"Unit URL - Application"}
                      url={getUnitDetails && (REACT_APPLICANT + '/' + getUnitDetails[0]?.URL)}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {getUnitDetails &&
                getUnitDetails[0]?.SubscriberDocUpload &&
                getUnitDetails &&
                getUnitDetails[0]?.DocUploadURL ? (
                  <div className="col-12 mr-b10">
                    <CopyUrls
                      heading={"Unit URL - Doc Upload"}
                      url={getUnitDetails && (`${window.location.protocol}//${window.location.host}/atss/cp/` + getUnitDetails[0]?.DocUploadURL)}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {getUnitDetails &&
                getUnitDetails[0]?.SubscriberGuestCard &&
                getUnitDetails &&
                getUnitDetails[0]?.GuestCardURL ? (
                  <div className="col-12 mr-b10">
                    <CopyUrls
                      heading={"Unit URL - Guest Card"}
                      url={getUnitDetails && (`${window.location.protocol}//${window.location.host}/atss/cp/` + getUnitDetails[0]?.GuestCardURL)}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )}

            <Box
              sx={{
                height: "60px",
                background: "#F5FDFF",
                boxShadow: "0px -1px 12px 0px rgba(0, 0, 0, 0.12)",
              }}
              display={"flex"}
              justifyContent={"end"}
              alignItems={"center"}
              className="sticky-bottom mt-4"
              zIndex={1}
            >
              <Box>
                <Button
                  sx={{ mx: 1 }}
                  onClick={() => {
                    refresh();
                    methods?.reset();
                    navigate(`/ats/settings/propertySetup/propertyDetails/${state?.propertyId}`,{state:{type:"unit"}});
                  }}
                  variant="outlined"
                  color="secondary"
                  type="button"
                >
                  Cancel
                </Button>
                <Button
                  startIcon={
                    state?.isAdding ? (
                      <CheckIcon />
                    ) : (
                      <img src={save_icon} alt="save icon" />
                    )
                  }
                  sx={{ mx: 1 }}
                  variant="contained"
                  type="submit"
                  disabled={isLoading}
                >
                  {state?.isAdding ? `Create Unit` : `Update`}
                </Button>
              </Box>
            </Box>
          </form>
        </FormProvider>
      </div>
    </Fragment>
  );
};

export default AddAndUpdateUnit;
