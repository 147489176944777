import React, { Fragment, useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate, useLocation } from "react-router";
import { ArrowBack } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import InputField2 from "../../common/InputComponents/InputField2";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import ToggleButtons from "../../common/InputComponents/ToggleButtons";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Button } from "@mui/material";
import {
  Loading,
  useCreate,
  useGetList,
  useNotify,
  useRefresh,
  useStore,
} from "react-admin";
import { useParams } from "react-router-dom";
import save_icon from "../../../assets/images/save_icon.svg";
import formValidation from "../../../yupFormValidationRules/formValidator";

const AddorUpdateHierarchyLevel = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const notify = useNotify();
  const refresh = useRefresh();
  const toggleButton = [
    {
      value: 0,
      label: "Inactive",
      icon: <ClearIcon />,
      borderColor: "#909090",
      backgroundColor: "#EEE",
      boxShadowColor: "#90909033",
      color: "#606060",
    },
    {
      value: 1,
      label: "Active",
      icon: <CheckIcon />,
      borderColor: "#46A96F",
      backgroundColor: "#EBFFF3",
      boxShadowColor: "#46a96f1a",
      color: "#46A96F",
    },
  ];

  const [switchSubscriberObj] = useStore("switchSubscriberName");
  const { id } = useParams();

  // get integration-setting-rule details
  const {
    data: getHierarchyLevelDetails,
    isLoading: loadingHierarchyLevelDetails,
  } = useGetList(
    "hierarchy-levels-list",
    {
      filter: {
        subscriberID: switchSubscriberObj?.value,
        id: id,
      },
    },
    {
      enabled: switchSubscriberObj?.value && id ? true : false,
      refetchOnWindowFocus: false,
    }
  );

  // form validation schema
  const validationSchema = yup.lazy((value) =>
    yup.object().shape({
      LevelCode: formValidation.name,
      HierarchyName: formValidation.name,
      Active: formValidation.StringRequiredValidation,
    })
  );

  // Initial form values
  const defaultFormValues = {
    LevelCode: "",
    HierarchyName: "",
    Active: 1,
  };

  const methods = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  useEffect(() => {
    if (!state?.isAdding) {
      let data = getHierarchyLevelDetails && getHierarchyLevelDetails[0];
      const getFormData: any = {
        LevelCode: data?.LevelCode,
        HierarchyName: data?.HierarchyName,
        Active: data?.Active,
      };
      methods.reset(getFormData);
    }
  }, [getHierarchyLevelDetails && getHierarchyLevelDetails[0], methods]);

  const [create, { isLoading, error }] = useCreate();

  // form submit handler
  const onSubmit = (value) => {
    const addData = {
      SubscriberID: switchSubscriberObj?.value,
      ...value,
    };
    const updateData = {
      ...value,
      ID: id,
      SubscriberID: switchSubscriberObj?.value,
    };
    create(
      state?.isAdding ? `add-hierarchy-levels` : `update-hierarchy-levels`,
      { data: state?.isAdding ? addData : updateData },
      {
        onSuccess: (data) => {
          notify(
            state?.isAdding
              ? `Hierarchy level created successfully.`
              : `Hierarchy level updated successfully.`,
            {
              type: "success",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            }
          );
          refresh();
          methods?.reset();
          navigate(-1);
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    );
  };

  //   useEffect(() => {
  //     document.title = state?.isAdding
  //       ? "Hierarchy Level - Add New Level"
  //       : "Hierarchy Level - Level Details";
  //   }, []);
 if(loadingHierarchyLevelDetails) return <Loading />
  return (
    <Fragment>
      <div className="home_content">
        <div className="row">
          <div className="col-12 d-flex">
            <Breadcrumbs aria-label="breadcrumb">
              <Typography sx={{ fontWeight: "bold" }}>
                <button
                  title="Go Back"
                  style={{ color: "#2cc0e4" }}
                  className="btn btn-link"
                  onClick={() => navigate(-1)}
                >
                  <ArrowBack />
                </button>
                {state?.isAdding ? (
                  <span>Add New Level</span>
                ) : (
                  <span>Level Details</span>
                )}
              </Typography>
            </Breadcrumbs>
          </div>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} className="mr-t30">
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputField2
                  label="Level Code"
                  source={`LevelCode`}
                  placeholder="Enter Level Code"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputField2
                  label="Hierarchy Name"
                  source={`HierarchyName`}
                  placeholder="Enter Hierarchy Name"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                />
              </div>
              <div className="col-sm-12 col-md-3 col-lg-3 mr-b20">
                <ToggleButtons
                  toggleButton={toggleButton}
                  label="Status"
                  labelClass={"lable-title"}
                  source={`Active`}
                />
              </div>
            </div>

            <Box
              sx={{
                height: "60px",
                background: "#F5FDFF",
                boxShadow: "0px -1px 12px 0px rgba(0, 0, 0, 0.12)",
              }}
              display={"flex"}
              justifyContent={"end"}
              alignItems={"center"}
              className="sticky-bottom mt-4"
            >
              <Box>
                <Button
                  sx={{ mx: 1 }}
                  onClick={() => {
                    refresh();
                    methods?.reset();
                    navigate(-1);
                  }}
                  variant="outlined"
                  color="secondary"
                  type="button"
                >
                  Cancel
                </Button>
                <Button
                  startIcon={
                    state?.isAdding ? (
                      <CheckIcon />
                    ) : (
                      <img src={save_icon} alt="save icon" />
                    )
                  }
                  sx={{ mx: 1 }}
                  variant="contained"
                  type="submit"
                  disabled={isLoading}
                >
                  {state?.isAdding ? `Create Hierarchy Level` : `Update`}
                </Button>
              </Box>
            </Box>
          </form>
        </FormProvider>
      </div>
    </Fragment>
  );
};

export default AddorUpdateHierarchyLevel;
