import React from "react";
import { useFieldArray, useFormContext, useFormState } from "react-hook-form";
// import InputCheckBoxField from "../../../../registration/Inputs/InputCheckBoxField";
// import InputField from "../../../../registration/Inputs/InputField";
// import { useDeleteSourceMutation } from "../../../../../services/identificationApi";
import { TiDelete } from "react-icons/ti";
import DeleteContainedBtn from "../../../../common/DeleteContainedBtn";
import InputCheckBoxField from "../../../../common/InputComponents/InputCheckBoxField";
// import InputFieldPhone from "../../../../registration/Inputs/InputFieldPhone";
// import { JSONTree } from "react-json-tree";
import InputField2 from "../../../../common/InputComponents/InputField2";
import TextInputPhone from "../../../components/inputComponents/TextInputPhone";
import { CardHeader } from "@mui/material";

const Applying = (props) => {
  const { register } = props;
  const { control, watch } = useFormContext();
  // const [doDelete, deleteResult] = useDeleteSourceMutation();
  const { fields, append, remove, replace } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "anyone_over_18.fields", // unique name for your Field Array
    keyName: "fields_id", //default to "id", you can change the key name
  });
  const { errors } = useFormState({
    control,
  });

  return (
    <>
      {fields.map((field, index) => (
        <div key={field.fields_id}>
          <div className="blue-box pd15 mr-t20 a-blue-box">
            <div className="row">
              <div className="col-sm-12 mb-2">
                {/* {index === 0 && (
                  <span className="s-no-index float-left">{index + 1}</span>
                )} */}
                {/* {!field?.is_fixed && (
                  <button
                    type="button"
                    disabled={field.is_submitted}
                    onClick={async () => {
                      remove(index);
                      props?.checkSourceAvailableHandler("applying");
                    }}
                    title="Delete this Employer"
                    className="float-right btn btn-sm btn-danger"
                  >
                    <TiDelete size={25} /> Delete
                  </button> */}
                <CardHeader
                  sx={{ padding: 0 }}
                  title={<b className="s-no-index-number">{index + 1}</b>}
                  action={
                    !field?.is_fixed && (
                      <DeleteContainedBtn
                        onClick={async () => {
                          remove(index);
                          props?.checkSourceAvailableHandler("applying");
                          props?.handleAPIDebounced();
                        }}
                        disabled={field.is_submitted}
                        title="Delete this Employer"
                      />
                    )
                  }
                />
                {/* )} */}
              </div>
            </div>
            <div className=" row">
              <div className="col-sm-12 col-md-6 col-lg-4 mr-b10">
                {/* <InputField
                  disable={field?.is_fixed}
                  label={`First Name`}
                  register={register(
                    `anyone_over_18.fields.${index}.first_name`
                  )}
                /> */}
                <InputField2
                  label="First Name"
                  source={`anyone_over_18.fields.${index}.first_name`}
                  placeholder="First Name"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                  handleOnChange={() => {
                    props?.handleAPIDebounced();
                  }}
                  disable={field?.is_fixed}
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4 mr-b10">
                {/* <InputField
                  disable={field?.is_fixed}
                  label={`Last Name`}
                  register={register(
                    `anyone_over_18.fields.${index}.last_name`
                  )}
                /> */}
                <InputField2
                  label="Last Name"
                  source={`anyone_over_18.fields.${index}.last_name`}
                  placeholder="Last Name"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                  handleOnChange={() => {
                    props?.handleAPIDebounced();
                  }}
                  disable={field?.is_fixed}
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4 mr-b10">
                {/* <InputField
                  disable={field?.is_fixed}
                  label={`Email`}
                  register={register(`anyone_over_18.fields.${index}.email`)}
                /> */}
                <InputField2
                  label="Email"
                  source={`anyone_over_18.fields.${index}.email`}
                  placeholder="Email"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                  handleOnChange={() => {
                    props?.handleAPIDebounced();
                  }}
                  disable={field?.is_fixed}
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4 mr-b10">
                {/* <InputFieldPhone
                  disable={field?.is_fixed}
                  label={`Mobile Number`}
                  register={register(`anyone_over_18.fields.${index}.mobile`)}
                  notRequired={true}
                /> */}
                <TextInputPhone
                  source={`anyone_over_18.fields.${index}.mobile`}
                  label={`Mobile Number`}
                  isCountryCode={`anyone_over_18.fields.${index}.country_code`}
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                  widthClass={"w-100"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                  onChangeCallback={() => {
                    props?.handleAPIDebounced();
                  }}
                  disabled={field?.is_fixed}
                  required={false}
                />
              </div>
              <div className="col-sm-12 mr-t10 mr-b10">
                <InputCheckBoxField
                  disabled={field?.is_fixed}
                  checked={watch(
                    `anyone_over_18.fields.${index}.financially_responsible`
                  )}
                  notRequired={true}
                  label={" Individual will not be responsible for the lease"}
                  name={`anyone_over_18.fields.${index}.financially_responsible`}
                  handleOnChange={() => {
                    props?.handleAPIDebounced();
                  }}
                />
              </div>

              {errors && errors[index] && errors[index].number}
            </div>
          </div>
        </div>
      ))}
      <div className="blue-box pd15">
        <div className="add-text-btn flex1 text-center ps-2 pt-3">
          <button
            type="button"
            onClick={(event) =>
              append({
                id: 0,
                first_name: "",
                last_name: "",
                email: "",
                mobile: null,
                individual: false,
              })
            }
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.68652 5.44287V0.442871H7.35319V5.44287H12.3532V7.10954H7.35319V12.1095H5.68652V7.10954H0.686523V5.44287H5.68652Z"
                fill="#2DC3E8"
              />
            </svg>
            &nbsp; Add another Co-Applicant over age 18
          </button>
        </div>
      </div>
    </>
  );
};

export default Applying;
