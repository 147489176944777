import React, { useState, useEffect ,forwardRef} from "react";
import { 
  useDataProvider, 
  useGetOne, 
  useNotify,
 } from "react-admin";
import {
  Controller,
  useController,
  useForm,
} from "react-hook-form";
import { JSONTree } from "react-json-tree";
import { useQuery } from 'react-query';
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { RootState } from "../../Store/store";

import BankingStatementForm from './BankingStatementForm';


const BankStatements =forwardRef((props:any,ref) => {
  const { record,required,status} = props
  // const { id } = useParams();
  // fix the co-applicant issue
  const userTrnId = useSelector((state: RootState) => state.users.userTrnId);
  const trn_id=props?.trn_id || userTrnId;
  const role:any = useSelector((state: RootState) => state.users.role);
  const methods = useForm();
  const [loading, setLoading] = useState(false);
  const [bankDetails, setBankDetails] = useState<any>(record || { banking: [] });
  const [metaData, setMetaData] = useState<any>({});
  const [SOCRstatus, setSOCRstatus] = useState({
    source_1: {SOCRStatus: null},
    source_2: {SOCRStatus: null},
    source_3: {SOCRStatus: null}
  });
  
  const { data: meta_d_response } = useGetOne('getMetaData',{id:6,meta:{trn_id}},{ refetchOnWindowFocus: false, staleTime: 5 })
  
//   const {data:meta_d_response} = useQuery(
//     ['getMetaData', 'getOne', { id: 6 }],
//     () => dataProvider.getMetaData({ document_id: 6 ,trn_id}),
//     { refetchOnWindowFocus: false,staleTime:5 }
// );

useEffect(()=>{
  if(meta_d_response && role !== 'applicant'){
  setMetaData(meta_d_response?.records);
  }
},[meta_d_response])


  const [
    adminValidationMessages,
    setAdminValidationMessages,
  ] = useState({} as any);

  const [
    applicantValidationMessages,
    setApplicantValidationMessages,
  ] = useState({} as any);

  const dataProvider = useDataProvider();
  const notify = useNotify();

  useEffect(() => {
    if(Object.keys(metaData).length==0 && metaData.validation_messages && metaData.other_check){
      return ;
    }
    const { validation_messages,other_check} = metaData;

    if(validation_messages && role !== 'applicant'){
      const admin_messages_source_1 = validation_messages?.source_1.map(
        (message) => ({
          ...message.admin,
        })
      );
      const admin_messages_source_2 = validation_messages?.source_2.map(
        (message) => ({
          ...message.admin,
        })
      );
      const admin_messages_source_3 = validation_messages?.source_3.map(
        (message) => ({
          ...message.admin,
        })
      );
  
      const admin_validation_messages = {
        source_1:admin_messages_source_1,
        source_2:admin_messages_source_2,
        source_3:admin_messages_source_3
      }
  
      setAdminValidationMessages(admin_validation_messages);
    }
    if(other_check && role !== 'applicant'){
      setSOCRstatus(other_check)
    } 
    // fetchRecord();
  }, [metaData]);

  const handleDeleteDocument = async (id) => {
    try {
      setLoading(true);
      await dataProvider.deleteDocument(id);
      notify("Document deleted", { type: "success", anchorOrigin: { vertical: 'top', horizontal: 'right' }});
    } catch (error) {
      setLoading(false);
      notify("Error: fail deleting document", { type: "warning", anchorOrigin: { vertical: 'top', horizontal: 'right' }});
    } finally {
      // fetchRecord();
      setLoading(false);
    }
  };

  yup.addMethod(yup.array, "uniqueBank", function (message) {
    return this.test("unique", message, function (data) {
      const list = data?.map((item) => {
        return { account_type: item?.account_type, bank_name: item?.bank_name };
      });
      let isUnique = true;
      let row = list?.length || 0;
      for (let i = 0; i < row; i++) {
        for (let j = i + 1; j < row; j++) {
          if (JSON.stringify(list?.[i]) === JSON.stringify(list?.[j])) {
            isUnique = false;
          }
        }
      }
      if (isUnique) {
        return true;
      }
      return false;
    });
  });

  const schema = yup.object().shape({
    bank_name: yup.string() .max(75, "maximum 75 characters is required").required("required"),
    account_type: yup.string().typeError("required").required("required"),
    current_balance: yup.number().nullable().notRequired(),
    files: yup.array().min(1, "At Least 1 Document Is Required"),
    banking:
      yup
        .array()
        .of(
          yup.object().shape({
            bank_name: yup.string() .max(75, "maximum 75 characters is required").required("required"),
            account_type: yup
              .string()
              .typeError("required")
              .required("required"),
            current_balance: yup.number().nullable().notRequired(),
            files: yup.array().min(1, "At Least 1 Document Is Required"),
          })
        )
        .min(1, "must have atleast 1 records"),
  });

  const handleSubmitDocReason = async (val) =>{

    const final_paylaod = {
      subUrl: "/income/saveBankingSection",
      trn_id: trn_id,
      Question: "Do you have a Bank Account?",
      // response: methods.watch("reason_banking"),
      response: val,
      banking: [],
      employerCount: 0
    };

    try {
      setLoading(true);
      await dataProvider.update("other_sections", {
        id: trn_id,
        data: final_paylaod,
        previousData: bankDetails,
      });
      notify("Bank details updated", { type: "success", anchorOrigin: { vertical: 'top', horizontal: 'right' }});
    } catch (error) {
      setLoading(false);
      notify("Error: fail updating bank details", { type: "warning", anchorOrigin: { vertical: 'top', horizontal: 'right' }});
    } finally {
      // fetchRecord();
      setLoading(false);
    }
  } 
  // return <><JSONTree data={meta_d_response} /></>;
  return  <BankingStatementForm 
    {...record} trn_id={trn_id} 
    OCR_SOCRstatus={SOCRstatus}
    adminValidationMessages={adminValidationMessages}
    applicantValidationMessages={[]}
    rules={metaData?.rules}
    role={role}
    ref={ref}
    required={required}
    status = {status}
    />;
});

export default BankStatements;
