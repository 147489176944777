import React, { useState } from "react";
import sanitizeHtml from "sanitize-html";
import SaveIcon from "@mui/icons-material/Save";
import DialogContent from "@mui/material/DialogContent";
import AddIcon from "@mui/icons-material/Add";
import DialogTitle from "@mui/material/DialogTitle";
import { RxCross2 } from "react-icons/rx";
import DialogActions from "@mui/material/DialogActions";
import { FiMoreVertical, FiX } from "react-icons/fi";
import * as Yup from "yup";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCreate, useGetOne, useGetList } from "react-admin";
import frame from "../../../assets/images/Frame.svg";
import AddNotesModal from "./AddNotesModal";
import { FaRegNoteSticky } from "react-icons/fa6";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  FormLabel,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Backdrop,
  Typography,
  Button as MuiButton,
} from "@mui/material";

export default function ShowNotesModal({ record }) {
  const [noteText, setNoteText] = useState("");
  const [openNotesDialog, setopenNotesDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenNotes = () => {
    setopenNotesDialog(true);
    handleCloseMenu();
  };

  const handleCloseNotes = () => {
    setopenNotesDialog(false);
    setNoteText(" ");
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
      // hour: "2-digit",
      // minute: "2-digit",

      // hour12: true,
    });
  };

  const { data: noteData, isLoading: loadingNote } = useGetList("viewNotes", {
    filter: {
      ApplicantID: record?.id,
    },
  },{ enabled: openNotesDialog });

  return (
    <>
      <div className="basic-subinfo px-2">
        <div className="modal-sm-button">
          <button
            type="button"
            className="p-0 mt-0"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            onClick={() => {
              handleOpenNotes();
            }}
            style={{ lineHeight: "22px" }}
          >
            <FaRegNoteSticky size={15} /> <br />
            <span>Notes</span>
          </button>
        </div>
      </div>

      <Dialog
        open={openNotesDialog}
        onClose={handleCloseNotes}
        fullWidth
        sx={{ "& .MuiDialog-paperFullWidth ": { maxWidth: "1000px" } }}
      >
        <DialogTitle
          sx={{ m: 0, p: 1, backgroundColor: "Gainsboro" }}
          id="customized-dialog-title"
        >
          Notes
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseNotes}
          sx={{
            position: "absolute",
            right: 6,
            top: 1,

            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon style={{ fontSize: "12px" }} />
        </IconButton>
        <DialogContent>
          <div style={{ overflowX: "hidden" }}>
            {loadingNote ? (
              <p>Loading...</p>
            ) : noteData ? (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date Created</TableCell>
                      <TableCell>Note Text</TableCell>
                      <TableCell>Category</TableCell>
                      <TableCell>UserName</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {noteData.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{formatDate(item.CreatedOn)}</TableCell>
                        <TableCell
                          title={sanitizeHtml(item?.Notes, {
                            allowedTags: [], // Allow no tags, only plain text
                            allowedAttributes: {}, // Allow no attributes
                          })}
                          style={{
                            maxWidth: "200px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: item?.Notes,
                          }}
                        />

                        <TableCell>{item.Category}</TableCell>
                        <TableCell>{item.UserName}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <p>No data available.</p>
            )}
          </div>
          <DialogActions>
            <AddNotesModal record={record} />
            <Button
              startIcon={<RxCross2 />}
              onClick={handleCloseNotes}
              variant="outlined"
            >
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
