import React, { useState,forwardRef ,useImperativeHandle} from "react";
import { useParams } from "react-router-dom";
import ProofOfIdForm from "./ProofOfIdForm";
interface RefObject {
  handleClickbyRef: () => void
}
const ProofOfId = forwardRef((props: any,ref) => {
  // const { id } = useParams();
  return  <ProofOfIdForm status={props.status} {...props.record} trn_id={props?.record?.id} role={props.role} ref={ref} required={props.required}/>;
});
export default ProofOfId;
