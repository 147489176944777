import React, { Fragment } from "react";
import ArchiveIcon from "@mui/icons-material/Archive";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InfoIcon from "@mui/icons-material/Info";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm, FormProvider } from "react-hook-form";
import { Box, Button } from "@mui/material";
import InputField2 from "../../common/InputComponents/InputField2";

const MarkActiveModal = () => {
  const [open, setOpen] = React.useState(false);

  // form validation schema
  const validationSchema = Yup.lazy((value) =>
    Yup.object().shape({
      Reason: Yup.string()
      .max(5000, "maximum 5000 characters is required")
        .trim("Reason is required!")
        .required("Reason is required!"),
    })
  );

  // form initial states
  const methods = useForm({
    defaultValues: {
      Reason: "",
    },
    resolver: yupResolver(validationSchema),
  });

  // form submit handler
  const onSubmit = (value) => {
    console.log(value);
  };

  const dialogTitle = (
    <DialogTitle color="error" id="alert-dialog-title">
      <InfoIcon /> Are you sure?
    </DialogTitle>
  );

  const dialogContent = (
    <DialogContent>
      <p>
        Are you sure you want to archive the applications? <br />
        You can find this later from Settings &gt; Archive.
      </p>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-12 mr-b10 mt-3">
              <InputField2
                label="Reason"
                source={`Reason`}
                placeholder="Enter Reason for Archiving Application"
                labelClass={"lable-title"}
                additionalInputClass={"cus-control"}
              />
            </div>
          </div>
          <Box display={"flex"} justifyContent={"end"}>
            <Button
              sx={{ mx: 1 }}
              onClick={() => {
                setOpen(false);
                methods?.reset();
              }}
              variant="outlined"
              color="secondary"
              type="button"
            >
              Cancel
            </Button>
            <Button
              startIcon={<ArchiveIcon />}
              sx={{ mx: 1 }}
              variant="contained"
              type="submit"
            >
              Archive
            </Button>
          </Box>
        </form>
      </FormProvider>
    </DialogContent>
  );

  return (
    <Fragment>
      <Button
        onClick={() => setOpen(true)}
        variant="text"
        sx={{ textTransform: "capitalize" }}
      >
        Mark as Inactive
      </Button>
      <Dialog
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {dialogTitle && dialogTitle}
        {dialogContent && dialogContent}
      </Dialog>
    </Fragment>
  );
};

export default MarkActiveModal;
