import React from "react";
import { useFieldArray, useFormContext, useFormState } from "react-hook-form";
import { TiDelete } from "react-icons/ti";
import {
  Button,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useSelector } from "react-redux";
import InputField from "../../common/InputComponents/InputField";
import DeleteContainedBtn from "../../common/DeleteContainedBtn";
import InputField2 from "../../common/InputComponents/InputField2";

const Alias = (props) => {
  //   const useWindowWidth = useSelector(
  //     (state) => state.identification.sideMenuSlice
  //   );
  const { register } = props;
  const [open, setOpen] = React.useState(false);
  const [deleteAddressIndex, setDeleteAddressIndex] = React.useState(0);

  const { control, watch } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "applicant.Alias", // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });
  const { errors } = useFormState({
    control,
  });

  return (
    <>
      {fields.map((field, index) => {
        let newIndex = index + 1;

        return (
          <div key={index}>
            <div
            //   className={`${
            //     useWindowWidth.windowWidth < 766
            //       ? "padding-left-zero padding-right-zero col-sm-12 col-md-12 col-lg-12"
            //       : "col-sm-12"
            //   }`}
            >
              <div className="blue-in-box a-blue-box">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 mb-2">
                    {/* <span className="s-no-index float-left">{newIndex}</span>
                    <button
                      type="button"
                      onClick={async () => {
                        setDeleteAddressIndex(index);
                        setOpen(true);
                      }}
                      title="Delete this Employer"
                      className="float-right btn btn-sm btn-danger"
                    >
                      <TiDelete size={25} /> Delete
                    </button> */}
                    {/*  */}
                    <CardHeader
                      sx={{ padding: 0 }}
                      title={<b className="s-no-index-number">{newIndex}</b>}
                      action={
                        <DeleteContainedBtn
                          onClick={async () => {
                            setDeleteAddressIndex(index);
                            setOpen(true);
                          }}
                        />
                      }
                    />
                    {/*  */}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="form-group cus-input-group mr-b0">
                      {/* <InputField
                        label="First Name"
                        register={register(`applicant.Alias.${index}.fname`)}
                      /> */}
                      <InputField2
                        label="First Name"
                        source={`applicant.Alias.${index}.fname`}
                        placeholder="First Name"
                        labelClass={"lable-title"}
                        additionalInputClass={"cus-control"}
                        handleOnChange={() => {
                          props?.handleAPIDebounced();
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="form-group cus-input-group mr-b0">
                      {/* <InputField
                        label="Last Name"
                        register={register(`applicant.Alias.${index}.lname`)}
                      /> */}
                      <InputField2
                        label="Last Name"
                        source={`applicant.Alias.${index}.lname`}
                        placeholder="Last Name"
                        labelClass={"lable-title"}
                        additionalInputClass={"cus-control"}
                        handleOnChange={() => {
                          props?.handleAPIDebounced();
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-4">
                    <div className="flex1 text-right">
                      {/* <button
                        type="button"
                        onClick={async () => {
                          setDeleteAddressIndex(index);
                          setOpen(true);
                        }}
                        title="Delete this Employer"
                        className="btn btn-danger"
                      >
                        <TiDelete size={25} /> Delete
                      </button> */}
                    </div>
                  </div>
                </div>
                {index === fields?.length - 1 && (
                  <>
                    <div className="add-text-btn flex1 text-center ps-2 pt-3">
                      <button
                        type="button"
                        onClick={(event) => append({ fname: "", lname: "" })}
                      >
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 13 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.68652 5.44287V0.442871H7.35319V5.44287H12.3532V7.10954H7.35319V12.1095H5.68652V7.10954H0.686523V5.44287H5.68652Z"
                            fill="#2DC3E8"
                          />
                        </svg>
                        &nbsp; Add another name
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
            <Dialog
              open={open}
              onClose={() => {
                setOpen(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {`Are you sure want to delete other ?`}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Warning! Once deleted cannot revert back .
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  No
                </Button>
                <Button
                  onClick={async () => {
                    setTimeout(() => {
                      remove(deleteAddressIndex);
                    }, 200);
                    setOpen(false);

                    setOpen(false);
                    props?.handleAPIDebounced();
                  }}
                  autoFocus
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        );
      })}
    </>
  );
};

export default Alias;
