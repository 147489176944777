import React, { useState } from "react";
import { useGetOne } from "react-admin";
import BarChartGraph from "./BarChartGraph";
import PieChartGraph from "./PieChartGraph";

const LogReportsGraph = () => {
  const [isDay, setIsDay] = useState<number>(0);
  // get getactivedatagraph for log reports Active Users
  const { data, isLoading, error } = useGetOne<any>("getactivedatagraph", {
    id: isDay,
  });
  // get getgraphdata for log reports Active Users
  const { data: getgraphdata } = useGetOne<any>("getgraphdata", {
    id: isDay,
  });
  return (
    <div className={`revenue-info-row mr-t20`}>
      <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-8">
          <BarChartGraph records={data} setIsDay={setIsDay} />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-4 mt-4 mt-md-0">
          <div className="n-app-graph">
            <div style={{ width: "100%", height: 200 }}>
              <p className="tracking pt-2">Tracking</p>
              <div className="row gx-0">
                <div className="col-6">
                  <PieChartGraph records={getgraphdata?.osdata} />
                </div>
                <div className="col-6">
                  <PieChartGraph records={getgraphdata?.broswerdata} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogReportsGraph;
