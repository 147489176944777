import React from 'react';
import { useController, useFormContext } from "react-hook-form";
import './input.css';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

const TextInputPhone = ({
  label,
  source,
  fullWidth = true,
  required = true,
  // countryCode,
  // setCountryCode=()=>{},
  // mobileno,setmno,
  isCountryCode = "country_code",
  labelClass="form-label",
  additionalInputClass="",
  widthClass="",
  requiredClass = {},
  onChangeCallback=()=>{},
  disabled= false,
}) => {
  // const { field, fieldState} = useController({ name: source });

  const { control, setValue } = useFormContext();
  const {
    field,
    fieldState: { error },
  } = useController({
    name: source,
    control,
  });

  // const onChange = (value, country, e, formattedValue) => {
  //   setValue('country_code', country?.dialCode);
  //   onChange(e);
  //   // setValue(source, formattedValue.split(`+${country.dialCode}`)[1]);
  // }

  return (
    <div>
      <div className="emp-inputs">
        <label className={`${error && "text-danger"} ${labelClass}`} htmlFor="">
          {label}
          {required && (<span>&nbsp;&#42;</span>)}
        </label>
        <PhoneInput
          disableDropdown={false}
          country={'us'}
          // {...field}
          value={field?.value?.toString() || ''}
          countryCodeEditable={false}
          inputProps={{
            ref: field.ref,
          }}
          inputClass={`${required && !field.value && "is-warning"} ${error && "is-invalid"
            } ${additionalInputClass} ${widthClass}`}
          onChange={(value, country:any, e, formattedValue) => {
            setValue(isCountryCode, country?.dialCode);
            field.onChange(formattedValue)
            onChangeCallback();
          }}
        // value={mobileno}
          disabled = {disabled}
        />
        <div
          className={`invalid-feedback ${error && 'd-block'}`}
          style={requiredClass}
        >
          {error && error?.message}
        </div>
      </div>
    </div>
  );
}

export default TextInputPhone;