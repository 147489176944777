import React, { useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  BlobProvider,
} from "@react-pdf/renderer";
import { pdf } from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import { JSONTree } from "react-json-tree";
import { Page as RpPage, Document as RpDocument, pdfjs } from "react-pdf";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

// Create Document Component
// const MyDoc  = () => (
//   <Document>
//     <Page size="A4">
//       <View>
//         <Image src='https://placekitten.com/g/200/300' />
//       </View>
//       {/* <View style={styles.section}>
//         <Text>Section #2</Text>
//       </View> */}
//     </Page>
//   </Document>
// );

export default function CustomPreviewUploadImage({
  zoomLevel,
  rotation,
  numPages,
  currentPages,
  onDocumentLoadSuccess,
  file_path,
}) {
  const MyDoc = (
    <Document>
      <Page size="A4">
        <View>
          <Image src={file_path} />
        </View>
        {/* <View style={styles.section}>
          <Text>Section #2</Text>
        </View> */}
      </Page>
    </Document>
  );
  return (
    <>
      <div className="position-absolute top-0 bottom-0 left-0 right-0">
        <BlobProvider document={MyDoc}>
          {({ blob, url, loading, error }) => {
            // Do whatever you need with blob here
            return (
              <RpDocument file={url} onLoadSuccess={onDocumentLoadSuccess}>
                <RpPage
                  renderTextLayer={false}
                  pageNumber={currentPages}
                  rotate={rotation}
                  scale={zoomLevel}
                />
              </RpDocument>
            );
          }}
        </BlobProvider>
      </div>
    </>
  );
}
