import React, { Fragment, useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate, useLocation } from "react-router";
import { ArrowBack } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import InputField2 from "../../common/InputComponents/InputField2";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import InputSelectField from "../../common/InputComponents/InputSelectField";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Button } from "@mui/material";
import {
  useCreate,
  useGetOne,
  useNotify,
  useRefresh,
  useStore,
} from "react-admin";
import { useParams } from "react-router-dom";
import save_icon from "../../../assets/images/save_icon.svg";
import moment from "moment";
import InputDateField from "../../common/InputComponents/InputDateFieldOld";
import formValidation from "../../../yupFormValidationRules/formValidator";

const UpdateStripeAccount = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const notify = useNotify();
  const refresh = useRefresh();
  const payOutFrequencyData = [
    {
      label: "Daily",
      value: "Daily",
    },
    {
      label: "Weekly",
      value: "Weekly",
    },
    {
      label: "Monthly",
      value: "Monthly",
    },
  ];
  const paymentDayData = [
    {
      label: "Sunday",
      value: "Sunday",
    },
    {
      label: "Monday",
      value: "Monday",
    },
    {
      label: "Tuesday",
      value: "Tuesday",
    },
    {
      label: "Wednesday",
      value: "Wednesday",
    },
    {
      label: "Thursday",
      value: "Thursday",
    },
    {
      label: "Friday",
      value: "Friday",
    },
    {
      label: "Saturday",
      value: "Saturday",
    },
  ];

  const [switchSubscriberObj] = useStore("switchSubscriberName");
  const { id } = useParams();
  // State for checking event is hit then true value
  const [checkEventOccurs, setCheckEventOccurs] = useState(false);

  // get getStripeDetails Data
  const { data: getStripeDetails, isLoading: loadinggetStripeDetails } =
    useGetOne(
      "get-stripe-details",
      {
        id: { id, type: state?.type },
      },
      {
        refetchOnWindowFocus: false,
        enabled: id ? true : false,
      }
    );

  // form validation schema
  const validationSchema = yup.lazy((value) =>
    yup.object().shape({
      bankName: formValidation.name,
      accountHolderName: formValidation.name,
      accountNumber: yup
        .string()
        .nullable()
        .required("Required!")
        .test("only_number", "Not valid", (value: any) => {
          if (value === "" || value === null) {
            return true;
          }
          if (id && !checkEventOccurs) {
            // Added this condition to prevent validation when updating an existing record, as the input value in a string.
            return true;
          }
          const regex =
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/;
          return regex.test(value);
        }),
      routingNumber: yup
        .string()
        .nullable()
        .required("Required!")
        .max(9, "Max 9 digits are required")
        .test("only_number", "Not valid", (value: any) => {
          if (value === "" || value === null) {
            return true;
          }
          const regex =
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/;
          return regex.test(value);
        }),
      taxID: yup.string().required("Required!").typeError("Required!").max(50, "Maximum 50 digits are allowed"),
      businessWebsite: yup
        .string()
        .required("Required!")
        .url("Enter a valid URL")
        .nullable(),
      payOutFrequency: formValidation.validationForDropDown,
      paymentDate: ((value?.payOutFrequency?.value === "Monthly" && formValidation.PaymentDate)
      // string().required("Required!").nullable())
      || (value?.payOutFrequency?.value === "Weekly" &&   formValidation.validationForDropDown))
      ,
    //   paymentDay:
    //     value?.payOutFrequency?.value === "Weekly" &&
    //     yup
    //       .object()
    //       .shape({
    //         value: yup.string().required("Required!").nullable(),
    //         label: yup.string().nullable(),
    //       })
    //       .nullable()
    //       .required("Required!"),
    }),
  );

  // Initial form values
  const defaultFormValues = {
    bankName: "",
    accountHolderName: "",
    accountNumber: "",
    routingNumber: "",
    taxID: "",
    businessWebsite: "",
    payOutFrequency : {value: "", label: ""},
    paymentDate: "",
    // paymentDay: ""
  };

  const methods = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  useEffect(() => {
    if (!state?.isAdding) {
      if (getStripeDetails?.data.length > 0) {
        getStripeDetails?.data?.map((subscriberDetails, index) => {
          if(subscriberDetails?.payOutFrequency === "Weekly"){
            paymentDayData?.filter((item) => {
              if (item?.value.toLowerCase() === subscriberDetails?.paymentDate) {
                let body = {
                  value: item?.value,
                  label: item?.label,
                };
  
                subscriberDetails.paymentDate = body;
              }
            });
          }
          // else if(subscriberDetails?.payOutFrequency === "Monthly"){
          //   subscriberDetails.paymentDate = subscriberDetails?.paymentDate ;
          // }
          payOutFrequencyData?.filter((item) => {
            if (item?.value == subscriberDetails?.payOutFrequency) {
              let body = {
                value: item?.value,
                label: item?.label,
              };
              subscriberDetails.payOutFrequency = body;
            }
          });
          
        });
      }
      let data = getStripeDetails && getStripeDetails?.data[0];
      const getFormData: any = {
        bankName: data?.bankName,
        accountHolderName: data?.accountHolderName,
        accountNumber: data?.accountNumber
          ? "**********" + data?.accountNumber
          : "",
        routingNumber: data?.routingNumber,
        taxID: data?.taxID,
        businessWebsite: data?.businessWebsite,
        payOutFrequency: data?.payOutFrequency,
        paymentDate: data?.paymentDate,
        // paymentDay: data?.paymentDay,
      };
      methods.reset(getFormData);
    }
  }, [getStripeDetails && getStripeDetails?.data[0], methods]);

  const [create, { isLoading, error }] = useCreate();

  // form submit handler
  const onSubmit = (value) => {
    const sendData = {
      ...value,
      subscriberID: switchSubscriberObj?.value,
      ID: id,
      email: state?.email[0],
      stripeFor: state?.type === "Office" ? "office" : "property",
      accountNumber: value?.accountNumber?.replace(/\*/g, ""),
      paymentDate:(value?.payOutFrequency?.value === "Monthly")?(
        value?.paymentDate
        ? value?.paymentDate
        : "2"):((value?.payOutFrequency?.value === "Weekly")?(value?.paymentDate?.value.toLowerCase()):"2"),
        // paymentDay: (value?.paymentDay?.value),
    };
    create(
      `updateStripeAccount`,
      { data: sendData },
      {
        onSuccess: (data) => {
          notify(
            state?.isAdding
              ? `Stripe Account created successfully.`
              : `Stripe Account updated successfully.`,
            {
              type: "success",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            }
          );
          refresh();
          methods?.reset();
          navigate(-1);
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    );
  };
const payOutFrequencyWatch = methods?.watch("payOutFrequency");
useEffect(()=>{
  if(payOutFrequencyWatch?.value === "Monthly" && typeof methods?.watch("paymentDate") === "object"){methods?.setValue("paymentDate","")};
},[payOutFrequencyWatch])
  // useEffect(() => {
  //   document.title = state?.isAdding
  //     ? "Property Setup - Property Details - Stripe Account"
  //     : "Property Setup - Property Details - Stripe Account";
  // }, []);

  return (
    <Fragment>
      <div className="home_content">
        <div className="row">
          <div className="col-12 d-flex">
            <Breadcrumbs aria-label="breadcrumb">
              <Typography sx={{ fontWeight: "bold" }}>
                <button
                  title="Go Back"
                  style={{ color: "#2cc0e4" }}
                  className="btn btn-link"
                  onClick={() => navigate(-1)}
                >
                  <ArrowBack />
                </button>
                <span>Stripe Account</span>
              </Typography>
            </Breadcrumbs>
          </div>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} className="mr-t30">
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputField2
                  label="Bank Name"
                  source={`bankName`}
                  placeholder="Enter Bank Name"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputField2
                  label="Account Holder Name"
                  source={`accountHolderName`}
                  placeholder="Enter Account Holder Name"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputField2
                  label="Account Number"
                  source={`accountNumber`}
                  placeholder="Enter Account Number"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                  handleOnChange={(event) => {
                    // setting state for handling a condition based on value change
                    setCheckEventOccurs(true);
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputField2
                  label="Routing Number"
                  source={`routingNumber`}
                  type="number"
                  placeholder="Enter Routing Number"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <div className="emp-inputs form-label">
                  <InputField2
                    label="Tax ID"
                    source={`taxID`}
                    type="number"
                    placeholder="Enter Tax ID"
                    labelClass={"lable-title"}
                    additionalInputClass={"cus-control"}
                  />
                </div>
              </div>

              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputField2
                  label="Business Website"
                  source={`businessWebsite`}
                  placeholder="Enter Business Website"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputSelectField
                  label="Pay Out Frequency"
                  source={`payOutFrequency`}
                  options={payOutFrequencyData?.map((item) => ({
                    value: item?.value,
                    label: item?.label,
                  }))}
                  labelAsteriskClass={"color-red"}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                  {(methods?.watch("payOutFrequency")?.value) === "Monthly" && <InputField2
                            source={`paymentDate`}
                            placeholder="Enter Payment Date"
                            type="number"
                    label="Day of the Month"
                    labelClass={"lable-title"}
                  />}
                  {(methods?.watch("payOutFrequency")?.value) === "Weekly" && <InputSelectField
                          label="Payment Day"
                          source={`paymentDate`}
                          options={paymentDayData?.map((item) => ({
                            value: item?.value,
                            label: item?.label,
                          }))}
                          labelAsteriskClass={"color-red"}
                          labelClass={"lable-title"}
                          requiredClass={{
                            fontSize: "70%",
                          }}
                        /> }
                </div>
            </div>

            <Box
              sx={{
                height: "60px",
                background: "#F5FDFF",
                boxShadow: "0px -1px 12px 0px rgba(0, 0, 0, 0.12)",
              }}
              display={"flex"}
              justifyContent={"end"}
              alignItems={"center"}
              className="mt-5"
            >
              <Box>
                <Button
                  sx={{ mx: 1 }}
                  onClick={() => {
                    refresh();
                    methods?.reset();
                    navigate(-1);
                  }}
                  variant="outlined"
                  color="secondary"
                  type="button"
                >
                  Cancel
                </Button>
                <Button
                  startIcon={
                    state?.isAdding ? (
                      <CheckIcon />
                    ) : (
                      <img src={save_icon} alt="save icon" />
                    )
                  }
                  sx={{ mx: 1 }}
                  variant="contained"
                  type="submit"
                  disabled={isLoading}
                >
                  {state?.isAdding ? `Create Stripe Account` : `Update`}
                </Button>
              </Box>
            </Box>
          </form>
        </FormProvider>
      </div>
    </Fragment>
  );
};

export default UpdateStripeAccount;
