import { createSlice } from "@reduxjs/toolkit";
interface users {
  appInvitationType: string | null | number;
}

const initialState: users = {
  appInvitationType: null,
};
const appInvitationTypeSlice = createSlice({
  name: "invitationType",
  initialState,
  reducers: {
    setAppInvitationType: (state, action) => {
      state.appInvitationType = action.payload;
    },
  },
});

export const { setAppInvitationType } = appInvitationTypeSlice.actions;
export default appInvitationTypeSlice.reducer;
