import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/DocuVerusLogoorg.png";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import InputField2 from "../../componets/common/InputComponents/InputField2";
import {
  Button,
  useCreate,
  useNotify,
  useRefresh,
  useGetList,
} from "react-admin";
import { CircularProgress } from "@mui/material";
import "../../assets/styles/style.css";
import { useParams, useNavigate, Link } from "react-router-dom";

const ResetPassword = (props) => {
  const notify = useNotify();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(" ");
  const [create, { isLoading, error }] = useCreate();
  const { id } = useParams();
  const param = useParams();
  const [style, setStyle] = useState({ color: "red", percentage: "0%" });

  const schema = yup.object().shape({
    password: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Use 8 or more characters with a mix of letters, numbers & symbols"
      )
      .required("required"),
    re_enter_password: yup
      .string()
      .oneOf([yup.ref("password"), null], "password must match")
      .required("required"),
  });

  // Initial form values
  const defaultFormValues = {
    password: "",
    re_enter_password: "",
  };
  const methods = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  useEffect(() => {
    document.title = "Docuverus";
  }, []);

  const onSubmit = (data) => {
    const sendData = {
      uid: id,
      password: data?.password,
    };
    create(
      "resetPassword",
      { data: sendData },
      {
        onSuccess: (data) => {
          notify(`${data?.message}`, {
            type: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          setErrorMessage("");
          methods?.reset();
        },
        onError: (e: any) => { 
          // notify(`Operation fail:${e.message}`, {
          //   type: "error",
          //   anchorOrigin: { vertical: "top", horizontal: "right" },
          // });
          setErrorMessage(`${e.message}`);
      },
    }
    );
  };

  return (
    <>
      <section className="login-wrap">
        <div className="login-cell">
          <div className="login-block">
            <div className="l-logo-block text-center">
              <img src={Logo} alt="Docuverus" title="Docuverus" />
            </div>
            <br/>
          {errorMessage && <p style={{color:"red",textAlign:'center',fontSize:'12px',fontWeight:'bold',opacity:'10px'}}>{errorMessage}</p>}

            <div className="auth-form mr-t30">
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <div className="form-group cus-input-group mr-b20">
                    <InputField2
                      label="Password"
                      source={`password`}
                      type="password"
                      placeholder="Enter Password here"
                      labelClass={"lable-title"}
                      additionalInputClass={"cus-control"}
                      handleOnChange={(e) => {
                        const strongRegex = new RegExp(
                          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
                        );
                        const mediumRegex = new RegExp(
                          "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
                        );
                        if (strongRegex.test(e.target.value)) {
                          setStyle({ color: "green", percentage: "100%" });
                        } else if (mediumRegex.test(e.target.value)) {
                          setStyle({ color: "yellow", percentage: "70%" });
                        } else {
                          const percentage = (e.target.value.length / 8) * 40;
                          setStyle({
                            color: "red",
                            percentage: percentage + "%",
                          });
                        }
                      }}
                    />
                    {methods.watch("password") !== "" && (
                      <div className="pass-progress mr-t10">
                        <div
                          className="pass-progress-bar"
                          style={{
                            width: style.percentage,
                            background: style.color,
                          }}
                        ></div>
                      </div>
                    )}
                    {methods?.formState?.errors?.password?.message && (
                      <div className="pass-msg-box">
                        <h5>
                          <span>Weak</span> -{" "}
                          {`${methods?.formState?.errors?.password?.message}`}
                        </h5>
                      </div>
                    )}
                  </div>

                  <div className="form-group cus-input-group mr-b20">
                    <InputField2
                      label="Re-Enter Password"
                      source={`re_enter_password`}
                      type="password"
                      placeholder="Re-Enter Password here"
                      labelClass={"lable-title"}
                      additionalInputClass={"cus-control"}
                    />
                  </div>

                  {isLoading ? (
                    <div className="static-d-info d-flex justify-content-center align-items-center mw-100">
                      <CircularProgress size="20px" />
                      <h5>&nbsp; &nbsp; Please wait...</h5>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center align-items-center">
                      <button type="submit" className="l-btn-forgetPsd">
                        Change Password
                      </button>
                    </div>
                  )}
                </form>
                <br />
                <div className="w-full p-t-3 p-b-5 text-right">
                  <Link to="/" className="txt1" style={{ color: "5555FF" }}>
                    Back to Login
                  </Link>
                </div>
              </FormProvider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ResetPassword;
