import React from "react";
import { ATS_API_URL } from "../../config";

const DownloadSampleButton = ({ downloadType }) => {
  return (
    <button
      type="button"
      className="blue-btn px-3 bg-transparent shadow-none"
      style={{ color: "#2DC3E8" }}
      onClick={() =>
        window.open(
          `${ATS_API_URL}/subscriber/donwload-sample-xls?sampleType=${downloadType}`
        )
      }
    >
      Download Sample
    </button>
  );
};

export default DownloadSampleButton;
