import React, { Fragment, useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate } from "react-router";
import { ArrowBack } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import InputField2 from "../../common/InputComponents/InputField2";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import ToggleButtons from "../../common/InputComponents/ToggleButtons";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Button } from "@mui/material";
import save_icon from "../../../assets/images/save_icon.svg";
import TextEditor from "../../common/InputComponents/TextEditor";

const SubscriberTemplateDetails = () => {
  const navigate = useNavigate();
  const [docuverusAccordion, setDocuverusAccordion] = React.useState(false);
  const [propertyManagementAccordion, setPropertyManagementAccordion] =
    React.useState(false);

  const toggleButton = [
    {
      value: "Inactive",
      label: "Inactive",
      icon: <ClearIcon />,
      borderColor: "#909090",
      backgroundColor: "#EEE",
      boxShadowColor: "#90909033",
      color: "#606060",
    },
    {
      value: "Active",
      label: "Active",
      icon: <CheckIcon />,
      borderColor: "#46A96F",
      backgroundColor: "#EBFFF3",
      boxShadowColor: "#46a96f1a",
      color: "#46A96F",
    },
  ];

  const schema = yup.object().shape({
    template_task: yup.string().required("required"),
    send_to: yup.string().required("required"),
    status: yup.string().required("required"),
    subject: yup.string().required("required"),
    body: yup.string().required("required"),
  });

  // Initial form values
  const defaultFormValues = {
    template_task: "",
    send_to: "",
    status: "",
    subject: "",
    body: "",
  };

  const methods = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  useEffect(() => {
    document.title = "Template Details";
  }, []);

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <Fragment>
      <div className="home_content">
        <div className="row">
          <div className="col-12 d-flex">
            <Breadcrumbs aria-label="breadcrumb">
              <Typography sx={{ fontWeight: "bold" }}>
                <button
                  title="Go Back"
                  style={{ color: "#2cc0e4" }}
                  className="btn btn-link"
                  onClick={() => navigate("/ats/emailSettings")}
                >
                  <ArrowBack />
                </button>
                {/* <span>Email Settings &gt; Template Details</span> */}
                <span>Template Details</span>
              </Typography>
            </Breadcrumbs>
          </div>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} className="mr-t30">
            <div className="row">
              <div className="col-sm-12 col-md-3 col-lg-3 mr-b20">
                <InputField2
                  label="Template Task"
                  source={`template_task`}
                  placeholder="Email sent to applicant upon app completion"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                />
              </div>
              <div className="col-sm-12 col-md-3 col-lg-3 mr-b20">
                <InputField2
                  label="Send to"
                  source={`send_to`}
                  placeholder="Applicant"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                />
              </div>
            </div>
            <div className="info-toggle mb-3">
              <div
                className="toggle-heads change_color"
                onClick={(event) => {
                  event.preventDefault();
                  setDocuverusAccordion((v) => !v);
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                <div className="row align-items-center justify-content-between ">
                  <div className="col">
                    <div className="d-flex align-items-center">
                      <b className="ms-3 me-2">Docuverus</b>
                    </div>
                  </div>

                  <div className="col text-end m-1 remove_mr">
                    <ToggleButtons
                      toggleButton={toggleButton}
                      label=""
                      labelClass={"lable-title"}
                      source={`status`}
                      onClickHandler={(event) => event.stopPropagation()}
                    />
                  </div>
                </div>

                <div
                  className={` ${
                    !docuverusAccordion ? "d-none" : "d-display"
                  } card-body cust-card-appl`}
                >
                  <div
                    className="card card-body w-100"
                    style={{ paddingTop: 0 }}
                  >
                    <div className="row">
                      <div className="col-sm-12 mr-b20 mr-t20">
                        <InputField2
                          label="Subject"
                          source={`subject`}
                          placeholder="Applicant {applicant.name} has completed the application"
                          labelClass={"lable-title"}
                          additionalInputClass={"cus-control"}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <TextEditor
                          label="Body"
                          source={`body`}
                          labelClass={"lable-title"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="info-toggle mb-4">
              <div
                className="toggle-heads change_color"
                onClick={(event) => {
                  event.preventDefault();
                  setPropertyManagementAccordion((v) => !v);
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                <div className="row align-items-center justify-content-between ">
                  <div className="col">
                    <div className="d-flex align-items-center">
                      <b className="ms-3 me-2">Property Management Company</b>
                    </div>
                  </div>

                  <div className="col text-end m-1 remove_mr">
                    <ToggleButtons
                      toggleButton={toggleButton}
                      label=""
                      labelClass={"lable-title"}
                      source={`status`}
                      onClickHandler={(event) => event.stopPropagation()}
                    />
                  </div>
                </div>

                <div
                  className={` ${
                    !propertyManagementAccordion ? "d-none" : "d-display"
                  } card-body cust-card-appl`}
                >
                  <div
                    className="card card-body w-100"
                    style={{ paddingTop: 0 }}
                  >
                    <div className="row">
                      <div className="col-sm-12 mr-b20 mr-t20">
                        <InputField2
                          label="Subject"
                          source={`subject`}
                          placeholder="Applicant {applicant.name} has completed the application"
                          labelClass={"lable-title"}
                          additionalInputClass={"cus-control"}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <TextEditor
                          label="Body"
                          source={`body`}
                          labelClass={"lable-title"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Box
              sx={{
                height: "60px",
                background: "#F5FDFF",
                boxShadow: "0px -1px 12px 0px rgba(0, 0, 0, 0.12)",
              }}
              display={"flex"}
              justifyContent={"end"}
              alignItems={"center"}
            >
              <Box>
                <Button
                  sx={{ mx: 1 }}
                  onClick={() => {
                    methods?.reset();
                  }}
                  variant="outlined"
                  color="secondary"
                  type="button"
                >
                  Cancel
                </Button>
                <Button
                  startIcon={<img src={save_icon} alt="save icon" />}
                  sx={{ mx: 1 }}
                  variant="contained"
                  type="submit"
                  // disabled={isLoading}
                >
                  Update
                </Button>
              </Box>
            </Box>
          </form>
        </FormProvider>
      </div>
    </Fragment>
  );
};

export default SubscriberTemplateDetails;
