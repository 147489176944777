import React, { useEffect } from 'react'
import {
  useParams,
  useNavigate
} from "react-router-dom";
import { Resource, useStore } from 'react-admin';
// import { GetsubscriberinboxList } from '../../componets/Ats/GetsubscriberinboxList';
import ApplicantDetailEdit from '../../componets/Ats/ApplicantDetailEdit';
import { GetsubscriberinboxList } from '../../componets/Ats/SubscriberInbox/GetsubscriberinboxList';

export default function Inbox() {
  return (
    <Resource name='getsubscriberinbox' list={<GetsubscriberinboxList />} />
  )
}
