import React, { useState, useEffect, useRef, createContext } from "react";
import "../../pages/Sidebar/sidebar.css";
import "../../assets/styles/application_det.css";
import "../../assets/styles/style.css";
import {
  useCreate,
  useGetOne,
  useGetList,
  Confirm,
  useGetIdentity,
} from "react-admin";
import SaveIcon from "@mui/icons-material/Save";
import DialogContent from "@mui/material/DialogContent";

import AddIcon from "@mui/icons-material/Add";
import DialogTitle from "@mui/material/DialogTitle";
import ApplicantDetailEdit from "../Ats/ApplicantDetailEdit";
// import { RxCross2 } from "react-icons/rx";
import DialogActions from "@mui/material/DialogActions";
import { FiMoreVertical, FiX } from "react-icons/fi";
import { GetdeleteddocumentlistList } from "../DocuploadTableComponent/GetDeletedDocumentList";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import * as Yup from "yup";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { yupResolver } from "@hookform/resolvers/yup";
import ApplicationHeader from "./ApplicationHeader";
import SelectInput from "./components/inputComponents/SelectInput";
import ProofOfId from "./ProofOfId";
import NameChangeModal from "./Modals/NameChangeModal";
import ProofOfNonEmploymentSection from "./ProofOfNonEmploymentIncome";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import BankStatements from "./BankStatements";
import OtherDocuments from "./OtherDocuments";
import ProofOfEmploymentIncome from "./ProofOfEmploymentIncome";
import BottomNavigation from "@mui/material/BottomNavigation";
import pencilIcon from "../../assets/images/pencilIcon.svg";
import Box from "@mui/material/Box";
import { useNotify, useDataProvider, Resource } from "react-admin";
import moment from "moment";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import AlertDialogTable from "../DocuploadTableComponent/AlertDialogTable";
import { RootState } from "../../Store/store";
import { useDispatch, useSelector } from "react-redux";
import PersonalDetailsAccordion from "./PersonalDetailsAccordion/PersonalDetailsAccordion";
import AddressAccordion from "./AddressAccordion/AddressAccordion";
import ConsentPaymentAccordion from "./ConsentPaymentAccordion/ConsentPaymentAccordion";
import ApplicantDetailsAccordion from "./ApplicantDetailsAccordion/ApplicantDetailsAccordion";
import { JSONTree } from "react-json-tree";
import SsnAccordion from "./SsnAccordion/SsnAccordion";
import { setApplicationStatus } from "../../Store/Slice/usersSlice";
import img1 from "../../assets/images/view_docs_icon.svg";

import Frame from "../../assets/images/Frame.svg";
import PrintIcon from "@mui/icons-material/Print";
import { BiSolidFileArchive, BiSolidFilePdf } from "react-icons/bi";
import { ATS_API_URL } from "../../config";
import { FaBook } from "react-icons/fa";
import { dataProvidersAts } from "../../dataproviders/DataProvidersAts";
import { toast, ToastContainer } from "react-toastify";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  FormLabel,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Backdrop,
  Typography,
  Button as MuiButton,
} from "@mui/material";
import ShowNotesModal from "./Modals/ShowNotesModal";
import GrayStripeBar from "../common/GrayStripeBar";
import { RiDeleteBinLine } from "react-icons/ri";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DateComponent from "../Ats/DateComponent";
import AuditlogModal from "./Modals/AuditlogModal";

interface RefObject {
  handleClickByRef: () => void;
  handleSubmitManual: () => void;
}

type DocumentAnalysisShowProps = {
  toggleDetails?: boolean;
  record?: any;
};
export const ApplicantContext = createContext<any>(null);
const DocumentAnalysisShow = ({
  toggleDetails,
  record,
}: DocumentAnalysisShowProps) => {

  const exportRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const navigation = useNavigate();
  // Fix the co-applicant issue
  // const trn_id = record.Trnid; //useSelector((state: RootState) => state.users.userTrnId);
  const userTrnId = useSelector((state: RootState) => state.users.userTrnId);
  const trn_id = record?.Trnid || userTrnId;
  const applicant_id = record?.id;

  const [applicantInfo, setApplicantInfo] = useState({
    trnId: trn_id,
    applicantId: applicant_id,
  });
  const isOpeningForDashboard = useSelector(
    (state: RootState) => state.app.isOpeningForDashboard
  );
  const appHeaderRequired = useSelector(
    (state: RootState) => state.app.appHeader
  );

  const appFooterRequired = useSelector(
    (state: RootState) => state.app.appFooter
  );
  const [isLoadingViewApp, setIsLoading] = useState(false);
  // const [buttonIsDisabled, setButtonIsDisabled] = useState(false);
  const isGuestCard = useSelector((state: RootState) => state.app.isGuestCard);
  const userRole = useSelector((state: RootState) => state.users.role);
  const poidRef = useRef<RefObject>(null);
  const poincome = useRef<RefObject>(null);
  const po_other_income = useRef<RefObject>(null);
  const po_banking = useRef<RefObject>(null);
  const [zipDownload, setZipDownload] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [grossMonthlyPay, setGrossMonthlyPay] = useState(0);

  const handleGrossvalue = (value) => {
    setGrossMonthlyPay(value);
  };

  const [noteText, setNoteText] = useState("");
  // name chage modal open state
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [deleteddocs, setdeleteddocs] = useState(false);
  const [value, setValue] = useState(false);

  const handleopendeleteddocs = () => {
    setdeleteddocs(true);
  };
  const handleclosedeleteddocs = () => {
    setdeleteddocs(false);
  };
  const dispatch = useDispatch();
  // const [openNotesDialog, setopenNotesDialog] = useState(false);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // const handleOpenNotes = () => {
  //   setopenNotesDialog(true);
  //   handleCloseMenu();
  // };

  // const handleCloseNotes = () => {
  //   setopenNotesDialog(false);
  //   setNoteText(" ");
  // };

  // get application type
  const { id, appType, roletype } = useParams();
  const schema = Yup.object({
    application_status: Yup.string().required("required").typeError("required"),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    // defaultValues: {
    //   application_status: "1",
    // },
  });

  const notify = useNotify();

  const dataProvider = useDataProvider();
  const [applicantDetails, setApplicantDetails] = React.useState<any>({
    application_id: null,
    application_status: null,
    creataed_on: null,
    email: null,
    first_name: null,
    last_name: null,
    middle_name: null,
    mobile: null,
    country_code: null,
    office_property_city: null,
    office_property_name: null,
  });

  const [subscriberData, setSubscriberData] = useState({
    CompanyName: null,
    StreetAddress: null,
    State: null,
    City: null,
    Email: null,
    NumberOfCoApplicant: null,
    LookBackPeriod: null,
  });

  // state for set a flag if data is enable/null of third Party Integration.
  const [isThirdPartyIntegration, setIsThirdPartyIntegration] = useState(false);

  useEffect(() => {
    if (applicantDetails?.archived == true) {
      navigation("/admin/archieved", { replace: true });
    }
  }, [applicantDetails]);
  const { data: proofOfIdsData, isLoading: proofOfIdsDataLoading } = useGetOne(
    "proofOfIdsData",
    { id: trn_id },
    { enabled: trn_id != null, refetchOnWindowFocus: false }
  );

  const IncomeAPIResponse = useGetOne(
    "income",
    { id: trn_id },
    { enabled: !isGuestCard && trn_id != null, refetchOnWindowFocus: false }
  );
  const { data: bankData, isLoading: bankDocLoading } = useGetOne(
    "bankDoc",
    { id: trn_id },
    { enabled: !isGuestCard && trn_id != null, refetchOnWindowFocus: false }
  );
  const { data: otherIncomeData, isLoading: otherIncomeDocLoding } = useGetOne(
    "otherIncomeDoc",
    { id: trn_id },
    { enabled: !isGuestCard && trn_id != null, refetchOnWindowFocus: false }
  );
  const sectionPermission = useGetOne(
    "getSubscriberProfileSection_new",
    { id: trn_id },
    { refetchOnWindowFocus: false, enabled: !!trn_id }
  );
  const { data: getDownloadDocumentsMessage } = useGetOne(
    "getDownloadDocumentsMessage",
    { id: trn_id },
    { enabled: trn_id != null, refetchOnWindowFocus: false }
  );

  // get get-subscriber-configurations Data
  const {
    data: getSubscriberConfigurations,
    isLoading: isLoadingSubscriberConfigurations,
  } = useGetOne(
    "get-subscriber-configurations",
    {
      id: id,
    },
    {
      refetchOnWindowFocus: false,
      enabled: id ? true : false,
    }
  );

  const {
    data: applicantData,
    isLoading: loading,
    refetch,
  } = useGetOne(
    "getApplicantDetails",
    { id: trn_id },
    {
      onSuccess: (value) => {
        setApplicantDetails(value?.Application_details);
        // methods.setValue(
        //   "application_status",
        //   value?.Application_details?.application_status
        // );
        setSubscriberData(value?.data?.subscriber_details);
        dispatch(
          setApplicationStatus(value?.Application_details?.application_status)
        ); // storing a application_status for global used
      },
      onError: (err) => {
        notify(`Error: ${err}`, {
          type: "warning",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
      refetchOnWindowFocus: false,
      enabled: trn_id != null,
    }
  );

  const { data: getlookbacks } = useGetOne(
    "getlookbacks",
    { id: record?.AppID },
    { enabled: record?.AppID != null, refetchOnWindowFocus: false }
  );
  useEffect(() => {
    if (zipDownload) {
      setTimeout(() => {
        notify("Downloading documents...", {
          type: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      });
    }
  }, [zipDownload]);

  const downloadZip = async (id) => {
    setZipDownload(true);
    // showToastMessage();
    const res = await dataProvidersAts.downloadZip(id);
    // if(res)
    // {
    //   toast.success("downloading documents......", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // }
    setZipDownload(false);
  };
  const location = useLocation();
  const params = useParams();
  const getlocalStorageItem: any = localStorage.getItem(
    "stateRecord" + params?.applicationId
  );
  let locationState = JSON.parse(getlocalStorageItem);
  const data = location.state || locationState;

  const [create] = useCreate();
  const onClickScreeningReport = async (event, data) => {
    create(
      "getscreeningreport",
      {
        data: {
          applicantID: data?.ApplicantID,
          subscriberId: data?.SubscriberID,
        },
      },
      {
        onSuccess: (data) => {
          notify(`Screening report opened successfully.`, {
            type: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    );
  };
  const handleApplicantSubmit = async (id) => {
    const final_paylaod = {
      trn_id: id,
      role: "applicant",
      status: "5",
      subUrl: "/document/updateApplicationStatus",
      office_property_name: record?.Property,
    };
    try {
      await dataProvider.update("other_sections", {
        id: id,
        data: final_paylaod,
        previousData: proofOfIdsData,
      });
      navigation("/admin/success", { replace: true });
      notify(`Application submitted Successfully`, {
        type: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    } catch (e) {
      notify(`Error: fail submitting application details`, {
        type: "warning",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    } finally {
      // setLoading(false);
    }
  };
  const [updateApplicant, updateApplicantStatus] = useCreate();

  const handle_Admin_Submit = async (values) => {
   
    const final_paylaod = {
      trn_id: trn_id,
      role: userRole,
      status: values.application_status,
      office_property_name: record?.Property,
      grossMonthlyPay:grossMonthlyPay
    };

    console.log("final_paylaod",final_paylaod)

    updateApplicant(
      "updateApplicationStatus",
      {
        data: final_paylaod,
      },
      {
        onSuccess: (data) => {
          notify(data?.records, {
            type: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          refetch();
          console.log("values?.application_status",values?.application_status)
          if (
            values?.application_status == "13" ||
            values?.application_status == "14" ||
            values?.application_status == "15"
          ) {
            console.log("test 5")
            if (isThirdPartyIntegration) {
              console.log("isThirdPartyIntegration",isThirdPartyIntegration)
              handledsendLeaseDocument();
            }
          }
        },
        onError: (error: any) => {
          notify(`${error.message}`, {
            type: "warning",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        },
      }
    );
  };

  const handledsendLeaseDocument = () => {
    const authJson = localStorage.getItem("auth") || "";
    const url = `${window.location.protocol}//${
      window.location.host
    }/admin/docupload_admin/Office/11/TestRealEstateCompany-Philadelphia/NuEF07JC8UuAluc0k-r7Yg/${
      trn_id + `?auth=` + encodeURIComponent(authJson)
    }`;

    updateApplicant(
      "sendLeaseDocument",
      {
        data: {
          trn_id: trn_id,
          url: url,
        },
      },
      {
        onSuccess: (data) => {},
        onError: (error: any) => {},
      }
    );
  };

  // Function to determine the flag value
  const hasAnyStatusOne = (objects) => {
    // Use the some method for concise and efficient checking
    return objects ? objects?.some((object) => object?.status === 1) : false;
  };

  useEffect(() => {
    if (getSubscriberConfigurations?.records) {
      let thirdPartyIntegration;
      if (
        typeof getSubscriberConfigurations?.records?.ThirdPartyIntegration !==
        "object"
      ) {
        thirdPartyIntegration = JSON?.parse(
          getSubscriberConfigurations?.records?.ThirdPartyIntegration
        );
        console.log("test 1")
      } else {
        thirdPartyIntegration =
          getSubscriberConfigurations?.records?.ThirdPartyIntegration;
          console.log("test 2")
      }
      console.log("thirdPartyIntegration", getSubscriberConfigurations?.records)
      console.log("hasAnyStatusOne(thirdPartyIntegration)", hasAnyStatusOne(thirdPartyIntegration))
      setIsThirdPartyIntegration(hasAnyStatusOne(thirdPartyIntegration));
    }
  }, [getSubscriberConfigurations?.records]);

  // storaging trn id in session storage.
  React.useEffect(() => {
    if (id) {
      sessionStorage.setItem("trn_id", JSON.stringify(id));
    }
  }, [id]);

  const onClickDocsHandler = async (event, applicantData) => {
    // get getalldocs list data
    const getalldocs = await dataProvider.getOne("getalldocs", {
      id: record?.id,
    });
    navigation("/ats/docview", {
      state: { getalldocs: getalldocs?.data?.data, record },
    });
  };
  var proof_of_id_section: any = null;
  var proof_of_income_section: any = null;
  var other_income_section: any = null;
  var bank_section: any = null;
  const [userCloseBrowser, setUserCloseBrowser] = useState(false);
  const submitButnRef = React.useRef<HTMLButtonElement>(null);
  const userrole: any = useGetIdentity();
  const role: any = useSelector((state: RootState) => state.users.role);
  const handleBeforeUnload = (event) => {
    // debugger;
    if (role == "Applicant" || userrole?.identity?.userrole == "Applicant") {
      const confirmationMessage =
        "You have unsaved changes. Do you want to save them before leaving?";
      event.returnValue = confirmationMessage;
      setUserCloseBrowser(true);
      return confirmationMessage;
    }
  };
  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  const launchApplication = ({ username }) => {
    // debugger;
    create(
      "loginFromAdminDashboard",
      {
        data: {
          email: username,
        },
      },
      {
        onSuccess: (data) => {
          window.open(data?.records?.launchUrl, "_blank");
          // notify(`Screening report opened successfully.`, {
          //   type: "success",
          //   anchorOrigin: { vertical: "top", horizontal: "right" },
          // });
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    );
  };
  const launchApplicationInvited = ({ applicantid }) => {
    // debugger;
    create(
      "loginFromAdminDashboardInvitedApp",
      {
        data: {
          applicantid: applicantid,
        },
      },
      {
        onSuccess: (data) => {
          // debugger;
          window.open(data?.records?.launchUrl, "_blank");
          // notify(`Screening report opened successfully.`, {
          //   type: "success",
          //   anchorOrigin: { vertical: "top", horizontal: "right" },
          // });
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    );
  };

  if (
    IncomeAPIResponse?.isLoading &&
    bankDocLoading &&
    otherIncomeDocLoding &&
    proofOfIdsDataLoading &&
    proofOfIdsDataLoading &&
    isLoadingSubscriberConfigurations &&
    sectionPermission.isLoading
  ) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={3}>
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
            <h2>&nbsp; Loading...</h2>
          </Box>
        </Grid>
      </Grid>
    );
  }

  if (sectionPermission?.data?.status[3].state == 2 && proofOfIdsData) {
    proof_of_id_section = (
      <ProofOfId
        record={proofOfIdsData}
        ref={poidRef}
        status={sectionPermission?.data?.status[3].state}
        required={false}
      />
    );
  } else if (sectionPermission?.data?.status[3].state == 1 && proofOfIdsData) {
    proof_of_id_section = (
      <ProofOfId
        record={proofOfIdsData}
        ref={poidRef}
        required={true}
        status={sectionPermission?.data?.status[3].state}
      />
    );
  }

  if (sectionPermission?.data?.status[0].state == 2 && IncomeAPIResponse.data) {
    proof_of_income_section = (
      <ProofOfEmploymentIncome
        role={userRole}
        ref={poincome}
        status={sectionPermission?.data?.status[0].state}
        IncomeAPIResponse={IncomeAPIResponse}
        LookbacksData={getlookbacks?.data}
        required={false}
        grossValue={handleGrossvalue}
      />
    );
  } else if (
    sectionPermission?.data?.status[0].state == 1 &&
    IncomeAPIResponse.data
  ) {
    proof_of_income_section = (
      <ProofOfEmploymentIncome
        role={userRole}
        IncomeAPIResponse={IncomeAPIResponse}
        LookbacksData={getlookbacks?.data}
        ref={poincome}
        required={true}
        status={sectionPermission?.data?.status[0].state}
        grossValue={handleGrossvalue}
      />
    );
  }

  if (
    sectionPermission?.data?.status[2].state == 2 &&
    otherIncomeData?.other_income_details
  ) {
    other_income_section = (
      <ProofOfNonEmploymentSection
        record={otherIncomeData?.other_income_details}
        ref={po_other_income}
        status={sectionPermission?.data?.status[2].state}
        required={false}
        trn_id={otherIncomeData.trn_id}
      />
    );
  } else if (
    sectionPermission?.data?.status[2].state == 1 &&
    otherIncomeData?.other_income_details
  ) {
    other_income_section = (
      <ProofOfNonEmploymentSection
        record={otherIncomeData?.other_income_details}
        ref={po_other_income}
        trn_id={otherIncomeData.trn_id}
        required={true}
        status={sectionPermission?.data?.status[2].state}
      />
    );
  }

  if (
    sectionPermission?.data?.status[1].state == 2 &&
    bankData?.banking_details
  ) {
    bank_section = (
      <BankStatements
        record={bankData?.banking_details}
        trn_id={bankData.trn_id}
        ref={po_banking}
        required={false}
        status={sectionPermission?.data?.status[1].state}
      />
    );
  } else if (
    sectionPermission?.data?.status[2].state == 1 &&
    bankData?.banking_details
  ) {
    bank_section = (
      <BankStatements
        record={bankData?.banking_details}
        trn_id={bankData.trn_id}
        ref={po_banking}
        required={true}
        status={sectionPermission?.data?.status[1].state}
      />
    );
  }

  const onClickViewAppHandler = async (event) => {
    const payloadData = {
      applicationNumber: record?.AppID,
      applicantID: record?.id,
    };
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    // get viewappreport list data
    const viewAppReport = await dataProvider.getOne("viewappreport", {
      id: payloadData,
    });
    window.open(`${viewAppReport?.data?.data}`, "_blank");
  };
  const handleReview = async () => {
    create(
      "updatereviewdone",
      {
        data: {
          applicationnumber: record?.AppID,
        },
      },
      {
        onSuccess: (data) => {
          // queryClient.invalidateQueries(["bankDoc"]);
          notify(`Review done successfully.`, {
            type: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    );
  };
  return (
    <>
      <ApplicantContext.Provider value={{ applicantInfo, setApplicantInfo }}>
        {/*deleted docs button  */}
        <Dialog open={deleteddocs} onClose={handleclosedeleteddocs}>
          <DialogTitle
            sx={{ m: 0, p: 1, backgroundColor: "Gainsboro" }}
            id="customized-dialog-title"
          >
            Deleted documents
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleclosedeleteddocs}
            sx={{
              position: "absolute",
              right: 6,
              top: 1,

              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon style={{ fontSize: "12px" }} />
          </IconButton>
          <DialogContent onClick={handleopendeleteddocs}>
            <Resource
              name="deletedDocument"
              list={GetdeleteddocumentlistList}
            />
          </DialogContent>
        </Dialog>

        <section className="main-sec">
          {appHeaderRequired && (
            <ApplicationHeader
              role={userRole}
              subscriberId={proofOfIdsData?.applicant?.SubscriberID}
              subscriberData={subscriberData}
              applicantName={`${applicantDetails?.first_name} ${applicantDetails?.last_name}`}
            />
          )}
          <div ref={exportRef} className="basic-info">
            <div className="d-flex flex-wrap mb-2">
              <div className="basic-subinfo px-2">
                <div className="info-block">
                  <label htmlFor="">App ID.</label>
                </div>
                <span>
                  {applicantDetails?.application_id
                    ? applicantDetails?.application_id
                    : ""}
                </span>
              </div>
              <div className="basic-subinfo px-2">
                <div className="info-block">
                  <label htmlFor="">Trn ID</label>
                </div>
                <span>{trn_id ? trn_id : ""}</span>
              </div>
              <div className="basic-subinfo px-2">
                <div className="info-block">
                  <label htmlFor="">Name</label>
                  <button
                    className="print-hide"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#namechange"
                    onClick={handleOpen}
                  >
                    <img src={pencilIcon} alt="" />
                  </button>
                </div>
                <span
                  title={`${applicantDetails?.first_name} ${applicantDetails?.last_name}  `}
                  style={{
                    maxWidth: "150px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    display: "inline-block",
                  }}
                >
                  {`${applicantDetails?.first_name} ${applicantDetails?.last_name}  `}
                </span>
              </div>
              <div className="basic-subinfo px-2">
                <div className="info-block">
                  <label htmlFor="">Mobile Phone</label>
                  <button
                    className="print-hide"
                    type="button"
                    data-bs-toggle="modal"
                    // data-bs-target="#mobilechange"
                    data-bs-target="#namechange"
                    onClick={handleOpen}
                  >
                    <img src={pencilIcon} alt="" />
                  </button>
                </div>
                <span>
                  {applicantDetails?.mobile
                    ? applicantDetails?.country_code + applicantDetails?.mobile
                    : "NA"}
                </span>
              </div>
              <div className="basic-subinfo px-2">
                <div className="info-block">
                  <label htmlFor="">Email</label>
                  <button
                    className="print-hide"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#namechange"
                    onClick={handleOpen}
                  >
                    <img src={pencilIcon} alt="" />
                  </button>
                </div>
                <span
                  title={applicantDetails?.email}
                  style={{
                    maxWidth: "150px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    display: "inline-block",
                  }}
                >
                  {applicantDetails?.email}
                </span>
              </div>

              <div className="basic-subinfo px-2">
                <div className="info-block">
                  <label htmlFor="">Date</label>
                </div>
                <span>
                  {localStorage.getItem("createdDateAsPerClientRequest") ? (
                    localStorage.getItem("createdDateAsPerClientRequest")
                  ) : (
                    <DateComponent date={applicantDetails?.creataed_on} />
                  )}
                </span>
              </div>
              <div className="basic-subinfo px-2">
                <div className="info-block">
                  <label htmlFor="">Property/Office Name</label>
                </div>
                <span>
                  {applicantDetails?.office_property_name
                    ? applicantDetails?.office_property_name
                    : ""}
                </span>
              </div>
              <div className="basic-subinfo px-2">
                <div className="info-block">
                  <label htmlFor="">User Name</label>
                </div>

                <span
                  title={applicantDetails?.UserName}
                  style={{
                    maxWidth: "150px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    display: "inline-block",
                  }}
                >
                  {applicantDetails?.UserName
                    ? applicantDetails?.UserName
                    : "NA"}
                </span>
              </div>

              {applicantData?.Application_details?.application_type !==
                "Guest Card" &&
                (data?.ApplicationStatus === "CompletedConditionallyApproved" ||
                  data?.ApplicationStatus === "Screening" ||
                  data?.ApplicationStatus === "Verified" ||
                  data?.ApplicationStatus === "Unverifiable" ||
                  data?.ApplicationStatus ===
                    "Further Documentation Required" ||
                  data?.ApplicationStatus === "Completed-Approved" ||
                  data?.ApplicationStatus === "Completed-Unacceptable" ||
                  data?.ApplicationStatus === "Submitted" ||
                  data?.ApplicationStatus === "Review Pending") &&
                data?.ApplicationStatus !== "Invited" &&
                data?.ApplicationStatus !== "Cancelled" &&
                data?.ApplicationStatus !== "In Progress" &&
                data?.ApplicationStatus !== "Archieved" &&
                userRole !== "applicant" && (
                  <div className="basic-subinfo px-2">
                    <div className="modal-sm-button">
                      <button
                        onClick={() => {
                          const authJson = localStorage.getItem("auth") || "";
                          window.open(
                            `${ATS_API_URL}/applicant/document/printpdf?url=${
                              window.location.protocol
                            }//${
                              window.location.host
                            }/admin/docupload_admin/Office/11/TestRealEstateCompany-Philadelphia/NuEF07JC8UuAluc0k-r7Yg/${
                              trn_id +
                              `?auth=` +
                              encodeURIComponent(authJson) +
                              `&createdDateAsPerClientRequest=` +
                              moment(applicantDetails?.creataed_on).format(
                                "MM/DD/YYYY h:mm A z"
                              )
                            }`,
                            "_blank"
                          );
                        }}
                        type="button"
                        className="p-0 mt-0 print-hide"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        style={{ lineHeight: "22px" }}
                      >
                        <BiSolidFilePdf size={16} />
                        <br />
                        <span> View/Print Result</span>
                      </button>
                    </div>
                  </div>
                )}

              {applicantData?.Application_details?.application_type !=
                "Guest Card" &&
                userRole !== "applicant" &&
                getDownloadDocumentsMessage?.message ===
                  "Documents Uploaded" && (
                  <div className="basic-subinfo px-2">
                    <div className="modal-sm-button">
                      <button
                        type="button"
                        className="p-0 mt-0 print-hide"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() => {
                          downloadZip(record?.id || id);
                        }}
                        style={{ lineHeight: "22px" }}
                      >
                        <BiSolidFileArchive size={16} /> <br />
                        <span> Download Documents</span>
                      </button>
                    </div>
                  </div>
                )}

              {applicantData?.Application_details?.application_type !=
                "Doc Upload" && (
                <div className="basic-subinfo px-2 print-hide">
                  <div className="modal-sm-button">
                    <ShowNotesModal record={record} />
                  </div>
                </div>
              )}

              {applicantData?.Application_details?.application_type ==
                "Application Invite" &&
                userRole !== "applicant" &&
                (applicantData?.Application_details?.application_status == 14 ||
                  applicantData?.Application_details?.application_status ==
                    13 ||
                  applicantData?.Application_details?.application_status == 4 ||
                  applicantData?.Application_details?.application_status ==
                    15) && (
                  <div className="basic-subinfo px-2">
                    <div className="modal-sm-button">
                      <button
                        type="button"
                        className="p-0 mt-0"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={(event) => onClickScreeningReport(event, data)}
                        style={{ lineHeight: "22px" }}
                      >
                        {/* <LibraryBooksIcon size={16}/> <br /> */}
                        <FaBook size={16} /> <br />
                        <span>Screening Report</span>
                      </button>
                    </div>
                  </div>
                )}

              {record?.ApplicantDocs && (
                <div className="basic-subinfo px-2">
                  <div className="modal-sm-button">
                    <button
                      type="button"
                      className="p-0 mt-0"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      onClick={(event) =>
                        onClickDocsHandler(event, applicantData)
                      }
                      style={{ lineHeight: "22px" }}
                    >
                      <img src={img1} alt="" /> <br />
                      <span>View Docs</span>
                    </button>
                  </div>
                </div>
              )}

              {appType == "1" && (
                <>
                  {/* <JSONTree data={record}/> */}
                  {
                    // (data?.ApplicationStatus ===
                    //   "CompletedConditionallyApproved" ||
                    //   data?.ApplicationStatus === "Screening" ||
                    //   data?.ApplicationStatus === "Verified" ||
                    //   data?.ApplicationStatus === "Unverifiable" ||
                    //   data?.ApplicationStatus ===
                    //   "Further Documentation Required" ||
                    //   data?.ApplicationStatus === "Completed-Approved" ||
                    //   data?.ApplicationStatus === "Completed-Unacceptable" || data?.ApplicationStatus === "Submitted") &&
                    record?.ApplicantStatus !== "Cancelled" &&
                      record?.ApplicantStatus !== "Archieved" &&
                      record?.ApplicantStatus == "Submitted" &&
                      userRole !== "applicant" && (
                        <div className="basic-subinfo px-2">
                          <div className="modal-sm-button">
                            <button
                              type="button"
                              className="p-0 mt-0"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                              disabled={isLoadingViewApp}
                              onClick={(event) => onClickViewAppHandler(event)}
                              style={{ lineHeight: "22px" }}
                            >
                              {/* <img src={img1} alt="" /> <br /> */}

                              {isLoadingViewApp ? (
                                <Box sx={{ display: "flex" }}>
                                  <CircularProgress size="25px" />
                                </Box>
                              ) : (
                                <>
                                  <PictureAsPdfIcon /> <br />
                                  <span>View App</span>
                                </>
                              )}
                            </button>
                          </div>
                        </div>
                      )
                  }
                </>
              )}
              {userRole != "applicant" && (
                <div className="basic-subinfo px-2 print-hide">
                  <div className="modal-sm-button">
                    <AuditlogModal
                      record={{
                        AppID: applicantDetails?.application_id,
                        id,
                        trn_id: record?.Trnid,
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="info-toggle-wrap">
            <div className="subheaders">
              {proofOfIdsData?.applicant?.thirdPartyAppName != null && (
                <>
                  {userRole !== "applicant" && (
                    <div>
                      {proofOfIdsData?.applicant?.interactionType == "Received"
                        ? " Received from "
                        : " Sent to "}
                      {proofOfIdsData?.applicant?.thirdPartyAppName}
                    </div>
                  )}
                </>
              )}
              {appType == "1" && (
                <>
                  {" "}
                  {!(Number(record?.ApplicationCompleted) > 79) && (
                    <div className="alert alert-danger" role="alert">
                      App Status:'{record.ApplicantStatus}'.
                      {location?.state?.isAppCreatedUsingNet &&
                        "This app has been created using an older system and is currently in view-only mode."}
                      {!location?.state?.isAppCreatedUsingNet && (
                        <>
                          {" "}
                          It is currently in view-only mode.{" "}
                          <a
                            onClick={(e) => {
                              e.preventDefault();
                              record.ApplicantStatus == "Invited"
                                ? launchApplicationInvited({
                                    applicantid: record.id,
                                  })
                                : launchApplication({
                                    username: record.UserName || record.Email,
                                  });
                            }}
                            href="#"
                            className="alert-link"
                          >
                            Click here
                          </a>
                          <span> to open the application.</span>
                        </>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
            {/*  */}
            {/* <IdentificationDetails/> */}
            {!toggleDetails && isOpeningForDashboard && (
              <PersonalDetailsAccordion Trnid={record?.Trnid} />
            )}
            {!toggleDetails && isOpeningForDashboard && (
              <SsnAccordion Trnid={record?.Trnid} />
            )}
            {/*  */}
            {proof_of_id_section}
            {!toggleDetails && isOpeningForDashboard && (
              <AddressAccordion Trnid={record?.Trnid} />
            )}
            {!toggleDetails && isOpeningForDashboard && (
              <>
                {/* <div className="subheaders">
                  <h6 className="ms-1">Applicant Details</h6>
                </div> */}
                <ApplicantDetailsAccordion Trnid={record?.Trnid} />
              </>
            )}
            {!isGuestCard && <div className="subheaders"></div>}
            {!isGuestCard && proof_of_income_section}
            {!isGuestCard && other_income_section}
            {!isGuestCard && bank_section}
            {!isGuestCard && <OtherDocuments Trnid={record?.Trnid} />}
            {/* {!toggleDetails &&
            isOpeningForDashboard &&
            applicantData?.Application_details?.application_status !== 1 &&
            applicantData?.Application_details?.application_status !== 2 && (
              <>
                <div className="subheaders">
                  <h6 className="ms-1">Consent &amp; Payment</h6>
                </div>
                <ConsentPaymentAccordion />
              </>
            )} */}
          </div>

          {/* {appFooterRequired && <> */}
          {data?.appInDAReviewQueue && (
            <button
              className="btn btn-info me-md-2"
              type="submit"
              style={{
                backgroundColor: "rgb(45, 195, 232)",
                color: "white",
                padding: "5px 8px 5px 8px",
                marginLeft: "5px",
                fontFamily: "OpenSans-Bold",
                border: "0",
                marginBottom: 10,
              }}
              onClick={handleReview}
            >
              Review Done
            </button>
          )}


          <br />
          <br />
          {((appType !== "1" &&
            userRole === "Docuverus Super Admin" &&
            applicantData?.Application_details?.application_status == 5) ||
          ((appType == "2" || appType == "3") &&
            userRole === "Docuverus Super Admin") ||
          (userRole !== "applicant" && appType !== "1"))&& !data?.appInDAReviewQueue  ? (
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(handle_Admin_Submit)}>
                <div className="print-hide">
                  <div className="row align-items-end">
                    <div className="col-md-3 col-sm-12">
                      <SelectInput
                        label="Application Status"
                        source={`application_status`}
                        placeholder="Select Application Status"
                        options={
                          appType == "1"
                            ? [
                                // { name: "Submitted", value: "3" },
                                { name: "Review Pending", value: "5" },
                              ]
                            : userRole !== "Docuverus Super Admin"
                            ? [{ name: "Review Pending", value: "5" }]
                            : [
                                // { name: "Invited", value: "1" },
                                // { name: "Application In Progress", value: "2" },
                                // // { name: "Submitted", value: "3" },
                                // { name: "Review Pending", value: "5" },
                                // { name: "Completed", value: "6" },
                                { name: "Verified", value: "13" },
                                {
                                  name: "Further Documentation Required",
                                  value: "14",
                                },
                                { name: "Unverifiable", value: "15" },
                              ]
                        }
                        required={false}
                        extraClass={"application_status_style"}
                      />
                    </div>

                    <div className="col-md-9 col-sm-12">
                      <div className="d-grid gap-2 d-sm-flex justify-content-between">
                        <button
                          className="btn btn-primary me-md-2"
                          type="submit"
                          style={{
                            backgroundColor: "rgb(45, 195, 232)",
                            color: "white",
                            padding: "5px 8px 5px 8px",
                            fontFamily: "OpenSans-Bold",
                            border: "0",
                            marginBottom: 14,
                          }}
                        >
                          Submit
                        </button>

                        {appFooterRequired && (
                          <>
                            {userRole == "Docuverus Super Admin" && (
                              <AlertDialogTable trn_id={trn_id} />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </FormProvider>
          ) : (
            <></>
          )}

          {appFooterRequired && (
            <>
              {userRole !== "applicant" && (
                <div className="d-flex justify-content-between align-items-end">
                  <div></div>
                  {/* <button
                  type="button"
                  onClick={() => {
                    updateApplicant(
                      "updateApplicationStatus",
                      {
                        data: {
                          trn_id: trn_id,
                          role: userRole,
                          status: "5",
                        },
                      },
                      {
                        onSuccess: (data) => {
                          //const styledMessage = `<p style="text-align: center;">${data?.records}</p>`;
                          notify(data?.records, {
                            type: "success",
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "right",
                            },
                          });
                        },
                        onError: (error: any) => {
                          notify(`${error.message}`, {
                            type: "warning",
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "right",
                            },
                          });
                        },
                      }
                    );
                  }}
                  className="btn btn-link print-hide"
                  style={{
                    color: "#2dc3e8",
                    padding: "5px 8px 5px 8px",
                    marginBottom: 40,
                    fontFamily: "OpenSans-Bold",
                    textDecoration: "none",
                  }}
                >
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-archive"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5V5a1 1 0 0 1-1-1V2zm2 3v7.5A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5V5H2zm13-3H1v2h14V2zM5 7.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                  </svg>{" "}
                  <strong>Archive Application</strong>
                </button> */}
                </div>
              )}
              {/* </div> */}
              {/* </div> */}
              {/* </div> */}
              {/* </div> */}
              {/* </div> */}
              {userRole === "applicant" && (
                <footer className="footer-custom">
                  <div className="row">
                    <div className="col-auto me-auto"></div>
                    <div className="col-auto">
                      <button
                        type="button"
                        ref={submitButnRef}
                        className="modal-blue-btn"
                        onClick={async () => {
                          if (!isGuestCard) {
                            let isValid_poidRef: any;
                            let isValid_possn: any;
                            let isValid_poincome: any;
                            let isValid_po_other_income: any;
                            let isValid_po_banking: any;

                            if (
                              sectionPermission?.data?.status[3].state == 2 ||
                              sectionPermission?.data?.status[3].state == 1
                            ) {
                              isValid_poidRef =
                                await poidRef?.current?.handleClickByRef();
                              if (!isValid_poidRef) return;
                            } else {
                              isValid_poidRef = true;
                            }
                            if (
                              sectionPermission?.data?.status[0].state == 2 ||
                              sectionPermission?.data?.status[0].state == 1
                            ) {
                              isValid_poincome =
                                await poincome?.current?.handleClickByRef();
                              if (!isValid_poincome) return;
                            } else {
                              isValid_poincome = true;
                            }
                            if (
                              sectionPermission?.data?.status[2].state == 2 ||
                              sectionPermission?.data?.status[2].state == 1
                            ) {
                              isValid_po_other_income =
                                await po_other_income?.current?.handleClickByRef();
                              if (!isValid_po_other_income) return;
                            } else {
                              isValid_po_other_income = true;
                            }
                            if (
                              sectionPermission?.data?.status[1].state == 2 ||
                              sectionPermission?.data?.status[1].state == 1
                            ) {
                              isValid_po_banking =
                                await po_banking?.current?.handleClickByRef();
                              if (!isValid_po_banking) return;
                            } else {
                              isValid_po_banking = true;
                            }

                            if (
                              isValid_poidRef &&
                              isValid_poincome &&
                              isValid_po_other_income &&
                              isValid_po_banking
                            ) {
                              try {
                                let callArray: any = [];
                                if (proof_of_id_section)
                                  callArray.push(
                                    poidRef?.current?.handleSubmitManual()
                                  );
                                // if (proof_of_ssn_section) callArray.push(possn?.current?.handleSubmitManual(),)
                                if (proof_of_income_section)
                                  callArray.push(
                                    poincome?.current?.handleSubmitManual()
                                  );
                                if (other_income_section)
                                  callArray.push(
                                    po_other_income?.current?.handleSubmitManual()
                                  );
                                if (bank_section)
                                  callArray.push(
                                    po_banking?.current?.handleSubmitManual()
                                  );
                                await Promise.all(callArray);
                              } catch (error) {
                                notify(`Error updating sections`, {
                                  type: "warning",
                                  anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "right",
                                  },
                                });
                              } finally {
                                handleApplicantSubmit(trn_id);
                              }
                            } else {
                            }
                          }
                          if (isGuestCard) {
                            let isValid_poidRef: any;
                            isValid_poidRef =
                              await poidRef?.current?.handleClickByRef();
                            if (!isValid_poidRef) return;
                            if (isValid_poidRef) {
                              try {
                                let callArray: any = [];
                                if (proof_of_id_section)
                                  callArray.push(
                                    poidRef?.current?.handleSubmitManual()
                                  );
                                await Promise.all(callArray);
                              } catch (error) {
                                notify(`Error updating sections`, {
                                  type: "warning",
                                  anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "right",
                                  },
                                });
                              } finally {
                                handleApplicantSubmit(trn_id);
                              }
                            }
                          }
                        }}
                        style={{
                          padding: "8px 18px",
                          marginRight: "100px",
                        }}
                      >
                        <svg
                          width="12"
                          height="9"
                          viewBox="0 0 12 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.6665 6.61463L10.7945 0.485962L11.7378 1.42863L4.6665 8.49996L0.423828 4.2573L1.3665 3.31463L4.6665 6.61463Z"
                            fill="white"
                          />
                        </svg>

                        <span style={{ fontFamily: "OpenSans-Bold" }}>
                          Submit
                        </span>
                      </button>
                    </div>
                  </div>
                </footer>
              )}
              <Confirm
                isOpen={userCloseBrowser && role == "applicant"}
                sx={{
                  "& .RaConfirm-iconPaddingStyle": { display: "none" },
                }}
                title="Do you want to submit the application?"
                content={
                  "If an application is not submitted, it will not be processed."
                }
                onConfirm={() => {
                  setUserCloseBrowser(false);
                  submitButnRef?.current?.click();
                }}
                onClose={() => {
                  setUserCloseBrowser(false);
                }}
              />
            </>
          )}
          {roletype != "docupload_admin" &&
            roletype !== "docupload_applicant" &&
            appType === "1" && <GrayStripeBar data={data} />}
        </section>
        <NameChangeModal
          trn_id={proofOfIdsData?.id}
          first_name={applicantDetails?.first_name}
          last_name={applicantDetails?.last_name}
          mobile={applicantDetails?.mobile}
          email={applicantDetails?.email}
          country_code={applicantDetails?.country_code}
          fetchRecord={() => refetch()}
          // state for open dialog
          handleClose={handleClose}
          open={open}
        />
      </ApplicantContext.Provider>
    </>
  );
};

export default DocumentAnalysisShow;
