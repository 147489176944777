import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useNotify, useRefresh, useCreate, useGetOne } from "react-admin";
import { useForm, FormProvider, get } from "react-hook-form";
import { useParams } from "react-router-dom";
import InputField2 from "../common/InputComponents/InputField2";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import LoadingSpinner from "../common/loadingSpinner";
import { FaRegNoteSticky } from "react-icons/fa6";
import InputSelectField from "../common/InputComponents/InputSelectField";
import save_icon from "../../assets/images/save_icon.svg";
import { PiFoldersBold } from "react-icons/pi";
import InputFileUpload from "../common/InputComponents/InputFileUpload";
import FilePreview2 from "../common/InputComponents/FilePreview2";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import MenuItem from "@mui/material/MenuItem";
import { JSONTree } from "react-json-tree";
import InputCheckBoxField from "../common/InputComponents/InputCheckBoxField";

const file_type = ["application/pdf"];
export const file_size = 4000 * 1024;

type processScreeningProps = {
  record: any;
  from?: string;
  isDotsMenus?: boolean;
  applicationData?: any
};

const ProcessScreening = ({
  record,
  from,
  isDotsMenus = true,
  applicationData
}: processScreeningProps) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { id, applicationId } = useParams();
  const subscriberId = id || record?.SubscriberID;
  const [screeningStatus, setScreeningStatus] = useState([
    {
      label: "Approved",
    },
    { label: "Conditionally Approved" },
    { label: "No Assessment" },
    { label: "Unacceptable" },
  ]);

  // get get-applicants data
  const { data: getApplicants } = useGetOne(
    "getApplicants",
    { id: record?.id || applicationId },
    { enabled: record?.id || applicationId ? true : false }
  );

  // form validation schema
  const validationSchema = Yup.lazy((value) =>
    Yup.object().shape({
      applicant: Yup.array()
        .of(
          Yup.object().shape({
            value: Yup.string().required("Required!").nullable(), // `value` must be a string, is required, but can be `null`
            label: Yup.string().nullable(), // `label` can be a string or `null`
          })
        )
        .min(1, "At least one applicant is required") // Ensures the array has at least one object
        .required("Required") // Ensures the array itself is not null or undefined
        .nullable(),
      screening_status: Yup.object()
        .shape({
          value: Yup.string().required("Required!").nullable(),
          label: Yup.string().nullable(),
        })
        .nullable()
        .required("Required!"),
      screening_score: Yup.string()
        .required("Required!")
        .test(
          "valueLimit",
          "Please enter a value less than or equal to 100",
          (value: any) => value <= 100
        )
        .test(
          "valueLimit",
          "Please enter a value greater than or equal to 0",
          (value: any) => value >= 0
        )
        .matches(
          /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
          "not valid"
        )
        .max(3, "Please enter less than 3 characters"),
      file: Yup.mixed()
        .nullable()
        .required("Required!")
        .test(
          "FILE_SIZE",
          "File size exceeds 4Mb",
          (value) => !value || (value && value.size <= file_size)
        )
        .test(
          "file_type",
          "Supported file format is pdf files only.",
          (value) => !value || (value && file_type.includes(value?.type))
        ),
    })
  );

  // form initial states
  const form = useForm({
    defaultValues: {
      applicant: "",
      screening_status: "",
      screening_score: "",
      file: null,
      sentReportToAgentFlag: true
    },
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  // Destructuring form
  const {
    reset,
    formState: { errors },
  } = form;

  // useEffect(()=>{
  //   if(applicationData?.data[0]?.State=='CA'){
  //     form.setValue('sentReportToAgentFlag', true)
  //   }else{
  //     form.setValue('sentReportToAgentFlag', false)
  //   }
  // },[applicationData?.data[0]?.SecondaryApp])

  const [create, { isLoading, error }] = useCreate();

  // form submit handler
  const onSubmit = (value) => {
    // debugger;
    const formData: any = new FormData();
    formData.append("reportID", 1);
    formData.append("applicationNumber", record?.id || applicationId);
    formData.append("subscriberID", subscriberId);
    value?.applicant?.forEach((e) => {
      formData.append("Applicants[]", e.value);
    })
    // formData.append("Applicants[]", [...value?.applicant?.map((e) => e.value)]);
    formData.append("screeningStatus", value?.screening_status?.value);
    formData.append("score", value?.screening_score);
    formData.append("file", value?.file);
    formData.append("sentReportToAgentFlag", value?.sentReportToAgentFlag);
    formData.append("documentTypeID", 9);
    create(
      "processScreening",
      { data: formData },
      {
        onSuccess: (data) => {
          notify(`Screening report added successfully.`, {
            type: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          refresh();
          handleClose();
          reset();
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    );
  };
  useEffect(()=>{
    form.setValue('applicant',getApplicants?.records?.map((item) => ({
      label: item?.FirstName + item?.LastName,
      value: item?.ID,
    })))
  },[open])

  return (
    <>
      {from === "verticalDotsMenu" ? (
        isDotsMenus ? (
          <MenuItem onClick={handleOpen}>Process Screening</MenuItem>
        ) : (
          <div className="col-lg-auto col-md-auto col-4 col-sm-3 d-none d-md-block">
            <div className="modal-sm">
              <button
                type="button"
                className=""
                data-bs-toggle="modal"
                data-bs-target="#sendmodal"
                onClick={handleOpen}

              >
                <PiFoldersBold style={{ fontSize: "16px" }} /> <br />
                <span style={{ marginLeft: "3px" }}>Process Screening</span>
              </button>
            </div>
          </div>
        )
      ) : (
        <BottomNavigationAction
          style={{ color: "#2cc0e4", display: "inline" }}
          label="Process Screening"
          className="pt-0"
          sx={{
            "&.MuiButtonBase-root span.MuiBottomNavigationAction-label": {
              opacity: 1,
            },
          }}
          icon={
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: "5px" }}
            >
              <g id="Frame" clip-path="url(#clip0_6334_30877)">
                <path
                  id="Vector"
                  d="M8.00004 1.33301C11.682 1.33301 14.6667 4.31767 14.6667 7.99967C14.6667 11.6817 11.682 14.6663 8.00004 14.6663C4.31804 14.6663 1.33337 11.6817 1.33337 7.99967C1.33337 4.31767 4.31804 1.33301 8.00004 1.33301ZM8.00004 2.66634C6.58555 2.66634 5.229 3.22824 4.2288 4.22844C3.22861 5.22863 2.66671 6.58519 2.66671 7.99967C2.66671 9.41416 3.22861 10.7707 4.2288 11.7709C5.229 12.7711 6.58555 13.333 8.00004 13.333C9.41453 13.333 10.7711 12.7711 11.7713 11.7709C12.7715 10.7707 13.3334 9.41416 13.3334 7.99967C13.3334 6.58519 12.7715 5.22863 11.7713 4.22844C10.7711 3.22824 9.41453 2.66634 8.00004 2.66634ZM8.00004 4.66634C8.70177 4.66661 9.38551 4.88832 9.95386 5.2999C10.5222 5.71148 10.9462 6.29192 11.1653 6.95854C11.3845 7.62516 11.3877 8.34394 11.1745 9.01249C10.9613 9.68104 10.5425 10.2652 9.97789 10.6819C9.41324 11.0985 8.7315 11.3263 8.02981 11.3329C7.32811 11.3394 6.64226 11.1243 6.06995 10.7183C5.49764 10.3122 5.06807 9.7359 4.84244 9.07144C4.61682 8.40698 4.60665 7.68826 4.81337 7.01767C4.96932 7.36615 5.24022 7.65049 5.58075 7.82312C5.92127 7.99574 6.31075 8.04618 6.68401 7.96598C7.05727 7.88578 7.39166 7.67982 7.63122 7.38256C7.87079 7.0853 8.00099 6.71478 8.00004 6.33301C8.00013 6.01199 7.9075 5.69777 7.7333 5.42812C7.5591 5.15848 7.31073 4.94488 7.01804 4.81301C7.32871 4.71767 7.65804 4.66634 8.00004 4.66634Z"
                  fill="#2DC3E8"
                />
              </g>
            </svg>
          }
          onClick={handleOpen}
        />
      )}

      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Process Screening</DialogTitle>
        <DialogContent>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 mr-b10">
                  <InputSelectField
                    multiple={true}
                    label="Applicant"
                    stopAutoFocus={true}
                    source={`applicant`}
                    options={getApplicants?.records?.map((item) => ({
                      label: item?.FirstName + item?.LastName,
                      value: item?.ID,
                    }))}
                    labelAsteriskClass={"color-red"}
                    labelClass={"lable-title"}
                    requiredClass={{
                      fontSize: "70%",
                    }}
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 mr-b10">
                  <InputSelectField
                    label="Screening Status"
                    source={`screening_status`}
                    stopAutoFocus={true}
                    options={screeningStatus?.map((item) => ({
                      label: item?.label,
                      value: item?.label,
                    }))}
                    labelAsteriskClass={"color-red"}
                    labelClass={"lable-title"}
                    requiredClass={{
                      fontSize: "70%",
                    }}
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 mr-b10">
                  <InputField2
                    type="number"
                    label="Screening Score"
                    source={`screening_score`}
                    placeholder="Enter Screening Score"
                    labelClass={"lable-title"}
                    additionalInputClass={"cus-control"}
                  />
                </div>

              </div>
              {applicationData?.data[0]?.State == 'CA' && <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 mr-b10">
                  <InputCheckBoxField
                    notRequired={true}
                    name={`sentReportToAgentFlag`}
                    label="Send report to agent"
                  />
                </div>
              </div>}

              <div className="row">
                <div className="col-12 mr-b20">
                  <div
                    className="p-3"
                    style={{ background: "#f5fdff", borderRadius: "8px" }}
                  >
                    <div className="row">
                      <div className="col-sm-12 col-md-6 col-lg-6 mr-b10">
                        <label className="lable-title">Screening Report</label>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6 mr-b10">
                        {form?.watch(`file`) !== null && !errors.file ? (
                          <FilePreview2 img={form?.watch(`file`)} />
                        ) : (
                          <InputFileUpload
                            source={`file`}
                            acceptedFiles={"application/pdf"}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Box display={"flex"} justifyContent={"end"}>
                <Button
                  sx={{ mx: 1 }}
                  onClick={() => {
                    handleClose();
                    reset();
                  }}
                  variant="outlined"
                  color="secondary"
                  type="button"
                >
                  Cancel
                </Button>
                <Button
                  startIcon={<img src={save_icon} alt="save icon" />}
                  sx={{ mx: 1 }}
                  variant="contained"
                  type="submit"
                  disabled={isLoading}
                >
                  Save
                </Button>
              </Box>
            </form>
          </FormProvider>
        </DialogContent>
      </Dialog>
      {isLoading ? <LoadingSpinner /> : ""}
    </>
  );
};

export default ProcessScreening;
