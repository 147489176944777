import React, {
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
  useMemo,
} from "react";
// import ApplicantDetail from "./applicant-detail/applicant-detail";
// import Contacts from "./contacts/contacts";
// import FinantialDetails from "./finantial-details/finantial-details";
// import Others from "./others/others";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DevTool } from "@hookform/devtools";
import { useSelector, useDispatch } from "react-redux";
// import { sideNavAction } from "../../../store/identification/sideMenuSlice";
// import {
//   useGetDetailsQuery,
//   identificationApi,
//   useGetSectionStatusQuery,
// } from "../../../services/identificationApi";
// import { useSaveContexttualMutation } from "../../../services/identificationApi";
// import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
// import { formSubmitStatusRest } from "../../../store/registration/fromSubmitSlice";
// import AiOutlinePlus from "@mui/icons-material/ExpandMore";
import { BsCheck2Circle } from "react-icons/bs";
import {
  Accordion,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  AccordionDetails,
  Snackbar,
} from "@mui/material";
// import { tabsStateAction } from "../../../store/identification/tabStateSlice";
import MuiAlert from "@mui/material/Alert";
import { AiOutlinePlus } from "react-icons/ai";

import CloseIcon from "@mui/icons-material/Close";
import { toast, ToastContainer } from "react-toastify";
// import SupportSection from "../../common/SupportSection/SupportSection";

import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useCreate, useGetOne, useNotify, useRefresh } from "react-admin";
import ApplicantDetailSection from "./ApplicantDetailSection/ApplicantDetailSection";
import { JSONTree } from "react-json-tree";
import debouce from "lodash.debounce";
import ContactsSection from "./ContactsSection/ContactsSection";
import FinancialDetailsSection from "./FinancialDetailsSection/FinancialDetailsSection";
import OthersSection from "./OthersSection/OthersSection";
import { setUploadPetImageId } from "../../../Store/Slice/applicantDetailsSlice";

// const Alert = React.forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

function validateDOB(dob) {
  var today = new Date();
  var minAge = new Date(
    today.getFullYear() - 100,
    today.getMonth(),
    today.getDate()
  );
  var maxAge = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  );

  if (dob > maxAge || dob < minAge) {
    return false;
  }
  return true;
}

const ApplicantDetailsAccordion = (props) => {
  const [dobError, setDobError] = React.useState(false);
  const [clickHere, setClickHere] = React.useState(true);

  //   const navigate = useNavigate();
  const dispatch = useDispatch();
  const submitBtnRef: any = useRef(null);

  const submitApplicantDetailsRef: any = useRef(null);
  const submitContactsRef: any = useRef(null);
  const submitFinancialDetailsRef: any = useRef(null);
  const submitOtherDetailsRef: any = useRef(null);
  //   const trn_id = useSelector(
  //     (state) => state.persistedReducer.loginSlice.trnId
  //   );
  const trn_id = props.Trnid; //useSelector((state: any) => state?.users?.userTrnId);
  //   const pet_id = useSelector((state) => state.identification.sideMenuSlice);
  const petImageId = useSelector((state: any) => state.applicantDetails);
  //   let sectionCompleteStatus = useGetSectionStatusQuery(trn_id);

  //   dispatch(sideNavAction.applicationStatusState(sectionCompleteStatus.data));

  //   const formSectionSubmit = useSelector(
  //     (state) => state.formSubmit.fromSectionSubmit
  //   );

  //   const { data, isLoading, isError } = useGetDetailsQuery(trn_id);
  // get getdetails api call
  const {
    data,
    isLoading: getdetailsLoading,
    isError,
  } = useGetOne("contextualgetdetails", {
    // id:27783,
    id: trn_id,
  });

  //   const [saveContextual, result] = useSaveContexttualMutation();
  const refresh = useRefresh();
  const notify = useNotify();
  const [create, { isLoading, error }] = useCreate();
  const saveContextual = (payload: any) => {
    return create(
      "savedetails",
      { data: payload },
      {
        onSuccess: (data) => {
          notify(`Request processed successfully.`, {
            type: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          refresh();
          // reset();
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    );
  };

  //   const sectionStatus = useGetSectionStatusQuery(trn_id);

  // useEffect(() => {
  //   document.title = "Contextual Questions";
  // }, []);

  useEffect(() => {
    if (data) {
      let fields = data?.have_pet?.fields;
      dispatch(
        setUploadPetImageId({
          data: fields,
          type: "APICALL",
        })
      );
    }
  }, [data, dispatch]);

  //   useEffect(() => {
  //     (async () => {
  //       if (formSectionSubmit) {
  //         if (!(await handleSubmitValidation("ApplicantDetails"))) return;
  //         if (!(await handleSubmitValidation("Contacts"))) return;
  //         if (!(await handleSubmitValidation("Finance"))) return;
  //         if (!(await handleSubmitValidation("Others"))) return;
  //         submitBtnRef?.current?.click();
  //       }
  //     })();
  //   }, [formSectionSubmit]);

  const notEmptyFieldsMethod = (
    value,
    path,
    createError,
    myFailureMessage,
    setNameError
  ) => {
    try {
      if (value) {
        setNameError(false);
        return true;
      }
      setNameError(true);
      return createError({ path, message: myFailureMessage });
    } catch (error) {
      console.error("🚀 ~ file: identification.js ~ line 96 ~ error", error);
    }
  };

  //   const today = new Date().setHours(0, 0, 0, 0);

  const schema = yup.object().shape({
    anyone_over_18: yup.object().shape({
      required: yup.string(),
      display: yup.string(),
      response: yup.string().when("required", {
        is: (value) => {
          return value == "true";
        },
        then: yup
          .string()
          .nullable()
          .typeError("Response Required")
          .required("Response Required"),
        otherwise: yup.string().nullable(),
      }),
      fields: yup.array().when("response", {
        is: (value) => {
          return value == "Yes";
        },
        then: yup.array().of(
          yup.object().shape({
            first_name: yup
              .string()
              .required("required")
              .max(75, "maximum 75 characters is required")
              .min(2, "minimum 2 characters is required")
              .typeError("required"),
            last_name: yup
              .string()
              .max(75, "maximum 75 characters is required")
              .required("required")
              .min(2, "minimum 2 characters is required")
              .typeError("required"),
            email: yup
              .string()
              .max(75, "maximum 75 characters is required")
              .email("Invalid email format")
              .required("required")
              .matches(/^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/, "Invalid domain"),
            mobile: yup.string().nullable(),
            financially_responsible: yup.bool().nullable(),
          })
        ),
      }),

      // ProfileTabFieldID: yup.string().when("required", {
      //   is: true,
      //   then: yup.string().required("required"),
      // }),
      Question: yup.string(),
    }),
    is_co_signer: yup.object().shape({
      required: yup.string(),
      display: yup.string(),
      response: yup.string().when("required", {
        is: (value) => {
          return value == "true";
        },
        then: yup
          .string()
          .nullable()
          .typeError("Response Required")
          .required("Response Required"),
        otherwise: yup.string().nullable(),
      }),

      fields: yup.array().when("response", {
        is: (value) => {
          return value == "Yes";
        },
        then: yup.array().of(
          yup.object().shape({
            first_name: yup
              .string()
              .required("required")
              .max(75, "maximum 75 characters is required")
              .min(2, "minimum 2 characters is required")
              .typeError("required"),
            last_name: yup
              .string()
              .max(75, "maximum 75 characters is required")
              .required("required")
              .min(2, "minimum 2 characters is required")
              .typeError("required"),
            email: yup
              .string()
              .email("Invalid email format")
              .required("required")
              .max(75, "maximum 75 characters is required")
              .matches(/^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/, "Invalid domain"),
            mobile: yup.string().nullable(),
          })
        ),
      }),

      ProfileTabFieldID: yup.string().when("required", {
        is: true,
        then: yup.string().required("required"),
      }),
      Question: yup.string(),
    }),

    co_occupants: yup.object().shape({
      required: yup.string(),
      display: yup.string(),
      response: yup.string().when("required", {
        is: (value) => {
          return value == "true";
        },
        then: yup
          .string()
          .nullable()
          .typeError("Response Required")
          .required("Response Required"),
        otherwise: yup.string().nullable(),
      }),
      fields: yup.array().when("response", {
        is: (value) => {
          return value == "Yes";
        },
        then: yup.array().of(
          yup.object().shape({
            first_name: yup
              .string()
              .max(75, "maximum 75 characters is required")
              .required("required")
              .min(2, "minimum 2 characters is required")
              .typeError("required"),
            last_name: yup
              .string()
              .max(75, "maximum 75 characters is required")
              .required("required")
              .min(2, "minimum 2 characters is required")
              .typeError("required"),
            dob: yup
              .string()
              .nullable()
              .required("required")
              .test("validate-dob", function (value) {
                const { path, createError } = this;
                const myFailureMessage =
                  "Invalid DOB.  Occupant must be under the age of 18.";
                try {
                  if (value) {
                    const tempValue: any = validateDOB(new Date(value));
                    let date = moment(new Date()).format("DD/MM/YYYY");
                    let today = moment(date, "DD-MM-YYYY");
                    let enteredDOB = moment(tempValue).format("DD/MM/YYYY");
                    let dob = moment(enteredDOB, "DD-MM-YYYY");
                    //
                    let totalYears = today.diff(dob, "years", true);
                    //
                    if (totalYears > 18) {
                      //
                      // return false;
                      return createError({ path, message: myFailureMessage });
                    } else if (dob > today) {
                      return createError({
                        path,
                        message: "DOB cannot not be future date",
                      });
                    } else {
                      return true;
                    }
                  }
                  return notEmptyFieldsMethod(
                    value,
                    path,
                    createError,
                    myFailureMessage,
                    setDobError
                  );
                } catch (error) {
                  //
                }
              }),
            relationship: yup
              .string()
              .required("required")
              .matches(
                /^[A-Za-z0-9_ ,.'-]+$|^$/i,
                "Only Alphabet and Numbers are Allowed"
              )
              .matches(/^$|^\S+.*/g, "Enter a valid value"),
            ssn: yup
              .string()
              .nullable()
              .typeError("Response Required")
              .matches(/^(\d*[0-9])|^$/g, "Only Numbers Allowed")
              .matches(/^$|^\S+.*/g, "Enter a valid value")
              .notRequired(),
          })
        ),
      }),

      ProfileTabFieldID: yup.string().when("required", {
        is: true,
        then: yup.string().required("required"),
      }),
      Question: yup.string(),
    }),
  });

  const methods = useForm({
    defaultValues: data,
    resolver: yupResolver(schema),
    mode: "all",
  });

  const contactsSchema = yup.object().shape({
    reference: yup.object().shape({
      required: yup.string(),
      display: yup.string(),
      response: yup.string().when("required", {
        is: (value) => {
          return value == "true";
        },
        then: yup
          .string()
          .nullable()
          .typeError("Response Required")
          .required("Response Required"),
        otherwise: yup.string().nullable(),
      }),
      fields: yup.array().when("response", {
        is: (value) => {
          return value == "Yes";
        },
        then: yup.array().of(
          yup.object().shape({
            first_name: yup
              .string()
              .required("required")
              .max(75, "maximum 75 characters is required")
              .min(2, "minimum 2 characters is required")
              .typeError("required"),
            last_name: yup
              .string()
              .max(75, "maximum 75 characters is required")
              .required("required")
              .min(2, "minimum 2 characters is required")
              .typeError("required"),
            email: yup
              .string()
              .max(75, "maximum 75 characters is required")
              .email("Invalid email format")
              .required("required")
              .matches(/^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/, "Invalid domain"),
            mobile: yup.string().nullable(),
            relationship: yup
              .string()
              .required("required")
              .matches(
                /^[A-Za-z0-9_ ,.'-]+$|^$/i,
                "Only Alphabet and Numbers are Allowed"
              )
              .matches(/^$|^\S+.*/g, "Enter a valid value"),
          })
        ),
      }),

      ProfileTabFieldID: yup.string().when("required", {
        is: true,
        then: yup.string().required("required"),
      }),
      Question: yup.string(),
    }),
    emergency_reference: yup.object().shape({
      required: yup.string(),
      display: yup.string(),
      response: yup.string().when("required", {
        is: (value) => {
          return value == "true";
        },
        then: yup
          .string()
          .nullable()
          .typeError("Response Required")
          .required("Response Required"),
        otherwise: yup.string().nullable(),
      }),
      fields: yup.array().when("response", {
        is: (value) => {
          return value == "Yes";
        },
        then: yup.array().of(
          yup.object().shape({
            first_name: yup
              .string()
              .max(75, "maximum 75 characters is required")
              .required("required")
              .min(2, "minimum 2 characters is required")
              .typeError("required"),
            last_name: yup
              .string()
              .max(75, "maximum 75 characters is required")
              .required("required")
              .min(2, "minimum 2 characters is required")
              .typeError("required"),
            email: yup
              .string()
              .max(75, "maximum 75 characters is required")
              .email("Invalid email format")
              .required("required")
              .matches(/^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/, "Invalid domain"),
            mobile: yup.string().nullable(),
            relationship: yup
              .string()
              .required("required")
              .matches(
                /^[A-Za-z0-9_ ,.'-]+$|^$/i,
                "Only Alphabet and Numbers are Allowed"
              )
              .matches(/^$|^\S+.*/g, "Enter a valid value"),
          })
        ),
      }),

      ProfileTabFieldID: yup.string().when("required", {
        is: true,
        then: yup.string().required("required"),
      }),
      Question: yup.string(),
    }),
  });

  const contactsMethods = useForm({
    defaultValues: data,
    resolver: yupResolver(contactsSchema),
    mode: "all",
  });

  const financialSchema = yup.object().shape({
    renters_insurance: yup.object().shape({
      required: yup.string(),
      display: yup.string(),
      response: yup.string().when("required", {
        is: (value) => {
          return value == "true";
        },
        then: yup
          .string()
          .nullable()
          .typeError("Response Required")
          .required("Response Required"),
        otherwise: yup.string().nullable(),
      }),
      fields: yup.array().when("response", {
        is: (value) => {
          return value == "Yes";
        },
        then: yup.array().of(
          yup.object().shape({
            insurance_companyName: yup
              .string()
              .typeError("required")
              .required("required")
              // .matches(
              //   /^[A-Za-z0-9_ ,.'-]+$|^$/i,
              //   "Only Alphabet and Numbers are Allowed"
              // )
              .matches(/^$|^\S+.*/g, "Enter a valid value"),

            policy_number: yup
              .string()
              .matches(/^[A-Za-z0-9_]+$/, "Only Alphanumeric Allowed") //Make Number with Letters
              .matches(/^$|^\S+.*/g, "Enter a valid value")
              .required("required"),
            policy_expirationdate: yup
              .string()
              .nullable()
              .typeError("required")
              .required("required"),
              // .test("policy_number", "Future Date Required", (value) => {
              //   if (!value) return true;
              //   var now = new Date();
              //   now.setHours(0, 0, 0, 0);
              //   return value > now ? true : false;
              // })
              // .transform((curr, orig) => (orig == "" ? null : curr)),
          })
        ),
      }),

      ProfileTabFieldID: yup.string().when("required", {
        is: true,
        then: yup.string().required("required"),
      }),
      Question: yup.string(),
    }),
    eviction_or_nonpayment: yup.object().shape({
      required: yup.string(),
      display: yup.string(),
      response: yup.string().when("required", {
        is: (value) => {
          return value == "true";
        },
        then: yup
          .string()
          .nullable()
          .typeError("Response Required")
          .required("Response Required"),
        otherwise: yup.string().nullable(),
      }),
      explanation: yup
        .string()
        .nullable()
        .when("response", {
          is: (value) => {
            return value == "Yes";
          },
          then: yup
            .string()
            .typeError("required")
            .required("required")
            .matches(/^$|^\S+.*/g, "Enter a valid value")
            .max(5000, "Text size exceeds a limit of 5000!"),
        }),

      ProfileTabFieldID: yup.string().when("required", {
        is: true,
        then: yup.string().required("required"),
      }),
      Question: yup.string(),
    }),
    contingencies: yup.object().shape({
      required: yup.string(),
      display: yup.string(),
      response: yup.string().when("required", {
        is: (value) => {
          return value == "true";
        },
        then: yup
          .string()
          .nullable()
          .typeError("Response Required")
          .required("Response Required"),
        otherwise: yup.string().nullable(),
      }),
      explanation: yup
        .string()
        .nullable()
        .when("response", {
          is: (value) => {
            return value == "Yes";
          },
          then: yup
            .string()
            .typeError("required")
            .required("required")
            .matches(/^$|^\S+.*/g, "Enter a valid value")
            .max(5000, "Text size exceeds a limit of 5000!"),
        }),

      ProfileTabFieldID: yup.string().when("required", {
        is: true,
        then: yup.string().required("required"),
      }),
      Question: yup.string(),
    }),
    filed_for_bankruptcy: yup.object().shape({
      required: yup.string(),
      display: yup.string(),
      response: yup.string().when("required", {
        is: (value) => {
          return value == "true";
        },
        then: yup
          .string()
          .typeError("Response Required")
          .required("Response Required"),
        otherwise: yup.string().nullable(),
      }),
      explanation: yup
        .string()
        .nullable()
        .when("response", {
          is: (value) => {
            return value == "Yes";
          },
          then: yup
            .string()
            .typeError("required")
            .required("required")
            .matches(/^$|^\S+.*/g, "Enter a valid value")
            .max(5000, "Text size exceeds a limit of 5000!"),
        }),
      ProfileTabFieldID: yup.string().when("required", {
        is: true,
        then: yup.string().required("required"),
      }),
      Question: yup.string(),
    }),
  });

  const financialMethods = useForm({
    defaultValues: data,
    resolver: yupResolver(financialSchema),
    mode: "all",
  });

  const othersSchema = yup.object().shape({
    is_student: yup.object().shape({
      required: yup.string(),
      display: yup.string(),
      response: yup.string().when("required", {
        is: (value) => {
          return value == "true";
        },
        then: yup
          .string()
          .nullable()
          .typeError("Response Required")
          .required("Response Required"),
        otherwise: yup.string().nullable(),
      }),
      fields: yup.array().when("response", {
        is: (value) => {
          return value == "Yes";
        },
        then: yup.array().of(
          yup.object().shape({
            collegeuniversity_name: yup
              .string()
              .required("required"),
            activities: yup
              .string(),
            major: yup
              .string(),
            anticipatedgraduation_date: yup
              .string()
              .typeError("required")
              .nullable()
              .transform((curr, orig) => (orig == "" ? null : curr))
              .required("required")
              .test("date", "Future date required", (value) => {
                if (!value) return true;
                return moment(value).isAfter(new Date());
              }),
          })
        ),
      }),

      ProfileTabFieldID: yup.string().when("required", {
        is: true,
        then: yup.string().required("required"),
      }),
      Question: yup.string(),
    }),
    have_vehicle: yup.object().shape({
      required: yup.string(),
      display: yup.string(),
      response: yup.string().when("required", {
        is: (value) => {
          return value == "true";
        },
        then: yup
          .string()
          .nullable()
          .typeError("Response Required")
          .required("Response Required"),
        otherwise: yup.string().nullable(),
      }),
      fields: yup.array().when("response", {
        is: (value) => {
          return value == "Yes";
        },
        then: yup.array().of(
          yup.object().shape({
            vehicle_year: yup
              .string()
              .typeError("required")
              .nullable()
              .matches(/^(\d+[0-9])$/, "Only Allowed Numbers")
              .required("required"),
            vehicle_make: yup
              .string()
              .required("required"),
            vehicle_model: yup
              .string()
              .required("required"),
            vehicle_color: yup
              .string()
              .required("required"),
            vehicle_licenseplate: yup
              .string()
              .required("required"),
            vehicle_monthlypayment: yup
              .string()
              .nullable()
              .matches(/^(\d*[0-9])|^$/g, "Only Numbers Allowed"),
          })
        ),
      }),

      ProfileTabFieldID: yup.string().when("required", {
        is: true,
        then: yup.string().required("required"),
      }),
      Question: yup.string(),
    }),
    have_pet: yup.object().shape({
      required: yup.string(),
      display: yup.string(),
      response: yup.string().when("required", {
        is: (value) => {
          return value == "true";
        },
        then: yup
          .string()
          .nullable()
          .typeError("Response Required")
          .required("Response Required"),
        otherwise: yup.string().nullable(),
      }),
      fields: yup.array().when("response", {
        is: (value) => {
          return value === "Yes";
        },
        then: yup.array().of(
          yup.object().shape({
            pet_type: yup
              .string()
              .required("required"),
            pet_breed: yup
              .string()
              .max(75, "maximum 75 characters is required")
              .required("required"),
            pet_weight: yup
              .string()
              .matches(/^(\d*[0-9])|^$/g, "Only Numbers Allowed"),
            pet_name: yup
              .string()
              .nullable(),
            service_animal: yup.boolean(),
          })
        ),
      }),

      ProfileTabFieldID: yup.string().when("required", {
        is: true,
        then: yup.string().required("required"),
      }),
      Question: yup.string(),
    }),
    do_you_smoke: yup.object().shape({
      required: yup.string(),
      display: yup.string(),
      response: yup.string().when("required", {
        is: (value) => {
          return value === "true";
        },
        then: yup
          .string()
          .nullable()
          .typeError("Response Required")
          .required("Response Required"),
        otherwise: yup.string().nullable(),
      }),

      ProfileTabFieldID: yup.string().when("required", {
        is: true,
        then: yup.string().required("required"),
      }),
      Question: yup.string(),
    }),
    convicted_for_crime: yup.object().shape({
      required: yup.string(),
      display: yup.string(),
      response: yup.string().when("required", {
        is: (value) => {
          return value === "true";
        },
        then: yup
          .string()
          .nullable()

          .typeError("Response Required")
          .required("Response Required"),
        otherwise: yup.string().nullable(),
      }),
      explanation: yup
        .string()
        .nullable()
        .when("response", {
          is: (value) => {
            //
            return value === "Yes";
          },
          then: yup
            .string()
            .typeError("required")
            .required("required")
            .max(5000, "Text size exceeds a limit of 5000!"),
        }),

      ProfileTabFieldID: yup.string().when("required", {
        is: true,
        then: yup.string().required("required"),
      }),
      Question: yup.string(),
    }),
  });

  const othersMethods = useForm({
    defaultValues: data,
    resolver: yupResolver(othersSchema),
    mode: "all",
  });
  //   const viewOnlyFlag = useSelector((state) => state.formSubmit.viewOnlyFlag);
  useEffect(() => {
    methods.reset(data);
  }, [data, methods]);

  useEffect(() => {
    contactsMethods.reset(data);
  }, [data, contactsMethods]);

  useEffect(() => {
    financialMethods.reset(data);
  }, [data, financialMethods]);

  useEffect(() => {
    othersMethods.reset(data);
  }, [data, othersMethods]);

  const onSubmit = async (fromData) => {
    let havePet = {
      ...fromData.have_pet,
      // pet_id: pet_id.uploadPetImageId,
      fields: fromData.have_pet?.fields?.map((petDetail, index) => {
        return {
          ...petDetail,
          // pet_id: pet_id.uploadPetImageId[index]?.id,
          pet_id: petImageId?.uploadPetImageId[index]?.id,
        };
      }),
    };
    let questions: any = [];
    questions[0] = fromData.anyone_over_18;
    questions[1] = fromData.co_occupants;
    questions[2] = fromData.is_co_signer;
    questions[3] = fromData.reference;
    questions[4] = fromData.emergency_reference;
    questions[5] = fromData.renters_insurance;
    questions[6] = fromData.eviction_or_nonpayment;
    questions[7] = fromData.contingencies;
    questions[8] = fromData.filed_for_bankruptcy;
    questions[9] = fromData.is_student;
    questions[10] = fromData.have_vehicle;
    questions[11] = havePet;
    questions[12] = fromData.convicted_for_crime;
    if (fromData?.do_you_smoke?.display == "true") {
      questions.push(fromData.do_you_smoke);
    }
    let tempObj = {
      trn_id,
      questions: questions,
    };
    const response = await toast.promise(saveContextual(tempObj), {
      pending: "Saving",

      error: "Saving failed 🤯",
    });
  };


  //  new states
  const [expandIdSection, setExpandIdSection] = React.useState(true);
  const [expandContactsIdSection, setExpandContactsIdSection] =
    React.useState(true);
  const [financialDetailsSection, setFinancialDetailsSection] =
    React.useState(true);
  const [expandOthersIdSection, setExpandOthersIdSection] =
    React.useState(true);

  const handleSubmitManual = (key: string) => {
    // onSubmit(methods.getValues());
    if (key === "ApplicantDetails") {
      submitApplicantDetailsRef?.current?.click();
    }
    if (key === "Contacts") {
      submitContactsRef?.current?.click();
    }
    if (key === "FinancialDetails") {
      submitFinancialDetailsRef?.current?.click();
    }
    if (key === "OtherDetails") {
      submitOtherDetailsRef?.current?.click();
    }
  };
  const handleAPIDebounced = useMemo(() => {
    return debouce(() => handleSubmitManual("ApplicantDetails"), 3000);
  }, []);

  const handleContactsAPIDebounced = useMemo(() => {
    return debouce(() => handleSubmitManual("Contacts"), 3000);
  }, []);

  const handleFinancialDetailsAPIDebounced = useMemo(() => {
    return debouce(() => handleSubmitManual("FinancialDetails"), 3000);
  }, []);

  const handleOtherDetailsDebounced = useMemo(() => {
    return debouce(() => handleSubmitManual("OtherDetails"), 3000);
  }, []);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <fieldset>
            <div className="info-toggle">
              <div className="toggle-heads">
                <div className="row align-items-center justify-content-between ">
                  <div className="col">
                    <div className="d-flex align-items-center">
                      <b className="ms-3 me-2">Applicant Details</b>
                    </div>
                  </div>

                  <div className="col text-end m-1">
                    <IconButton
                      className="print-hide"
                      onClick={() => setExpandIdSection((v) => !v)}
                      color="primary"
                    >
                      {!expandIdSection ? <AddIcon /> : <RemoveIcon />}
                    </IconButton>
                  </div>
                </div>

                <div
                  className={` ${
                    !expandIdSection ? "d-none" : "d-display"
                  } card-body cust-card-appl`}
                >
                  <div
                    className="card card-body w-100"
                    style={{ paddingTop: 0 }}
                  >
                    <ApplicantDetailSection
                      // checkValidation={handleSubmitValidation}
                      // que={data}
                      handleAPIDebounced={handleAPIDebounced}
                    />
                    <button
                      type="submit"
                      ref={submitApplicantDetailsRef}
                      className="d-none"
                    >
                      submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      </FormProvider>
      <FormProvider {...contactsMethods}>
        <form onSubmit={contactsMethods.handleSubmit(onSubmit)}>
          <fieldset>
            <div className="info-toggle">
              <div className="toggle-heads">
                <div className="row align-items-center justify-content-between ">
                  <div className="col">
                    <div className="d-flex align-items-center">
                      <b className="ms-3 me-2">Contacts</b>
                    </div>
                  </div>

                  <div className="col text-end m-1">
                    <IconButton
                      className="print-hide"
                      onClick={() => setExpandContactsIdSection((v) => !v)}
                      color="primary"
                    >
                      {!expandContactsIdSection ? <AddIcon /> : <RemoveIcon />}
                    </IconButton>
                  </div>
                </div>

                <div
                  className={` ${
                    !expandContactsIdSection ? "d-none" : "d-display"
                  } card-body cust-card-appl`}
                >
                  <div
                    className="card card-body w-100"
                    style={{ paddingTop: 0 }}
                  >
                    <ContactsSection
                      // checkValidation={handleSubmitValidation}
                      // que={data}
                      handleAPIDebounced={handleContactsAPIDebounced}
                    />
                    <button
                      type="submit"
                      ref={submitContactsRef}
                      className="d-none"
                    >
                      submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      </FormProvider>
      <FormProvider {...financialMethods}>
        <form onSubmit={financialMethods.handleSubmit(onSubmit)}>
          <fieldset>
            <div className="info-toggle">
              <div className="toggle-heads">
                <div className="row align-items-center justify-content-between ">
                  <div className="col">
                    <div className="d-flex align-items-center">
                      <b className="ms-3 me-2">Financial Details</b>
                    </div>
                  </div>

                  <div className="col text-end m-1">
                    <IconButton
                      className="print-hide"
                      onClick={() => setFinancialDetailsSection((v) => !v)}
                      color="primary"
                    >
                      {!financialDetailsSection ? <AddIcon /> : <RemoveIcon />}
                    </IconButton>
                  </div>
                </div>

                <div
                  className={` ${
                    !financialDetailsSection ? "d-none" : "d-display"
                  } card-body cust-card-appl`}
                >
                  <div
                    className="card card-body w-100"
                    style={{ paddingTop: 0 }}
                  >
                    <FinancialDetailsSection
                      // checkValidation={handleSubmitValidation}
                      // que={data}
                      handleAPIDebounced={handleFinancialDetailsAPIDebounced}
                    />
                    <button
                      type="submit"
                      ref={submitFinancialDetailsRef}
                      className="d-none"
                    >
                      submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      </FormProvider>
      <FormProvider {...othersMethods}>
        <form onSubmit={othersMethods.handleSubmit(onSubmit)}>
          <fieldset>
            <div className="info-toggle">
              <div className="toggle-heads">
                <div className="row align-items-center justify-content-between ">
                  <div className="col">
                    <div className="d-flex align-items-center">
                      <b className="ms-3 me-2">Others</b>
                    </div>
                  </div>

                  <div className="col text-end m-1">
                    <IconButton
                      className="print-hide"
                      onClick={() => setExpandOthersIdSection((v) => !v)}
                      color="primary"
                    >
                      {!expandOthersIdSection ? <AddIcon /> : <RemoveIcon />}
                    </IconButton>
                  </div>
                </div>

                <div
                  className={` ${
                    !expandOthersIdSection ? "d-none" : "d-display"
                  } card-body cust-card-appl`}
                >
                  <div
                    className="card card-body w-100"
                    style={{ paddingTop: 0 }}
                  >
                    <OthersSection
                      data={data}
                      handleAPIDebounced={handleOtherDetailsDebounced}
                    />
                    <button
                      type="submit"
                      ref={submitOtherDetailsRef}
                      className="d-none"
                    >
                      submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      </FormProvider>

      {/* <DevTool control={methods.control} /> */}
    </>
  );
};

export default ApplicantDetailsAccordion;
