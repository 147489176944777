import React, { useState, useEffect } from 'react';

export const useUserRole = () => {
  const [userRole, setUserRole] = useState(null); // Initial state

  useEffect(() => {
    const storedRole:any = localStorage.getItem('userRole');
    setUserRole(storedRole);
  }, []); // Empty dependency array for initial fetch only

  return userRole;
};