import React, { useRef, useState } from 'react';
import { Controller, useController, useFormContext } from "react-hook-form";
const YesNoRadioButton = ({ label, source, disable = false, handleOnClick = (e) => { }, required = true }) => {
  // const { field, fieldState } = useController({ name: source });
  const [delay, setDelay] = useState(false);
  const { control, setValue } = useFormContext();
  const {
    field,
    fieldState: { error },
  } = useController({
    name: source,
    control,
  });
  const inputRef = useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (error !== undefined && required) {
      inputRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [error, required]);
  const randomIdYesBtn = Math.floor(1000000000 + Math.random() * 9000000000)
  const randomIdNoBtn = Math.floor(1000000000 + Math.random() * 8000000000)
  return (
    <Controller
      control={control}

      {...field}
      render={() => {
        // const { onChange, ...rest } = field;
        return (
          <>
            <div className="row" ref={inputRef}>

              <div className="col-12">
                <label htmlFor="component_title" className='component_title mb-1'>{label} {required && <span className="new-star">*</span>}</label>
              </div>
            </div>

            <div className='inner-block-one'>
              <div className="selector new-selector">
                <div className="selecotr-item new-c">
                  <input
                    disabled={delay || disable}
                    type="radio"
                    id={`${source}_${label}_${randomIdYesBtn}`}
                    {...field}
                    className="selector-item_radio"
                    checked={field.value === "Yes"}
                    value={"Yes"}
                    onClick={() => {
                      setDelay(true);
                      handleOnClick("Yes")
                      setTimeout(() => setDelay(false), 4000)
                    }}
                  />
                  <label htmlFor={`${source}_${label}_${randomIdYesBtn}`} className="selector-item_label" style={!field.value ? { background: `${required ? '#fff8f8' : '#fff'}`, minHeight: 35 } : { minHeight: 35 }}>
                    Yes
                  </label>
                </div>
                <div className="selecotr-item  new-c">
                  <input
                    disabled={delay || disable}
                    type="radio"
                    id={`${source}_${label}_${randomIdNoBtn}`}
                    {...field}
                    className="selector-item_radio"
                    checked={field.value === "No"}
                    value={"No"}
                    onClick={() => {
                      setDelay(true);
                      handleOnClick("No")
                      setTimeout(() => setDelay(false), 1000)
                    }}
                  />
                  <label htmlFor={`${source}_${label}_${randomIdNoBtn}`} className="selector-item_label" style={!field.value ? { background: `${required ? '#fff8f8' : '#fff'}`, minHeight: 35 } : { minHeight: 35 }}>
                    No
                  </label>
                </div>
              </div>
              <div
                className="invalid-feedback"
                style={{ display: "block", fontSize: "70%" }}
              >
                {error !== undefined && error.message}
              </div>
            </div>
          </>
        )
      }} />)
}
export default YesNoRadioButton;