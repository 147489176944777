import React from "react";
import { useFormContext, useController } from "react-hook-form";

const InputTextArea = (props) => {
  const required = props.notRequired === undefined ? true : !props.notRequired;
  const { register, labelClass = "", labelAsteriskClass = "red" , additionalInputClass = "", styleProperty = {}, messageText = true, requiredClass = {}, handleOnChange=()=>{} } = props;
  const { control, watch } = useFormContext();
  const {
    field,
    fieldState: { error },
  } = useController({
    name: register,
    control,
  });
  //    const error = errors[name];
  return (
    <>
      <label
        htmlFor={register}
        className={`form-label ${
          error !== undefined && "text-danger"
        } ${labelClass}`}
      >
        {props.label}{" "}
        {required && <span className={labelAsteriskClass}>*</span>}
      </label>
      <textarea
        name={register}
        className={`form-control cus-input-control ${
          required && !field.value && "is-warning"
        } ${error !== undefined && "is-invalid"} ${additionalInputClass}`}
        rows="3"
        {...field}
        placeholder={props.placeholder || `Enter ${props.label}`}
        style={styleProperty}
        onChange={e=>{
          field.onChange(e);
          handleOnChange()
        }}
      ></textarea>
      {messageText && <em className="text-limit ml-auto d-block text-right">
        Max 5000 Characters
      </em>}
      <div className="invalid-feedback" style={requiredClass}>
        {error !== undefined && error.message}
      </div>
    </>
  );
};

export default InputTextArea;
