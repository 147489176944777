import {
  Datagrid,
  FunctionField,
  LinearProgress,
  ListBase,
  ListToolbar,
  Pagination,
  TextField,
  Title,
  useCreate,
  useDataProvider,
  useGetIdentity,
  useListContext,
  useStore,
} from "react-admin";
import { useParams, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Card } from "@mui/material";
import { NumberField, Filter, TextInput } from "react-admin";
import {
  Grid,
  Typography,
  TextField as MuiTextField,
  Button,
  Box,
} from "@mui/material";
import { debounce } from "lodash";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FilterModal from "./FilterModal";

// Define a filter component for the list view
const MyListToolBar = ({ title }) => {
  const { displayedFilters, filterValues, setFilters, hideFilter } =
    useListContext();
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();

  // Debounce the search function
  const debouncedSearch = debounce((value) => {
    // Perform your search logic here
    setFilters({ ...filterValues, q_yardi: value }, []);
  }, 500); // 500ms debounce delay

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchText(value);
    debouncedSearch(value);
  };

  return (
    <Grid style={{ marginTop: 10, marginBottom: 8 }} container spacing={3}>
      {/* Left side with title */}
      <Grid item xs={12} md={4}>
        <Typography variant="h6">{title}</Typography>
      </Grid>

      {/* Right side with search input, filter button, and add button */}
      <Grid
        item
        xs={12}
        md={8}
        container
        justifyContent="flex-end"
        alignItems="center"
      >
        {/* Search Input */}
        <MuiTextField
          placeholder="search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          defaultValue={filterValues.q}
          onChange={handleSearchChange}
          variant="outlined"
          size="small"
        />

        <div className="group-btn">
          {/* Filter Button */}
          <FilterModal />
          {/* Add property button */}
          <button
            type="button"
            onClick={() =>
              navigate(
                `/ats/settings/integrationSettings/yardi/addyardiIntegrationSettings`,
                { state: { isAdding: true, thirdPartyAppId: 2 } }
              )
            }
            className="blue-btn px-4"
          >
            <svg
              className="mr-r10"
              width="10"
              height="11"
              viewBox="0 0 10 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.33337 4.83333V0.833334H5.66671V4.83333H9.66671V6.16667H5.66671V10.1667H4.33337V6.16667H0.333374V4.83333H4.33337Z"
                fill="white"
              />
            </svg>
            Integration Settings
          </button>
        </div>
      </Grid>
    </Grid>
  );
};

// Define the list view component using ListBase
export const MyList = ({ children, toolBar, ...props }) => {
  return (
    <ListBase
      {...props}
      // filters={<MyFilter/>}
      // filters={<MyFilter />}
      perPage={20} // Number of items per page
      // pagination={<Pagination />}
    >
      {toolBar}
      {/* Define the structure of each list item using Datagrid */}
      {/* <Card> */}
      {children}
      {/* </Card> */}
      {/* <Pagination /> */}
    </ListBase>
  );
};

export const ActiveBtn = () => {
  const myStyles = {
    display: "flex",
    width: "79px",
    padding: "8px 11px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "4px",
    background: "#E6FDF0",
  };
  const myTextStyles = {
    color: "var(--Success, #46A96F)",
    // textAlign: 'center',
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  };
  return (
    <div style={myStyles}>
      <div style={myTextStyles}>Active</div>
    </div>
  );
};
export const DeActiveBtn = () => {
  const myStyles = {
    display: "flex",
    width: "79px",
    padding: "8px 11px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "4px",
    background: "#FFF1F1",
  };
  const myTextStyles = {
    color: "var(--Success, #FF4848)",
    // textAlign: 'center',
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  };
  return (
    <div style={myStyles}>
      <div style={myTextStyles}>Inactive</div>
    </div>
  );
};

const YardiList = () => {
  const navigate = useNavigate();
  const [switchSubscriberObj] = useStore("switchSubscriberName");
  return (
    <MyList
      toolBar={<MyListToolBar title={"Integration Settings"} />}
      sort={{ field: "id", order: "DESC" }}
      filter={{ subscriberID: switchSubscriberObj?.value, thirdPartyAppId: 2 }}
    >
      <Datagrid
        sx={{
          "& .RaDatagrid-headerCell": {
            fontWeight: "bold",
            fontSize: 12,
          },
          "& .RaDatagrid-rowEven": { backgroundColor: "#F9F9F9" },
          "& .MuiTableBody-root.datagrid-body.RaDatagrid-tbody tr td.MuiTableCell-root.MuiTableCell-body:nth-child(2), & .MuiTableBody-root.datagrid-body.RaDatagrid-tbody tr td.MuiTableCell-root.MuiTableCell-body:nth-child(3)":
            { width: "200px" },
        }}
        bulkActionButtons={false}
      >
        <TextField source="ruleName" />
        <FunctionField
          sortByOrder="DESC"
          sortBy="Status"
          label={"Status"}
          render={(record) => (
            <>
              <div className="d-flex align-items-center">
                {record.status ? <ActiveBtn /> : <DeActiveBtn />}
                {record?.isDefault ? (
                  <span className="mr-l30">Default</span>
                ) : (
                  ""
                )}
              </div>
            </>
          )}
        />
        <FunctionField
          label={""}
          render={(record) => (
            <Box display={"flex"}>
              <IconButton
                aria-label="view details"
                color="primary"
                sx={{
                  py: 0,
                }}
                onClick={() =>
                  navigate(
                    `/ats/settings/integrationSettings/yardi/yardiDetails/${record?.id}`,
                    { state: { isAdding: false, thirdPartyAppId: 2 } }
                  )
                }
                className="show_on_hover"
              >
                <ArrowForwardIcon />
              </IconButton>
            </Box>
          )}
        />
        {/* Add more fields as needed */}
      </Datagrid>
    </MyList>
  );
};

export default YardiList;
