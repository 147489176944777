import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';

export default function AlertDialogEmp() {
  const [open, setOpen] = React.useState(false);
  const [displayContent, setDisplayContent] = React.useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className='print-hide'>
      <IconButton onClick={()=>handleClickOpen()} style={{color:"#ffc107"}} size="small" aria-label="info" component="label" >
        <InfoIcon sx={{fontSize:15}} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <p className='small'>
              <strong>IMPORTANT!</strong> In order to calculate and verify your
              employment income, if you are currently employed, we will need you
              to upload PDF’s of your paystubs downloaded
              directly from your payroll website. Please do NOT upload pictures
              or scanned images, and please do NOT “print to PDF”, “save as” or
              otherwise modify your PDF documents in any way as doing so can
              render your documents unverifiable. If you have questions, you can
              find answers to frequently-asked questions below by clicking the
              relevant question in blue.
            </p>
            <small
              className="btn btn-link small "
              onClick={() => setDisplayContent(1)}
            >
              Why original PDF’s?
            </small>
            <small className={`mb-2 ${displayContent === 1 ? "" : "d-none"}`}>
              <br />
              The Docuverus software directly imports original PDF documents,
              which allows our system to more accurately read and validate your
              documents, expedites processing of your application, and can help
              to qualify you more quickly.
            </small>
            <br />
            <div style={{ height: "5px" }} />
            <small
              className="btn btn-link small"
              onClick={() => setDisplayContent(2)}
            >
              Newly employed?
            </small>
            <small className={`mb-2 ${displayContent === 2 ? "" : "d-none"}`}>
              <br />
              If you are newly employed and do not yet have access to original
              PDF’s of your paystubs, then please check the box to
              the below, which will allow you to upload a PDF of your offer
              letter.
            </small>
            <br />
            <div style={{ height: "5px" }} />

            <small
              className="btn btn-link small"
              onClick={() => setDisplayContent(3)}
            >
              Self-employed?
            </small>
            <small className={`mb-2 ${displayContent === 3 ? "" : "d-none"}`}>
              <br />
              If you are self-employed and do not receive
              paystubs, then in order to verify your income, we will need for
              you to upload original PDF’s of your 3 most recent personal bank
              statements downloaded directly from your online bank account,
              which you can upload under the banking section below.
            </small>
            <br />
            <div style={{ height: "5px" }} />
            <div style={{ height: "5px" }} />
            <small
              className="btn btn-link small"
              onClick={() => setDisplayContent(5)}
            >
              Have non-employment income?
            </small>
            <small className={`mb-2 ${displayContent === 5 ? "" : "d-none"}`}>
              <br />
              If you wish to upload proof of non-employment income, you can
              upload relevant documents in the “Proof of Non-Employment Income”
              section below.
            </small>
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
