import * as React from "react";
import {
  useListContext,
  useGetOne,
  useGetIdentity,
} from "react-admin";
import { useForm } from "react-hook-form";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { JSONTree } from "react-json-tree";


export default function MainGlobalSearchFilters(props) {
  const { id } = useParams();
  const { data: identity } = useGetIdentity();
  const submitBtnRef = React.useRef<HTMLButtonElement>(null);
  const { data, isLoading, error } = useGetOne("getfilterforinbox", {
    id: id || identity?.subscriberid,
  });

  const { displayedFilters, filterValues, setFilters, hideFilter } =
    useListContext();
  // get user role from  global store
 
  const form = useForm({
    defaultValues: filterValues,
  });

  // if (!displayedFilters.main) return null;
  const applyFilter = (values) => {
    if (Object.keys(values).length > 0) {
      const filter = {
        ...values,
        end_date: values.end_date
          ? moment(values.end_date).format("MM/DD/yyyy")
          : "",
        start_date: values.start_date
          ? moment(values.start_date).format("MM/DD/yyyy")
          : "",
      };
      setFilters(filter, displayedFilters);
    }
  };
  
  // React.useEffect(() => {
  //   if(filterValues?.q){
  //     form.setValue('q',filterValues?.q);
  //   }
  // },[filterValues?.q])

  return (
    <>
      <div className="app-option-btn" id="a-oprtions">
      { (
          <label htmlFor="c0">
            <input
              type="radio"
              onChange={() => {
                form.setValue("app_invitation_type", "");
                applyFilter({...filterValues, ...form.getValues(),});
              }}
              checked={
                !filterValues.app_invitation_type ||
                filterValues.app_invitation_type == ""
              }
              name="a-oprtions"
              id="c0"
            />
            <span>All</span>
          </label>
        )}
        { (
          <label htmlFor="c1">
            <input
              type="radio"
              onChange={() => {
                form.setValue("app_invitation_type", "1");
                applyFilter({...filterValues, ...form.getValues(),});
              }}
              checked={
                filterValues.app_invitation_type == 1
              }
              name="a-oprtions"
              id="c1"
            />
            <span>Application</span>
          </label>
        )}
        { (
          <label htmlFor="c2">
            <input
              type="radio"
              onChange={() => {
                form.setValue("app_invitation_type", "2");
                applyFilter({...filterValues, ...form.getValues(),});
              }}
              checked={filterValues.app_invitation_type == 2}
              name="a-oprtions"
              id="c2"
            />
            <span>Doc Upload</span>
          </label>
        )}
        { (
          <label htmlFor="c3">
            <input
              type="radio"
              onChange={() => {
                form.setValue("app_invitation_type", "3");
                applyFilter({...filterValues, ...form.getValues(),});
              }}
              checked={filterValues.app_invitation_type == 3}
              name="a-oprtions"
              id="c3"
            />
            <span>Guest Card</span>
          </label>
        )}
      </div>
    </>
  );
}
