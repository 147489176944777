import React from "react";
import { Button, useGetList, useGetOne } from "react-admin";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import parse from "html-react-parser";

const TermsOfUse = (props) => {
  const { data, isLoading: isLoadingData } = useGetOne("gettermsofuse", {
    id: 1,
  });

  return (
    <div
      className="container"
      style={{
        marginTop: "50px",
      }}
    >
      <Card>
       
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            <b>Terms Of Use</b>
         
          </Typography>
          <hr />
          {data?.data?.Description ? parse(data?.data?.Description) : ""}
        </CardContent>
      </Card>
    
    </div>
  );
};

export default TermsOfUse;
