import React, { useEffect } from 'react'
import {
  useParams,
  useNavigate
} from "react-router-dom";
import { Resource, useStore } from 'react-admin';
import { GetsubscriberinboxList } from '../../componets/Ats/GetsubscriberinboxList';
import ApplicantDetailEdit from '../../componets/Ats/ApplicantDetailEdit';
import { GetAdmininboxList } from '../../componets/Ats/SuperAdminInbox/GetAdmininboxList';
import GetAdminGlobalSearchList from '../../componets/Ats/SuperAdminInbox/GetAdmininboxGlobalList';

// const LastThrityDay=(props)=>{
//   return <GetAdmininboxList  dashboardOption={3} />
// }

/**
 * 
 * @param dashboardOption
 * This options used to decide filter 
 * @returns 
 */
export default function InboxAdmin({dashboardOption=1}) {
  // if(dashboardOption == 2)
  return (
    <Resource name='getAdminAllinboxType' list={<GetAdmininboxList />} />
  )
  // else if(dashboardOption == 3){
  //   return (
  //     <Resource name='getlastthirtydaysqueue' list={<GetAdmininboxList30Ats/>} />
  //   )
  // }

  // return (
  //   <Resource name='getadmininbox' list={<GetAdmininboxList30doc />} />
  // )
}

// export  function InboxAdmin1() {
//   return (
//     <Resource name='getlastthirtydaysqueue' list={<GetAdmininboxList30Ats />} />
//   )
// }

// export  function InboxAdmin2() {
//     return (
//       <Resource name='getlastthirtydaysqueue' list={<GetAdmininboxList30doc/>} />
//     )
//   }

//   export  function InboxAdmin3() {
//     return (
//       <Resource name='getatsqueue' list={<GetAdmininboxList30Ats/>} />
//     )
//   }


  export function Getglobalsearch(){
    return (
      <Resource name='getglobalsearch' list={<GetAdminGlobalSearchList/>} />
    )
  }