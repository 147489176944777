import moment from "moment";
import React from "react";
import { JSONTree } from "react-json-tree";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";

const DocumentView = () => {
  const location = useLocation();
  const state = location.state;
  const navigate = useNavigate();

  // function for docs UI looping
  const DocumentRenderUI = ({ heading, docsData }) => {
    return (
      <div
        style={{
          marginBottom:
            !state?.showHeading || docsData?.length ? "10px" : "0px",
        }}
      >
        <center>
          {(!state?.showHeading || docsData?.length) > 0 && (
            <div
              style={{
                height: "40px",
                width: "100%",
                verticalAlign: "center",
                backgroundColor: "#eeeeee",
                display: "flex",
                alignItems: "center",
                paddingLeft: "10px",
              }}
            >
              <h2
                style={{
                  fontSize: "25px",
                  lineHeight: "28px",
                  fontFamily: "OpenSans-Bold",
                  color: "#909090",
                  textAlign: "start",
                  // height: "40px",
                  // width: "100%",
                  verticalAlign: "center",
                }}
              >
                {heading}
              </h2>
            </div>
          )}
          {docsData?.map((item) => {
            return (
              <embed
                src={item?.BlobPathFile}
                width="800"
                height="500"
                key={item?.DocumentTypeID}
                className="mw-100"
              />
            );
          })}
        </center>
      </div>
    );
  };

  return (
    <>
      <div className="well with-header">
        <div
          className="header bordered-themeprimary"
          style={{ fontSize: "x-large" }}
        >
          <button
            title="Go Back"
            style={{ color: "#2cc0e4" }}
            className="btn btn-link"
            onClick={() => navigate(-1)}
          >
            <ArrowBack style={{ color: "#303030" }} />
          </button>
          Application Details
        </div>

        <div className="row">
          <div className="col-lg-6 col-sm-6 col-xs-12">
            <div className="row app_detail">
              <div
                className="col-lg-3 col-sm-3 col-xs-12"
                style={{ width: "35%" }}
              >
                <label className="control-label documentHeader">
                  Application ID
                </label>
              </div>
              <div
                className="col-lg-3 col-sm-3 col-xs-12"
                style={{ width: "auto" }}
              >
                <span className="control-label documentHeader text-primary">
                  {state?.record?.AppID ? state?.record?.AppID : "--"}
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-6 col-xs-12">
            <div className="row app_detail">
              <div
                className="col-lg-3 col-sm-3 col-xs-12"
                style={{ width: "35%" }}
              >
                <label className="control-label documentHeader">
                  Application Date
                </label>
              </div>
              <div
                className="col-lg-3 col-sm-3 col-xs-12"
                style={{ width: "auto" }}
              >
                <span className="control-label documentHeader text-primary">
                  {state?.record?.creataed_on
                    ? moment(state?.record?.creataed_on).format("MM/DD/yyyy")
                    : "--"}
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-6 col-xs-12">
            <div className="row app_detail">
              <div
                className="col-lg-3 col-sm-3 col-xs-12"
                style={{ width: "35%" }}
              >
                <label className="control-label documentHeader">
                  Applicant Name
                </label>
              </div>
              <div
                className="col-lg-3 col-sm-3 col-xs-12"
                style={{ width: "auto" }}
              >
                <span className="control-label documentHeader text-primary">
                  {`${
                    state?.record?.FirstName && state?.record?.LastName
                      ? `${state?.record?.FirstName} ${state?.record?.LastName}`
                      : "--"
                  }`}
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-6 col-xs-12">
            <div className="row app_detail">
              <div
                className="col-lg-3 col-sm-3 col-xs-12"
                style={{ width: "35%" }}
              >
                <label className="control-label documentHeader">
                  Applicant Email
                </label>
              </div>
              <div
                className="col-lg-3 col-sm-3 col-xs-12"
                style={{ width: "auto" }}
              >
                <span className="control-label documentHeader text-primary">
                  {state?.record?.Email ? state?.record?.Email : "--"}
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-6 col-xs-12">
            <div className="row app_detail">
              <div
                className="col-lg-3 col-sm-3 col-xs-12"
                style={{ width: "35%" }}
              >
                <label className="control-label documentHeader">
                  Subscriber Name
                </label>
              </div>
              <div
                className="col-lg-3 col-sm-3 col-xs-12"
                style={{ width: "auto" }}
              >
                <span className="control-label documentHeader text-primary">
                  {state?.record?.SubscriberName
                    ? state?.record?.SubscriberName
                    : "--"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {state?.getalldocs && state?.getalldocs?.length > 0 ? (
        <>
          <DocumentRenderUI
            heading="Proof of Identity"
            docsData={state?.getalldocs?.filter(
              (item) =>
                item.DocumentType === "License" ||
                item.DocumentType === "Barcode" ||
                item.DocumentType === "Passport" ||
                item.DocumentType === "PermanentResidentID" ||
                item.DocumentType === "Visa"
            )}
          />
          <DocumentRenderUI
            heading="Proof of SSN"
            docsData={state?.getalldocs?.filter(
              (item) => item.DocumentType === "SSN"
            )}
          />
          <DocumentRenderUI
            heading="Pet Document"
            docsData={state?.getalldocs?.filter(
              (item) => item.DocumentType === "Pet"
            )}
          />
          <DocumentRenderUI
            heading="Proof of Income"
            docsData={state?.getalldocs?.filter(
              (item) => item.DocumentType === "Paystub"
            )}
          />
          <DocumentRenderUI
            heading="Other Income"
            docsData={state?.getalldocs?.filter(
              (item) => item.DocumentType === "OtherIncome"
            )}
          />
          <DocumentRenderUI
            heading="Banking Information"
            docsData={state?.getalldocs?.filter(
              (item) => item.DocumentType === "Bank Statement"
            )}
          />
          <DocumentRenderUI
            heading="Other"
            docsData={state?.getalldocs?.filter(
              (item) => item.DocumentType === "Other"
            )}
          />
        </>
      ) : (
        "No Document Found"
      )}
    </>
  );
};

export default DocumentView;
