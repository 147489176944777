import React, { useState } from "react";
import { Toolbar } from "react-admin";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { JsonToTable } from "react-json-to-table";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  FormLabel,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Backdrop,
  Typography,
  Button as MuiButton,
} from "@mui/material";

function FrontBackForIds({ title, data }) {
  const [showDialog, setShowDialog] = useState(false);
  const handleClick = () => {
    setShowDialog(true);
  };
  const handleCloseClick = () => {
    setShowDialog(false);
  };
  const properties = [
    "license_id",
    "first_name",
    "middle_name",
    "last_name",
    "address",
    "dob",
    "city",
    "state",
    "postal_code",
    "doc_state_type",
    "issue_date",
    "expiration_date",
  ];
  function capitalizeFirstLetter(str) {
    return str.replace(/\b\w/g, (match) => match.toUpperCase());
  }

  function isDifferent(frontValue, backValue) {
    // Check if either front or back value is blank
    if (!frontValue || !backValue) {
      return false;
    }
    
    // Check if both values are in different cases
    if (
      (frontValue.toUpperCase() === backValue.toUpperCase() && frontValue !== backValue) ||
      (frontValue.toLowerCase() === backValue.toLowerCase() && frontValue !== backValue)
    ) {
      return false;
    }
  
    return frontValue !== backValue;
  }
  
  return (
    <>
      <div>
        <div className="new-wrp-tooltip">
          <a
            href="#"
            className="btn btn-link"
            style={{
              fontFamily: "OpenSans-Bold",
              textDecoration: "none",
              color: "#2dc3e8",
              textAlign: "center",
            }}
            onClick={(e) => {
              e.preventDefault();
              handleClick();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="currentColor"
              className="bi bi-table"
              viewBox="0 0 16 16"
            >
              <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z" />
            </svg>{" "}
            {title}
          </a>
        </div>
      </div>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create post"
        PaperProps={{
          style: { borderRadius: 20 },
        }}
      >
        <div className="modal-header p-3 px-4">
          <h5 className="modal-title " id="emailchange">
            {title}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => handleCloseClick()}
          ></button>
        </div>

        <DialogContent>
          <div>
            {/* <JsonToTable json={data} /> */}
            <TableContainer>
              <Table>
                <TableHead
                  style={{
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Front OCR</TableCell>
                    <TableCell>Back OCR</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {properties?.map((property, index) => (
                    <TableRow
                      key={index}
                      style={{
                        backgroundColor: isDifferent(
                          data?.frontOCR[property],
                          data?.backOCR[property]
                        )
                          ? "#FFF8E2"
                          : "transparent",
                      }}
                    >
                      <TableCell
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {capitalizeFirstLetter(property.replace(/_/g, " "))}
                      </TableCell>
                      <TableCell>{data?.frontOCR[property]}</TableCell>
                      <TableCell>{data?.backOCR[property]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default FrontBackForIds;

function PostShowRawDataToolbar({ onCopy, onCancel, ...props }) {
  return (
    <Toolbar
      {...props}
      style={{
        backgroundColor: "white",
        justifyContent: "flex-end",
      }}
    >
      <CancelButton onClick={onCancel} />
    </Toolbar>
  );
}

function CancelButton(props) {
  return (
    <button
      type="button"
      className=" cancelbtn"
      {...props}
      // data-bs-dismiss="modal"
    >
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.99999 4.05734L8.29999 0.757339L9.24266 1.70001L5.94266 5.00001L9.24266 8.30001L8.29999 9.24267L4.99999 5.94267L1.69999 9.24267L0.757324 8.30001L4.05732 5.00001L0.757324 1.70001L1.69999 0.757339L4.99999 4.05734Z"
          fill="#2DC3E8"
        />
      </svg>

      <span>Cancel</span>
    </button>
  );
}
