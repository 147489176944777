import React, { useState } from "react";
import { useFormContext, useController } from "react-hook-form";

import * as Yup from "yup";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { yupResolver } from "@hookform/resolvers/yup";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import Dialog from "@mui/material/Dialog";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Button } from "@mui/material";
import InputTextArea from "./InputTextArea";
import InputCheckboxGroupField from "./InputCheckboxGroupField";

const InputFieldButtonGroup = ({ source, notRequired = false }) => {
  const { control, setValue, watch } = useFormContext();
  const {
    fieldState: { error },
  } = useController({
    name: source,
    control,
  });
  const checkboxValues = [
    {
      ID: "1",
      Reason: "Income to Rent Ratio",
      Selected: false,
    },
    {
      ID: "2",
      Reason: "Over Income Limit",
      Selected: false,
    },
    {
      ID: "3",
      Reason: "Report Score / Credit",
      Selected: false,
    },
    {
      ID: "4",
      Reason: "Landlord-Tenant Filing",
      Selected: false,
    },
    {
      ID: "5",
      Reason: "Employment History or Unverifiable Income",
      Selected: false,
    },
    {
      ID: "6",
      Reason: "Insufficient or Unsatisfactory Residency History",
      Selected: false,
    },
    {
      ID: "7",
      Reason: "Too many occupants",
      Selected: false,
    },
    {
      ID: "8",
      Reason:
        "Bankruptcy, Foreclosure action, Tax Lien, or other civil judgment",
      Selected: false,
    },
    {
      ID: "9",
      Reason: "Other",
      Selected: false,
    },
    {
      ID: "10",
      Reason: "Unacceptable criminal report",
      Selected: false,
    },
  ];
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [reject, setReject] = useState(false);
  const [approveWithCondition, setapproveWithCondition] = useState(false);
  const [openRentalDecision, setOpenRentalDecision] = useState(false);

  const [decisionXML, setDecisionXML] = useState(checkboxValues);

  const handleApproveClick = () => {
    setConfirmDelete(true);
  };
  const handleCancel = () => {
    setConfirmDelete(false);
  };

  const handleRentalDecision = () => {
    setOpenRentalDecision(true);
  };

  const handleRejectClick = () => {
    setReject(true);
  };
  const handleCancelReject = () => {
    setReject(false);
  };

  const validationSchemaReject = Yup.object().shape({
    DecisionXML: Yup.array().min(1, "Select at least 1 reason"),
  });
  const validationSchemaApproveCondition = Yup.object().shape({
    LandlordStatusReason: Yup.string()
      .required("Condition is required")
      .max(5000, "exceed max length"), // Assuming textareaName is the name of your textarea
    DecisionXML: Yup.array().min(1, "Select at least 1 reason"),
  });

  const handleApproveConditionClick = () => {
    setapproveWithCondition(true);
  };
  const handleApproveConditionCancel = () => {
    setapproveWithCondition(false);
  };

  const approvemethods = useForm();
  const rejectmethods = useForm({
    resolver: yupResolver(validationSchemaReject), // Pass validationSchema to useForm
  });
  const ApproveWithConditionmethods = useForm({
    resolver: yupResolver(validationSchemaApproveCondition), // Pass validationSchema to useForm
  });

  const rejectSubmit = (formValues) => {
    handleCancelReject();
    const filteredValues = checkboxValues?.map((item) => {
      // return item;
      const foundData = formValues?.DecisionXML?.findIndex(
        (i) => i == item?.ID
      );
      //   debugger;
      if (foundData != -1)
        return {
          ...item,
          Selected: true,
        };
      return item;
    });
    setValue(
      source,
      {
        ...formValues,
        DecisionXML: filteredValues,
        LandlordStatus: "Rejected",
      },
      { shouldValidate: true }
    );
  };

  const approveSubmit = (formValues) => {
    handleCancel();
    setValue(source, { LandlordStatus: "Approved" }, { shouldValidate: true });
  };

  const approvedWithConditionSubmit = (formValues) => {
    handleApproveConditionCancel();
    const filteredValues = checkboxValues?.map((item) => {
      const foundData = formValues?.DecisionXML?.findIndex(
        (i) => i == item?.ID
      );

      if (foundData != -1)
        return {
          ...item,
          Selected: true,
        };
      return item;
    });
    setValue(
      source,
      {
        ...formValues,
        DecisionXML: filteredValues,
        LandlordStatus: "Approved with Conditions",
      },
      { shouldValidate: true }
    );
  };

  return (
    <>
      {/* confirm delete popup */}

      <Dialog
        open={confirmDelete}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{ m: 0, p: 1, backgroundColor: "Gainsboro" }}
          id="customized-dialog-title"
        >
          Approve
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCancel}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent style={{ width: "600px" }}>
          <FormProvider {...approvemethods}>
            <form onSubmit={approvemethods.handleSubmit(approveSubmit)}>
              <p style={{ textAlign: "center" }}>
                Are you sure you want to approve application?
              </p>

              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Button
                  sx={{ mx: 1 }}
                  variant="contained"
                  type="submit"
                  onClick={() => {
                    handleRentalDecision();
                  }}
                >
                  Confirm
                </Button>
                <Button
                  sx={{ mx: 1 }}
                  onClick={() => {
                    handleCancel();
                  }}
                  variant="contained"
                  type="button"
                >
                  Cancel
                </Button>
              </Box>
            </form>
          </FormProvider>
        </DialogContent>
      </Dialog>
      {/* Reject popup */}

      <Dialog
        open={reject}
        onClose={handleCancelReject}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{ m: 0, p: 1, backgroundColor: "Gainsboro" }}
          id="customized-dialog-title"
        >
          Unacceptable
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCancelReject}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent style={{ width: "600px" }}>
          <FormProvider {...rejectmethods}>
            <form onSubmit={rejectmethods.handleSubmit(rejectSubmit)}>
              <p>
                <b>Reason(s):</b>
              </p>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <InputCheckboxGroupField
                  source={"DecisionXML"}
                  options={checkboxValues.map((item) => ({
                    label: item.Reason,
                    value: item.ID,
                  }))}
                />
              </div>

              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <button
                  type="submit"
                  className="blue-btn px-4 mx-2"
                  style={{
                    height: "40px",
                  }}
                >
                  {" "}
                  <svg
                    className="mr-r10"
                    width="12"
                    height="9"
                    viewBox="0 0 12 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.66666 6.61463L10.7947 0.485962L11.738 1.42863L4.66666 8.49996L0.423996 4.2573L1.36666 3.31463L4.66666 6.61463Z"
                      fill="white"
                    />
                  </svg>
                  &nbsp;Confirm
                </button>
              </Box>
            </form>
          </FormProvider>
        </DialogContent>
      </Dialog>
      {/* approve with conditions */}
    

<Dialog
        open={approveWithCondition}
        onClose={handleApproveConditionCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{ m: 0, p: 1, backgroundColor: "Gainsboro" }}
          id="customized-dialog-title"
        >
          Approve with Condition
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleApproveConditionCancel}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
 
        <DialogContent style={{ width: "600px" }}>
          <FormProvider {...ApproveWithConditionmethods}>
            <form
 
            >
              <div className="emp-inputs">
                <InputTextArea
                  register={"LandlordStatusReason"}
                  label="Conditions"
                  placeholder="Enter conditions"
                  labelClass={"lable-title"}
                  labelAsteriskClass={"color-red"}
                  additionalInputClass={"cus-control"}
                  styleProperty={{ height: "auto" }}
                  messageText={false}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                />
                <em
                  className="d-block"
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "19px",
                    textAlign: "right",
                    color: "#B4B4B4",
                  }}
                >
                  Max 5000 Characters
                </em>
              </div>
 
              <br />
              <br />
 
              <p>
                <b>Reason(s):</b>
              </p>
 
              <div style={{ display: "flex", flexDirection: "column" }}>
                <InputCheckboxGroupField
                  source={"DecisionXML"}
                  options={checkboxValues.map((item) => ({
                    label: item.Reason,
                    value: item.ID,
                  }))}
                />
              </div>
 
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <button
                  type="button"
                  className="blue-btn px-4 mx-2"
                  style={{
                    height: "40px",
                  }}
                  onClick={(event) => {
                    event.preventDefault(); // Prevent default form submission
                    ApproveWithConditionmethods.handleSubmit(approvedWithConditionSubmit)(event);
                  }}
                >
                  {" "}
                  <svg
                    className="mr-r10"
                    width="12"
                    height="9"
                    viewBox="0 0 12 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.66666 6.61463L10.7947 0.485962L11.738 1.42863L4.66666 8.49996L0.423996 4.2573L1.36666 3.31463L4.66666 6.61463Z"
                      fill="white"
                    />
                  </svg>
                  &nbsp;Confirm
                </button>
              </Box>
            </form>
          </FormProvider>
        </DialogContent>
      </Dialog>

      {/* rental popup */}
      <div className="emp-inputs form-label">
        <div className="app-option-btn my-4" id="a-oprtions">
          <label htmlFor="Approved">
            <input
              type="radio"
              onClick={() => {
                handleApproveClick();
              }}
              name="a-oprtions"
              id="Approved"
            />
            <span
              style={{
                width: "230px",
                height: "40px",
              }}
            >
              Approve
            </span>
          </label>
          <label htmlFor="Approved with Conditions">
            <input
              type="radio"
              onClick={() => {
                handleApproveConditionClick();
              }}
              name="a-oprtions"
              id="Approved with Conditions"
            />
            <span
              style={{
                width: "230px",
                height: "40px",
              }}
            >
              Approve with conditions
            </span>
          </label>
          <label htmlFor="Rejected">
            <input
              type="radio"
              onClick={() => {
                handleRejectClick();
              }}
              name="a-oprtions"
              id="Rejected"
            />
            <span
              style={{
                width: "230px",
                height: "40px",
              }}
            >
              Reject
            </span>
          </label>
        </div>
        <div
          className="invalid-feedback"
          style={{ display: "block", fontSize: "70%" }}
        >
          {error !== undefined && error.message}
        </div>
      </div>
    </>
  );
};

export default InputFieldButtonGroup;
