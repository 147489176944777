import React, { Fragment, useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate } from "react-router";
import { ArrowBack } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import InputField2 from "../../common/InputComponents/InputField2";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import ToggleButtons from "../../common/InputComponents/ToggleButtons";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Button } from "@mui/material";
import save_icon from "../../../assets/images/save_icon.svg";
import TextEditor from "../../common/InputComponents/TextEditor";

const TemplateDetails = () => {
  const navigate = useNavigate();

  const toggleButton = [
    {
      value: "Inactive",
      label: "Inactive",
      icon: <ClearIcon />,
      borderColor: "#909090",
      backgroundColor: "#EEE",
      boxShadowColor: "#90909033",
      color: "#606060",
    },
    {
      value: "Active",
      label: "Active",
      icon: <CheckIcon />,
      borderColor: "#46A96F",
      backgroundColor: "#EBFFF3",
      boxShadowColor: "#46a96f1a",
      color: "#46A96F",
    },
  ];

  const schema = yup.object().shape({
    template_task: yup.string().required("required"),
    send_to: yup.string().required("required"),
    status: yup.string().required("required"),
    subject: yup.string().required("required"),
    body: yup.string().required("required"),
  });

  // Initial form values
  const defaultFormValues = {
    template_task: "",
    send_to: "",
    status: "",
    subject: "",
    body: "",
  };

  const methods = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  useEffect(() => {
    document.title = "Template Details";
  }, []);

  const onSubmit = (data) => {
    console.log(data);
  };
  return (
    <Fragment>
      <div className="home_content">
        <div className="row">
          <div className="col-12 d-flex">
            <Breadcrumbs aria-label="breadcrumb">
              <Typography sx={{ fontWeight: "bold" }}>
                <button
                  title="Go Back"
                  style={{ color: "#2cc0e4" }}
                  className="btn btn-link"
                  onClick={() => navigate("/ats/emailSettings")}
                >
                  <ArrowBack />
                </button>
                {/* <span>Email Settings &gt; Template Details</span> */}
                <span>Template Details</span>
              </Typography>
            </Breadcrumbs>
          </div>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} className="mr-t30">
            <div className="row">
              <div className="col-sm-12 col-md-3 col-lg-3 mr-b20">
                <InputField2
                  label="Template Task"
                  source={`template_task`}
                  placeholder="Email sent to applicant upon app completion"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                />
              </div>
              <div className="col-sm-12 col-md-3 col-lg-3 mr-b20">
                <InputField2
                  label="Send to"
                  source={`send_to`}
                  placeholder="Applicant"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                />
              </div>
              <div className="col-sm-12 col-md-3 col-lg-3 mr-b20">
                <ToggleButtons
                  toggleButton={toggleButton}
                  label="Status"
                  labelClass={"lable-title"}
                  source={`status`}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 mr-b20">
                <InputField2
                  label="Subject"
                  source={`subject`}
                  placeholder="Applicant {applicant.name} has completed the application"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 mr-b20">
                <TextEditor
                  label="Body"
                  source={`body`}
                  labelClass={"lable-title"}
                />
              </div>
            </div>
            <Box
              sx={{
                height: "60px",
                background: "#F5FDFF",
                boxShadow: "0px -1px 12px 0px rgba(0, 0, 0, 0.12)",
              }}
              display={"flex"}
              justifyContent={"end"}
              alignItems={"center"}
            >
              <Box>
                <Button
                  sx={{ mx: 1 }}
                  onClick={() => {
                    methods?.reset();
                  }}
                  variant="outlined"
                  color="secondary"
                  type="button"
                >
                  Cancel
                </Button>
                <Button
                  startIcon={<img src={save_icon} alt="save icon" />}
                  sx={{ mx: 1 }}
                  variant="contained"
                  type="submit"
                  // disabled={isLoading}
                >
                  Update
                </Button>
              </Box>
            </Box>
          </form>
        </FormProvider>
      </div>
    </Fragment>
  );
};

export default TemplateDetails;
